import InputForm from "@components/HRIS/InputForm";
import {
  postOrganizationRequest,
  putOrganizationRequest,
  resetOrganization
} from "@stores/HRIS/OrganizationSlice";
import { useAppDispatch, useAppSelector } from "@stores/store";
import * as R from "ramda";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { IHRISStore, IPayloadAdd } from "@typed/HRIS/allHris";
import { Formik } from "formik";
import { Button } from "antd";

const AddOrganization = () => {
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      data.filter(
        (item) =>
          item._id === id &&
          // setFieldValue("name: item.name, description: item.description)
          setAddOrg({ ...addOrg, name: item.name, description: item.description })
      );
    }
  }, [id]);
  const navigate = useNavigate();
  const [addOrg, setAddOrg] = useState<IPayloadAdd>({} as IPayloadAdd);
  const { name, description } = addOrg;
  const dispatch = useAppDispatch();
  const { lastCreateData, isLoading, data } = useAppSelector(
    (state: { hrisOrganization: IHRISStore }) => state.hrisOrganization
  );

  useEffect(() => {
    if (!R.isEmpty(lastCreateData)) {
      dispatch(resetOrganization());
      setTimeout(() => {
        navigate("/hris/organization");
      }, 600);
    }
  }, [lastCreateData]);

  return (
    <>
      <Toaster />
      <div className="flex items-center justify-center">
        <div className="bg-white w-[45%] shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <Formik
            initialValues={{ name: "", description: "" }}
            onSubmit={(values) => {
              //   onSubmit;
              const { name, description } = values;
              const Data = {
                name,
                description
              };
              if (id) {
                dispatch(putOrganizationRequest({ id, Data }));
              } else {
                dispatch(postOrganizationRequest(Data));
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue
              /* and other goodies */
            }) => {
              useEffect(() => {
                if (id) {
                  data.filter(
                    (item: any) =>
                      item._id === id &&
                      (setFieldValue("name", item.name),
                      setFieldValue("description", item.description))
                  );
                }
              }, []);

              return (
                <div>
                  <form className="flex flex-col gap-3" onSubmit={handleSubmit}>
                    <h1 className="text-center text-[1.1rem]">
                      {id ? "Update " : "Create "} Organization
                    </h1>
                    <div>
                      <label className="block text-gray-700 text-sm font-bold" htmlFor="username">
                        Name
                      </label>
                      <input
                        placeholder="Name"
                        name="name"
                        className="border border-slate-400 w-full h-8 rounded px-3"
                        type="text"
                        onChange={handleChange}
                        value={values.name}
                      />
                      {errors.description}
                    </div>
                    <div>
                      <label className="block text-gray-700 text-sm font-bold ">Description</label>
                      <input
                        placeholder="Description"
                        type="text"
                        name="description"
                        className="border border-slate-400 w-full h-8 rounded px-3"
                        onChange={handleChange}
                        value={values.description}
                      />
                    </div>

                    <input
                      className={
                        isLoading
                          ? "cursor-not-allowed flex gap-2 items-center justify-center bg-blue-500 active:bg-blue-400 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                          : "flex gap-2 items-center justify-center bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                      }
                      type="Submit"
                    />
                  </form>
                </div>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default AddOrganization;
