import API from "@constants/API";
import { IPayloadHRIS } from "@typed/HRIS/allHris";
const BASE_URL = "https://api.dev.daily.co.id/hris/level/dashboard/v1/level";

export const getLevelAPI = async () => {
  const response = await API.HTTP.get(BASE_URL + "s");
  return response.data;
};

export const getDetailLevelAPI = async (id: string) => {
  const response = await API.HTTP.get(`${BASE_URL}/${id}`);
  return response.data;
};

export const postLevelAPI = async (Data: object) => {
  const response = await API.HTTP.post(BASE_URL, Data);
  return response.data;
};

export const putLevelAPI = async ({ id, Data }: IPayloadHRIS) => {
  const response = await API.HTTP.put(`${BASE_URL}/${id}`, Data);
  return response.data;
};

export const putActiveLevelAPI = async (id: string, isActive: boolean) => {
  const Data = {
    isActived: isActive ? false : true
  };
  const response = await API.HTTP.put(`${BASE_URL}/${id}`, Data);
  return response.data;
};

export const putDeletedLevelAPI = async (id: string, isDelete: boolean) => {
  const Data = {
    isDeleted: isDelete === false && true
  };
  const response = await API.HTTP.put(`${BASE_URL}/${id}`, Data);
  return response.data;
};
