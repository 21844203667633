import React, { useEffect } from "react";
import { nf } from "@helpers/utils";
import { Table } from "antd";
import { useAppSelector, useAppDispatch } from "@stores/store";
import { getReportTopupRequest } from "@stores/dailycoin/ReportingSlice";

import DataTable from "react-data-table-component";

const columns = [
  {
    name: "Date",
    selector: (row: any) => row._id
  },
  {
    name: "Transaction",
    selector: (row: any) => row.transaction
  },
  {
    name: "Topup",
    key: "topup",
    selector: (row: any) => row.topup,
    cell: (row: any) => <div>{nf.format(row.topup)}</div>
  },
  {
    name: "Cashback",
    selector: (row: any) => row.cashback,
    cell: (row: any) => <div>{nf.format(row.cashback)}</div>
  },
  {
    name: "Dailycoin Created",
    selector: (row: any) => row.dailycoinCreated,
    render: (row: any) => <div>{nf.format(row.dailycoinCreated)}</div>
  }
];

interface IProps {
  startDate: string;
  endDate: string;
  dataCSV: any[] | [];
}

const TopupReport: React.FC<IProps> = ({ dataCSV, startDate, endDate }) => {
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector((state) => state.dailyReport);
  const reportTopup = useAppSelector((state: { dailyReport: { reportTopup: any[] } }) =>
    state.dailyReport.reportTopup.map((i) => ({ ...i, key: i._id }))
  );

  return (
    <>
      <div>
        {/* <Table loading={isLoading} dataSource={reportTopup} columns={columns} /> */}
        <DataTable
          data={reportTopup}
          columns={columns}
          pagination
          persistTableHead
          // actions={actionMemo}
          // subHeaderComponent={subHeaderComponentMemo}
        />
      </div>
    </>
  );
};

export default TopupReport;
