import { dateToString, flattenObject } from "@helpers/utils";
import { Descriptions } from "antd";
import React, { useEffect, useState } from "react";
import moment from "moment";

export const checkAndFormatField = (field: any) => {
  const type = typeof field;

  switch (type) {
    case "string":
      if (
        (field as string).match(
          /^\d{4}-(?:0[1-9]|1[0-2])-(?:[0-2][1-9]|[1-3]0|3[01])T(?:[0-1][0-9]|2[0-3])(?::[0-6]\d)(?::[0-6]\d)?(?:\.\d{3})?(?:[+-][0-2]\d:[0-5]\d|Z)?$/
        )
      ) {
        return moment(field).format("MMMM Do YYYY, h:mm");
      } else if ((field as string).match(/(https?:\/\/[^\s]+)/g)) {
        return (
          <a href={field} target={"_blank"} rel="noreferrer">
            View image
          </a>
        );
      } else if (Number.isNaN(field)) {
        return <h1 className="font-medium bold text-red-500">--</h1>;
      } else {
        return field;
      }
    case "undefined":
      return <h1 className="font-medium bold text-red-500">--</h1>;
    case "object":
      return <h1 className="font-medium bold text-red-500">--</h1>;
    case "boolean":
      return field ? "true" : "false";
    default:
      return field;
  }
};

const Detail = ({
  data,
  select,
  title,
  extra
}: {
  data: any;
  select?: any[];
  title: string | React.ReactNode;
  extra: React.ReactNode;
}) => {
  const [formateddata, setFormatedData] = useState();

  useEffect(() => {
    setFormatedData(() => {
      let formated: any = {};
      const rest = data;
      if (select) {
        select.forEach((v, i, arr) => {
          if (typeof v === "object") {
            formated[v.label] = rest[v.value];
          } else {
            formated[v] = rest[v];
          }
        });
      } else {
        formated = rest;
      }
      return formated;
    });
  }, [data]);

  return (
    <>
      {formateddata && (
        <Descriptions bordered size="small" title={title} extra={extra}>
          <>
            {(Object.keys(formateddata) as (keyof typeof formateddata)[]).map((key, index) => (
              <Descriptions.Item label={key as string} key={index}>
                <div className="font-medium bold">{checkAndFormatField(formateddata[key])}</div>
              </Descriptions.Item>
            ))}
          </>
        </Descriptions>
      )}
    </>
  );
};

export default Detail;
