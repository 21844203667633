import API from "@constants/API";

const BASE_URL = "https://api.dev.daily.co.id/hris/organization/dashboard/v1/organization";

export const getOrganizationAPI = async () => {
  const response = await API.HTTP.get(BASE_URL + "s");
  return response.data;
};

export const getDetailOrganizationAPI = async (id: string) => {
  const response = await API.HTTP.get(`${BASE_URL}/${id}`);

  return response.data;
};

export const postOrganizationAPI = async (Data: object) => {
  const response = await API.HTTP.post(BASE_URL, Data);

  return response.data;
};

export const putOrganizationAPI = async ({ id, Data }: { id: string; Data: object }) => {
  const response = await API.HTTP.put(`${BASE_URL}/${id}`, Data);

  return response.data;
};

export const putActiveOrganizationAPI = async (id: string, isActive: boolean) => {
  const Data = {
    isActived: isActive ? false : true
  };
  const response = await API.HTTP.put(`${BASE_URL}/${id}`, Data);
  return response.data;
};

export const putDeletedOrganizationAPI = async (id: string, isDelete: boolean) => {
  const Data = {
    isDeleted: isDelete === false && true
  };
  const response = await API.HTTP.put(`${BASE_URL}/${id}`, Data);
  return response.data;
};
