import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { IBranchStore } from "@typed/HRIS/Branch";
import { getBranchAPI } from "./BranchAPI";

export const getBranchRequest = createAsyncThunk("hris-branch/getBranchRequest", async () => {
  const response = await getBranchAPI();
  return response;
});

const initialState: IBranchStore = {
  Branch: [],
  isLoading: false
};

const BranchSlice = createSlice({
  name: "hris-branch",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getBranchRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getBranchRequest.fulfilled, (state, action) => {
      if (action.payload.code === 1000) {
        state.Branch = action.payload.data;
      }
      state.isLoading = false;
    });
    builder.addCase(getBranchRequest.rejected, (state) => {
      state.isLoading = false;
    });
  }
});

export default BranchSlice.reducer;
