import React from "react";
import { Descriptions, Tag } from "antd";
import { useAppSelector } from "@stores/store";
import { Link, useParams } from "react-router-dom";
import { dateToString } from "@helpers/utils";
import { IoStar } from "react-icons/io5";

const DetailReview = () => {
  const { id } = useParams();
  const reportReview = useAppSelector((state) =>
    state.CEReport.reportReview.find((item) => item._id?.id === id)
  );

  return (
    <>
      <Descriptions bordered title={reportReview?.custName} size="small">
        <Descriptions.Item label="Order Date">
          {dateToString(reportReview.orderDate)}
        </Descriptions.Item>
        <Descriptions.Item label="Scan Date">
          {dateToString(reportReview.scanDate)}
        </Descriptions.Item>
        <Descriptions.Item label="Review Date">
          {dateToString(reportReview.reviewDate)}
        </Descriptions.Item>

        {/*  */}
        <Descriptions.Item label="Bill Number">
          <Tag color="success">{reportReview?.billNum}</Tag>
        </Descriptions.Item>

        <Descriptions.Item label="Customer Phone">
          <a target="_blank" rel="noreferrer" href={`https://wa.me/${reportReview?.custPhone}`}>
            +{reportReview?.custPhone}
          </a>
        </Descriptions.Item>
        <Descriptions.Item label="Content">
          {reportReview.content !== "" ? (
            reportReview.content
          ) : (
            <h1 className="text-red-500">Empty</h1>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Rate">
          <div className="flex items-center gap-1">
            <IoStar />
            {reportReview.rate}
          </div>
        </Descriptions.Item>
        <Descriptions.Item label="Total">{reportReview.total}</Descriptions.Item>

        <Descriptions.Item label="Outlet">{reportReview.outlet}</Descriptions.Item>
      </Descriptions>
    </>
  );
};

export default DetailReview;
