import Loading from "@components/loading";
import { dateToString } from "@helpers/utils";
import { deleteBannerProfileRequest, getBannerProfileRequest } from "@stores/dailyapp/BannerSlice";
import { useAppDispatch, useAppSelector } from "@stores/store";
import { Table } from "antd";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const BannerProfile = () => {
  const dispatch = useAppDispatch();
  const dataBanner = useAppSelector((state) =>
    state.dailyBanner.data?.map((i: any) => ({ ...i, key: i._id }))
  );
  const { isLoading } = useAppSelector((state) => state.dailyBanner);
  const column = [
    {
      dataIndex: "createdAt",
      title: "Date",
      render: (date: string) => <div>{dateToString(date)}</div>
    },
    {
      dataIndex: "title",
      title: "Title"
    },
    {
      title: "Thumbnail",
      dataIndex: ["image", "mediaUrl"],
      key: "thumbnail",
      render: (images: string) => (
        <div>
          <img src={images} className="w-16 object-cover h-16 rounded" />
        </div>
      )
    },
    {
      dataIndex: "description",
      title: "Description"
    },
    {
      title: "Action",
      dataIndex: "_id",
      key: "action",
      render: (id: string) => (
        <div className="flex items-center gap-2">
          <Link
            to={id}
            className="bg-blue-500 text-white p-2 px-4 rounded hover:text-white hover:bg-blue-600"
          >
            Detail
          </Link>
          <button
            onClick={() => dispatch(deleteBannerProfileRequest(id))}
            className="bg-red-500 text-white py-2 px-4 rounded hover:text-white hover:bg-red-600"
          >
            Delete
          </button>
        </div>
      )
    }
  ];

  useEffect(() => {
    dispatch(getBannerProfileRequest());
  }, []);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div>
          <div className="mb-4">
            <Link
              to="add-banner-profile"
              className=" py-2 text-center px-6 bg-green-500 hover:bg-green-600 hover:text-white rounded hover:shadow-lg text-white font-semibold"
            >
              Create Banner
            </Link>
          </div>
          <Table columns={column} dataSource={dataBanner?.reverse()} />
        </div>
      )}
    </>
  );
};

export default BannerProfile;
