import { dateToString, nf } from "@helpers/utils";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { IStoreReport } from "@typed/dailycoin/Reporting";
import moment from "moment";
import {
  getReportPaymentAPI,
  getReportTopupAPI,
  getReportBillAPI,
  getSummariesAPI,
  getReportFinanceAPI,
  getTopupEmployeAPI,
  getCashbackRegisAPI,
  getPaymentOriginAPI,
  getCutoffOriginAPI,
  getCutoffByPeriode,
  getSummaryMutasiAPI,
  topupByProviderAPI,
  gettopupByProviderDetailAPI
} from "./ReportingAPI";

export const getReportPaymentRequest = createAsyncThunk(
  "dailyapp-report/getReportPaymentRequest",
  async ({ startDate, endDate }: { startDate: string; endDate: string }) => {
    const response = await getReportPaymentAPI(startDate, endDate);
    return response;
  }
);

export const getReportTopupRequest = createAsyncThunk(
  "dailyapp-report/getReportTopupRequest",
  async ({ startDate, endDate }: { startDate: string; endDate: string }) => {
    const response = await getReportTopupAPI(startDate, endDate);
    return response;
  }
);

export const getReportBillRequest = createAsyncThunk(
  "dailyapp-report/getReportBillRequest",
  async ({ startDate, endDate }: { startDate: string; endDate: string }) => {
    const response = await getReportBillAPI(startDate, endDate);
    return response;
  }
);

export const getSummariesRequest = createAsyncThunk(
  "dailyapp-report/getSummariesRequest",
  async () => {
    const response = await getSummariesAPI();
    return response;
  }
);

export const getFinanceRequest = createAsyncThunk(
  "dailyapp-report/getFinanceRequest",
  async ({ startDate, endDate }: { startDate: string; endDate: string }) => {
    const response = await getReportFinanceAPI(startDate, endDate);
    return response;
  }
);

export const getTopupEmployeRequest = createAsyncThunk(
  "dailyapp-report/getTopupEmployeRequest",
  async ({ startDate, endDate }: { startDate: string; endDate: string }) => {
    const response = await getTopupEmployeAPI(startDate, endDate);
    return response;
  }
);

export const getCashbackRegisRequest = createAsyncThunk(
  "dailyapp-report/getCashbackRegisRequest",
  async ({ startDate, endDate }: { startDate: string; endDate: string }) => {
    const response = await getCashbackRegisAPI(startDate, endDate);
    return response;
  }
);

export const getPaymentOriginRequest = createAsyncThunk(
  "dailyapp-report/getPaymentOriginRequest",
  async ({ startDate, endDate }: { startDate: string; endDate: string }) => {
    const response = await getPaymentOriginAPI(startDate, endDate);
    return response;
  }
);

export const getCutOffOriginRequest = createAsyncThunk(
  "dailyapp-report/getCutOffOriginRequest",
  async () => {
    const response = await getCutoffOriginAPI();
    return response;
  }
);

export const getCutOffByPeriodeRequest = createAsyncThunk(
  "dailyapp-report/getCutOffByPeriodeRequest",
  async ({ startDate, endDate }: { startDate: string; endDate: string }) => {
    const response = await getCutoffByPeriode(startDate, endDate);
    return response;
  }
);

export const getSummaryMutasiRequest = createAsyncThunk(
  "dailyapp-report/getSummaryMutasiRequest",
  async () => {
    const response = await getSummaryMutasiAPI();
    return response;
  }
);

export const getTopupProviderReq = createAsyncThunk(
  "dailyapp-report/getTopupProviderReq",
  async ({ startDate, endDate }: { startDate: string; endDate: string }) => {
    const response = await topupByProviderAPI(startDate, endDate);
    return response;
  }
);

export const getTopupProviderDetailReq = createAsyncThunk(
  "dailyapp-report/getTopupProviderDetailReq",
  async ({ date, provider }: { date: string; provider: string }) => {
    const response = await gettopupByProviderDetailAPI(date, provider);
    return response;
  }
);

const initialState: IStoreReport = {
  reportSummaryMutasi: [],
  SummaryMutasiCSV: [],
  isLoading: false,
  reportPayment: [],
  reportTopup: [],
  reportBill: [],
  reportSummaries: [],
  reportFinance: [],
  FinanceCSV: [],
  reportTopupEmploye: [],
  TopupEmployeeCSV: [],
  startDate: moment().startOf("month").format("YYYY-MM-DD"),
  endDate: moment().format("YYYY-MM-DD"),
  dataCashback: [],
  CashbackCSV: [],
  paymentOrigin: [],
  paymentOriginCSV: [],
  cutOffOrigin: [],
  cutOffOriginCSV: [],
  dataChartPO: [],
  topupProvider: [],
  singleTopupProvider: []
};

const ReportingSlice = createSlice({
  name: "dailyapp-report",
  initialState: initialState,
  reducers: {
    CSVCashbackRegister: (state: IStoreReport) => {
      state.CashbackCSV = state.dataCashback?.map((item) => ({
        Date: dateToString(item._id),
        Transaction: item.transaction,
        Cashback: item.cashback
      }));
    },
    CSVFinance: (state: IStoreReport) => {
      state.FinanceCSV = state.reportFinance?.map((item) => ({
        Date: dateToString(item._id),
        "Number of Transaction - Topup": item.transactionTopup,
        "Number of Transaction - Scanned Bill": item.transactionBill,
        "Number of Transaction Dailycoin Payment": item.totalDailycoinPayment,
        "Dailycoin Topup": item.dailycoinTopup,
        "Dailycoin Cashback - Topup": item.cashbackTopup,
        "Dailycoin Cashback - Scanned Bill": item.dailycoinCashbackBill,
        "Total Dailycoin Created": item.dailycoinTopupCreated,
        "Dailycoin Payment": item.totalDailycoinPayment,
        "Dailycoin Payment - Dailyclick": item.totalDailycoinDailyclickPayment
      }));
    },
    CSVTopupEmployee: (state: IStoreReport) => {
      state.TopupEmployeeCSV = state.reportTopupEmploye?.map((item) => ({
        Date: dateToString(item._id),
        Transaction: item.transaction,
        Cashback: item.cashback,
        Topup: item.topup,
        "Dailycoin Created": item.cashback
      }));
    },

    CSVOriginpayment: (state: IStoreReport) => {
      state.paymentOriginCSV = state.paymentOrigin?.map((item) => ({
        Date: dateToString(item.date),
        Outlet: item.outlet,
        Name: item.customer,
        Transaction: item.transaction,
        Payment: item.payment,
        Currency: item.payment * 1000
      }));
    },
    setStartDate: (state: IStoreReport, action: PayloadAction<string>) => {
      state.startDate = action.payload;
    },
    setEndDate: (state: IStoreReport, action: PayloadAction<string>) => {
      state.endDate = action.payload;
    },

    CSVFilterCutOffOrigin: (state: IStoreReport, action: PayloadAction<any[]>) => {
      state.cutOffOriginCSV = action.payload?.map((item) => ({
        Date: dateToString(item.date),
        Customer: item.customer,
        Transaction: item.transaction,
        "Total Coin": item.totalCoin,
        "Category Cut off": item.categoryCutoff,
        Notes: item.notes
      }));
    },
    CSVSummaryMutasi: (state: IStoreReport) => {
      state.SummaryMutasiCSV = state.reportSummaryMutasi?.map((item) => item);
    }
  },
  extraReducers(builder) {
    builder.addCase(getReportPaymentRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getReportPaymentRequest.fulfilled, (state, action) => {
      action.payload.code === 1000 ? (state.reportPayment = action.payload.data) : null;
      state.isLoading = false;
    });
    builder.addCase(getReportPaymentRequest.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getReportTopupRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getReportTopupRequest.fulfilled, (state, action) => {
      action.payload.code === 1000 ? (state.reportTopup = action.payload.data) : null;
      state.isLoading = false;
    });
    builder.addCase(getReportTopupRequest.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getReportBillRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getReportBillRequest.fulfilled, (state, action) => {
      action.payload.code === 1000 ? (state.reportBill = action.payload.data) : null;
      state.isLoading = false;
    });
    builder.addCase(getReportBillRequest.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getSummariesRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getSummariesRequest.fulfilled, (state, action) => {
      action.payload.code === 1000 ? (state.reportSummaries = action.payload.data) : null;
      state.isLoading = false;
    });
    builder.addCase(getSummariesRequest.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getFinanceRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getFinanceRequest.fulfilled, (state, action) => {
      action.payload.code === 1000 ? (state.reportFinance = action.payload.data) : null;
      state.isLoading = false;
    });
    builder.addCase(getFinanceRequest.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getTopupEmployeRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getTopupEmployeRequest.fulfilled, (state, action) => {
      action.payload.code === 1000 ? (state.reportTopupEmploye = action.payload.data) : null;
      state.isLoading = false;
    });
    builder.addCase(getTopupEmployeRequest.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getCashbackRegisRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCashbackRegisRequest.fulfilled, (state, action) => {
      action.payload.code === 1000 ? (state.dataCashback = action.payload.data) : null;
      state.isLoading = false;
    });
    builder.addCase(getCashbackRegisRequest.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getPaymentOriginRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getPaymentOriginRequest.fulfilled, (state, action) => {
      const groupedData = action.payload.data.reduce((result: any, item: any) => {
        const date = item.date.split("T")[0]; // Extract the date portion

        // Check if the date is already present in the result object
        if (result[date]) {
          // If the outlet is already present, increment the payment count
          if (result[date][item.outlet]) {
            result[date][item.outlet] += item.payment;
          } else {
            // If the outlet is not present, add it to the result object
            result[date][item.outlet] = item.payment;
          }
        } else {
          // If the date is not present, create a new entry in the result object
          result[date] = {
            [item.outlet]: item.payment
          };
        }

        result[date][item.outlet] = {
          ...result[date][item.outlet],
          id: item.date,
          value: item.payment
        };

        return result;
      }, {});
      const groupArrays = Object.keys(groupedData).map((date: any) => {
        return {
          // id: date.date,
          date: date,
          outlet: groupedData[date]
        };
      });

      state.paymentOrigin = action.payload.data;

      // state.dataChartPO = groupArrays;
      state.isLoading = false;
    });
    builder.addCase(getPaymentOriginRequest.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getCutOffOriginRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCutOffOriginRequest.fulfilled, (state, action) => {
      action.payload.code === 1000 ? (state.cutOffOrigin = action.payload.data) : null;
      state.isLoading = false;
    });
    builder.addCase(getCutOffOriginRequest.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getCutOffByPeriodeRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCutOffByPeriodeRequest.fulfilled, (state, action) => {
      action.payload.code === 1000 ? (state.cutOffOrigin = action.payload.data) : null;
      state.isLoading = false;
    });
    builder.addCase(getCutOffByPeriodeRequest.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getSummaryMutasiRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getSummaryMutasiRequest.fulfilled, (state, action) => {
      action.payload.code === 1000 ? (state.reportSummaryMutasi = action.payload.data) : null;
      state.isLoading = false;
    });
    builder.addCase(getSummaryMutasiRequest.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getTopupProviderReq.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getTopupProviderReq.fulfilled, (state, action) => {
      state.topupProvider = action.payload.data;
      state.isLoading = false;
    });
    builder.addCase(getTopupProviderReq.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getTopupProviderDetailReq.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getTopupProviderDetailReq.fulfilled, (state, action) => {
      state.singleTopupProvider = action.payload.data;
      state.isLoading = false;
    });
    builder.addCase(getTopupProviderDetailReq.rejected, (state) => {
      state.isLoading = false;
    });
  }
});

export default ReportingSlice.reducer;
export const {
  CSVCashbackRegister,
  CSVOriginpayment,
  CSVFinance,
  CSVTopupEmployee,
  setStartDate,
  setEndDate,
  CSVFilterCutOffOrigin,
  CSVSummaryMutasi
} = ReportingSlice.actions;
