import * as FileSaver from "file-saver";
// import { CSVLink } from "react-csv";
import React from "react";
import { AiOutlineDownload } from "react-icons/ai";
import * as XLSX from "xlsx";

interface IProps {
  csvBill: any[];
  csvTopup: any[];
  csvPayment: any[];
  csvSummaries: any[];
  csvCashback: any[];
  csvTopupProvider: any[];
  fileName: any;
}

const ReportCSV: React.FC<IProps> = ({
  csvBill,
  csvSummaries,
  csvTopup,
  csvPayment,
  csvCashback,
  csvTopupProvider,
  fileName
}) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (
    csvBill: any[],
    csvTopup: any[],
    csvPayment: any[],
    csvSummaries: any[],
    csvCashback: any[],
    csvTopupProvider: any[],
    fileName: any
  ) => {
    const Bill = XLSX.utils.json_to_sheet(csvBill);
    const Topup = XLSX.utils.json_to_sheet(csvTopup);
    const Payment = XLSX.utils.json_to_sheet(csvPayment);
    const Summaries = XLSX.utils.json_to_sheet(csvSummaries);
    const RegisterCashback = XLSX.utils.json_to_sheet(csvCashback);
    const TopupProvider = XLSX.utils.json_to_sheet(csvTopupProvider);

    const wb = {
      Sheets: {
        "Payment Report": Payment,
        "Topup Report": Topup,
        "Bill Cashback Report": Bill,
        "Register Cashback": RegisterCashback,
        "Summaries report": Summaries,
        "Topup By Provider": TopupProvider
      },
      SheetNames: [
        "Payment Report",
        "Topup Report",
        "Bill Cashback Report",
        "Register Cashback",
        "Summaries report",
        "Topup By Provider"
      ]
    };

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "buffer" });

    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <button
      className="flex text-medium items-center gap-1 text-center outline-none h-9 w-[10rem] rounded bg-blue-500 hover:bg-blue-400 text-white justify-center"
      onClick={(e) =>
        exportToCSV(
          csvBill,
          csvTopup,
          csvPayment,
          csvSummaries,
          csvCashback,
          csvTopupProvider,
          fileName
        )
      }
    >
      <AiOutlineDownload className="text-medium" /> Download XLS
    </button>
  );
};

export default ReportCSV;
