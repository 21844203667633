import API from "@constants/API";

const PRODUCT_URL = "https://api.prod.daily.co.id/click/product/dashboard/v1/click-product";
// https://api.prod.daily.co.id/click/product/dashboard/v1/click-product

const BASE_URL = "https://api.prod.daily.co.id/click/product/no/image/dashboard/click-product";

export const getProductClickAPI = async () => {
  const response = await API.HTTP.get(
    "https://api.prod.daily.co.id/click/product/no/image/dashboard/v1/click-product"
  );
  return response.data;
};

export interface IPayloadCreateProduct {
  name: string;
  value: string;
  description: string;
  isActive: string;
  startCoin: string;
  image: any;
  coinClick: string;
  dateStart: string;
  dateEnd: string;
  timeEnd: string;
  timeStart: string;
  price: string;
}

export const postProductClickAPI = async (data: IPayloadCreateProduct) => {
  const formData = new FormData();
  formData.append("name", data.name);
  formData.append("image", data.image);
  formData.append("description", data.description);
  formData.append("isActive", data.isActive);
  formData.append("value", data.value);
  formData.append("startCoin", data.startCoin);
  formData.append("coinClick", data.coinClick);
  formData.append("timeEnd", data.timeEnd);
  formData.append("timeStart", data.timeStart);
  formData.append("dateStart", data.dateStart);
  formData.append("dateEnd", data.dateEnd);
  formData.append("price", data.price);

  const response = await API.HTTP.post(`${PRODUCT_URL}/image`, formData);

  return response.data;
};

export const putProductClickAPI = async (id: string, data: IPayloadCreateProduct) => {
  const formData = new FormData();
  formData.append("name", data.name);
  formData.append("image", data.image ?? undefined);
  formData.append("description", data.description);
  formData.append("isActive", data.isActive);
  formData.append("value", data.value);
  formData.append("startCoin", data.startCoin);
  formData.append("coinClick", data.coinClick);
  formData.append("timeEnd", data.timeEnd ?? undefined);
  formData.append("timeStart", data.timeStart ?? undefined);
  formData.append("dateStart", data.dateStart ?? undefined);
  formData.append("dateEnd", data.dateEnd ?? undefined);

  const response = await API.HTTP.put(`${PRODUCT_URL}/${id}`, formData);
  return response.data;
};

export const singleProductClickAPI = async (id: string) => {
  const response = await API.HTTP.get(
    `https://api.prod.daily.co.id/click/product/no/image/dashboard/v1/click-product/${id}`
  );
  return response.data;
};

export const putActiveProduct = async (id: string, isActive: boolean) => {
  const data = {
    isActive: isActive ? false : true
  };
  const response = await API.HTTP.put(
    `https://api.prod.daily.co.id/click/product/no/image/dashboard/v1/click-product/image-less/${id}`,
    data
  );
  return response.data;
};

export const putEndEvent = async (id: string, isEndEvent: boolean) => {
  const data = {
    isEndEvent: isEndEvent ? false : true
  };
  const response = await API.HTTP.put(
    `https://api.prod.daily.co.id/click/product/no/image/dashboard/v1/click-product/image-less/${id}`,
    data
  );
  return response.data;
};

export const putRedeemProduct = async (id: string, isRedeemed: boolean) => {
  console.log("id", id);
  const data = {
    isRedeemed: isRedeemed ? false : true
  };
  const response = await API.HTTP.put(
    `https://api.prod.daily.co.id/click/product/no/image/dashboard/v1/click-product/image-less/${id}`,
    data
  );
  return response.data;
};
