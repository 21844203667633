import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@stores/store";
import {
  VoucherOnRegsiterCSV,
  getVoucherAvailableReq,
  getVoucherCreatedReq,
  getVoucherHistoryReq,
  getVoucherOnRegisterReq,
  getVoucherRedeemedReq,
  getVoucherRegisterESBReq,
  getVoucherTakenReq,
  postConfirmVoucherReq,
  postRegisterVoucherReq
} from "@stores/dailyapp/VoucherSlice";
import { Tabs } from "antd";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";

interface IPropsVoucher {
  data: any[] | [];
  isLoading: boolean;
  children?: React.ReactNode;
  viewData?: boolean;
}

const columns = [
  {
    name: "Brand",
    selector: (row: any) => row._id,
    sortable: true
  },
  {
    name: "Count",
    selector: (row: any) => row.count,
    sortable: true
  }
];

const columnsESB = [
  {
    name: "Brand",
    selector: (row: any) => row._id,
    sortable: true
  },
  {
    name: "Count",
    selector: (row: any) => row.count,
    sortable: true
  },
  {
    name: "Action",
    selector: (row: any) => row.productId,
    sortable: true,
    cell: (row: any) => {
      return (
        <Link
          to={`/dailyapp/voucher-on-register/${row.productId}`}
          className="px-8 py-2 bg-blue-500 rounded-md font-bold hover:bg-blue-600 text-white hover:text-white"
        >
          View Data
        </Link>
      );
    }
  }
];

const VoucherComponent: React.FC<IPropsVoucher> = ({
  data,
  isLoading,
  children,
  viewData = false
}) => {
  return (
    <div>
      {children}
      <div className="mt-4">
        <DataTable
          progressPending={isLoading}
          columns={viewData ? columnsESB : columns}
          data={data}
        />
      </div>
    </div>
  );
};

const VoucherUsed = () => {
  const dispatch = useAppDispatch();
  const {
    voucherHistory,
    isLoadingVoucher,
    isLoadingAvailable,
    isLoadingTaken,
    isLoadingRedeemed,
    isLoadingRegister,
    isLoadingCreated,
    voucherAvailable,
    voucherTaken,
    voucherRedeemed,
    voucherRegister,
    voucherCreated
  } = useAppSelector((state) => state.dailyVoucher);

  const getVoucherHistory = () => {
    // dispatch(getVoucherHistoryReq());
    dispatch(getVoucherAvailableReq());
    dispatch(getVoucherCreatedReq());
    dispatch(getVoucherTakenReq());
    dispatch(getVoucherRedeemedReq());
    dispatch(getVoucherRegisterESBReq());
  };

  useEffect(() => {
    getVoucherHistory();
  }, []);

  const handleComplete = () => {
    dispatch(postRegisterVoucherReq());
    getVoucherHistory();
  };

  return (
    <div>
      {/* <ModalExportVoucher isOpen={showModal} closeModal={() => setShowModal(!showModal)} /> */}
      <h1 className="font-bold text-[1.5rem] text-center">Voucher History</h1>
      <div>
        <Tabs
          defaultActiveKey="1"
          items={[
            {
              label: "Available",
              key: "1",
              children: <VoucherComponent data={voucherAvailable} isLoading={isLoadingAvailable} />
            },
            {
              label: "Created",
              key: "2",
              children: <VoucherComponent data={voucherCreated} isLoading={isLoadingCreated} />
            },
            {
              label: "Taken",
              key: "3",
              children: <VoucherComponent data={voucherTaken} isLoading={isLoadingTaken} />
            },
            {
              label: "Redeemed",
              key: "4",
              children: <VoucherComponent data={voucherRedeemed} isLoading={isLoadingRedeemed} />
            },
            {
              label: "Register To ESB",
              key: "5",
              children: (
                <VoucherComponent viewData data={voucherRegister} isLoading={isLoadingRegister}>
                  <div className="flex items-center gap-3 justify-between">
                    <div className="flex items-center gap-3">
                      <button
                        onClick={handleComplete}
                        className="px-8 py-2 bg-green-500 rounded-md font-bold hover:bg-green-600 text-white hover:text-white"
                      >
                        Complete Register
                      </button>
                    </div>
                  </div>
                </VoucherComponent>
              )
            }
          ]}
        />
      </div>
    </div>
  );
};

export default VoucherUsed;
