import { useAppDispatch, useAppSelector } from "@stores/store";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

const SingleOutletAccount = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const AppOutlet = useAppSelector((state) =>
    state.dailyappOutlet.AppOutlet.find((item) => item._id === id && item)
  );
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    // if (id) {
    // }

    console.log("AppOutlet", AppOutlet);
  }, [id, setData]);

  return (
    <>
      <div>SingleOutletAccount</div>
    </>
  );
};

export default SingleOutletAccount;
