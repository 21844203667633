import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useAppDispatch, useAppSelector } from "@stores/store";
import AddVoucher from "./AddVoucher";
import { VoucherExcel, getVoucherReq } from "@stores/dailyapp/VoucherSlice";
import { dateToString, nf } from "@helpers/utils";
import ExportCSV from "@components/ExportCSV";
import Modal from "@components/Modal";
import { AiOutlineDownload } from "react-icons/ai";
import SelectComponents from "@components/Select";
import ModalVoucherVerify from "./VoucherVerify";
import { getOutletsRequest } from "@stores/dailyapp/dailyOutletSlice";

const columns = [
  {
    name: "Date",
    selector: (row: any) => row.createdAt,
    id: "date",
    sortable: true,
    cell: (row: any) => <div>{dateToString(row.createdAt)}</div>
  },
  {
    name: "Code",
    sortable: true,
    selector: (row: any) => row.code,
    id: "code"
  },
  {
    name: "Price",
    sortable: true,
    selector: (row: any) => nf.format(row.productId.price * 1000),
    id: "price"
  },
  {
    name: "Expired",
    sortable: true,
    selector: (row: any) => row.expIn,
    id: "price"
  }
];

interface IPropsModalVoucher {
  isOpen: boolean;
  VoucherCSV: any[];
  closeModal: () => void;
}

const options = [
  {
    label: "ANTARASA",
    value: "antarasa"
  },
  {
    label: "AYAM AMANAH",
    value: "ayam-amanah"
  },
  {
    label: "BREADLIFE",
    value: "breadlife"
  },
  {
    label: "DAILYBOX",
    value: "dailybox"
  },
  {
    label: "LUMIERE",
    value: "lumiere"
  },
  {
    label: "SHIRATO",
    value: "shirato"
  },
  {
    label: "TIANTEA",
    value: "tiantea"
  }
];

const optionBrands = [
  { value: "DB", label: "DAILYBOX" },
  { value: "SH", label: "SHIRATO" },
  { value: "BL", label: "BREADLIFE" },
  { value: "AA", label: "AYAM AMANAH" },
  { value: "AN", label: "ANTARASA" },
  { value: "LM", label: "LUMIERE" },
  { value: "TN", label: "TIANTEA" },
  ,
];

interface IPropsModalBrand {
  isOpen: boolean;
  typeBrand: string;
  setTypeBrand: React.Dispatch<React.SetStateAction<string>>;
  closeModal: () => void;
}

const ModalSelectBrand: React.FC<IPropsModalBrand> = ({
  isOpen,
  closeModal,
  typeBrand,
  setTypeBrand
}) => {
  const dispatch = useAppDispatch();

  const handleSubmit = () => {
    dispatch(getVoucherReq(typeBrand));
  };

  return (
    <>
      <div>
        <Modal isOpen={isOpen} closeModal={closeModal} title="Select Brand">
          <div className="bg-white h-[11rem] pt-16 flex flex-col gap-3">
            <div className="z-10">
              <SelectComponents
                options={optionBrands}
                onChange={(newValue: any, actionMeta) => {
                  setTypeBrand(newValue.value);
                }}
              />

              <div className="pt-10">
                <button
                  onClick={handleSubmit}
                  className="px-5 py-2 bg-blue-500 rounded-md font-bold hover:bg-blue-600 text-white hover:text-white"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

const ModalVoucher: React.FC<IPropsModalVoucher> = ({ isOpen, closeModal, VoucherCSV }) => {
  const dispatch = useAppDispatch();
  const [label, setLabel] = useState<string>("");

  return (
    <>
      <Modal isOpen={isOpen} closeModal={closeModal} title="Download Voucher">
        <div className="bg-white h-[14rem] pt-16 flex flex-col gap-3">
          <div>
            <h1>Filter by brand</h1>
            <SelectComponents
              options={options}
              onChange={(newValue: any, actionMeta) => {
                setLabel(newValue.label);
                dispatch(VoucherExcel(newValue.value));
              }}
            />
          </div>

          <div className="mt-10 flex justify-end">
            <ExportCSV csvData={VoucherCSV} fileName={`Vouchers-${label}`} />
          </div>
        </div>
      </Modal>
    </>
  );
};

const Voucher = () => {
  const dispatch = useAppDispatch();
  const { allVoucher, VoucherCSV, isLoadingVoucher, codeCreateVoucher } = useAppSelector(
    (state) => state.dailyVoucher
  );
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalDownload, setModalDownload] = useState<boolean>(false);
  const [modalBrand, setModalBrand] = useState<boolean>(false);
  const [modalVerify, setModalVerify] = useState<boolean>(false);
  const [typeBrand, setTypeBrand] = useState("DB");

  useEffect(() => {
    dispatch(getVoucherReq(typeBrand));
    dispatch(getOutletsRequest());
  }, []);

  useEffect(() => {
    dispatch(getVoucherReq(typeBrand));
  }, [codeCreateVoucher]);

  return (
    <>
      <ModalVoucherVerify openModal={modalVerify} setOpenModal={setModalVerify} />
      <ModalVoucher
        VoucherCSV={VoucherCSV}
        isOpen={modalDownload}
        closeModal={() => setModalDownload(!modalDownload)}
      />
      <ModalSelectBrand
        typeBrand={typeBrand}
        setTypeBrand={setTypeBrand}
        isOpen={modalBrand}
        closeModal={() => setModalBrand(!modalBrand)}
      />
      <AddVoucher isOpen={showModal} closeModal={() => setShowModal(!showModal)} />
      <div className="mb-5 flex justify-between items-center">
        <div>
          <button
            onClick={() => setShowModal(!showModal)}
            className="px-8 py-2 bg-green-500 rounded-md font-bold hover:bg-green-600 text-white hover:text-white"
          >
            Create Voucher
          </button>

          <button
            onClick={() => setModalBrand(!modalBrand)}
            className="px-8 ml-3 py-2 bg-blue-500 rounded-md font-bold hover:bg-blue-600 text-white hover:text-white"
          >
            Select Brand
          </button>

          <button
            onClick={() => setModalVerify(true)}
            className="px-8 ml-3 py-2 bg-slate-800 rounded-md font-bold hover:bg-slate-700 text-white hover:text-white"
          >
            Verify Voucher
          </button>
        </div>

        <div className="flex items-center gap-2">
          <div>
            <button
              className="flex px-8 py-2 text-medium items-center gap-1 text-center outline-none h-9 rounded bg-blue-500 hover:bg-blue-400 text-white justify-center"
              onClick={() => setModalDownload(!modalDownload)}
            >
              <AiOutlineDownload className="text-medium" />
              <h1 className="m-0 text-white text-[1rem] p-0">Export</h1>
            </button>
          </div>
        </div>
      </div>

      <div>
        <DataTable
          progressPending={isLoadingVoucher}
          data={allVoucher}
          columns={columns}
          highlightOnHover
          pagination
          persistTableHead
        />
      </div>
    </>
  );
};

export default Voucher;
