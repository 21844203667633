import { getOutletsRequest } from "@stores/dailyojol/OutletSlice";
import { useAppDispatch, useAppSelector } from "@stores/store";
import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { Link } from "react-router-dom";
import { IDataOutlets } from "@typed/dailojol/Outlet";
import Loading from "@components/loading";
import InputSearch from "@components/inputSearch";

const column = [
  {
    title: "Outlet Name",
    key: "name",
    dataIndex: "name"
  },
  {
    title: "Address",
    key: "address",
    width: "20%",
    dataIndex: "address",
    render: (address: string) => (
      <div
        style={{
          width: "200px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          padding: "20px",
          resize: "horizontal"
        }}
      >
        {address}
      </div>
    )
  },
  {
    title: "Code",
    key: "code",
    dataIndex: "code",
    render: (code: string | null) => (
      <div>{code === "" ? <div className="text-red-500">Data no available</div> : code}</div>
    )
  },
  {
    title: "Region",
    key: "region",
    dataIndex: "region",
    render: (region: string | null) => (
      <div>{region === null ? <div className="text-red-500">Data no available</div> : region}</div>
    )
  },
  {
    title: "Kitchen",
    key: "kitchen",
    dataIndex: "kitchen",
    render: (kitchen: string | null) => (
      <div>
        {kitchen === null ? <div className="text-red-500">Data no available</div> : kitchen}
      </div>
    )
  },
  {
    key: "action",
    title: "Action",
    render: (data: IDataOutlets) => {
      return (
        <div className="flex gap-3">
          <Link
            className="bg-blue-500 text-white p-2 px-4 rounded hover:bg-blue-600"
            to={`/dailyojol/outlet/${data._id}`}
          >
            Detail
          </Link>
        </div>
      );
    }
  }
];

const listOutlet = () => {
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.dailyojolOutlet);
  const Outlets = useAppSelector((state) =>
    state.dailyojolOutlet.Outlet.map((x: any) => ({ ...x, key: x._id }))
  );
  const [dataOutlet, setDataOutlet] = useState(Outlets);
  const [searchOutlet, setSearchOutlet] = useState<string>("");

  useEffect(() => {
    dispatch(getOutletsRequest({ skip: 0, limit: 50 }));
  }, [dispatch]);

  useEffect(() => {
    setDataOutlet(Outlets);
  }, [dispatch]);

  useEffect(() => {
    let Data = Outlets.filter((item) => {
      return item.name?.toLowerCase().includes(searchOutlet.toLowerCase());
    });
    if (searchOutlet !== "") {
      setDataOutlet(Data);
    } else if (searchOutlet === "") {
      setDataOutlet(Outlets);
    }
  }, [searchOutlet, loading]);

  return (
    <div className="">
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className=" flex h-12 items-center gap-4">
            <InputSearch
              value={searchOutlet}
              handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSearchOutlet(e.target.value);
              }}
              placeholder="Search by name of outlet"
            />
            <div className="mb-4">
              <Link
                to="/dailyojol/outlet/create-outlet"
                className=" py-2 text-center px-6 bg-green-500 hover:bg-green-600 hover:text-white rounded hover:shadow-lg text-white font-semibold"
              >
                Create Outlet
              </Link>
            </div>
          </div>
          <Table className="mt-3 dark:bg-slate-600" columns={column} dataSource={dataOutlet} />
        </>
      )}
    </div>
  );
};

export default listOutlet;
