import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { IEmployeStore } from "@typed/HRIS/Person";
import { getEmployeAPI, putEmployeAPI } from "./EmployeAPI";

export const getEmployeRequest = createAsyncThunk("hris-employe/getEmployeRequest", async () => {
  const response = await getEmployeAPI();
  return response;
});

export const putEmployeRequest = createAsyncThunk(
  "hris-employe/putEmployeRequest",
  async (id: string, data: object) => {
    const response = await putEmployeAPI(id, data);

    return response;
  }
);

const initialState: IEmployeStore = {
  isLoading: false,
  dataEmploye: []
};

const EmployeSlice = createSlice({
  name: "hris-employe",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getEmployeRequest.pending, (state) => {
      state.isLoading = true;
    }),
      builder.addCase(getEmployeRequest.fulfilled, (state, action) => {
        if (action.payload.code === 1000) {
          state.dataEmploye = action.payload.data;
        }
        state.isLoading = false;
      }),
      builder.addCase(getEmployeRequest.rejected, (state) => {
        state.isLoading = false;
      });

    builder.addCase(putEmployeRequest.pending, (state) => {
      state.isLoading = true;
    }),
      builder.addCase(putEmployeRequest.fulfilled, (state, action) => {
        state.isLoading = false;
      }),
      builder.addCase(putEmployeRequest.rejected, (state) => {
        state.isLoading = false;
      });
  }
});

export default EmployeSlice.reducer;
