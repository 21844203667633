import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import MyTable from "@components/Table/MyTable";
import Modal from "@components/Modal";
import SelectComponents from "@components/Select";
import Button from "@components/Button.component";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@stores/store";
import { getPaymentBrandCSV, getPaymentBrandReq } from "@stores/brandPayment/BrandPaymentSlice";
import { DatePickerProps, DatePicker } from "antd";
import ExportCSV from "@components/ExportCSV";

const option = [
  {
    value: "Lumiere",
    label: "LUMIERE"
  }
];

export const ExistingBrand = () => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(true);
  function handleCloseModal() {
    setOpenModal(!openModal);
  }

  function handleSave() {
    navigate("/brand-settlement/brand-payment");
    setOpenModal(!openModal);
  }

  return (
    <>
      <Modal isOpen={openModal} title="Existing Brand" closeModal={handleCloseModal}>
        <div className="bg-white h-[10rem] pt-16 flex flex-col gap-3">
          <SelectComponents options={option} />
        </div>

        <div className="flex justify-end">
          <Button
            onClick={handleSave}
            // className="bg-blue-500 hover:bg-glue-600 text-white hover:text-white rounded font-bold py-2 px-8"
          >
            Submit
          </Button>
        </div>
      </Modal>
    </>
  );
};

const BrandPayment = () => {
  const dispatch = useAppDispatch();
  const { brandPayment, paymentBrandLoading, brandPaymentCSV } = useAppSelector(
    (state) => state.brandSettlement
  );

  const startDate = useRef(moment().startOf("month").format("YYYY-MM-DD"));
  const endDate = useRef(moment().format("YYYY-MM-DD"));

  const handleStartDate: DatePickerProps["onChange"] = (date: any, dateString: any) => {
    startDate.current = dateString;
  };

  const handleEndDate: DatePickerProps["onChange"] = (date: any, dateString: any) => {
    endDate.current = dateString;
  };

  const getPayment = async () => {
    const payload = {
      startDate: startDate.current,
      endDate: endDate.current
    };
    dispatch(getPaymentBrandReq(payload));
  };

  const filterPaymentBrand = () => {
    getPayment();
  };

  useEffect(() => {
    dispatch(getPaymentBrandCSV());
    getPayment();
  }, []);

  useEffect(() => {
    dispatch(getPaymentBrandCSV());
  }, [paymentBrandLoading]);

  return (
    <>
      <div className="flex justify-between items-center">
        <div className=" flex items-center gap-4">
          <h1 className="m-0">Filter </h1>

          <DatePicker
            className="h-9"
            name="startDate"
            onChange={handleStartDate}
            placeholder="Start date"
          />

          <DatePicker
            className="h-9"
            name="endDate"
            onChange={handleEndDate}
            placeholder="End date"
          />

          <button
            onClick={filterPaymentBrand}
            className="flex text-medium items-center gap-1 text-center outline-none h-9 w-[10rem] rounded bg-blue-500 hover:bg-blue-400 text-white justify-center"
          >
            Submit
          </button>
        </div>
        <ExportCSV
          csvData={brandPaymentCSV}
          fileName={`paymentBrand-LUMIERE-${startDate.current}-${endDate.current}`}
        />
      </div>
      <MyTable
        dataSource={brandPayment}
        loading={paymentBrandLoading}
        select={[
          { label: "Date", value: "date" },
          { label: "Username", value: "userName" },
          { label: "Outlet", value: "outlet" },
          { label: "Outlet Code", value: "outletCode" },
          { label: "Transaction", value: "transaction" },
          { label: "Payment", value: "payment" },
          { label: "Payment Code", value: "paymentCode" }
        ]}
        fileName={""}
      />
    </>
  );
};

export default BrandPayment;
