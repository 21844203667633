import React, { useEffect, useRef, useState } from "react";
import { CSVAnswer, getAnswerReq } from "@stores/dailyapp/QuestionsAnwerSlice";
import { useAppDispatch, useAppSelector } from "@stores/store";
import moment from "moment";
import DataTable from "react-data-table-component";
import ExportCSV from "@components/ExportCSV";
import { flattenArray } from "@helpers/utils";
import { CSVLink } from "react-csv";

const columns: any = [
  {
    name: "Date",
    selector: (row: any) => row.createdAt,
    sortable: true,
    cell: (row: any) => moment(row.createdAt).format("MMMM Do YYYY, h:mm")
  },
  {
    name: "Username",
    selector: (row: any) => row.userName,
    sortable: true
  },
  {
    name: "Phone",
    selector: (row: any) => row.phone,
    sortable: true
  }
];

const customDataAnswer = (dataAnswer: any) => {
  const cda = dataAnswer?.map((da: any, i: number) => {
    const rda = da?.questionAndAnswer?.reduce(
      (a: any, v: any) => ({ ...a, [v.question]: v.answer }),
      {}
    );
    return { ...da, ...rda };
  });
  return cda;
};

const customCSV = (dataAnswer: any) => {
  const cda = dataAnswer?.map((da: any, i: number) => {
    const rda = da?.questionAndAnswer?.reduce(
      (a: any, v: any) => ({ ...a, [v.question]: v.answer.toString() }),
      {}
    );
    return { ...da, userId: da._id.userId, ...rda };
  });
  return cda;
};

const Answer = () => {
  const dispatch = useAppDispatch();
  const { dataAnswer, isLoadingBatch, dataAnswerCSV } = useAppSelector(
    (state) => state.dailyQuestionsBatch
  );
  const [data, setData] = useState(dataAnswer);

  let additionalData: any[] = dataAnswer[0]?.questionAndAnswer;

  useEffect(() => {
    dispatch(getAnswerReq());
    dispatch(CSVAnswer());
  }, []);

  useEffect(() => {
    setData(flattenArray(dataAnswer));
  }, []);

  let datas: any[] = dataAnswer;
  datas.map((item) => item).sort((a, b) => a.questionAndAnswer - b.questionAndAnswer);

  const additionalCols = additionalData?.map((it: any, index: number) => {
    return {
      name: it.question
        .split(" ")
        .map(function (item: any) {
          return item[0];
        })
        .join("")
        .toUpperCase(),
      selector: (row: any) => row[it.question],
      sortable: true
    };
  });

  const newCols = [...columns, ...additionalCols];
  const startDate = useRef(moment().startOf("month").format("YYYY-MM-DD"));
  const endDate = useRef(moment().format("YYYY-MM-DD"));
  return (
    <>
      {/* <CSVLink data={flattenArray(dataAnswer)} filename={"my-data.csv"}>
        Export to CSV
      </CSVLink> */}
      <ExportCSV
        csvData={customCSV(dataAnswer)}
        fileName={`report-QNA-Answer-${startDate.current}-${endDate.current}`}
      />
      <div className="mt-3">
        <DataTable
          paginationRowsPerPageOptions={[10, 20, 50]}
          pagination
          progressPending={isLoadingBatch}
          data={customDataAnswer(dataAnswer)}
          columns={newCols}
        />
      </div>
    </>
  );
};

export default Answer;
