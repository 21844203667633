import React, { useEffect, useState } from "react";
import { Table, Switch, Tag } from "antd";
import type { ColumnsType } from "antd/lib/table";
import { useAppDispatch, useAppSelector } from "@stores/store";
import { getOjolCodeRequest } from "@stores/dailyojol/CodeSlice";
import { dateToString } from "@helpers/utils";
import { IDataDetailCode } from "@typed/dailojol/Code";
import Loading from "@components/loading";
const Code = () => {
  const dispatch = useAppDispatch();
  const { data, loading } = useAppSelector((state) => state.dailyojolCode);

  const datasCode = data.map((x) => ({ ...x, key: x._id }));

  useEffect(() => {
    dispatch(getOjolCodeRequest());
  }, []);

  const column: ColumnsType<IDataDetailCode> = [
    {
      key: "code",
      title: "Code",
      dataIndex: "code",
      fixed: "left"
    },
    {
      key: "outlet",
      title: "Outlet",
      dataIndex: "outlet"
    },
    {
      key: "userId",
      title: "ID User",
      dataIndex: "userID"
    },
    {
      key: "orderNumber",
      title: "Order Number",
      dataIndex: "orderNumber"
    },

    {
      key: "status",
      title: "Status",
      dataIndex: "status",

      render: (status: string) => (
        <div>
          {status === "approved" ? (
            <Tag color="green">{status}</Tag>
          ) : (
            <Tag color="red">{status}</Tag>
          )}
        </div>
      )
    },
    {
      key: "orderDate",
      title: "Order Date",
      dataIndex: "orderDate",
      render: (orderDate: string) => <div>{dateToString(orderDate)}</div>
    }
  ];

  // const [fixedTop, setFixedTop] = useState(false);
  return <div>{loading ? <Loading /> : <Table columns={column} dataSource={datasCode} />}</div>;
};

export default Code;
