import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { IDataDetailProduct, IProductStore } from "@typed/dailojol/Product";
import toast from "react-hot-toast";
import {
  delOjolProductAPI,
  getOjolDetailProductAPI,
  getOjolProductsAPI,
  postOjolProductAPI,
  putOjolProductAPI
} from "./ProductAPI";

export const getOjolProductsRequest = createAsyncThunk(
  "dailyojol-product/getOjolProductsRequest",
  async () => {
    const response = await getOjolProductsAPI();

    return response.data;
  }
);

export const getOjolDetailProductRequest = createAsyncThunk(
  "dailyojol-product/getOjolDetailProductRequest",
  async (id: string) => {
    const response = await getOjolDetailProductAPI(id);
    return response.data;
  }
);

export const delOjolProductRequest = createAsyncThunk(
  "dailyojol-product/delOjolProductRequest",
  async (id: string) => {
    const response = await delOjolProductAPI(id);

    return response;
  }
);

export const postOjolProductRequest = createAsyncThunk(
  "dailyojol-product/postOjolProductRequest",
  async (Data: object) => {
    const response = await postOjolProductAPI(Data);

    return response;
  }
);

type payloadEdit = {
  Data: object;
  id: string;
};

export const putOjolProductRequest = createAsyncThunk(
  "dailyojol-product/putOjolProductRequest",
  async ({ Data, id }: payloadEdit) => {
    const response = await putOjolProductAPI(Data, id);

    return response;
  }
);

const initialState: IProductStore = {
  data: [],
  loading: false,
  forReset: {} as IDataDetailProduct,
  detailProduct: {} as IDataDetailProduct
};

export const ProductOjolSlice = createSlice({
  name: "dailyojol-product",
  initialState: initialState,
  reducers: {
    resetProduct: (state: { forReset: IDataDetailProduct }) => {
      state.forReset = {} as IDataDetailProduct;
    }
  },
  extraReducers(builder) {
    builder.addCase(getOjolProductsRequest.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getOjolProductsRequest.fulfilled, (state, action) => {
      if (action.payload.code === 1000) {
        state.data = action.payload.data;
        state.loading = false;
      }
    });
    builder.addCase(getOjolProductsRequest.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getOjolDetailProductRequest.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getOjolDetailProductRequest.fulfilled, (state, action) => {
      if (action.payload.code === 1000) {
        state.detailProduct = action.payload.data;
        state.loading = false;
      }
    });
    builder.addCase(getOjolDetailProductRequest.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(delOjolProductRequest.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(delOjolProductRequest.fulfilled, (state, action) => {
      // console.log(action, "< action");
      if (action.payload.code === 1000) {
        state.data = state.data.filter((item) => item._id !== action.meta.arg);
      }
      state.loading = false;
    });
    builder.addCase(delOjolProductRequest.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(postOjolProductRequest.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(postOjolProductRequest.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.code === 1000) {
        toast.success("Success Create Product");
        state.forReset = action.payload.data;
      }
    });
    builder.addCase(postOjolProductRequest.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(putOjolProductRequest.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(putOjolProductRequest.fulfilled, (state, action) => {
      if (action.payload.code === 1000) {
        toast.success("Success Update Product");
        state.forReset = action.payload.data;
      }
      state.loading = false;
    });
    builder.addCase(putOjolProductRequest.rejected, (state) => {
      state.loading = false;
    });
  }
});

export default ProductOjolSlice.reducer;
export const { resetProduct } = ProductOjolSlice.actions;
