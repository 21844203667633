import React, { useState, useEffect, useRef } from "react";

import { Table, DatePicker, DatePickerProps } from "antd";
import { useAppDispatch, useAppSelector } from "@stores/store";
import moment from "moment";
import ExportCSV from "@components/ExportCSV";
import { CSVTopupEmployee, getTopupEmployeRequest } from "@stores/dailycoin/ReportingSlice";
import Loading from "@components/loading";
import DataTable from "react-data-table-component";

const columns = [
  {
    name: "Date",
    selector: (row: any) => row._id
  },
  {
    name: "Transaction",
    selector: (row: any) => row.transaction
  },
  {
    name: "Topup",
    selector: (row: any) => row.topup
  },
  {
    name: "Cashback",
    selector: (row: any) => row.cashback
  },
  {
    name: "Dailycoin Created",
    selector: (row: any) => row.dailycoinCreated
  }
];

const TopupEmploye = () => {
  const dispatch = useAppDispatch();
  const { isLoading, TopupEmployeeCSV } = useAppSelector((state) => state.dailyReport);
  const TopupEmployee = useAppSelector((state) =>
    state.dailyReport.reportTopupEmploye.map((i) => ({ ...i, key: i._id }))
  );
  const startDate = useRef<any>(moment().startOf("month"));
  const endDate = useRef<any>(moment().endOf("month"));

  const handleStartDate: DatePickerProps["onChange"] = (date: any, dateString: any) => {
    startDate.current = dateString;
  };

  const handleEndDate: DatePickerProps["onChange"] = (date: any, dateString: any) => {
    endDate.current = dateString;
  };

  useEffect(() => {
    dispatch(CSVTopupEmployee());
  }, [isLoading]);

  const filterByDate = () => {
    dispatch(
      getTopupEmployeRequest({
        startDate: startDate.current,
        endDate: endDate.current
      })
    );
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <div className="flex justify-between items-center">
        <div className=" flex items-center gap-4">
          <h1 className="m-0">Filter </h1>

          <DatePicker
            className="h-9"
            name="startDate"
            onChange={handleStartDate}
            placeholder="Start date"
          />

          <DatePicker
            className="h-9"
            name="endDate"
            onChange={handleEndDate}
            placeholder="End date"
          />

          <button
            onClick={filterByDate}
            className="flex text-medium items-center gap-1 text-center outline-none h-9 w-[10rem] rounded bg-blue-500 hover:bg-blue-400 text-white justify-center"
          >
            Submit
          </button>
        </div>
        <ExportCSV
          csvData={TopupEmployeeCSV}
          fileName={`topup-Employee-${startDate.current}-${endDate.current}`}
        />
      </div>
      <div className="mt-5">
        <DataTable data={TopupEmployee} columns={columns} pagination />
      </div>
    </>
  );
};

export default TopupEmploye;
