import API from "@constants/API";

const BASE_URL = "https://api.dev.daily.co.id/dashboard/redeem/redeem";

export const getOjolRedemAPI = async () => {
  const response = await API.HTTP.get(`${BASE_URL}`);

  return response;
};

export const getDetailOjolRedemAPI = async (id: string) => {
  const response = await API.HTTP.get(`${BASE_URL}/${id}`);

  return response.data;
};
