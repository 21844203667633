import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { DatePicker, DatePickerProps } from "antd";
import MyTable from "@components/Table/MyTable";
import { useAppDispatch, useAppSelector } from "@stores/store";
import { getUserSPBRequest } from "@stores/CE/CEXPSlice";

const SPB = () => {
  const dispatch = useAppDispatch();
  const { userSPB, isLoading } = useAppSelector((state) => state.CEcustomer);

  const startDateRef = useRef<any>(moment().startOf("month").format("YYYY-MM-DD"));
  const endDateRef = useRef<any>(moment().endOf("month").format("YYYY-MM-DD"));

  const handleStartDate: DatePickerProps["onChange"] = (date: any, dateString: any) => {
    startDateRef.current = dateString;
  };

  const handleEndDate: DatePickerProps["onChange"] = (date: any, dateString: any) => {
    endDateRef.current = dateString;
  };

  const handleFilter = () => {
    dispatch(
      getUserSPBRequest({
        startDate: startDateRef.current,
        endDate: endDateRef.current
      })
    );
  };

  useEffect(() => {
    dispatch(
      getUserSPBRequest({
        startDate: startDateRef.current,
        endDate: endDateRef.current
      })
    );
  }, []);

  return (
    <>
      <div className="flex mb-3 items-center gap-2">
        <h1 className="m-0">Filter by</h1>
        <DatePicker
          className="h-9"
          name="startDate"
          onChange={handleStartDate}
          placeholder="Start date"
        />

        <DatePicker
          className="h-9"
          name="endDate"
          onChange={handleEndDate}
          placeholder="End date"
        />
        <button
          onClick={handleFilter}
          className="flex text-medium items-center gap-1 text-center outline-none h-9 w-[10rem] rounded bg-blue-500 hover:bg-blue-400 text-white justify-center"
        >
          Submit
        </button>
      </div>
      <div>
        <MyTable
          dataSource={userSPB}
          loading={isLoading}
          fileName={"user-spb"}
          exportable
          select={[
            { label: "Date", value: "createdAt" },
            { label: "SPB ID", value: "sid" },
            { label: "Username", value: "userName" },
            { label: "Phone", value: "userPhone" }
          ]}
        />
      </div>
    </>
  );
};

export default SPB;
