import React, { useEffect, useRef, useState } from "react";
import { ColumnSortFunction } from "react-data-table-component/dist/src/DataTable/types";
import DataTable, { TableColumn } from "react-data-table-component";
import { checkAndFormatField } from "@components/Detail";
import InputSearch from "@components/inputSearch";
import { filterByKeyword, flattenArray } from "@helpers/utils";
import ExportCSV from "@components/ExportCSV";
import { useAppDispatch, useAppSelector } from "@stores/store";

type IProps = {
  dataSource: any[];
  action?: React.FC;
  functionSort?: ColumnSortFunction<any> | undefined;
  loading?: boolean;
  select?: any[];
  searchable?: boolean;
  exportable?: boolean;
  fetchingPagination?: any;
  Children?: React.ReactNode | undefined;
  fileName: string;
};

const createColumn = (data: any[], select: any[] | undefined, Action: any) => {
  let newColumns: TableColumn<any>[] = [];
  let setSelect = select || ["_id"];

  newColumns = setSelect.map((x: any, _) => ({
    name: x.label ? x.label : x,
    selector: (row: any) => (typeof x === "string" ? row[x] : row[x.value]),
    sortable: true,
    cell: (row: any) => {
      let value = row[x];
      if (typeof x === "object") {
        value = row[x.value];
      }

      return (
        <>
          {value !== "n/a" ? (
            <div className="font-medium bold">{checkAndFormatField(value)}</div>
          ) : (
            <h1 className="text-red-500 ">No data available</h1>
          )}
        </>
      );
    }
  }));

  if (Action) {
    newColumns.push({
      name: "Action",
      selector: (row) => row._id,
      cell: (row) => {
        return <Action {...row} />;
      }
    });
  }

  return newColumns;
};

const MyTable = ({
  dataSource,
  action,
  loading,
  select,
  searchable,
  exportable,
  fetchingPagination,
  Children,
  fileName
}: IProps) => {
  const dispatch = useAppDispatch();

  const [keyword, setKeyword] = useState("");
  const [data, setData] = useState<any[]>(dataSource);
  const [newFetching, setNewFetching] = useState(false);

  useEffect(() => {
    setData(flattenArray(dataSource));
  }, [dataSource, newFetching]);

  useEffect(() => {
    if (keyword) setData(filterByKeyword(dataSource, keyword));
  }, [keyword]);

  return (
    <div>
      <div className="flex">
        <div>{Children}</div>
        <div className="flex-1">
          {searchable && (
            <InputSearch
              value={keyword}
              placeholder="Search by keyword"
              handleChange={(e: React.ChangeEvent<HTMLInputElement>) => setKeyword(e.target.value)}
            />
          )}
        </div>
        <div>{exportable && <ExportCSV csvData={data} fileName={`DailyApp-${fileName}`} />}</div>
      </div>
      <div className="mt-5 w-full overflow-scroll">
        <DataTable
          data={data}
          progressPending={loading}
          columns={createColumn(data, select, action)}
          pagination
          highlightOnHover
          persistTableHead
        />
      </div>
    </div>
  );
};

export default MyTable;
