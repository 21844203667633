import { useAppDispatch, useAppSelector } from "@stores/store";
import {
  createOutletAccountRequest,
  putOutletAccountRequest,
  reset
} from "@stores/dailyojol/OutlietAccountSlice";
import React, { useEffect, FC, useRef, useState } from "react";
import { ICreateOutlet, IDataOutlet } from "@typed/dailojol/Outlet";
import { Switch } from "antd";
import { Toaster } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { getOutletsRequest } from "@stores/dailyojol/OutletSlice";
import { GDetailOjolRegionRequest } from "@stores/dailyojol/RegionSlice";
import SelectComponents from "@components/Select";
import { isEmpty } from "ramda";
import Input from "@components/Input.components";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import swal from "sweetalert";

const validationSchema = {
  name: Yup.string().required(),
  email: Yup.string().required(),
  outlet: Yup.string().required(),
  confirmPassword: Yup.string().required(),
  flagActive: Yup.boolean().required()
};

const AddOutletAccount: FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { status, data, loading } = useAppSelector((state) => state.dailyojolOutletAccount);
  const Outlet = useAppSelector((state) =>
    state.dailyojolOutlet.Outlet.map((item) => ({ label: item.name, value: item._id }))
  );
  const [checkPassword, setCheckPassword] = useState<boolean>(false);
  const [Status, setStatus] = useState<number>(0);

  useEffect(() => {
    dispatch(getOutletsRequest({ skip: 0, limit: 50 }));
  }, []);

  return (
    <>
      <div className="flex items-center justify-center">
        <Toaster />
        <Formik
          initialValues={{} as ICreateOutlet}
          // validationSchema={validationSchema}
          onSubmit={(values) => {
            console.log(values);
            const { confirmPassword, password, outlet, email, name } = values;

            if (id) {
              const Data = {
                name: name,
                email: email,
                outlet: outlet
              };
              dispatch(putOutletAccountRequest({ Data, id })).then(({ payload }) => {
                if (payload.code === 1000) {
                  navigate("/dailyojol/outlet-account");
                  swal("Success!", "You outlet account is update!", "success");
                }
              });
            } else {
              if (checkPassword) return;
              else {
                const payload = {
                  comfirmPassword: confirmPassword,
                  password,
                  outlet,
                  email,
                  name
                };
                dispatch(createOutletAccountRequest(payload)).then(({ payload }) => {
                  navigate("/dailyojol/outlet-account");
                  swal("Success!", "You outlet account is created!", "success");
                });
                setStatus(status);
              }
            }
          }}
        >
          {({ values, handleSubmit, errors, touched, handleChange, setFieldValue }) => {
            const { name, email, flagActive, outlet, confirmPassword, password } = values;

            useEffect(() => {
              if (id) {
                data.filter((item) =>
                  item._id === id
                    ? (setFieldValue("password", item.password),
                      setFieldValue("name", item.name),
                      setFieldValue("email", item.email),
                      setFieldValue("outlet", {
                        label: "Testing",
                        value: "tseting"
                      }))
                    : null
                );
              }
            }, [id]);
            return (
              <Form className="w-[50%] h-auto bg-white rounded  ">
                <h1 className="text-center font-semibold text-[1.3rem] ">
                  {id ? "Edit" : "Create"} Outlet Account
                </h1>

                <div className="flex p-2 flex-col gap-3">
                  <div>
                    <Input
                      // required="after:text-content-['*'] after:text-red-500"
                      title="Name"
                      type="text"
                      placeholder="Name"
                      name="name"
                      value={name ?? ""}
                      onChange={handleChange}
                    />
                  </div>

                  <div>
                    <Input
                      title="Email"
                      type="email"
                      name="email"
                      placeholder="Email"
                      value={email ?? ""}
                      onChange={handleChange}
                    />
                  </div>

                  <div>
                    <h1 className="p-0 m-0 after:content-['*'] after:text-red-500">Outlet</h1>
                    <SelectComponents
                      onChange={(option: any, actionMeta: any) => {
                        console.log("value outlet", option);

                        setFieldValue("outlet", option.value);
                      }}
                      options={Outlet}
                      defaultValue={outlet ?? ""}
                    />
                  </div>

                  {id ? null : (
                    <>
                      <div>
                        <Input
                          title="Password"
                          name="password"
                          type="password"
                          placeholder="Password"
                          value={password ?? ""}
                          onChange={handleChange}
                        />
                      </div>

                      <div>
                        <Input
                          title="Confirm Password"
                          name="confirmPassword"
                          type="password"
                          placeholder="Confirm password"
                          value={confirmPassword ?? ""}
                          onChange={handleChange}
                        />
                      </div>

                      <div>
                        <h1 className="p-0 m-0 after:content-['*'] after:text-red-500">isActive</h1>

                        <Switch
                          defaultChecked
                          onChange={(checked: boolean) => {
                            setFieldValue("flagActive", checked);
                          }}
                        />
                      </div>
                    </>
                  )}
                  <button
                    className="py-2 px-7 hover:bg-green-600 bg-green-500 text-white font-semibold rounded"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
        {/* {Status} */}
      </div>
    </>
  );
};

export default AddOutletAccount;
