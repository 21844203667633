import React from "react";

const CardDetail = () => {
  return (
    <>
      <div className="flex flex-wrap gap-3">
        <div className="w-[12rem] h-[8rem] flex flex-col  justify-center px-6 animate-pulse bg-white">
          <div className="bg-gray-300 w-24 rounded h-4"></div>
          <div className="bg-gray-300 w-12 h-4 mt-2  rounded"></div>
          <div className="bg-gray-300 w-14 h-3 mt-2 rounded"></div>
        </div>
      </div>
    </>
  );
};

export default CardDetail;
