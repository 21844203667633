import React, { useState, useEffect } from "react";
// import MyTable from "@components/Table/MyTable";
import DataTable from "react-data-table-component";
import ExportCSV from "@components/ExportCSV";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "@stores/store";
import {
  CSVSummaryMutasi,
  getSummariesRequest,
  getSummaryMutasiRequest
} from "@stores/dailycoin/ReportingSlice";

const columns = [
  {
    name: "Dailycoin type",
    selector: (row: any) => {
      return row.dailycoinType;
    }
  },
  {
    name: "Total",
    selector: (row: any) => row.total
  }
];

const SummaryMutasi = () => {
  const dispatch = useAppDispatch();
  const { reportSummaryMutasi, SummaryMutasiCSV, isLoading } = useAppSelector(
    (state) => state.dailyReport
  );

  useEffect(() => {
    dispatch(getSummaryMutasiRequest());
  }, []);

  useEffect(() => {
    dispatch(CSVSummaryMutasi());
  }, [isLoading]);

  return (
    <>
      <div className="flex justify-end">
        <ExportCSV fileName={"summary-mutasi"} csvData={SummaryMutasiCSV} />
      </div>
      <div className="mt-3">
        <DataTable data={reportSummaryMutasi} columns={columns} />
      </div>
    </>
  );
};

export default SummaryMutasi;
