import { useAppSelector } from "@stores/store";
import React from "react";
import { Outlet, Link } from "react-router-dom";
import Forbidden from "@components/forbidden";
import useRole from "@hooks/useRole";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { IoPeopleOutline } from "react-icons/io5";
import { PieChartOutlined } from "@ant-design/icons";
import { TbReport } from "react-icons/tb";

const MenuBrandSettlement = () => {
  const employeeData = useAppSelector((state) => state.auth.employeeData);
  const isForbidden = !useRole(employeeData.roles, "BRAND-SETTLEMENT");
  return isForbidden ? (
    <Forbidden />
  ) : (
    <>
      <div className="flex mt-[70px] flex-row">
        <div className="w-[200px]">
          <ProSidebar width={200} className="fixed">
            <Menu iconShape="square">
              <MenuItem key="dailycoint" icon={<PieChartOutlined />}>
                <Link to="/brand-settlement/brand-payment">Brand Payment</Link>
              </MenuItem>
            </Menu>
          </ProSidebar>
        </div>
        <div className="flex-1 p-5 bg-slate-100 min-h-screen">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default MenuBrandSettlement;
