import React, { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@stores/store";
import { getVoucherOnRegisterReq } from "@stores/dailyapp/VoucherSlice";
import { useParams } from "react-router-dom";
import MyTable from "@components/Table/MyTable";

const VoucherOnRegister = () => {
  const { id } = useParams();
  const { VoucherOnReg, isLoadingVoucherOnReg } = useAppSelector((state) => state.dailyVoucher);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (id) {
      dispatch(getVoucherOnRegisterReq(id));
    }
  }, [id]);

  return (
    <>
      <div className="pt-3">
        <MyTable
          fileName="voucher-on-register"
          dataSource={VoucherOnReg}
          loading={isLoadingVoucherOnReg}
          exportable
          select={[
            { label: "id", value: "_id" },
            { label: "date", value: "createdAt" },
            { label: "Brand", value: "brand" },
            { label: "Code", value: "code" },
            { label: "Price", value: "price" }
          ]}
        />
      </div>
    </>
  );
};

export default VoucherOnRegister;
