import React, { useEffect, lazy, Suspense } from "react";
import { Routes, Route, useNavigate, Link } from "react-router-dom";
import Dashboard from "@screens/dashboard";
import Login from "@screens/login";
import Secure from "@layouts/secure";

import Loading from "@components/loading";
// import PageNotFound from "@screens/pageNotFound";
import PageNotFound from "@screens/NotFound";
import Dailyapp from "@screens/dailyapp";
import Hris from "@screens/hris";

// ===== DAILYAPP =====
import Dailyojol from "@screens/dailyojol";
import DailyappDashboard from "@screens/dailyapp/dashboard";
import Users from "@screens/dailyapp/Users/users";
import SingleUser from "@screens/dailyapp/Users/singleUsers";
import Reviews from "@screens/dailyapp/Feedback/reviews";
import Complains from "@screens/dailyapp/Feedback/complains";
import SingleComplain from "@screens/dailyapp/Feedback/singleComplain";
import Products from "@screens/dailyapp/Products/products";
import AddProduct from "@screens/dailyapp/Products/addProduct";
import Product from "@screens/dailyapp/Products/singleProduct";
import SingleRedem from "@screens/dailyojol/singleRedem";
import ConfirmRedem from "@screens/dailyojol/confirmRedem";
import Transaction from "@screens/dailyapp/Transaction/Transaction";
import Redem from "@screens/dailyojol/Redem";
import News from "@screens/dailyapp/News/News";
import SingleNews from "@screens/dailyapp/News/SingleNews";
import AddNews from "@screens/dailyapp/News/AddNews";
import Banner from "@screens/dailyapp/Banner/Banner";
import SingleBanner from "@screens/dailyapp/Banner/SingleBanner";
import AddBanner from "@screens/dailyapp/Banner/AddBanner";
import BannerProfile from "@screens/dailyapp/BannerProfile/BannerProfile";
import Outlets from "@screens/dailyapp/Outlet/Outlets";
import SingleOutlet from "@screens/dailyapp/Outlet/SingleOutlet";
import AddOutlet from "@screens/dailyapp/Outlet/AddOutlet";
import CreateOutletAccount from "@screens/dailyapp/Outlet/AddOutletAccount";
import OutletAccounts from "@screens/dailyapp/Outlet/OutletAccounts";
import SingleOutletAccount from "@screens/dailyapp/Outlet/SingleOutletAccount";
import AddBannerProfile from "@screens/dailyapp/BannerProfile/AddBannerProfile";
import BannerDailycoin from "@screens/dailyapp/BannerDailycoin/BannerDailycoin";
import AddBannerDailycoin from "@screens/dailyapp/BannerDailycoin/AddBannerDailycoin";
import AddBannerBrand from "@screens/dailyapp/BannerBrand/AddBannerBrand";
import BannerBrand from "@screens/dailyapp/BannerBrand/BannerBrand";
import SingleBannerDailycoin from "@screens/dailyapp/BannerDailycoin/SingleBannerDailycoin";
import SingleBannerProfile from "@screens/dailyapp/BannerProfile/SingleBannerProfile";
import SingleBannerBrand from "@screens/dailyapp/BannerBrand/SingleBannerBrand";
import Voucher from "@screens/dailyapp/Voucher/Voucher";
import VoucherHistory from "@screens/dailyapp/Voucher/VoucherHistory";
import QandA from "@screens/dailyapp/Q&A/QandA";
import SingleQandA from "@screens/dailyapp/Q&A/SingleQandA";
import PushNotification from "@screens/dailyapp/PushNotification/pushNotification";
import AddMembership from "@screens/dailyapp/Users/AddMembership";
import VoucherOnRegister from "@screens/dailyapp/Voucher/VoucherOnRegister";
import VoucherUser from "@screens/dailyapp/Voucher/VoucherUser";

// ===== DAILYCLICK =====
import Dailyclick from "@screens/Dailyclick/Product";
// import ProductClick from "@screens/Dailyclick/Product";
const ProductClick = lazy(() => import("@screens/Dailyclick/Product"));
import AddProductClick from "@screens/Dailyclick/Product/AddProduct";
import SingleProductClick from "@screens/Dailyclick/Product/SingleProduct";

// ===== DAILYOJOL =====
import DailyojolUsers from "@screens/dailyojol/user/listUser";
import DailyojolUserDetail from "@screens/dailyojol/user/[id]";
import DailyojolOutlets from "@screens/dailyojol/Outlet/listOutlet";
import DailyojolAddOutlet from "@screens/dailyojol/Outlet/AddOutlet";
import DailyojolDetailOutlet from "@screens/dailyojol/Outlet/[id]";
import DailyojolAddOutletAccount from "@screens/dailyojol/OutletAccount/AddOutletAccount";
import DailyojolOutletAccount from "@screens/dailyojol/OutletAccount/outletAccount";
import DailyojolOutletAccDetail from "@screens/dailyojol/OutletAccount/[id]";
import DailyojolProduct from "@screens/dailyojol/product/Products";
import DailyojolRegion from "@screens/dailyojol/region/Region";
import DailyojolRedem from "@screens/dailyojol/redem/Redem";
import DailyojolCode from "@screens/dailyojol/code/Code";
import DailyojolProductDetail from "@screens/dailyojol/product/[id]";
import DailyojolCreateRegion from "@screens/dailyojol/region/AddRegion";
import DailyojolProductCreate from "@screens/dailyojol/product/AddProduct";
import DailyojolDashboard from "@screens/dailyojol/dailyojolDashboard";
import DailyojolRegionDetail from "@screens/dailyojol/redem/[id]";
// import { createBrowserHistory as createHistory } from "history";
// import {createBrowserHistory}

// ===== HRIS =====
import Organization from "@screens/hris/Organization";
import SingleOrganization from "@screens/hris/SingleOrganization";
import AddOrganization from "@screens/hris/addOrganization";
import Position from "@screens/hris/position/Position";
import AddPosition from "@screens/hris/position/addPosition";
import SinglePosition from "@screens/hris/position/SinglePosition";
import Levels from "@screens/hris/Level/Level";
import SingleLevel from "@screens/hris/Level/SingleLevel";
import Status from "@screens/hris/Status/Status";
import AddStatus from "@screens/hris/Status/addStatus";
import AddLevel from "@screens/hris/Level/addLevel";
import SingleStatus from "@screens/hris/Status/SingleStatus";
import Ptkp from "@screens/hris/PTKP/Ptkp";
import SinglePtkp from "@screens/hris/PTKP/SinglePtkp";
import AddPtkp from "@screens/hris/PTKP/addPtkp";
import Person from "@screens/hris/Person/Person";
import StepPerson from "@screens/hris/Person";
import AddBankAccount from "@screens/hris/Bank/addBankAccount";
import ApprovalEmploye from "@screens/hris/Employe/Approval";
import SinglePerson from "@screens/hris/Person/SinglePerson";
import NameCard from "@screens/hris/name-card/NameCard";
import SingleNameCard from "@screens/hris/name-card/SingleNameCard";
import AddNameCard from "@screens/hris/name-card/AddNameCard";

// ===== DAILYCOIN =====
import DailyCoin from "@screens/dailycoin";
import DashboardCoin from "@screens/dailycoin/DashboardCoin";
import ReviewOutlet from "@screens/dailycoin/Review";
import ReportPayment from "@screens/dailycoin/Report";
import SPB from "@screens/ce/SPB";

// ==== ce ===
import DashboardCe from "@screens/ce";
import Report from "@screens/ce/Report/Report";

import InboxWeb from "@screens/ce/Feedback/WebInbox";
import ReportReview from "@screens/ce/Report/ReportReview";
import ReportComplain from "@screens/ce/Report/ReportComplain";
import DetailReview from "@screens/ce/Report/DetailReview";
import DetailComplain from "@screens/ce/DetailComplain";
import DetailRegisterCashback from "@screens/ce/Report/DetailRegisterCashback";
import Event from "@screens/ce/Event/Event";
import DetailEvent from "@screens/ce/Event/DetailEvent";
import MenuBrandSettlement from "@screens/brandSettlement";
import BrandPayment, { ExistingBrand } from "@screens/brandSettlement/BrandPayment";

// PROMO
import MenuPromo from "@screens/promo";
import VoucherReport from "@screens/dailyapp/Voucher/VoucherReport";
import Questions from "@screens/dailyapp/Q&A/Questions";
import SingleQuestion from "@screens/dailyapp/Q&A/SingleQuestion";
import Answer from "@screens/dailyapp/Q&A/Answer";
import TopupProvider from "@screens/dailycoin/Report/Topup/TopupProvider";
import SingleTopupProvider from "@screens/dailycoin/Report/Topup/SingleTopupProvider";
import UserAcquisition from "@screens/ce/UserAcquisition";
import DailyCashier from "@screens/ce/DailyCashier/DailyCashier";

function App() {
  return (
    <Routes>
      <Route element={<Secure />}>
        <Route path="/" element={<Dashboard />} />
        {/* ====== DAILYAPP ======*/}
        <Route path="/dailyapp" element={<Dailyapp />}>
          <Route path="/dailyapp/" element={<DailyappDashboard />} />
          <Route path="/dailyapp/products" element={<Products />} />
          <Route path="/dailyapp/products/add" element={<AddProduct />} />
          <Route path="/dailyapp/products/edit/:id" element={<AddProduct />} />
          <Route path="/dailyapp/products/:id" element={<Product />} />
          <Route path="/dailyapp/users" element={<Users />} />
          <Route path="/dailyapp/users/:id" element={<SingleUser />} />
          <Route path="/dailyapp/outlets" element={<Outlets />} />
          <Route path="/dailyapp/outlet/:id" element={<SingleOutlet />} />
          <Route path="/dailyapp/outlet/add" element={<AddOutlet />} />
          <Route path="/dailyapp/outlet/edit/:id" element={<AddOutlet />} />
          <Route path="/dailyapp/add-outlet-account/:id" element={<CreateOutletAccount />} />
          <Route path="/dailyapp/outlet-account" element={<OutletAccounts />} />
          <Route path="/dailyapp/outlet-account/:id" element={<SingleOutletAccount />} />
          {/*  */}
          <Route path="/dailyapp/reviews" element={<Reviews />} />
          <Route path="/dailyapp/complains" element={<Complains />} />
          <Route path="/dailyapp/complains/:id" element={<SingleComplain />} />
          <Route path="/dailyapp/redem" element={<Redem />} />
          <Route path="/dailyapp/redem/add" element={<ConfirmRedem />} />
          <Route path="/dailyapp/redem/:id" element={<SingleRedem />} />
          <Route path="/dailyapp/transaction" element={<Transaction />} />
          <Route path="/dailyapp/news" element={<News />} />
          <Route path="/dailyapp/news/:id" element={<SingleNews />} />
          <Route path="/dailyapp/news/add" element={<AddNews />} />
          <Route path="/dailyapp/news/edit/:id" element={<AddNews />} />
          <Route path="/dailyapp/news/edit/:id" element={<AddNews />} />
          <Route path="/dailyapp/banner" element={<Banner />} />
          <Route path="/dailyapp/banner/:id" element={<SingleBanner />} />
          <Route path="/dailyapp/banner/add" element={<AddBanner />} />
          <Route path="/dailyapp/banner/:id" element={<SingleBanner />} />
          <Route path="/dailyapp/vouchers" element={<Voucher />} />
          <Route path="/dailyapp/voucher-history" element={<VoucherHistory />} />
          <Route path="/dailyapp/voucher-report" element={<VoucherReport />} />
          <Route path="/dailyapp/banner-profile/:id" element={<SingleBannerProfile />} />
          <Route path="/dailyapp/banner-profile" element={<BannerProfile />} />
          <Route
            path="/dailyapp/banner-profile/add-banner-profile"
            element={<AddBannerProfile />}
          />
          <Route path="/dailyapp/banner-profile/edit/:id" element={<AddBannerProfile />} />
          <Route
            path="/dailyapp/banner-brand/add-banner-brand"
            element={<AddBannerBrand title="Banner" />}
          />
          <Route
            path="/dailyapp/banner-brand/add-banner-brand/edit/:id"
            element={<AddBannerBrand title={"Banner"} />}
          />
          <Route path="/dailyapp/banner-brand/" element={<BannerBrand title={"Banner"} />} />
          <Route path="/dailyapp/banner-brand/:id" element={<SingleBannerBrand />} />
          <Route path="/dailyapp/banner-dailycoin" element={<BannerDailycoin />} />
          <Route
            path="/dailyapp/banner-dailycoin/add-banner-dailycoin"
            element={<AddBannerDailycoin />}
          />
          <Route path="/dailyapp/banner-dailycoin/:id" element={<SingleBannerDailycoin />} />
          <Route path="/dailyapp/banner-dailycoin/edit/:id" element={<AddBannerDailycoin />} />
          <Route path="/dailyapp/questions-batch" element={<QandA />} />
          <Route path="/dailyapp/question-batch/:id" element={<SingleQandA />} />
          <Route path="/dailyapp/questions" element={<Questions />} />
          <Route path="/dailyapp/question/:id" element={<SingleQuestion />} />
          <Route path="/dailyapp/answer" element={<Answer />} />
          <Route path="/dailyapp/push-notification" element={<PushNotification />} />
          <Route path="/dailyapp/membership" element={<AddMembership />} />
          <Route path="/dailyapp/voucher-on-register/:id" element={<VoucherOnRegister />} />
          <Route path="/dailyapp/user-voucher" element={<VoucherUser />} />

          <Route path="/dailyapp/*" element={<PageNotFound />} />
        </Route>

        {/* ====== DAILYCLICK ======*/}
        <Route path="/dailyclick" element={<Dailyclick />}>
          <Route
            path="/dailyclick/product"
            element={
              <Suspense fallback={<Loading />}>
                <ProductClick />
              </Suspense>
            }
          />
          <Route path="/dailyclick/product/:id" element={<SingleProductClick />} />
          <Route path="/dailyclick/product/add" element={<AddProductClick />} />
          <Route path="/dailyclick/product/update/:id" element={<AddProductClick />} />
        </Route>

        {/* ====== DAILYOJOL ======*/}
        <Route path="/dailyojol" element={<Dailyojol />}>
          <Route path="/dailyojol/" element={<DailyojolDashboard />} />
          <Route path="/dailyojol/users" element={<DailyojolUsers />} />
          <Route path="/dailyojol/user/:id" element={<DailyojolUserDetail />} />
          <Route path="/dailyojol/outlet" element={<DailyojolOutlets />} />
          <Route path="/dailyojol/outlet/:id" element={<DailyojolDetailOutlet />} />
          <Route path="/dailyojol/outlet/create-outlet" element={<DailyojolAddOutlet />} />
          <Route path="/dailyojol/outlet/edit-outlet/:id" element={<DailyojolAddOutlet />} />
          <Route path="/dailyojol/outlet-account" element={<DailyojolOutletAccount />} />
          <Route path="/dailyojol/outlet-account/:id" element={<DailyojolOutletAccDetail />} />
          <Route
            path="/dailyojol/outlet-account/create-outlet"
            element={<DailyojolAddOutletAccount />}
          />
          <Route
            path="/dailyojol/outlet-account/edit-outlet/:id"
            element={<DailyojolAddOutletAccount />}
          />
          <Route path="/dailyojol/product" element={<DailyojolProduct />} />
          <Route path="/dailyojol/product/create-product" element={<DailyojolProductCreate />} />
          <Route path="/dailyojol/product/edit-product/:id" element={<DailyojolProductCreate />} />
          <Route path="/dailyojol/product/:id" element={<DailyojolProductDetail />} />
          <Route path="/dailyojol/region" element={<DailyojolRegion />} />
          <Route path="/dailyojol/region/create-region" element={<DailyojolCreateRegion />} />
          <Route path="/dailyojol/region/edit-region/:id" element={<DailyojolCreateRegion />} />
          <Route path="/dailyojol/code" element={<DailyojolCode />} />
          <Route path="/dailyojol/redem" element={<DailyojolRedem />} />
          <Route path="/dailyojol/redem/:id" element={<DailyojolRegionDetail />} />
        </Route>

        {/* ====== HRIS ======*/}
        <Route path="/hris" element={<Hris />}>
          <Route path="/hris/organization" element={<Organization />} />
          <Route path="/hris/organization/:id" element={<SingleOrganization />} />
          <Route path="/hris/organization/edit/:id" element={<AddOrganization />} />
          <Route path="/hris/organization/add" element={<AddOrganization />} />
          <Route path="/hris/position" element={<Position />} />
          <Route path="/hris/position/:id" element={<SinglePosition />} />
          <Route path="/hris/position/add" element={<AddPosition />} />
          <Route path="/hris/position/edit/:id" element={<AddPosition />} />
          <Route path="/hris/level" element={<Levels />} />
          <Route path="/hris/level/add" element={<AddLevel />} />
          <Route path="/hris/level/edit/:id" element={<AddLevel />} />
          <Route path="/hris/level/:id" element={<SingleLevel />} />
          <Route path="/hris/status" element={<Status />} />
          <Route path="/hris/status/add" element={<AddStatus />} />
          <Route path="/hris/status/edit/:id" element={<AddStatus />} />
          <Route path="/hris/status/:id" element={<SingleStatus />} />
          <Route path="/hris/ptkp" element={<Ptkp />} />
          <Route path="/hris/ptkp/add" element={<AddPtkp />} />
          <Route path="/hris/ptkp/edit/:id" element={<AddPtkp />} />
          <Route path="/hris/ptkp/:id" element={<SinglePtkp />} />
          <Route path="/hris/person" element={<Person />} />
          <Route path="/hris/employe-approval" element={<ApprovalEmploye />} />
          <Route path="/hris/person/add" element={<StepPerson />} />
          <Route path="/hris/person/edit/:id" element={<StepPerson />} />
          <Route path="/hris/person/:id" element={<SinglePerson />} />
          <Route path="/hris/bank/add" element={<AddBankAccount />} />
          <Route path="/hris/name-card" element={<NameCard />} />
          <Route path="/hris/name-card/add" element={<AddNameCard />} />
          <Route path="/hris/name-card/edit/:id" element={<AddNameCard />} />
          <Route path="/hris/name-card/:id" element={<SingleNameCard />} />
        </Route>

        <Route path="/daily-coin" element={<DailyCoin />}>
          <Route path="/daily-coin/" element={<DashboardCoin />} />
          <Route path="/daily-coin/review/:id" element={<ReviewOutlet />} />
          <Route path="/daily-coin/report-payment" element={<ReportPayment />} />
          <Route path="/daily-coin/report-topup/:id" element={<SingleTopupProvider />} />
          <Route path="/daily-coin/users" element={<Users />} />
          <Route path="/daily-coin/users/:date" element={<SingleUser />} />
        </Route>

        {/* ===== ce === */}
        <Route path="/costumer-experience" element={<DashboardCe />}>
          <Route path="/costumer-experience/reviews" element={<Reviews />} />
          <Route path="/costumer-experience/complains" element={<Complains />} />
          <Route path="/costumer-experience/complains/:id" element={<SingleComplain />} />
          <Route path="/costumer-experience/inbox-web" element={<InboxWeb />} />

          <Route path="/costumer-experience/report-review/:id" element={<DetailReview />} />
          <Route path="/costumer-experience/customer" element={<Users />} />
          <Route path="/costumer-experience/customer/:id" element={<SingleUser />} />
          <Route path="/costumer-experience/report/" element={<Report />} />
          <Route path="/costumer-experience/complains-report/:id" element={<DetailComplain />} />
          <Route path="/costumer-experience/event" element={<Event />} />
          <Route path="/costumer-experience/event/:id" element={<DetailEvent />} />
          <Route path="/costumer-experience/spb" element={<SPB />} />
          <Route path="/costumer-experience/membership" element={<AddMembership />} />
          {/* <Route path="/costumer-experience/user-acquisition" element={<UserAcquisition />} /> */}
          <Route path="/costumer-experience/daily-cashier/" element={<DailyCashier />} />

          <Route
            path="/costumer-experience/report/register/:id"
            element={<DetailRegisterCashback />}
          />
        </Route>

        {/* BRAND-SETTLEMENT */}
        <Route path="/brand-settlement/" element={<ExistingBrand />} />
        <Route path="/brand-settlement" element={<MenuBrandSettlement />}>
          <Route path="/brand-settlement/brand-payment" element={<BrandPayment />} />
        </Route>

        {/* PROMO */}

        <Route path="/promo" element={<MenuPromo />}>
          <Route path="/promo/promo-brand/" element={<BannerBrand title={"Promo"} />} />
          <Route path="/promo/promo-brand/add-brand" element={<AddBannerBrand title="Promo" />} />
          <Route
            path="/promo/promo-brand/add-brand/edit/:id"
            element={<AddBannerBrand title="Promo" />}
          />
          <Route path="/promo/promo-brand/:id" element={<SingleBannerBrand />} />
        </Route>

        {/* ====== def ====== */}
        <Route path="*" element={<PageNotFound />} />
      </Route>
      <Route path="login" element={<Login />} />
    </Routes>
  );
}

export default App;
