import React, { useEffect, useRef } from "react";
import swal from "sweetalert";
import { Form, Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { postNewsRequest, putNewsRequest } from "@stores/dailyapp/NewsSlice";
import { useAppDispatch, useAppSelector } from "@stores/store";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import Loading from "@components/loading";
import MDEditor from "@uiw/react-md-editor";
import rehypeSanitize from "rehype-sanitize";
import MarkdownIt from "markdown-it";
// import style manually

const validationSchema = Yup.object({
  title: Yup.string().required(),
  description: Yup.string().required(),
  content: Yup.string().required(),
  image: Yup.string().required()
});

interface IValues {
  title: string;
  description: string;
  content: string;
  image: null | any;
}

const AddNews = () => {
  const imageRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const { data, isLoading } = useAppSelector((state) => state.dailyNews);

  const { id } = useParams();
  const navigate = useNavigate();

  const renderError = (message: string) => <p className="text-red-500 is-danger ">{message}</p>;
  return (
    <>
      <Toaster />
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="flex items-center justify-center">
            <Formik
              validationSchema={validationSchema}
              initialValues={{} as IValues}
              enableReinitialize={true}
              onSubmit={(values, { resetForm, setStatus }) => {
                const { title, image, content, description } = values;
                const formData = new FormData();
                formData.append("title", title);
                formData.append("image", image);
                formData.append("description", description);
                formData.append("content", content);

                return id
                  ? dispatch(putNewsRequest({ formData, id })).then(({ payload }) => {
                      payload.code === 1000 &&
                        swal({
                          title: "Success!",
                          text: "You news is update!",
                          icon: "success",

                          buttons: {
                            roll: {
                              text: "OK",
                              value: navigate("/dailyapp/news")
                            }
                          }
                        });
                    })
                  : dispatch(postNewsRequest(formData)).then(({ payload }) => {
                      if (payload.code === 1000) {
                        imageRef.current = null;
                        swal({
                          title: "Success!",
                          text: "You news is created!",
                          icon: "success",
                          buttons: {
                            roll: {
                              text: "OK",
                              value: navigate("/dailyapp/news")
                            }
                          }
                        });
                      }
                    });
              }}
            >
              {({ values, handleChange, handleSubmit, setFieldValue }) => {
                useEffect(() => {
                  if (id) {
                    data.find(
                      (item) =>
                        item._id === id &&
                        (setFieldValue("title", item.title),
                        setFieldValue("content", item.content),
                        setFieldValue("description", item.description),
                        setFieldValue("image", item.image.mediaUrl),
                        (imageRef.current = item.image.mediaUrl))
                    );
                  }
                }, []);

                const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
                  let file = e.target.files;
                  if (file) {
                    setFieldValue("image", file[0]);
                    imageRef.current = URL.createObjectURL(file[0]);
                  }
                };
                return (
                  <Form className="w-[50%] p-2 bg-white gap-3 h-auto rounded shadow flex flex-col">
                    <h1 className="text-center text-[1.1rem]">{id ? "Edit" : "Create"} News</h1>

                    <div>
                      <h1 className="font-semibold p-0 m-0 after:text-red-500 after:content-['*']">
                        Title
                      </h1>
                      <input
                        className="border-gray px-2 rounded focus:outline-none border-[1px] h-9 w-full"
                        placeholder="Title"
                        value={values.title}
                        onChange={handleChange}
                        name="title"
                      />
                      <ErrorMessage name="title" render={renderError} />
                    </div>

                    <div>
                      <h1 className="font-semibold p-0 m-0 after:text-red-500 after:content-['*']">
                        Description
                      </h1>
                      <textarea
                        className="border-gray px-2 rounded focus:outline-none border-[1px] h-20 resize-none overflow-y-scroll w-full"
                        placeholder="Description"
                        name="description"
                        onChange={handleChange}
                        value={values.description}
                      />
                      <ErrorMessage name="description" render={renderError} />
                    </div>

                    <div>
                      <h1 className="font-semibold p-0 m-0 after:text-red-500 after:content-['*']">
                        Thumbnail
                      </h1>
                      <div className="flex  items-center">
                        {imageRef.current === null ? (
                          <label className="w-[30%]  dark:text-white flex flex-col justify-center  bg-white text-blue rounded-lg shadow tracking-wide  border border-blue cursor-pointer hover:bg-blue-400 hover:text-white">
                            <svg
                              className="h-6 w-6 mx-auto"
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                            >
                              <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                            </svg>
                            <p className="text-left  m-auto">Select a file</p>
                            <input className="hidden" onChange={handleUpload} type="file" />
                          </label>
                        ) : (
                          <div className="flex items-center gap-2">
                            <img src={imageRef.current} className=" w-30 h-20 rounded" />
                            <div>
                              <label className="w-auto flex flex-col justify-center  bg-white text-blue rounded shadow tracking-wide  border border-blue cursor-pointer   p-2">
                                Change
                                <input className="hidden" onChange={handleUpload} type="file" />
                              </label>
                            </div>
                            <div>
                              <button
                                onClick={() => {
                                  imageRef.current = null;
                                  setFieldValue("image", null);
                                }}
                                className="border-none outline-none focus:outline-none focus:border-none text-red-500"
                              >
                                Remove
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div>
                      <h1 className="font-semibold p-0 m-0 after:text-red-500 after:content-['*']">
                        Content
                      </h1>
                      <div data-color-mode="light" className="">
                        <MDEditor
                          value={values.content ?? ""}
                          className="h-[350px]"
                          onChange={(e) => setFieldValue("content", e)}
                          previewOptions={{
                            rehypePlugins: [[rehypeSanitize]]
                          }}
                        />
                      </div>

                      <ErrorMessage name="description" render={renderError} />
                    </div>
                    <button
                      className="py-2 px-4 font-semibold bg-green-500 text-white hover:bg-green-600 rounded"
                      type="submit"
                    >
                      Submit
                    </button>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </>
      )}
    </>
  );
};

export default AddNews;
