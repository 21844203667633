import React, { useCallback, useEffect, useState, useRef } from "react";
import { Table, Popover } from "antd";
import { useAppDispatch, useAppSelector } from "@stores/store";
import { delOjolRegionRequest, getOjolRegionRequest } from "@stores/dailyojol/RegionSlice";
import { dateToString } from "@helpers/utils";
import { IoTrashOutline } from "react-icons/io5";
import Loading from "@components/loading";
import { Link } from "react-router-dom";
import { HiOutlinePencilAlt } from "react-icons/hi";
import { IDataDetailRegion } from "@typed/dailojol/Region";
import ReactModal from "@components/ReactModal";
const Region: React.FC = () => {
  const dispatch = useAppDispatch();
  const { data, loading } = useAppSelector((state) => state.dailyojolRegion);

  const Regions = data.map((x) => ({ ...x, key: x._id }));
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [text, setText] = useState<string>("");

  const getId = useRef<string>("");

  useEffect(() => {
    dispatch(getOjolRegionRequest());
  }, []);
  const column = [
    { key: "name", title: "Name", dataIndex: "name" },
    {
      key: "authority",
      title: "Author",
      dataIndex: "authority"
    },
    {
      key: "createdAt",
      title: "Date ",
      dataIndex: "createdAt",
      render: (createdAt: string) => <div>{dateToString(createdAt)}</div>
    },
    {
      key: "action",
      title: "Action",
      render: (data: IDataDetailRegion) => (
        <div className="flex items-center gap-4">
          <Popover content="Delete">
            <button onClick={() => showModal(data._id)}>
              <IoTrashOutline color="red" />
            </button>
          </Popover>

          <Popover content="Edit">
            <Link to={`/dailyojol/region/edit-region/${data._id}`}>
              <HiOutlinePencilAlt className="text-blue-500 text-[1.1rem]" />
            </Link>
          </Popover>
        </div>
      )
    }
  ];

  const showModal = useCallback(
    (id: string) => {
      setIsModalVisible((prev: boolean) => !prev);
      setText("Are you sure you want to delete the Region ?");
      getId.current = id;
    },
    [getId]
  );

  const handleOkModal = useCallback(() => {
    setIsModalVisible((prev) => !prev);
    dispatch(delOjolRegionRequest(getId.current));
    setTimeout(() => {
      getId.current = "";
    }, 2000);
  }, [setIsModalVisible, getId]);

  const handleCancel = useCallback(() => {
    setIsModalVisible((prev) => !prev);
  }, []);

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <>
          <ReactModal
            title="Confirm"
            handleOkModal={handleOkModal}
            text={text}
            handleCancel={handleCancel}
            isModalVisible={isModalVisible}
          />
          <Link
            to="/dailyojol/region/create-region"
            className="py-3 hover:bg-green-600 hover:text-white rounded px-6 text-white font-semibold mb-5 bg-green-500"
          >
            Create Region
          </Link>
          <Table className="mt-5" dataSource={Regions} columns={column} />
        </>
      )}
    </div>
  );
};

export default Region;
