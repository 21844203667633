import { useAppSelector } from "@stores/store";
import { IPayloadAdd } from "@typed/HRIS/allHris";
import React from "react";
import { AiOutlineLoading } from "react-icons/ai";
import { Button, Spin } from "antd";

interface IProps {
  title: string;
  Name?: string;
  value: IPayloadAdd;
  isLoading: boolean;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  id?: string;
  handleSubmit: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const InputForm: React.FC<IProps> = ({
  isLoading,
  id,
  handleSubmit,
  value,
  handleChange,
  title,
  Name
}) => {
  const { name, description } = value;

  return (
    <>
      <div className="w-full flex justify-center">
        <form className="bg-white w-[45%] shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <h1 className="text-center text-[1.1rem]">
            {id ? "Update " : "Create "}
            {/* {title} */}
          </h1>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold after:content-['*'] after:text-red-500">
              Name
            </label>
            <input
              className="border border-slate-400 w-full h-8 rounded px-3"
              id="username"
              onChange={handleChange}
              value={name ?? ""}
              type="text"
              name="name"
              placeholder="Name"
            />
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold " htmlFor="password">
              Description
            </label>
            <input
              className="border border-slate-400 w-full h-8 rounded px-3"
              type="text"
              placeholder="Description"
              onChange={handleChange}
              value={description ?? ""}
              name="description"
            />
          </div>
          <Button
            loading={isLoading}
            onClick={handleSubmit}
            type="primary"
            className={
              isLoading
                ? "cursor-not-allowed flex gap-2 items-center justify-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                : "flex gap-2 items-center justify-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            }
          >
            Submit
          </Button>
        </form>
      </div>
    </>
  );
};

export default InputForm;
