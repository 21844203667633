import React, { useEffect, useRef, useState } from "react";
import { ColumnSortFunction } from "react-data-table-component/dist/src/DataTable/types";
import DataTable, { TableColumn } from "react-data-table-component";
import { checkAndFormatField } from "@components/Detail";
import { AiOutlineDownload } from "react-icons/ai";
import InputSearch from "@components/inputSearch";
import { flattenArray } from "@helpers/utils";
import ExportCSV from "@components/ExportCSV";
import { useAppDispatch } from "@stores/store";
import { LIMIT } from "@stores/dailyapp/UserAPI";
import { useDebounce } from "use-debounce";
import ModalMyTablePagination, { IPropsModal } from "./ModalMyTablePagination";

type IProps = {
  dataSource: any[];
  action?: React.FC;
  functionSort?: ColumnSortFunction<any> | undefined;
  setPage?: any;
  loading?: boolean;
  titleModal?: string;
  skip?: number;
  setSkip?: any;
  select?: any[];
  fileName: string;
  searchable?: boolean;
  exportable?: boolean;
  totalUser?: number;
  fetchingPagination?: any;
  ModalChildren?: React.ReactNode | undefined;
  funcResetPage?: any;
};

const createColumn = (data: any[], select: any[] | undefined, Action: any) => {
  let newColumns: TableColumn<any>[] = [];
  let setSelect = select || ["_id"];

  newColumns = setSelect.map((x: any, _) => ({
    name: x.label ? x.label : x,
    selector: (row: any) => (typeof x === "string" ? row[x] : row[x.value]),
    sortable: true,
    cell: (row: any) => {
      let value = row[x];
      if (typeof x === "object") {
        value = row[x.value];
      }

      return (
        <>
          {value !== "n/a" ? (
            <div className="font-medium bold">{checkAndFormatField(value)}</div>
          ) : (
            <h1 className="text-red-500 ">No data available</h1>
          )}
        </>
      );
    }
  }));

  if (Action) {
    newColumns.push({
      name: "Action",
      selector: (row) => row._id,
      cell: (row) => {
        return <Action {...row} />;
      }
    });
  }

  return newColumns;
};

const MyPaginationTable = ({
  dataSource,
  action,
  loading,
  titleModal,
  select,
  searchable,
  exportable,
  totalUser,
  skip,
  setSkip,
  fetchingPagination,
  funcResetPage,
  setPage,
  ModalChildren,
  fileName
}: IProps) => {
  const dispatch = useAppDispatch();

  const [keyword, setKeyword] = useState("");
  const [debounceKeyword] = useDebounce(keyword, 1000);
  const [data, setData] = useState<any[]>(dataSource);
  const [showModal, setShowModal] = useState<boolean>(false);

  const fetchData = (page: number = 1, keyword?: string) => {
    const payload = {
      page,
      keyword
    };
    dispatch(fetchingPagination(payload));
  };

  const handlePageChange = (page: number) => {
    if (setPage) {
      setPage();
    } else {
      dispatch(setSkip());
    }
    fetchData(page);
  };

  useEffect(() => {
    if (keyword.length > 2) {
      fetchData(0, keyword);
    } else {
      fetchData(0);
    }
  }, [debounceKeyword]);

  useEffect(() => {
    if (funcResetPage) {
      dispatch(funcResetPage());
    }
  }, [keyword]);

  useEffect(() => {
    setData(flattenArray(dataSource));
  }, [dataSource]);

  return (
    <>
      <ModalMyTablePagination
        isOpen={showModal}
        title={titleModal ? titleModal : ""}
        closeModal={() => setShowModal(!showModal)}
      >
        {ModalChildren}
      </ModalMyTablePagination>
      <div>
        <div className="flex">
          <div className="flex-1">
            {searchable && (
              <InputSearch
                value={keyword}
                placeholder="Search by keyword"
                handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setKeyword(e.target.value);
                }}
              />
            )}
          </div>
          <div>{exportable && <ExportCSV csvData={data} fileName={`DailyApp-${fileName}`} />}</div>
        </div>
        <div className="mt-5">
          <DataTable
            data={data}
            columns={createColumn(data, select, action)}
            pagination
            progressPending={loading}
            highlightOnHover
            paginationServer
            paginationPerPage={LIMIT}
            paginationTotalRows={totalUser}
            persistTableHead
            onChangePage={(page) => handlePageChange(page)}
          />
        </div>
      </div>
    </>
  );
};

export default MyPaginationTable;
