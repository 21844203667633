import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
// import { ICreateOutlet } from "@typed/dailojol/Outlet";
import Input from "@components/Input.components";
import Loading from "@components/loading";
import Modal from "@components/Modal";
import Button from "@components/Button.component";
import { useAppDispatch, useAppSelector } from "@stores/store";
import { postOutletRequest, putOutletRequest } from "@stores/dailyapp/dailyOutletSlice";
import { Navigate, useNavigate, useParams } from "react-router-dom";

interface ICreateOutlet {
  name: string;
  lat: string;
  long: string;
  address: string;
  code: string;
}

const validationSchema = Yup.object({
  name: Yup.string().required(),
  address: Yup.string().required(),
  lat: Yup.string().required(),
  long: Yup.string().required()
});

const AddOutlet = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const { isLoading, AppOutlet } = useAppSelector((state) => state.dailyappOutlet);
  const [openModal, setOpenModal] = useState(true);

  return (
    <>
      <Formik
        validationSchema={validationSchema}
        initialValues={{} as ICreateOutlet}
        onSubmit={(values, { resetForm }) => {
          if (id) {
            dispatch(putOutletRequest({ id, data: values }));
            resetForm({ values });
            setTimeout(() => navigate("/dailyapp/outlets"), 3000);
          } else {
            dispatch(postOutletRequest(values));
            resetForm();
          }
        }}
      >
        {({ values, resetForm, handleSubmit, handleChange, setFieldValue }) => {
          useEffect(() => {
            if (id) {
              AppOutlet.filter((item) =>
                item._id === id
                  ? (setFieldValue("name", item.name),
                    setFieldValue("address", item.address),
                    setFieldValue("code", item.code),
                    setFieldValue("lat", item.lat),
                    setFieldValue("long", item.long))
                  : null
              );
            }
          }, [id]);

          return (
            <Form
              onSubmit={handleSubmit}
              className="block border-[1px] border-abuAbu rounded-md m-auto bg-white w-[33rem] p-4 h-auto"
            >
              <h1 className=" font-medium  text-[1.2rem]">
                {id ? "Edit outlet " : "Create outlet"}
              </h1>
              <div className="flex flex-col gap-5">
                <Input
                  required={`after:content-['*'] after:text-red-500`}
                  name="name"
                  title="Name"
                  onChange={handleChange}
                  type={"text"}
                  placeholder={"Name"}
                  value={values.name ?? ""}
                />

                <div className="border-[1px]  hover:shadow-4xl focus-within:shadow-4xl focus-within:border-[#1894ed] bg-Inputcol border-abuAbu rounded-md p-2">
                  <h1 className="text-[#6A7380] after:content-['*'] after:text-red-500">Address</h1>
                  <div>
                    <textarea
                      value={values.address ?? ""}
                      name="address"
                      onChange={handleChange}
                      className="bg-transparent h-24 w-full resize-none  border-none outline-none"
                      placeholder="Address"
                    />
                  </div>
                </div>

                <Input
                  // required={`after:content-['*'] after:text-red-500`}
                  name="code"
                  title="Code"
                  onChange={handleChange}
                  type={"text"}
                  placeholder={"Code"}
                  value={values.code ?? ""}
                />
                <div className="flex items-center gap-3">
                  <Input
                    required={`after:content-['*'] after:text-red-500`}
                    name="lat"
                    className="w-[18rem]"
                    title="Lat"
                    onChange={handleChange}
                    type={"text"}
                    placeholder={"Lat"}
                    value={values.lat ?? ""}
                  />

                  <Input
                    required={`after:content-['*'] after:text-red-500`}
                    name="long"
                    className="w-[18rem]"
                    title="Long"
                    onChange={handleChange}
                    type={"text"}
                    placeholder={"Long"}
                    value={values.long ?? ""}
                  />
                </div>
                <Button isLoading={isLoading}>Submit</Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default AddOutlet;
