import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { IPayloadMembership, postMembershipAPI } from "./MembershipAPI";

export const postMembershipReq = createAsyncThunk(
  "dailyapp-membership/postMembershipReq",
  async (payload: any) => {
    const res = await postMembershipAPI(payload);
    return res;
  }
);

const initialState = {
  dataMembership: [],
  isLoading: false
};

const MemberShipSlice = createSlice({
  name: "dailyapp-membership",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(postMembershipReq.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(postMembershipReq.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(postMembershipReq.rejected, (state) => {
      state.isLoading = false;
    });
  }
});

export default MemberShipSlice.reducer;
