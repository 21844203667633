import API from "@constants/API";
import { TPayloadPost } from "./RedemSlice";

const BASE_URL = "https://api.dev.daily.co.id/dashboard/redeem/v3/redeem";

export const getRedemDailyappAPI = async () => {
  const response = await API.HTTP.get(`${BASE_URL}`);

  return response.data;
};

export const postRedemVerifAPI = async (Data: TPayloadPost) => {
  const { redeemNumber, ConfirmCode, outletId, creatorId } = Data;
  const payload = {
    redeemNumber: redeemNumber,
    confirmCode: ConfirmCode,
    outletId: outletId,
    creatorId: creatorId
  };
  const response = await API.HTTP.post(`${BASE_URL}-verify`, payload);

  return response.data;
};
