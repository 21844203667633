import React, { useState } from "react";
import Loading from "@components/loading";
import CreatePerson from "@components/Step/PersonStep";
import CreateEmploye from "@components/Step/EmployeStep";
import FinalStep from "@components/Step/FinalStep";
import Stepper from "./Stepper";
import { useAppSelector } from "@stores/store";
import { useParams } from "react-router-dom";

const StepParent: React.FC = () => {
  const [current, setCurrent] = useState<number>(1);
  const { isLoading } = useAppSelector((state) => state.hrisPerson);
  const { id } = useParams();

  const steps = id ? ["Edit Person", "Done"] : ["Create Person", "Completed your Data", "Done"];

  const displayStep = (
    current: number,
    setCurrent: React.Dispatch<React.SetStateAction<number>>
  ) => {
    switch (current) {
      case 1:
        return <CreatePerson current={current} step={steps} setCurrent={setCurrent} />;
      case 2:
        return <CreateEmploye current={current} setCurrent={setCurrent} />;
      case 3:
        return <FinalStep setCurrent={setCurrent} />;
      default:
    }
  };

  return (
    <>
      <div className="mx-auto rounded-2xl bg-white px-2 pb-2 shadow-xl w-[60%]">
        <div className="horizontal container mt-5 ">
          <Stepper steps={steps} current={current} />
          {isLoading ? (
            <Loading />
          ) : (
            <div className={`mt-12 transition px-4`}>{displayStep(current, setCurrent)}</div>
          )}
        </div>

        {/* navigation button */}
        {/* {current !== steps.length && (
          <StepControl handleClick={handleClick} currentStep={current} steps={steps} />
        )} */}
      </div>
    </>
  );
};

export default StepParent;
