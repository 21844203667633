import { useAppDispatch, useAppSelector } from "@stores/store";
import Input from "@components/Input.components";
import { Formik, Form } from "formik";
import React, { useEffect, useRef } from "react";
import Textarea from "@components/Textarea";
import Loading from "@components/loading";
import {
  postBannerDailycoinRequest,
  putBannerDailycoinRequest
} from "@stores/dailyapp/BannerSlice";
import swal from "sweetalert";
import { useNavigate, useParams } from "react-router-dom";

interface IValues {
  title: string;
  description: string;
  content: string;
  image: null | any;
  uri: string;
}

const AddBannerDailycoin = () => {
  const dispatch = useAppDispatch();
  const { isLoading, data } = useAppSelector((state) => state.dailyBanner);
  const imageRef = useRef<any>(null);
  const navigate = useNavigate();
  const { id } = useParams();

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="flex items-center justify-center">
            <Formik
              initialValues={{} as IValues}
              onSubmit={(values, { resetForm, setStatus }) => {
                const { title, image, content, description, uri } = values;
                const formData = new FormData();
                if (title) {
                  formData.append("title", title);
                }
                if (image) {
                  formData.append("image", image);
                }
                if (description) {
                  formData.append("description", description);
                }
                if (content) {
                  formData.append("content", content);
                }
                if (uri) {
                  formData.append("uri", uri);
                }

                return id
                  ? dispatch(putBannerDailycoinRequest({ formData, id })).then(({ payload }) => {
                      if (payload.code === 1000) {
                        setStatus({ succes: true });
                        resetForm({});
                        swal({
                          title: "Success!",
                          text: "You banner is update!",
                          icon: "success",
                          buttons: {
                            confirm: {
                              text: "OK",
                              value: navigate("/dailyapp/banner-dailycoin")
                            }
                          }
                        });
                      }
                    })
                  : dispatch(postBannerDailycoinRequest(formData)).then(({ payload }) => {
                      if (payload.code === 1000) {
                        setStatus({ succes: true });
                        resetForm({});
                        swal({
                          title: "Success!",
                          text: "You banner is created!",
                          icon: "success",
                          buttons: {
                            confirm: {
                              text: "OK",
                              value: navigate("/dailyapp/banner-dailycoin")
                            }
                          }
                        });
                      }
                    });
              }}
            >
              {({ values, handleChange, setFieldValue, handleSubmit }) => {
                useEffect(() => {
                  if (id) {
                    data.find((item) =>
                      item._id === id
                        ? (setFieldValue("title", item.title),
                          setFieldValue("content", item.content),
                          setFieldValue("image", item.image.mediaUrl),
                          setFieldValue("description", item.description),
                          setFieldValue("uri", item.uri),
                          (imageRef.current = item.image.mediaUrl))
                        : undefined
                    );
                  }
                }, [id]);

                const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
                  let file = e.target.files;
                  if (file) {
                    setFieldValue("image", file[0]);
                    imageRef.current = URL.createObjectURL(file[0]);
                  }
                };
                return (
                  <Form className="w-[55%] p-2 bg-white gap-3 h-auto rounded shadow flex flex-col">
                    <h1 className="text-center text-[1.1rem]">
                      {id ? "Edit" : "Create"}Create Banner
                    </h1>

                    <Input
                      placeholder="Title"
                      value={values.title}
                      onChange={handleChange}
                      name="title"
                      title="Title"
                    />

                    {/* <div>
                      <h1 className="font-semibold p-0 m-0 after:text-red-500 after:content-['*']">
                        Description
                      </h1>
                      <textarea
                        className="border-gray px-2 rounded focus:outline-none border-[1px] h-20 resize-none overflow-y-scroll w-full"
                        placeholder="Description"
                        name="description"
                        onChange={handleChange}
                        value={values.description}
                      />
                      <ErrorMessage name="description" render={renderError} />
                    </div> */}

                    <Textarea
                      placeholder="Description"
                      value={values.description}
                      onChange={handleChange}
                      name="description"
                      title="Description"
                    />

                    <div>
                      <h1 className="font-semibold p-0 m-0 after:text-red-500 after:content-['*']">
                        Thumbnail
                      </h1>
                      <div className="flex  items-center">
                        {imageRef.current === null ? (
                          <label className="w-[30%] h-[100px] dark:text-white flex flex-col justify-center  bg-white text-blue rounded-lg shadow tracking-wide  border border-blue cursor-pointer hover:bg-blue-400 hover:text-white">
                            <svg
                              className="h-6 w-6 mx-auto my-auto"
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                            >
                              <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                            </svg>
                            <p className="text-left  m-auto">Select a file</p>
                            <input className="hidden" onChange={handleUpload} type="file" />
                          </label>
                        ) : (
                          <div className="flex items-center gap-2">
                            <img src={imageRef.current} className=" w-30 h-20 rounded" />
                            <div>
                              <label className="text-white bg-[#24292F] hover:bg-[#24292F]/90 focus:ring-4 focus:outline-none focus:ring-[#24292F]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-500 dark:hover:bg-[#050708]/30 mr-2 mb-2">
                                Change
                                <input className="hidden" onChange={handleUpload} type="file" />
                              </label>
                            </div>
                            <div>
                              <button
                                onClick={() => {
                                  imageRef.current = null;
                                  setFieldValue("image", null);
                                }}
                                className="border-none outline-none focus:outline-none focus:border-none text-red-500"
                              >
                                Remove
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <Input
                      title={"URI"}
                      placeholder={"URI"}
                      value={values.uri}
                      onChange={handleChange}
                      name={"uri"}
                    />
                    <button
                      className="py-2 px-4 font-semibold bg-green-500 text-white hover:bg-green-600 rounded"
                      type="submit"
                    >
                      Submit
                    </button>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </>
      )}
    </>
  );
};

export default AddBannerDailycoin;
