import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { Overwrite, PayloadResponse } from "@typed/global";
import { getReviewsApi } from "./ReviewAPI";
import { Review, ReviewStoreState } from "@typed/dailyapp/review";

type Payload = Overwrite<PayloadResponse, { data: [Review] }>;

export const getReviewsRequest = createAsyncThunk("dailyapp-review/getReviewsRequest", async () => {
  const response: Payload = await getReviewsApi();
  return response;
});

const initialState: ReviewStoreState = {
  reviews: [],
  errorMessage: "",
  isLoading: false
};

export const reviewSlice = createSlice({
  name: "dailyapp-review",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getReviewsRequest.pending, (state) => {
      state.errorMessage = "";
      state.isLoading = true;
    });
    builder.addCase(getReviewsRequest.fulfilled, (state, action) => {
      if (action.payload.code === 1000) {
        state.reviews = action.payload.data;
      } else {
        state.errorMessage = "Error :: with status code" + action.payload.code;
      }
      state.isLoading = false;
    });
    builder.addCase(getReviewsRequest.rejected, (state) => {
      state.errorMessage = "Error :: Case rejected";
      state.isLoading = false;
    });
  }
});

// Action creators are generated for each case reducer function
export const {} = reviewSlice.actions;

export default reviewSlice.reducer;
