import React, { useEffect } from "react";
import { useAppSelector, useAppDispatch } from "@stores/store";
import { getOrganizationRequest } from "@stores/HRIS/OrganizationSlice";
import { Table, Tag } from "antd";
import { Link } from "react-router-dom";
import { IDataHRIS } from "@typed/HRIS/allHris";
import { dateToString } from "@helpers/utils";
import Loading from "@components/loading";

const column = [
  {
    title: "Date",
    key: "date",
    dataIndex: "createdAt",
    render: (date: string) => <div>{dateToString(date)}</div>
  },
  {
    title: "Name",
    key: "name",
    dataIndex: "name"
  },
  {
    title: "Description",
    key: "description",
    dataIndex: "description"
  },
  {
    title: "isActived",
    key: "isactive",
    dataIndex: "isActived",
    render: (active: boolean) => (
      <div>
        {active === true ? <Tag color="success">Active</Tag> : <Tag color="error">NoActive</Tag>}
      </div>
    ),
    onFilter: (value: any, record: IDataHRIS) => record.isActived === value,
    filters: [
      {
        text: "Active",
        value: true
      },
      {
        text: "NoActive",
        value: false
      }
    ]
  },
  {
    title: "Action",
    key: "action",
    dataIndex: "_id",
    render: (id: string) => {
      return (
        <div>
          <Link to={`/hris/organization/${id}`}>Detail</Link>
        </div>
      );
    }
  }
];

const Organization = () => {
  const Organization = useAppSelector((state) =>
    state.hrisOrganization.data?.map((x: IDataHRIS) => ({ ...x, key: x._id }))
  );
  const { isLoading } = useAppSelector((state) => state.hrisOrganization);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getOrganizationRequest());
  }, []);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Link
            to="/hris/organization/add"
            className="py-2 w-full px-2 rounded hover:bg-green-600 hover:text-white bg-green-500 text-white font-semibold"
          >
            Create
          </Link>
          <div className="mt-4">
            <Table columns={column} dataSource={Organization} />
          </div>
        </>
      )}
    </>
  );
};

export default Organization;
