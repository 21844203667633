import axios from "axios";

const BASE_URL = "https://c7ao4wxfv8.execute-api.ap-southeast-1.amazonaws.com/Prod";

export const refreshTokenRequestAPI = async ({ refreshToken }: { refreshToken: string }) => {
  const response = await axios.get(`${BASE_URL}/refresh-token`, {
    headers: {
      refreshToken: refreshToken
    }
  });
  return response.data;
};
