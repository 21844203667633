import React, { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "@stores/store";
import MyTable from "@components/Table/MyTable";
import {
  getQandABatchReq,
  postQandABatchReq,
  putQandABatchReq,
  statusQandABatchReq
} from "@stores/dailyapp/QuestionsAnwerSlice";
import { Link } from "react-router-dom";
import Modal from "@components/Modal";
import Input from "@components/Input.components";
import Button from "@components/Button.component";
import { Tag, Popover } from "antd";
import { FiCheck } from "react-icons/fi";
import swal from "sweetalert";

const QandA = () => {
  const dispatch = useAppDispatch();
  const { dataBatch, isLoadingBatch } = useAppSelector((state) => state.dailyQuestionsBatch);

  const [showModal, setShowModal] = useState(false);
  const [modalStatus, setModalStatus] = useState(false);
  const [data, setData] = useState<any>();
  const [id, setId] = useState("");

  useEffect(() => {
    dispatch(getQandABatchReq());
  }, []);

  const handleCloseModal = () => {
    setShowModal(!showModal);
    setData(undefined);
  };

  const handleStatus = (row: any) => {
    setModalStatus(!modalStatus);
    swal(`Are you sure want to ${row?.isActive} Batch ?`, {
      buttons: {
        then: {
          text: "Cancel",
          value: "cancel"
        },
        catch: {
          text: "OK",
          value: "catch"
        }
      }
    }).then((value) => {
      switch (value) {
        case "then":
          swal("Success!", `${row?.isActive ? "Inactive" : "Active"}`, "success");
          break;

        case "catch":
          console.log("RUNNING CATCH");
          const payload = {
            id: row?._id,
            isActive: row?.isActive ? false : true
          };
          dispatch(statusQandABatchReq(payload)).then((res) => {
            res.payload?.code === 1000
              ? dispatch(getQandABatchReq())
              : swal("Ops!", `${res.payload?.code} : Something went wrong`, "error");
          });

          break;
      }
    });
  };

  const Action = (row: any) => {
    return (
      <div className="flex items-center gap-3">
        <div>
          <Popover content={row.isActive ? "Inactive Batch ?" : "Active Batch?"}>
            <button
              className={`py-2 px-3 font-bold rounded text-white ${
                row.isActive ? "bg-green-600" : "bg-red-600"
              }`}
              onClick={() => handleStatus(row)}
            >
              {row.isActive ? "Active" : "Deactive"}
            </button>
          </Popover>
        </div>
        <Button
          onClick={() => {
            setData(row);
            setShowModal(!showModal);
          }}
        >
          Update
        </Button>
      </div>
    );
  };

  return (
    <>
      <CreateBatch setData={setData} data={data} isOpen={showModal} closeModal={handleCloseModal} />
      <div className="mb-4">
        <button
          className=" py-2 text-center px-6 bg-green-500 hover:bg-green-600 hover:text-white rounded hover:shadow-lg text-white font-semibold"
          onClick={() => {
            setShowModal(!showModal);
          }}
        >
          Create Batch
        </button>
      </div>
      <MyTable
        dataSource={dataBatch}
        fileName=""
        exportable
        searchable
        loading={isLoadingBatch}
        action={Action}
        select={[
          { label: "Date", value: "createdAt" },
          { label: "Name", value: "name" },
          { label: "Sequence", value: "sequence" },
          { label: "Status Active", value: "isActive" }
        ]}
      />
    </>
  );
};

interface ICreateBatchProps {
  isOpen: boolean;
  closeModal: () => void;
  data: any;
  setData: React.Dispatch<any>;
}

const CreateBatch: React.FC<ICreateBatchProps> = ({ isOpen, closeModal, data, setData }) => {
  const dispatch = useAppDispatch();
  const { isLoadingBatch } = useAppSelector((state) => state.dailyQuestionsBatch);

  const [postBatch, setPostBatch] = useState("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setPostBatch(value);
  };

  useEffect(() => {
    if (!data?._id) {
      setPostBatch("");
    }
    setPostBatch(data?.sequence);
  }, [data?._id]);

  const handlePostBatch = () => {
    if (data?._id) {
      const payload = {
        id: data._id,
        sequence: Number(postBatch)
      };
      dispatch(putQandABatchReq(payload)).then((res) => {
        closeModal();
        if (res.payload?.code === 1000) {
          setData(undefined);
          closeModal();
          setPostBatch("");
          swal("Good job!", "Your Batch update was successful!", "success");
          dispatch(getQandABatchReq());
        } else {
          swal("Ops!", `${res.payload?.code} : Something went wrong!`, "error");
        }
      });
    } else {
      const payload = {
        sequence: Number(postBatch)
      };
      dispatch(postQandABatchReq(payload)).then((res) => {
        if (res.payload?.code === 1000) {
          setData(undefined);
          closeModal();
          setPostBatch("");
          swal("Good job!", "Your Batch create was successful!", "success");
        } else {
          swal("Ops!", `${res.payload?.code} : Something went wrong!`, "error");
        }
      });
      dispatch(getQandABatchReq());
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} closeModal={closeModal} title="Create Question Batch">
        <div className="bg-white h-[13rem] pt-16 flex flex-col gap-3">
          <Input
            required={`after:content-['*'] after:text-red-500`}
            placeholder="Sequence"
            value={postBatch}
            onChange={handleChange}
            name="sequence"
            title="Sequence"
          />

          <div className="pt-4">
            <Button isLoading={isLoadingBatch} onClick={handlePostBatch}>
              Submit
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default QandA;
