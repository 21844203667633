import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IDataOutlets, TOutletStore } from "@typed/dailojol/Outlet";
import { Overwrite, PayloadResponse } from "@typed/global";
import {
  getOutletsAPI,
  getOutletDetailAPI,
  createOutletAPI,
  editOutletAPI,
  IEditData
} from "./OutletAPI";
import toast from "react-hot-toast"; //notify

export const getOutletsRequest = createAsyncThunk(
  "dailyojol-outlet/getOutletsRequest",
  async ({ skip, limit }: { skip?: number; limit?: number }) => {
    const response = await getOutletsAPI({ skip, limit });
    return response;
  }
);

export const getOutletDetailRequest = createAsyncThunk(
  "dailyojol-outlet/getOutletDetailRequest",
  async (id: string | undefined) => {
    const response = await getOutletDetailAPI(id);
    return response.data;
  }
);

export const createOutletRequest = createAsyncThunk(
  "dailyojol-outlet/createOutletRequest",
  async (Data: Object) => {
    const response = await createOutletAPI(Data);
    return response;
  }
);

export const editOutletRequest = createAsyncThunk(
  "dailyojol-outlet/editOutletRequest",
  async (Data: IEditData) => {
    const response = await editOutletAPI(Data);
    return response;
  }
);

const initialState: TOutletStore = {
  Outlet: [],
  detailOutlet: {} as IDataOutlets,
  loading: false,
  updateOutlet: {} as IDataOutlets
};

const OutletSlice = createSlice({
  name: "dailyojol-outlet",
  initialState: initialState,
  reducers: {
    resetOutlet: (state: { updateOutlet: IDataOutlets }) => {
      state.updateOutlet = {} as IDataOutlets;
    }
  },
  extraReducers(builder) {
    builder.addCase(getOutletsRequest.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getOutletsRequest.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.code === 1000) {
        state.Outlet = action.payload.data;
      }
    });
    builder.addCase(getOutletsRequest.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getOutletDetailRequest.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getOutletDetailRequest.fulfilled, (state, action) => {
      state.detailOutlet = action.payload.data;
      state.loading = false;
    });
    builder.addCase(getOutletDetailRequest.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(createOutletRequest.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createOutletRequest.fulfilled, (state, action) => {
      if (action.payload.code === 2002) {
        toast.error(action.payload.error);
      } else if (action.payload.code === 1000) {
        toast.success("Success");
        state.updateOutlet = action.payload.data;
      }
      state.loading = false;
    });
    builder.addCase(createOutletRequest.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(editOutletRequest.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(editOutletRequest.fulfilled, (state, action) => {
      const { id } = action.meta.arg;
      if (action.payload.code === 1000) {
        toast.success("Success Update");
        state.updateOutlet = action.payload.data;
      }
      state.loading = false;
    });
    builder.addCase(editOutletRequest.rejected, (state) => {
      state.loading = false;
    });
  }
});

export default OutletSlice.reducer;
export const { resetOutlet } = OutletSlice.actions;
