import API from "@constants/API";

const BASE_URL = "https://api.prod.daily.co.id/expansion/outlet/dashboard/v1/exp-outlet";

const ACTIVITY_URL = "https://api.prod.daily.co.id/expansion/outlet/dashboard/v1/outlet-account";

export const getOutletAppAPI = async () => {
  const response = await API.HTTP.get(BASE_URL + "s");
  return response.data;
};

export const getDetailOutletAppAPI = async (id: string) => {
  const response = await API.HTTP.get(`${BASE_URL}/${id}`);
  return response.data;
};

export const postCreateOutletAppAPI = async (data: object) => {
  const response = await API.HTTP.post(BASE_URL, data);
  return response.data;
};

export const putUpdateOutletAppAPI = async (id: string, data: object) => {
  const response = await API.HTTP.put(`${BASE_URL}/${id}`, data);
  return response.data;
};

export const postOutletAccountAPI = async (data: object) => {
  const response = await API.HTTP.post(ACTIVITY_URL, data);
  return response.data;
};

export const getOutletAccountAPI = async () => {
  const response = await API.HTTP.get(ACTIVITY_URL + "s");
  return response.data;
};

export const putOutletAccountAPI = async (id: string, data: object) => {
  const response = await API.HTTP.put(`${ACTIVITY_URL}/${id}`, data);
  return response.data;
};
