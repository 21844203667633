import React from "react";
import { Empty } from "antd";
import { IoAlert, IoAlertCircleOutline } from "react-icons/io5";

const Forbidden = () => {
  return (
    <div className="flex justify-center items-center">
      <div className="flex flex-col mt-20 text-center items-center">
        <IoAlertCircleOutline size={80} />
        <div className="text-3xl font-bold mb-3">Ooop.. Forbidden</div>
        <div className="text-xl">You don&apos;t have permission to access this page</div>
      </div>
    </div>
  );
};

export default Forbidden;
