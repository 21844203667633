import React, { useEffect } from "react";
import { useAppSelector, useAppDispatch } from "@stores/store";
import { Table } from "antd";
import { getNewsRequest, deleteNewsRequest } from "@stores/dailyapp/NewsSlice";
import { dateToString } from "@helpers/utils";
import { Link } from "react-router-dom";

import Loading from "@components/loading";

const News: React.FC = () => {
  const dispatch = useAppDispatch();
  const data = useAppSelector((state) => state.dailyNews.data.map((i) => ({ ...i, key: i._id })));
  const { isLoading } = useAppSelector((state) => state.dailyNews);

  const column = [
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "date",
      render: (date: string) => <div>{dateToString(date)}</div>
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title"
    },
    {
      title: "Thumbnail",
      dataIndex: ["image", "mediaUrl"],
      key: "thumbnail",
      render: (images: string) => (
        <div>
          <img src={images} className="w-16 object-cover h-16 rounded" />
        </div>
      )
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (desc: string) => <div>{desc?.slice(0, 40) + "..."}</div>
    },
    {
      title: "Action",
      dataIndex: "_id",
      key: "action",
      render: (id: string) => (
        <div className="flex items-center gap-2">
          <Link
            to={id}
            className="bg-blue-500 text-white p-2 px-4 rounded hover:text-white hover:bg-blue-600"
          >
            Detail
          </Link>
          <button
            onClick={() => dispatch(deleteNewsRequest(id))}
            className="bg-red-500 text-white py-2 px-4 rounded hover:text-white hover:bg-red-600"
          >
            Delete
          </button>
        </div>
      )
    }
  ];

  useEffect(() => {
    dispatch(getNewsRequest());
  }, []);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="mb-4">
            <Link
              to="add"
              className=" py-2 text-center px-6 bg-green-500 hover:bg-green-600 hover:text-white rounded hover:shadow-lg text-white font-semibold"
            >
              Create News
            </Link>
          </div>
          <Table columns={column} dataSource={data.reverse()} />
        </>
      )}
    </>
  );
};

export default News;
