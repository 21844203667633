import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import { combineReducers } from "redux";
import { useDispatch, useSelector, TypedUseSelectorHook } from "react-redux";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE
} from "redux-persist";
import createIdbStorage from "@piotr-cz/redux-persist-idb-storage";

import TokenReducer from "./TokenSlice";
import AuthReducer from "./AuthSlice";

import AsyncStorage from "@react-native-async-storage/async-storage";
import ProductReducer from "./dailyapp/ProductSlice";
import UserReducer from "./dailyapp/UserSlice";
import ReviewReducer from "./dailyapp/ReviewSlice";
import ComplainReducer from "./dailyapp/ComplainSlice";
import TransactionReducer from "./dailyapp/TransactionSlice";
import CoinReducer from "./dailyapp/CoinSlice";
import RedemReducer from "./dailyapp/RedemSlice";
import DashboardReducer from "./dailyapp/DashboardSlice";
import OutletDailyappReducer from "./dailyapp/dailyOutletSlice";
import VoucherReducer from "./dailyapp/VoucherSlice";
import MembershipReducer from "./dailyapp/MembershipSlice";

import ListUserReducer from "./dailyojol/UserSlice";
import OutletAccountReducer from "./dailyojol/OutlietAccountSlice";
import OutletReducer from "./dailyojol/OutletSlice";
import ProductOjolReducer from "./dailyojol/ProductSlice";
import RegionReducer from "./dailyojol/RegionSlice";
import CodeOjolReducer from "./dailyojol/CodeSlice";
import RedemOjolReducer from "./dailyojol/RedemSlice";
import NewsReducer from "./dailyapp/NewsSlice";
import BannerReducer from "./dailyapp/BannerSlice";
import QuestionsBatchReducer from "./dailyapp/QuestionsAnwerSlice";

// HRIS
import OrganizationReducer from "./HRIS/OrganizationSlice";
import PositionReducer from "./HRIS/position/PositionSlice";
import LevelReducer from "./HRIS/Level/LevelSlice";
import StatusReducer from "./HRIS/Status/StatusSlice";
import PtkpReducer from "./HRIS/PTKP/PtkpSlice";
import PersonReducer from "./HRIS/Person/PersonSlice";
import BranchReducer from "./HRIS/Branch/BranchSlice";
import EmployeReducer from "./HRIS/Employe/EmployeSlice";
import NameCardReducer from "./HRIS/Name-card/NameCardSlice";

// DAILYCOIN
import dailyCoinsReducer from "./dailycoin/dailyCoinSlice";
import ReportingReducer from "./dailycoin/ReportingSlice";

//  CUSTOMER EXPERIENCE
import ReportReducer from "./dailycoin/report/ReportSlice";
import CustomerReducer from "./CE/CEXPSlice";

// DAILYCLICK EVENT
import DailyclickReducer from "./Dailyclick/DailyclickSlice";

// BRAND PAYMENT
import BrandPaymentReducer from "./brandPayment/BrandPaymentSlice";

const rootReducer = combineReducers({
  auth: AuthReducer,
  token: TokenReducer,
  // dailyapp
  dailyappProduct: ProductReducer,
  dailyappUser: UserReducer,
  dailyappOutlet: OutletDailyappReducer,
  dailyReview: ReviewReducer,
  dailyComplain: ComplainReducer,
  dailyTransaction: TransactionReducer,
  dailyCoin: CoinReducer,
  dailyDashboard: DashboardReducer,
  dailyRedem: RedemReducer,
  dailyNews: NewsReducer,
  dailyBanner: BannerReducer,
  dailyVoucher: VoucherReducer,
  dailyQuestionsBatch: QuestionsBatchReducer,
  dailyMembership: MembershipReducer,

  // dailyclick
  dailyClick: DailyclickReducer,

  // dailyOjol
  dailyojolUser: ListUserReducer,
  dailyojolOutlet: OutletReducer,
  dailyojolOutletAccount: OutletAccountReducer,
  dailyojolProduct: ProductOjolReducer,
  dailyojolRegion: RegionReducer,
  dailyojolCode: CodeOjolReducer,
  dailyojolRedem: RedemOjolReducer,

  // HRIS
  hrisOrganization: OrganizationReducer,
  hrisPosition: PositionReducer,
  hrisLevel: LevelReducer,
  hrisStatus: StatusReducer,
  hrisPtkp: PtkpReducer,
  hrisPerson: PersonReducer,
  hrisBranch: BranchReducer,
  hrisEmployee: EmployeReducer,
  hrisNameCard: NameCardReducer,

  // DAILYCOINS
  dailyCoins: dailyCoinsReducer,
  dailyReport: ReportingReducer,

  // CE
  CEReport: ReportReducer,
  CEcustomer: CustomerReducer,

  // BRAND-SETTLEMENT
  brandSettlement: BrandPaymentReducer
});

const persistConfig = {
  key: "root",
  version: 1,
  // storage: AsyncStorage,
  storage: createIdbStorage({ name: "portal", storeName: "keyval" }),
  serialize: false, // Data serialization is not required and disabling it allows you to inspect storage value in DevTools; Available since redux-persist@5.4.0
  deserialize: false // Required to bear same value as `serialize` since redux-persist@6.0
  // blacklist: ["dailyappUser", "CEcustomer", "CEReport", "dailyTransaction", "dailyCoins"] //blacklisting a store attribute name, will not persist that store attribute.
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    }).concat(thunk)
});

export const persistor = persistStore(store);
export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
