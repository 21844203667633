import React, { useEffect } from "react";
import { Form, Formik } from "formik";
import Input from "@components/Input.components";
import { useAppDispatch, useAppSelector } from "@stores/store";
import Loading from "@components/loading";
import { postOutletAccountRequest } from "@stores/dailyapp/dailyOutletSlice";
import { useParams } from "react-router-dom";

type TAddOutlet = {
  name: string;
  password: string;
  email: string;
  outlet: string;
};

const AddOutletAccount = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { isLoading } = useAppSelector((state) => state.dailyappOutlet);
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex justify-center">
          <Formik
            initialValues={{} as TAddOutlet}
            onSubmit={(values) => {
              dispatch(postOutletAccountRequest(values));
            }}
          >
            {({ values, handleSubmit, handleChange, setFieldValue }) => {
              useEffect(() => {
                if (id) {
                  setFieldValue("outlet", id);
                }
              }, [id]);
              return (
                <Form className="block border-[1px] border-abuAbu rounded-md m-auto bg-white w-[35rem] p-4 h-auto">
                  <h1 className="text-lg text-center">Create Outlet account</h1>

                  <div className="flex mt-5 flex-col gap-5">
                    <Input
                      required={`after:content-['*'] after:text-red-500`}
                      name="name"
                      title="Name"
                      onChange={handleChange}
                      type={"text"}
                      placeholder={"Name"}
                      value={values.name ?? ""}
                    />

                    <Input
                      required={`after:content-['*'] after:text-red-500`}
                      name="email"
                      title="Email"
                      onChange={handleChange}
                      type={"email"}
                      placeholder={"Email"}
                      value={values.email ?? ""}
                    />

                    <Input
                      required={`after:content-['*'] after:text-red-500`}
                      name="password"
                      title="Password"
                      onChange={handleChange}
                      type={"password"}
                      placeholder={"Password"}
                      value={values.password ?? ""}
                    />
                    <div className="flex justify-end">
                      <button className="bg-gray-600 py-2 px-8 rounded-lg text-white hover:text-white hover:bg-blue-500">
                        Submit
                      </button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      )}
    </>
  );
};

export default AddOutletAccount;
