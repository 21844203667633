import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IDataDetailRegion, IRegionStore } from "@typed/dailojol/Region";
import { Overwrite, PayloadResponse } from "@typed/global";
import {
  DataPutRegion,
  delOjolRegionAPI,
  getDetailOjolRegionAPI,
  getOjolRegionAPI,
  postOjolRegionAPI,
  putOjolRegionAPI
} from "./RegionAPI";
import toast from "react-hot-toast";

export const getOjolRegionRequest = createAsyncThunk(
  "dailyojol-region/getOjolRegionRequest",
  async () => {
    const response = await getOjolRegionAPI();

    return response.data;
  }
);

export const delOjolRegionRequest = createAsyncThunk(
  "dailyojol-region/delOjolRegionRequest",
  async (_id: string) => {
    const response = await delOjolRegionAPI(_id);
    return response;
  }
);

export const postOjolRegionRequest = createAsyncThunk(
  "dailyojol-region/postOjolRegionRequest",
  async (Data: object) => {
    const response = await postOjolRegionAPI(Data);

    return response;
  }
);

export const GDetailOjolRegionRequest = createAsyncThunk(
  "dailyojol-region/GDetailOjolRegionRequest",
  async (id: string | undefined) => {
    const response = await getDetailOjolRegionAPI(id);
    return response;
  }
);

export const putOjolRegionRequest = createAsyncThunk(
  "dailyojol-region/putOjolRegionRequest",
  async (Data: DataPutRegion) => {
    const response = await putOjolRegionAPI(Data);

    return response;
  }
);

const initialState: IRegionStore = {
  data: [],
  loading: false,
  getDetail: {} as IDataDetailRegion
};

const RegionSlice = createSlice({
  name: "dialyojol-region",
  initialState: initialState,
  reducers: {
    reset: (state: { getDetail: IDataDetailRegion }) => {
      state.getDetail = {} as IDataDetailRegion;
    }
  },
  extraReducers(builder) {
    builder.addCase(getOjolRegionRequest.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getOjolRegionRequest.fulfilled, (state, action) => {
      if (action.payload.code === 1000) {
        state.data = action.payload.data;
        state.loading = false;
      }
    });
    builder.addCase(getOjolRegionRequest.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(delOjolRegionRequest.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(delOjolRegionRequest.fulfilled, (state, action) => {
      state.loading = false;
      state.data = state.data.filter((i) => i._id !== action.meta.arg);
    });
    builder.addCase(delOjolRegionRequest.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(postOjolRegionRequest.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(postOjolRegionRequest.fulfilled, (state, action) => {
      toast.success(action.payload.message);
      state.loading = false;
      state.getDetail = action.payload.data;
    });
    builder.addCase(postOjolRegionRequest.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(GDetailOjolRegionRequest.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(GDetailOjolRegionRequest.fulfilled, (state, action) => {
      state.getDetail = action.payload.data;
    });
    builder.addCase(GDetailOjolRegionRequest.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(putOjolRegionRequest.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(putOjolRegionRequest.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.code === 1000) {
        state.getDetail = action.payload.data;
        toast.success("Update Success");
      }
    });
    builder.addCase(putOjolRegionRequest.rejected, (state) => {
      state.loading = false;
    });
  }
});

export default RegionSlice.reducer;
export const { reset } = RegionSlice.actions;
