import {
  postOjolProductRequest,
  putOjolProductRequest,
  resetProduct
} from "@stores/dailyojol/ProductSlice";
import { useAppDispatch, useAppSelector } from "@stores/store";
import { isEmpty } from "ramda";
import React, { FC, useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";

export type TCreateProduct = {
  name: string;
  image: any;
  price?: number | any;
  imgPrev?: any;
};
const AddProduct: FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [newProduct, setNewProduct] = useState<TCreateProduct>({} as TCreateProduct);
  const { forReset, data, loading } = useAppSelector((state) => state.dailyojolProduct);
  const { name, image, price, imgPrev } = newProduct;
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (id) {
      data.map(
        (item) =>
          item._id === id &&
          setNewProduct({
            name: item.name,
            image: item.image,
            imgPrev: item.image,
            price: item.price
          })
      );
    }
  }, [id]);

  useEffect(() => {
    if (!isEmpty(forReset)) {
      dispatch(resetProduct());
      setNewProduct({} as TCreateProduct);
      setTimeout(() => {
        navigate("/dailyojol/product");
      }, 600);
    }
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setNewProduct({ ...newProduct, [name]: value });
  };

  const handleImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files;
    let files;
    let prev: any;
    if (file) {
      files = file[0];
      prev = file[0];
    }
    setNewProduct({ ...newProduct, image: files, imgPrev: URL.createObjectURL(prev) });
  };

  const handlePost = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (image === null) {
      toast.error("Please Upload Image");
    } else {
      if (id) {
        const Data = new FormData();
        Data.append("name", name);
        Data.append("image", image);
        dispatch(putOjolProductRequest({ Data, id }));
      } else {
        const Data = new FormData();
        Data.append("name", name);
        Data.append("image", image);
        Data.append("price", price);
        dispatch(postOjolProductRequest(Data));
      }
    }
  };

  return (
    <>
      <Toaster />
      <div className="flex items-center justify-center">
        <div className="w-[45%] bg-white min-h-[30vh] p-2">
          <h1 className="text-center font-semibold text-[1.3rem]">Create Product</h1>

          <div className="">
            <h1 className="m-0 p-0 font-semibold outline-none ">Name</h1>
            <input
              onChange={handleChange}
              name="name"
              value={name ?? ""}
              className="w-full border border-slate-400 h-8 rounded pl-2"
              type="text"
              placeholder="Name"
            />
          </div>

          <div className={id ? "hidden " : "block"}>
            <h1 className="m-0 p-0 font-semibold ">Price</h1>
            <input
              onChange={handleChange}
              name="price"
              type="number"
              value={price ?? ""}
              className="w-full border h-8 pl-2 rounded border-slate-400"
              placeholder="Price"
            />
          </div>

          <div className="mt-3  flex w-full items-center jusitfy-center">
            <div className="">
              <h1 className="m-0 p-0 font-semibold">Image</h1>
              <input
                onChange={handleImage}
                className=""
                type="file"
                // accept="audio/*,video/*,image/"
                accept="image/*;capture=camera"
                placeholder="Price"
              />
            </div>
            {imgPrev && (
              <div>
                <img alt="Product" className="w-32 h-32 rounded-xl" src={imgPrev} />
              </div>
            )}
          </div>
          <div className="mt-3  flex w-full items-center jusitfy-center">
            <button
              onClick={handlePost}
              className={
                loading
                  ? "cursor-wait w-full py-1 text-[1.2rem] font-semibold rounded text-white bg-slate-500 "
                  : "w-full py-1 text-[1.2rem] font-semibold rounded text-white bg-blue-500 hover:bg-blue-600"
              }
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddProduct;
