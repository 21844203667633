import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { Overwrite, PayloadResponse } from "@typed/global";
import { getCoinByIdApi } from "./CoinAPI";
import { CoinStoreState } from "@typed/dailyapp/coin";

type Payload = Overwrite<PayloadResponse, { data: any }>;

export const getCoinByIdRequest = createAsyncThunk(
  "dailyapp-coin/getCoinByIdRequest",
  async (payload: { id: string | undefined }) => {
    const response: Payload = await getCoinByIdApi(payload.id);
    return response;
  }
);

const initialState: CoinStoreState = {
  userCoin: 0,
  errorMessage: "",
  isLoading: false
};

export const coinSlice = createSlice({
  name: "dailyapp-coin",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCoinByIdRequest.pending, (state) => {
      state.userCoin = 0;
      state.errorMessage = "";
      state.isLoading = true;
    });
    builder.addCase(getCoinByIdRequest.fulfilled, (state, action) => {
      if (action.payload.code === 1000) {
        // console.log("present amout", action.payload.data);

        state.userCoin = action.payload.data?.presentAmount;
        console.log("present amout", action.payload.data);

        state.userCoin = action.payload.data.presentAmount;
      } else {
        state.errorMessage = "Error :: with status code" + action.payload.code;
      }
      state.isLoading = false;
    });
    builder.addCase(getCoinByIdRequest.rejected, (state) => {
      state.errorMessage = "Error :: Case rejected";
      state.isLoading = false;
    });
  }
});

// Action creators are generated for each case reducer function
export const {} = coinSlice.actions;

export default coinSlice.reducer;
