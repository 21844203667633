import React from "react";
import Loading from "@components/loading";
import { useAppSelector, useAppDispatch } from "@stores/store";
import MyTable from "@components/Table/MyTable";

const SummariesReport = () => {
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector((state) => state.dailyReport);
  const reportSummaries = useAppSelector((state) =>
    state.dailyReport.reportSummaries.map((i) => ({ ...i, key: i._id }))
  );

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <div>
        <MyTable
          fileName="summaries-report"
          dataSource={reportSummaries}
          select={[
            { label: "Type", value: "dailycoinType" },
            { label: "Total", value: "total" }
          ]}
        />
      </div>
    </>
  );
};

export default SummariesReport;
