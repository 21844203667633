import API from "@constants/API";

const BASE_URL = "https://api.prod.dailyapp.id/dashboard-v1-membership/member-ship";

export interface IPayloadMembership {
  phoneNumber: string;
  level: number;
}

export const postMembershipAPI = async (payload: IPayloadMembership) => {
  const res = await API.HTTP.post(BASE_URL, payload);
  return res.data;
};
