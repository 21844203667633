import React, { useEffect, useState } from "react";
import Modal from "@components/Modal";
import SelectComponents from "@components/Select";
import { useAppDispatch, useAppSelector } from "@stores/store";
import {
  getIdProductReq,
  getVoucherReq,
  postVoucherReq,
  resetCodeVoucher
} from "@stores/dailyapp/VoucherSlice";

import Button from "@components/Button.component";
import swal from "sweetalert";

interface IVoucherProps {
  isOpen: boolean;
  closeModal: () => void;
}

const optionCount = [
  { value: 1, label: 1 },
  { value: 50, label: 50 },
  { value: 100, label: 100 },
  { value: 200, label: 200 },
  { value: 300, label: 300 },
  { value: 400, label: 400 },
  { value: 500, label: 500 },
  { value: 1000, label: 1000 }
];

const optionBrands = [
  { value: "AN20", label: "ANTARASA 20K" },
  { value: "AN5", label: "ANTARASA 5K" },
  { value: "AA10", label: "AYAM AMANAH 10K" },
  { value: "AA5", label: "AYAM AMANAH 5K" },
  { value: "BL10", label: "BREADLIFE 10K" },
  { value: "BL5", label: "BREADLIFE 5K" },
  { value: "DB10", label: "DAILYBOX 10K" },
  { value: "DB5", label: "DAILYBOX 5K" },
  { value: "LM10", label: "LUMIERE 10K" },
  { value: "LM5", label: "LUMIERE 5K" },
  { value: "SH10", label: "SHIRATO 10K" },
  { value: "SH5", label: "SHIRATO 5K" },
  { value: "TN10", label: "TIANTEA 10K" },
  { value: "TN5", label: "TIANTEA 5K" },
  ,
];

const AddVoucher: React.FC<IVoucherProps> = ({ isOpen, closeModal }) => {
  const dispatch = useAppDispatch();
  const idProduct = useAppSelector((state) =>
    state.dailyVoucher.idProduct?.map((i: any) => ({ label: i.name, value: i._id }))
  );
  const { isLoadingVoucher, codeCreateVoucher } = useAppSelector((state) => state.dailyVoucher);

  const [addVoucher, setAddVoucher] = useState({
    productId: "",
    countList: 0,
    brandCode: ""
  });

  useEffect(() => {
    dispatch(getIdProductReq());
  }, []);

  const handleCreateProduct = () => {
    console.log("addVoucher", addVoucher);
    dispatch(postVoucherReq(addVoucher));
    if (codeCreateVoucher === 1000) {
      swal("Success!", "You create voucher!", "success");

      closeModal();

      setTimeout(() => {
        dispatch(resetCodeVoucher());
      }, 1500);
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} closeModal={closeModal} title="Create Voucher">
        <div className="bg-white h-[20rem] pt-16 flex flex-col gap-3">
          <div>
            <h1 className=" text-gray-600 after:content-['*'] after:text-red-500 m-0">
              Product name
            </h1>
            <SelectComponents
              options={idProduct}
              onChange={(option: any, actionMeta: any) => {
                setAddVoucher({ ...addVoucher, productId: option.value });
              }}
            />
          </div>

          <div>
            <h1 className="text-gray-600 after:content-['*'] after:text-red-500 m-0">Count list</h1>
            <SelectComponents
              options={optionCount}
              onChange={(option: any, actionMeta: any) => {
                setAddVoucher({ ...addVoucher, countList: option.value });
              }}
            />
          </div>

          <div>
            <h1 className="text-gray-600 after:content-['*'] after:text-red-500 m-0 ">
              Brand code
            </h1>
            <SelectComponents
              options={optionBrands}
              onChange={(option: any, actionMeta: any) => {
                setAddVoucher({ ...addVoucher, brandCode: option.value });
              }}
            />
          </div>
        </div>
        <div className="flex justify-end">
          <Button
            onClick={handleCreateProduct}
            isLoading={isLoadingVoucher}
            // className="bg-blue-500 hover:bg-glue-600 text-white hover:text-white rounded font-bold py-2 px-8"
          >
            Submit
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default AddVoucher;
