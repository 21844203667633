import React, { useState, useRef, useEffect } from "react";
import Select from "react-select";
import toast, { Toaster } from "react-hot-toast";
import { Formik, Form, ErrorMessage } from "formik";
import { IaddEmploye } from "@typed/HRIS/Employe";
import { useAppDispatch, useAppSelector } from "@stores/store";
import * as Yup from "yup";
import { validateInputNum } from "@helpers/utils";
import { postEmployeAPI } from "@stores/HRIS/Employe/EmployeAPI";
import { resetPerson } from "@stores/HRIS/Person/PersonSlice";
import { getStatusRequest } from "@stores/HRIS/Status/StatusSlice";
import { getPtkpRequest } from "@stores/HRIS/PTKP/PtkpSlice";
import { getOrganizationRequest } from "@stores/HRIS/OrganizationSlice";
import { getLevelRequest } from "@stores/HRIS/Level/LevelSlice";
import { getPositionRequest } from "@stores/HRIS/position/PositionSlice";
import { getBranchRequest } from "@stores/HRIS/Branch/BranchSlice";
import Loading from "@components/loading";
import { useParams } from "react-router-dom";
import { getEmployeRequest } from "@stores/HRIS/Employe/EmployeSlice";
import moment from "moment";
import { IInfoAPI } from "@typed/HRIS/Person";

const validationSchema = Yup.object({
  organizationId: Yup.string().required(),
  positionId: Yup.string().required(),
  statusId: Yup.string().required(),
  ptkpStatusId: Yup.string().required(),
  phone: Yup.string().required(),
  branchId: Yup.string().required(),
  joinDate: Yup.string().required()
});

interface IProps {
  current: number;
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
}

const EmployeStep: React.FC<IProps> = ({ current, setCurrent }) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();

  //  ===== START GLOBAL STATE =====
  const { dataEmploye } = useAppSelector((state) => state.hrisEmployee);
  const Level = useAppSelector((state) =>
    state.hrisLevel.data.map((item) => ({ value: item._id, label: item.name }))
  );
  const Status = useAppSelector((state) =>
    state.hrisStatus.data.map((item) => ({ value: item._id, label: item.name }))
  );
  const Organization = useAppSelector((state) =>
    state.hrisOrganization.data.map((item) => ({ value: item._id, label: item.name }))
  );
  const Position = useAppSelector((state) =>
    state.hrisPosition.data.map((item) => ({ value: item._id, label: item.name }))
  );
  const PTKP = useAppSelector((state) =>
    state.hrisPtkp.data.map((item) => ({ value: item._id, label: item.name }))
  );
  const Branch = useAppSelector((state) =>
    state.hrisBranch.Branch.map((item) => ({ value: item._id, label: item.name }))
  );
  //  ===== END GLOBAL STATE =====

  const [infoAPI, setInfoAPI] = useState<IInfoAPI>({} as IInfoAPI);
  const { isLoading, isCode } = infoAPI;

  useEffect(() => {
    if (id) {
      dispatch(getEmployeRequest());
    }
  });
  const { initialPerson } = useAppSelector((state) => state.hrisPerson);

  // === GET ID ===
  useEffect(() => {
    dispatch(getStatusRequest());
    dispatch(getPtkpRequest());
    dispatch(getOrganizationRequest());
    dispatch(getLevelRequest());
    dispatch(getPositionRequest());
    dispatch(getBranchRequest());
  }, []);

  const renderError = (message: string) => <p className="text-red-500 is-danger">{message}</p>;

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex w-full items-center justify-center">
          <Formik
            validationSchema={validationSchema}
            initialValues={{} as IaddEmploye}
            onSubmit={async (values) => {
              console.log(values);
              const {
                email,
                phone,
                npwp,
                organizationId,
                branchId,
                statusId,
                ptkpStatusId,
                positionId,
                levelId,
                joinDate
              } = values;
              const dateTime = new Date(joinDate);
              const formatPhone = phone.replace("0", "62");

              const insuranceHealthyID = values.insuranceHealthy?.insuranceID;
              const insuranceHealthyName = values.insuranceHealthy?.insuranceName;
              const insuranceID = values.insuranceWorker?.insuranceID;
              const insuranceName = values.insuranceWorker?.insuranceName;

              const Data = {
                email,
                phone: formatPhone,
                joinDate: dateTime,
                npwp,
                insuranceWorker: {
                  insuranceName: insuranceName,
                  insuranceID: insuranceID
                },
                insuranceHealthy: {
                  insuranceName: insuranceHealthyName,
                  insuranceHealthyName: insuranceHealthyID
                },
                personId: initialPerson.id,
                organizationId,
                positionId,
                levelId,
                statusId,
                ptkpStatusId,
                branchId
              };

              // =====  SEND TO API =====
              await postEmployeAPI(Data, setInfoAPI);
              if (initialPerson.code === 1000) {
                dispatch(resetPerson());
                toast.success("Success Submit Employe");
                setCurrent(current + 1);
              }
            }}
          >
            {({ values, handleChange, errors, touched, handleSubmit, setFieldValue }) => {
              useEffect(() => {
                if (id) {
                  {
                    dataEmploye.find(
                      (item) =>
                        item._id === id &&
                        (setFieldValue("year", moment(item.joinDate).format("YYYY")),
                        setFieldValue("month", moment(item.joinDate).format("MMMM")),
                        setFieldValue("day", moment(item.joinDate).format("D")))
                    );
                  }
                }
              }, []);

              return (
                <>
                  <Toaster />
                  <Form className="flex w-full flex-col gap-4">
                    <h1 className="font-medium text-[1.1rem] text-gray-800">
                      {initialPerson?.firstName} {initialPerson?.lastName}
                    </h1>

                    <div className="flex w-full mt-2 gap-2">
                      <div className="w-[50%]">
                        <label className="font-bold after:text-red-500 after:content-['*']">
                          Phone ✆
                        </label>
                        <input
                          type={"text"}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const { name, value } = e.target;
                            const arg = validateInputNum(value);
                            setFieldValue("phone", arg);
                          }}
                          placeholder="Phone"
                          name="phone"
                          value={values.phone ?? ""}
                          className="border-gray px-2 rounded focus:outline-none border-[1px] h-9 w-full"
                        />
                        <ErrorMessage name="phone" render={renderError} />
                      </div>

                      <div className="w-[50%]">
                        <label className="font-bold">Email</label>
                        <input
                          type={"text"}
                          placeholder="Email"
                          onChange={handleChange}
                          name="email"
                          value={values.email ?? ""}
                          className="border-gray px-2 rounded focus:outline-none border-[1px] h-9 w-full"
                        />
                      </div>
                    </div>

                    <div className="flex gap-2 jsutify-between items-center">
                      <div>
                        <label className="block  after:text-red-500 after:content-['*'] text-gray-700 text-sm font-bold">
                          Join Date
                        </label>
                        <div className="flex  w-full gap-2">
                          <input
                            type="date"
                            name="joinDate"
                            onChange={handleChange}
                            value={values.joinDate ?? ""}
                            className="border-gray px-2 rounded focus:outline-none border-[1px] h-9 w-full"
                          />

                          <ErrorMessage name="statusId" render={renderError} />
                        </div>
                      </div>
                      <div className="w-full">
                        <label className="font-bold">NPWP</label>
                        <input
                          type={"text"}
                          onChange={handleChange}
                          placeholder="NPWP"
                          name="npwp"
                          value={values.npwp ?? ""}
                          className="border-gray px-2 rounded focus:outline-none border-[1px] h-9 w-full"
                        />
                      </div>
                    </div>
                    {/* === JOB INSURANCE START ===*/}

                    <div className=" w-full ">
                      <label className="text-gray-700 text-sm font-bold">Job Insurance</label>
                      <div className="flex gap-2">
                        <div className="w-[50%]">
                          <input
                            type={"text"}
                            onChange={handleChange}
                            placeholder="Insurance Name"
                            name="insuranceWorker.insuranceName"
                            value={values.insuranceWorker?.insuranceName ?? ""}
                            className="border-gray px-2 rounded focus:outline-none border-[1px] h-9 w-full"
                          />
                        </div>

                        <div className="w-[50%]">
                          <input
                            type="text"
                            placeholder="Insurance ID"
                            onChange={handleChange}
                            value={values.insuranceWorker?.insuranceID ?? ""}
                            className="border-gray px-2 rounded focus:outline-none border-[1px] h-9 w-full"
                          />
                        </div>
                      </div>
                    </div>
                    {/* === JOB INSURANCE END ===*/}

                    {/*=== HEALTHY INSURANCE START ===  */}
                    <div className=" w-full ">
                      <label className=" text-gray-700 text-sm font-bold">Health Insurance</label>
                      <div className="flex gap-2">
                        <div className="w-[50%]">
                          <input
                            type="text"
                            placeholder="Insurance Name"
                            onChange={handleChange}
                            name="insuranceHealthy.insuranceName"
                            value={values.insuranceHealthy?.insuranceName ?? ""}
                            className="border-gray px-2 rounded focus:outline-none border-[1px] h-9 w-full"
                          />
                        </div>

                        <div className="w-[50%]">
                          <input
                            type="text"
                            placeholder="Insurance ID"
                            onChange={handleChange}
                            name="insuranceHealthy.insuranceID"
                            value={values.insuranceHealthy?.insuranceID ?? ""}
                            className="border-gray px-2 rounded focus:outline-none border-[1px] h-9 w-full"
                          />
                        </div>
                      </div>
                    </div>
                    {/*=== HEALTHY INSURANCE END ===  */}
                    <div className="w-full mt-2">
                      <div className="flex justify-between  gap-2">
                        <div className="w-[50%] ">
                          <label className="font-bold after:text-red-500 after:content-['*']">
                            Organization
                          </label>
                          <Select
                            options={Organization}
                            className="react-select-container"
                            classNamePrefix="react-select"
                            defaultValue={values.organizationId}
                            onChange={(option: any, actionMeta: any) => {
                              setFieldValue("organizationId", option.value);
                            }}
                          />
                          <ErrorMessage name="organizationId" render={renderError} />
                        </div>

                        <div className="w-[50%] ">
                          <label className="font-bold after:text-red-500 after:content-['*']">
                            Status
                          </label>
                          <Select
                            options={Status}
                            className="react-select-container"
                            classNamePrefix="react-select"
                            defaultValue={values.statusId}
                            onChange={(option: any, actionMeta: any) => {
                              setFieldValue("statusId", option.value);
                            }}
                          />
                          <ErrorMessage name="statusId" render={renderError} />
                        </div>
                      </div>
                    </div>

                    <div className="flex w-full mt-2 gap-2">
                      <div className="w-[50%]">
                        <label className="font-bold after:text-red-500 after:content-['*']">
                          Level
                        </label>
                        <Select
                          options={Level}
                          className="react-select-container"
                          classNamePrefix="react-select"
                          defaultValue={values.levelId}
                          onChange={(option: any, actionMeta: any) => {
                            setFieldValue("levelId", option.value);
                          }}
                        />

                        <ErrorMessage name="levelId" render={renderError} />
                      </div>

                      <div className="w-[50%]">
                        <label className="font-bold after:text-red-500 after:content-['*']">
                          PTKP
                        </label>
                        <Select
                          options={PTKP}
                          className="react-select-container"
                          classNamePrefix="react-select"
                          defaultValue={values.ptkpStatusId}
                          onChange={(option: any, actionMeta: any) => {
                            setFieldValue("ptkpStatusId", option.value);
                          }}
                        />
                        <ErrorMessage name="ptkpStatusId" render={renderError} />
                      </div>
                    </div>

                    <div className="flex w-full mt-2 gap-2">
                      <div className="w-[50%]">
                        <label className="font-bold after:text-red-500 after:content-['*']">
                          Branch
                        </label>

                        <Select
                          options={Branch}
                          className="react-select-container"
                          classNamePrefix="react-select"
                          defaultValue={values.branchId}
                          onChange={(option: any, actionMeta: any) => {
                            setFieldValue("branchId", option.value);
                          }}
                        />
                        <ErrorMessage name="branchId" render={renderError} />
                      </div>

                      <div className="w-[50%]">
                        <label className="font-bold after:text-red-500 after:content-['*']">
                          Position
                        </label>

                        <Select
                          options={Position}
                          className="react-select-container"
                          classNamePrefix="react-select"
                          defaultValue={values.positionId}
                          onChange={(option: any, actionMeta: any) => {
                            setFieldValue("positionId", option.value);
                          }}
                        />

                        <ErrorMessage name="positionId" render={renderError} />
                      </div>
                    </div>

                    <button
                      type="submit"
                      className=" w-[130px] my-3  items-center hover:bg-green-600 justify-center bg-green-500 text-white font-semibold py-2 px-4 rounded "
                    >
                      Submit
                    </button>
                  </Form>
                </>
              );
            }}
          </Formik>
        </div>
      )}
    </>
  );
};

export default EmployeStep;
