import {
  deletedLevel,
  getDetailLevelRequest,
  putActiveLevelRequest,
  putDeletedLevelRequest
} from "@stores/HRIS/Level/LevelSlice";
import { useAppDispatch, useAppSelector } from "@stores/store";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "@components/loading";
import CardDetail from "@components/HRIS/CardDetail";
import detailOutlet from "@screens/dailyojol/Outlet/[id]";
import { Toaster } from "react-hot-toast";

const SingleLevel = () => {
  const dispatch = useAppDispatch();
  const { deletedRedirect, isLoading, dataDetail } = useAppSelector((state) => state.hrisLevel);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (deletedRedirect) {
      dispatch(deletedLevel());
      setTimeout(() => {
        navigate("/hris/level");
      }, 600);
    }
  });

  useEffect(() => {
    if (id) {
      dispatch(getDetailLevelRequest(id));
    }
  }, [id]);

  const handleActive = () => {
    const id = dataDetail._id;
    const isActive = dataDetail.isActived;
    dispatch(putActiveLevelRequest({ id, isActive }));
  };

  const handleDelete = () => {
    const id = dataDetail._id;
    const isDelete = dataDetail.isDeleted;
    dispatch(putDeletedLevelRequest({ id, isDelete }));
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Toaster />
          <CardDetail
            handleDelete={handleDelete}
            handleActive={handleActive}
            active={dataDetail.isActived}
            name="Name"
            linkTo={`/hris/level/edit/${id}`}
            value={dataDetail.name}
            description="Description"
            valueDesc={dataDetail.description}
          />
        </>
      )}
    </>
  );
};

export default SingleLevel;
