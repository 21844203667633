import React, { useState, useEffect } from "react";
import Input from "@components/Input.components";
import Textarea from "@components/Textarea";
import Button from "@components/Button.component";
import { Tabs } from "antd";
import { postNotifSelectionBlastAPI, postNotifBlastAPI } from "@stores/dailyapp/notifAPI";
import swal from "sweetalert";

interface IPropsBlast {
  dataNotif: {
    title: string;
    content: string;
  };
  setDataNotif: React.Dispatch<React.SetStateAction<any>>;
}

interface IPropsModal {
  dataNotif: any;
  setDataNotif: React.Dispatch<React.SetStateAction<any>>;
}

const ModalBlast = ({ dataNotif, setDataNotif }: IPropsBlast) => {
  const { title, content } = dataNotif;
  const [loading, setLoading] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setDataNotif({ ...dataNotif, [name]: value });
  };

  const handlePostNotif = async () => {
    swal(`Are you sure want to send notification ?`, {
      buttons: {
        then: {
          text: "Cancel",
          value: "cancel"
        },
        catch: {
          text: "OK",
          value: "catch"
        }
      }
    }).then((value) => {
      switch (value) {
        case "then":
          break;

        case "catch":
          const payload = {
            title,
            content
          };
          setLoading(true);
          postNotifBlastAPI(payload).then((res) => {
            if (res.code === 1000) {
              setLoading(false);
              swal("success", "Success post notification", "success");
            } else {
              setLoading(false);
              swal("Ops!", `${res.code}: Something went wrong`, "error");
            }
          });
          swal("Success!", "Success post notif!", "success");
          break;
      }
    });
  };

  return (
    <>
      <div className="bg-white h-auto p-3 rounded flex flex-col gap-3">
        <div>
          <h1 className="font-bold text-[1.3rem] text-center">Blast</h1>
        </div>
        <Input
          required={`after:content-['*'] after:text-red-500`}
          name={"title"}
          title="Title"
          onChange={handleChange}
          type={"text"}
          placeholder={"Title"}
          value={title ?? ""}
        />

        <Textarea
          required={`after:content-['*'] after:text-red-500`}
          name={"content"}
          title="Content"
          onChange={handleChange}
          placeholder={"Content"}
          value={content ?? ""}
        />

        <div className="flex justify-end mt-3">
          <Button isLoading={loading} onClick={handlePostNotif}>
            Submit
          </Button>
        </div>
      </div>
    </>
  );
};

const ModalSelectionBlast = ({ dataNotif, setDataNotif }: IPropsModal) => {
  const { title, content, phones } = dataNotif;
  const [loading, setLoading] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setDataNotif({ ...dataNotif, [name]: value });
  };

  const handleChangePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = e.target;
    setDataNotif({ ...dataNotif, phones: value.split(",") });
  };

  const handlePostNotif = async () => {
    setLoading(true);
    const res = await postNotifSelectionBlastAPI(dataNotif);
    if (res.code === 1000) {
      setDataNotif({ ...dataNotif, phones: [] });
      setLoading(false);
      swal("Success", "You send notification", "success");
    } else {
      swal("Ops!", `${res.code}: Something went wrong`, "error");
      setLoading(false);
    }
  };

  return (
    <>
      <div className="bg-white h-auto p-3 rounded flex flex-col gap-3">
        <div>
          <h1 className="font-bold text-[1.3rem] text-center">Selection Blast</h1>
        </div>

        <Input
          required={`after:content-['*'] after:text-red-500`}
          name={"title"}
          title="Title"
          onChange={handleChange}
          type={"text"}
          placeholder={"Title"}
          value={title ?? ""}
        />

        <Textarea
          required={`after:content-['*'] after:text-red-500`}
          name={"content"}
          title="Content"
          onChange={handleChange}
          placeholder={"Content"}
          value={content ?? ""}
        />

        <Textarea
          required={`after:content-['*'] after:text-red-500`}
          name={"phones"}
          title="Phones"
          onChange={handleChangePhone}
          placeholder={`Start with "62". Separate numbers with comma ( , ) `}
          value={phones ?? ""}
        />
        <div className="flex justify-end mt-3">
          <Button isLoading={loading} onClick={handlePostNotif}>
            Submit
          </Button>
        </div>
      </div>
    </>
  );
};

const pushNotification = () => {
  const [selectNotifBlast, setSelectNotifBlast] = useState({
    title: "",
    content: "",
    phones: []
  });
  const [notifBlast, setNotifBlast] = useState({
    title: "",
    content: ""
  });

  return (
    <>
      <Tabs
        defaultActiveKey="1"
        items={[
          {
            label: "Blast",
            key: "1",
            children: <ModalBlast dataNotif={notifBlast} setDataNotif={setNotifBlast} />
          },
          {
            label: "Selection Blast",
            key: "2",
            children: (
              <ModalSelectionBlast
                setDataNotif={setSelectNotifBlast}
                dataNotif={selectNotifBlast}
              />
            )
          }
        ]}
      />
    </>
  );
};

export default pushNotification;
