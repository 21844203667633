import API from "@constants/API";

const BASE_URL = "https://api.dev.daily.co.id/hris/bank-account/dashboard/v1/bank-account";

export const postBankAccountAPI = async (
  data: object,
  setLoadingConfirm: React.Dispatch<React.SetStateAction<boolean>>
) => {
  let code;
  try {
    setLoadingConfirm(true);
    await API.HTTP.post(BASE_URL, data).then(({ data }) => {
      setLoadingConfirm(false);
      code = data;
    });
  } catch (err) {
    console.log(err);
    setLoadingConfirm(false);
  }
  return code;
};
