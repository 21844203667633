import { authRequest } from "@stores/AuthSlice";
import { useAppDispatch, useAppSelector } from "@stores/store";
import { Button, Card, Input, Space } from "antd";
import { Formik } from "formik";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthPayload } from "typed/global";

const Login = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const auth = useAppSelector((state) => state.auth);

  useEffect(() => {
    if (auth.isLoggedIn) {
      navigate("/");
    }
  }, [auth]);

  return (
    <>
      <div className="flex items-center justify-center w-full h-screen">
        <Card title="Login" style={{ width: 300, margin: "auto" }}>
          <Formik
            initialValues={{ employeeId: "", password: "" } as AuthPayload}
            validate={(values) => {
              const errors = {} as any;
              if (!values.employeeId) {
                errors.employeeId = "ID is required";
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              dispatch(authRequest(values));
              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit}>
                <Space direction="vertical" size={"middle"}>
                  <div>
                    <Input
                      type="employeeId"
                      name="employeeId"
                      placeholder="employeeId"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.employeeId}
                    />
                    {errors.employeeId && touched.employeeId && errors.employeeId}
                  </div>
                  <div>
                    <Input
                      type="password"
                      name="password"
                      placeholder="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      autoComplete="on"
                    />
                    {errors.password && touched.password && errors.password}
                  </div>
                  <div>
                    <Button
                      htmlType="submit"
                      type="primary"
                      loading={isSubmitting || auth.isPageLoading}
                    >
                      Login
                    </Button>
                  </div>
                </Space>
              </form>
            )}
          </Formik>
        </Card>
      </div>
    </>
  );
};

export default Login;
