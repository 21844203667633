import API from "@constants/API";
import axios from "axios";

const SPB = "https://api.prod.dailyapp.id/dashboard-spg/v1/spg-users";
const BASE_URL = "https://api.prod.daily.co.id/dashboard/cexp/customer/v1/customer-cexp";
const USERACQUISITION = "https://api.prod.dailyapp.id/api-dashboard-user-outlet/user-outlet";
const USER_REPORT = "https://api.prod.dailyapp.id/api-dashboard-user-outlet/user-report";
const CASHBACK_REPORT = "https://api.prod.dailyapp.id/api-dashboard-user-outlet/cashback-report";
const CASHBACK_ORIGIN_REPORT =
  "https://api.prod.dailyapp.id/api-dashboard-user-outlet/cashback-origin-report";

export const getAllCustomerAPI = async () => {
  const response = await API.HTTP.get(BASE_URL);
  return response.data;
};

export const getDetailCustomerAPI = async (id: string) => {
  const response = await API.HTTP.get(BASE_URL + "/" + id);
  return response.data;
};

export const getCoinCustomerAPI = async (id: string) => {
  const response = await API.HTTP.get(`${BASE_URL}/dailycoin/${id}`);
  return response.data;
};

export const getTransactionCustomerAPI = async (id: string) => {
  const response = await API.HTTP.get(`${BASE_URL}/transactions/${id}`);
  return response.data;
};

export const postCutDailycoinAPI = async (data: object) => {
  const response = await API.HTTP.post(
    "https://api.new.v1.daily.co.id/dailyapps/test/dailycoin-cutoff",
    data
  );
  return response.data;
};

export const getuserSPBAPI = async (startDate: string, endDate: string) => {
  const response = await API.HTTP.get(`${SPB}?startdate=${startDate}&enddate=${endDate}`);
  return response.data;
};

export const getInboxWebAPI = async (page: number) => {
  const config = {
    headers: {
      Authorization: `Bearer f9e4aae9d28c329a911c2145c088c383f1f53642f1e6c63deead989af11a79e701e2a4cf4b79a587f2d87a82025021befe785f25efd7f5555758df5b4bd593478030836c61d4fdef53e5c5bbe0c63f8bb2599108ecc4fdf2fefabcdc1f90c0b6dc744e2b8bb471d12bde3f3628ae56177b1c1bd6f5159405df1ba7098e39926f`
    }
  };
  const response = await axios.get(
    `https://cms.daily.co.id/api/inboxes?pagination[page]=${page}&pagination[pageSize]=10`,
    config
  );
  return response.data;
};

// User Acquisition
export const getUserAcquisitionAPI = async (startDate: string, endDate: string) => {
  const response = await API.HTTP.get(
    `${USERACQUISITION}?startdate=${startDate}&enddate=${endDate}`
  );
  return response.data;
};

// User Report
export const getUserReportAPI = async (startDate: string, endDate: string) => {
  const response = await API.HTTP.get(`${USER_REPORT}?startdate=${startDate}&enddate=${endDate}`);
  return response.data;
};

// Cashback Report
export const getCashbackReportAPI = async (startDate: string, endDate: string) => {
  const response = await API.HTTP.get(
    `${CASHBACK_REPORT}?startdate=${startDate}&enddate=${endDate}`
  );
  return response.data;
};

// Cashback Origin Report
export const getCashbackOriginReportAPI = async (startDate: string, endDate: string) => {
  const response = await API.HTTP.get(
    `${CASHBACK_ORIGIN_REPORT}?startdate=${startDate}&enddate=${endDate}`
  );
  return response.data;
};
