import InputSearch from "@components/inputSearch";
import { dateToString, nf } from "@helpers/utils";
import { CSVReportComplain, getReportComplainReq } from "@stores/dailycoin/report/ReportSlice";
import { useAppDispatch, useAppSelector } from "@stores/store";
import { DatePicker, DatePickerProps, Table } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Loading from "@components/loading";
import moment from "moment";
import ExportCSV from "@components/ExportCSV";
import DataTable from "react-data-table-component";

const columns = [
  {
    name: "Complain Date",
    id: "complainDate",
    grow: 2,
    selector: (row: any) => row.complainDate,
    cell: (row: any) => <div>{dateToString(row.complainDate)}</div>
  },
  {
    name: "Name",
    id: "name",
    selector: (row: any) => row.custName
  },
  {
    name: "Phone",
    id: "phone",
    selector: (row: any) => row.custPhone
  },
  {
    name: "Content",
    dataIndex: "content",
    id: "content",
    selector: (row: any) => row.content
  },
  {
    name: "Outlet",
    id: "outlet",
    selector: (row: any) => row.outlet
  },
  {
    name: "Review Status",
    id: "reviewStatus",
    selector: (row: any) => row.reviewStatus,
    cell: (row: any) => (
      <div
        className={`font-bold uppercase ${
          row.reviewStatus === "reviewed" ? "text-green-500" : "text-red-500"
        }`}
      >
        {row.reviewStatus}
      </div>
    )
  },
  {
    name: "Review Message",
    id: "reviewMessage",
    selector: (row: any) => row.reviewMessage,
    cell: (row: any) => <div className="font-bold uppercase">{row.reviewMessage}</div>
  },
  {
    name: "Image",
    selector: (row: any) => row.image,
    id: "image",
    cell: (row: any) => (
      <div>
        {row.image !== null ? (
          <a target="_blank" rel="noreferrer" href={`${row.image}`}>
            {/* {text} */}
            View Image
          </a>
        ) : (
          <div>No Image</div>
        )}
      </div>
    )
  },
  {
    name: "Action",
    selector: (row: any) => row._id.id,
    id: "action",
    cell: (row: any) => (
      <div className="flex gap-3 items-center justify-center">
        <Link
          className="hover:text-white rounded py-2 px-5 bg-blue-500 text-white"
          to={`/costumer-experience/complains-report/${row._id.id}`}
        >
          Detail
        </Link>
      </div>
    )
  }
];
const pageSize = 10;

const ReportComplain = () => {
  const dispatch = useAppDispatch();
  const { reportComplain, reportComplainCSV, isLoading } = useAppSelector(
    (state) => state.CEReport
  );

  const startDate = useRef(moment().startOf("month").format("YYYY-MM-DD"));
  const endDate = useRef(moment().format("YYYY-MM-DD"));

  useEffect(() => {
    dispatch(CSVReportComplain());
  }, [isLoading]);

  useEffect(() => {
    dispatch(getReportComplainReq({ startDate: startDate.current, endDate: endDate.current }));
    dispatch(CSVReportComplain());
  }, []);

  const filterByDate = () => {
    dispatch(getReportComplainReq({ startDate: startDate.current, endDate: endDate.current }));
  };

  const handleStartDate: DatePickerProps["onChange"] = (date: any, dateString: any) => {
    startDate.current = dateString;
  };

  const handleEndDate: DatePickerProps["onChange"] = (date: any, dateString: any) => {
    endDate.current = dateString;
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="flex overflow-scroll justify-between items-center">
            <div className=" flex items-center gap-4">
              <h1 className="m-0">Filter</h1>

              <DatePicker
                className="h-9"
                name="startDate"
                onChange={handleStartDate}
                placeholder="Start date"
              />

              <DatePicker
                className="h-9"
                name="endDate"
                onChange={handleEndDate}
                placeholder="End date"
              />

              <button
                onClick={filterByDate}
                className="flex text-medium items-center gap-1 text-center outline-none h-9 w-[10rem] rounded bg-blue-500 hover:bg-blue-400 text-white justify-center"
              >
                Submit
              </button>
            </div>
            <ExportCSV
              csvData={reportComplainCSV}
              fileName={`report-review-${startDate.current}-${endDate.current}`}
            />
          </div>

          <div className="mt-5">
            <DataTable data={reportComplain} columns={columns} pagination persistTableHead />
          </div>
        </>
      )}
    </>
  );
};

export default ReportComplain;
