import React, { useEffect, useState } from "react";
import Loading from "@components/loading";
import { dateToString } from "@helpers/utils";
import { deleteBannerBrandRequest, getBannerBrandRequest } from "@stores/dailyapp/BannerSlice";
import { useAppDispatch, useAppSelector } from "@stores/store";
import { Link } from "react-router-dom";
import SelectComponents from "@components/Select";
import MyTable from "@components/Table/MyTable";
import AddBannerBrand from "./AddBannerBrand";

const options = [
  { label: "Dailybox", value: "dailybox" },
  { label: "Shirato", value: "shirato" },
  { label: "Antarasa", value: "antarasa" },
  { label: "Breadlife", value: "breadlife" },
  { label: "Tiantea", value: "tiantea" },
  { label: "Lumiere", value: "lumiere" },
  { label: "Ayam Amanah", value: "ayam amanah" }
];

const Select = [
  {
    label: "Date",
    value: "createdAt"
  },
  {
    label: "Title",
    value: "title"
  },
  {
    label: "Thumbnail",
    value: "imageMediaUrl"
  },
  {
    label: "Description",
    value: "description"
  },
  {
    label: "Type",
    value: "type"
  }
];

const Action = (row: any) => {
  return (
    <>
      <div className="flex items-center gap-2">
        <Link
          to={row._id}
          className="bg-blue-500 text-white p-2 px-4 rounded hover:text-white hover:bg-blue-600"
        >
          Detail
        </Link>
      </div>
    </>
  );
};

interface IBrandProps {
  title: string;
}

const bannerBrand: React.FC<IBrandProps> = ({ title }) => {
  const dispatch = useAppDispatch();
  const dataBanner = useAppSelector((state) =>
    state.dailyBanner.data?.map((x: any) => ({ ...x, key: x._id }))
  );
  const { isLoading } = useAppSelector((state) => state.dailyBanner);

  const [openModal, setOpenModal] = useState(false);
  const [stateType, setStateType] = useState("dailybox");

  useEffect(() => {
    dispatch(getBannerBrandRequest(stateType));
  }, []);

  useEffect(() => {
    dispatch(getBannerBrandRequest(stateType));
  }, [stateType]);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div>
          <div className="mb-4 flex justify-between">
            <Link
              to="add-brand"
              className=" py-2 text-center px-6 bg-green-500 hover:bg-green-600 hover:text-white rounded hover:shadow-lg text-white font-semibold"
            >
              Create {title}
            </Link>
            <div className="w-[40%]">
              <SelectComponents
                options={options}
                onChange={(value: any) => {
                  setStateType(value.value);
                }}
              />
            </div>
          </div>
          <MyTable action={Action} fileName="" select={Select} dataSource={dataBanner?.reverse()} />
        </div>
      )}
    </>
  );
};

export default bannerBrand;
