import API from "@constants/API";

const REPORT_REVIEW = "https://api.prod.daily.co.id/dashboard/cexp/v1/review-report?";
const REPORT_COMPLAIN = "https://api.prod.daily.co.id/dashboard/cexp/v1/complain-report?";

export const getReportReviewApi = async (startDate: string, endDate: string) => {
  const response = await API.HTTP.get(`${REPORT_REVIEW}startdate=${startDate}&enddate=${endDate}`);
  return response.data;
};

export const getReportComplainApi = async (startDate: any, endDate: string) => {
  const response = await API.HTTP.get(
    `${REPORT_COMPLAIN}startdate=${startDate}&enddate=${endDate}`
  );
  return response.data;
};
