import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import { IPayloadAdd } from "@typed/HRIS/allHris";
import { useAppDispatch, useAppSelector } from "@stores/store";
import { useNavigate, useParams } from "react-router-dom";
import { postStatusRequest, putStatusRequest, resetStatus } from "@stores/HRIS/Status/StatusSlice";
import InputForm from "@components/HRIS/InputForm";
import * as R from "ramda";
import InputFormik, { IValue } from "@components/HRIS/Person/InputFormik";

const AddStatus = () => {
  const [addStatus, setAddStatus] = useState<IPayloadAdd>({} as IPayloadAdd);
  const dispatch = useAppDispatch();
  const { data, isLoading, lastCreateData } = useAppSelector((state) => state.hrisStatus);
  const { id } = useParams();
  const navigate = useNavigate();
  const { name, description } = addStatus;

  const initialValues = {
    name: "",
    description: ""
  };

  // useEffect(() => {
  //   if (!R.isEmpty(lastCreateData)) {
  //     dispatch(resetStatus());
  //     setTimeout(() => {
  //       navigate("/hris/status");
  //     }, 600);
  //   }
  // }, [lastCreateData]);

  useEffect(() => {
    if (id) {
      data.filter(
        (item) =>
          item._id === id &&
          setAddStatus({ ...addStatus, name: item.name, description: item.description })
      );
    }
  }, [id]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setAddStatus({ ...addStatus, [name]: value });
  };

  const handleSubmit = (values: IValue) => {
    const { name, description } = values;
    const Data = {
      name,
      description
    };
    if (id) {
      dispatch(putStatusRequest({ id, Data })).then(({ payload }) => {
        if (payload.code === 1000) {
          swal({
            title: "Success!",
            text: "You status is update!",
            icon: "success",
            buttons: {
              confirm: {
                text: "OK",
                value: navigate("/hris/status")
              }
            }
          });
        }
      });
    } else {
      dispatch(postStatusRequest(Data)).then(({ payload }) => {
        if (payload.code === 1000) {
          swal({
            title: "Success!",
            text: "You status is created!",
            icon: "success",
            buttons: {
              confirm: {
                text: "OK",
                value: navigate("/hris/status")
              }
            }
          });
        }
      });
    }
  };

  return (
    <>
      {/* <Toaster /> */}
      <InputFormik
        data={data}
        isLoading={isLoading}
        title="Status"
        id={id}
        onSubmit={handleSubmit}
        initialValue={initialValues}
      />
    </>
  );
};

export default AddStatus;
