import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { IStoreCoin, IDetailCoin, IOutletTrx } from "@typed/dailycoin/dailyCoin";
import { getCoinPaymenAPI, getDailyCoinsAPI, getTrxOutletAPI } from "./dailyCoinAPI";
import swal from "sweetalert";

export const getDailyCoinsRequest = createAsyncThunk(
  "daily-coin/getDailyCoinsRequest",
  async () => {
    const response = await getDailyCoinsAPI();
    return response;
  }
);

export const getCoinPaymentRequest = createAsyncThunk(
  "daily-coin/getCoinPaymentRequest",
  async ({ startDate, endDate }: { startDate: any; endDate: any }) => {
    const response = await getCoinPaymenAPI(startDate, endDate);
    return response;
  }
);

export const getOutletTrxRequest = createAsyncThunk(
  "daily-coin/getOutletTrxRequest",
  async ({ id, startDate, endDate }: { id: string | undefined; startDate: any; endDate: any }) => {
    const response = await getTrxOutletAPI(id, startDate, endDate);
    return response;
  }
);

const initialState: IStoreCoin = {
  data: {} as IDetailCoin,
  isLoading: false,
  coinOutlet: [],
  outletTransaction: {} as IOutletTrx,
  outletSCV: []
};

const DailyCoinSlice = createSlice({
  name: "daily-coin",
  initialState: initialState,
  reducers: {
    filteringCSV: (state: IStoreCoin) => {
      state.outletSCV = state.coinOutlet.map((item: any) => ({
        Periode: item.periode,
        Outlet: item.outlet,
        Dailycoin: item.dailycoin,
        DailycoinCurrency: item.dailycoinCurrency
      }));
    }
  },
  extraReducers(builder) {
    builder.addCase(getDailyCoinsRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getDailyCoinsRequest.fulfilled, (state, action) => {
      action.payload.code === 1000
        ? (state.data = action.payload.data)
        : swal("Ups!", "Something went wrong!", "error");
      state.isLoading = false;
    });
    builder.addCase(getDailyCoinsRequest.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getCoinPaymentRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCoinPaymentRequest.fulfilled, (state, action) => {
      action.payload.code === 1000
        ? (state.coinOutlet = action.payload.data)
        : swal("Ups!", "Something went wrong!", "error");
      state.isLoading = false;
    });
    builder.addCase(getCoinPaymentRequest.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getOutletTrxRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getOutletTrxRequest.fulfilled, (state, action) => {
      action.payload.code === 1000
        ? (state.outletTransaction = action.payload.data)
        : swal("Ups!", "Something went wrong!", "error");
      state.isLoading = false;
    });
    builder.addCase(getOutletTrxRequest.rejected, (state) => {
      state.isLoading = false;
    });
  }
});

export default DailyCoinSlice.reducer;
export const { filteringCSV } = DailyCoinSlice.actions;
