import React, { useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

interface IRadioProps {
  selectedOption: string;
  dataSource: any[];
  className?: string;
  setSelectedOption: React.Dispatch<React.SetStateAction<string>>;
}

const RadioComponent = ({
  selectedOption,
  setSelectedOption,
  className,
  dataSource
}: IRadioProps) => {
  const [showRadio, setShowRadio] = useState<boolean>(false);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
  };

  return (
    <div className="relative">
      <div className="group inline-block relative">
        <button
          onClick={() => setShowRadio(!showRadio)}
          className={`bg-[#7c3fad] text-white font-semibold ${
            className ?? "w-[120px]"
          } py-1 px-4 rounded flex justify-between items-center`}
        >
          <span className="mr-1">{selectedOption}</span>
          <svg
            className="fill-current h-4 w-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
          </svg>
        </button>
        {showRadio ? (
          <div
            style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
            className={`absolute rounded-md w-full top-11 z-10 max-h-[12rem] overflow-y-scroll bg-white  `}
          >
            {dataSource.map((item, idx) => (
              <div key={idx} className="flex gap-2 p-1 cursor-pointer">
                <input
                  type="radio"
                  name={item}
                  onChange={handleRadioChange}
                  value={item}
                  // checked
                  checked={selectedOption === item}
                />
                {item}
              </div>
            ))}
          </div>
        ) : undefined}
      </div>
    </div>
  );
};

export default RadioComponent;
