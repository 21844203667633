import { useAppDispatch, useAppSelector } from "@stores/store";
import React, { FC, useEffect, useState } from "react";
import { Button, Card, Col, Descriptions, List, Popover, Row, Space, Statistic, Table } from "antd";
import { any } from "ramda";
import { DataUser } from "@typed/dailojol/listUser";
import { useParams } from "react-router-dom";
import { getUserDetailRequest } from "@stores/dailyojol/UserSlice";
import { dateToString } from "@helpers/utils";
const DetailUser: FC = () => {
  const userDetail = useAppSelector((state) => state.dailyojolUser.detailUser);
  const loading = useAppSelector((state) => state.dailyojolUser.loading);
  const { id } = useParams();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (id !== undefined) {
      dispatch(getUserDetailRequest({ id: id }));
    }
  }, [id]);
  return (
    <>
      {loading ? (
        <div className="flex items-center justify-center">
          <div>
            <div
              className="spinner-border animate-spin inline-block w-12 h-12 border-4 rounded-full"
              role="status"
            >
              <span className="visually-hidden"></span>
            </div>
          </div>
        </div>
      ) : (
        <div className="p-3 flex flex-col">
          <Descriptions bordered title={userDetail?.username} size="small">
            <Descriptions.Item label="Birthdate">
              {dateToString(userDetail?.birthDate)}
            </Descriptions.Item>
            <Descriptions.Item label="OTP Code">{userDetail?.code || "Empty"}</Descriptions.Item>
            {/* <Descriptions.Item label="Email">{userDetail?.email || "Empty"}</Descriptions.Item> */}
            <Descriptions.Item label="Gender">{userDetail?.gender}</Descriptions.Item>
            <Descriptions.Item label="Last Login">
              {dateToString(userDetail?.lastLogin)}
            </Descriptions.Item>
            <Descriptions.Item label="Phone">
              <a href={`https://wa.me/`}>+{userDetail?.phoneNumber}</a>
            </Descriptions.Item>
            <Descriptions.Item label="Referral Code">{userDetail?.uid}</Descriptions.Item>
            <Descriptions.Item label="Daily ID">{userDetail?._id}</Descriptions.Item>
            {/* <Descriptions.Item label="outl ID">{userDetail?._id}</Descriptions.Item> */}
          </Descriptions>
          <div className=" mt-5">
            <Descriptions bordered title="Outlet" size="small">
              <Descriptions.Item label="name">{userDetail?.outlet?.name}</Descriptions.Item>
              <Descriptions.Item label="id">{userDetail?.outlet?._id}</Descriptions.Item>
            </Descriptions>
          </div>
        </div>
      )}
    </>
  );
};

export default DetailUser;
