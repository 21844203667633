import CardDetail from "@components/HRIS/CardDetail";
import {
  deletedPosition,
  getDetailPositionRequest,
  putActivePositionRequest,
  putDeletePositionRequest
} from "@stores/HRIS/position/PositionSlice";
import { useAppDispatch, useAppSelector } from "@stores/store";
import React, { useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import Loading from "@components/loading";
import { Toaster } from "react-hot-toast";

const SinglePosition = () => {
  const { id } = useParams();
  const { deletedRedirect, dataDetail, isLoading } = useAppSelector((state) => state.hrisPosition);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (deletedRedirect) {
      dispatch(deletedPosition());
      setTimeout(() => {
        navigate("/hris/position");
      }, 600);
    }
  }, [deletedRedirect]);

  useEffect(() => {
    if (id) {
      dispatch(getDetailPositionRequest(id));
    }
  }, [id]);

  const handleActive = () => {
    const id = dataDetail._id;
    const isActive = dataDetail.isActived;
    dispatch(putActivePositionRequest({ id, isActive }));
  };

  const handleDelete = () => {
    const id = dataDetail._id;
    const isDelete = dataDetail.isDeleted;
    dispatch(putDeletePositionRequest({ id, isDelete }));
  };
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Toaster />
          <CardDetail
            handleDelete={handleDelete}
            handleActive={handleActive}
            name="Name"
            active={dataDetail.isActived}
            linkTo={`/hris/position/edit/${id}`}
            value={dataDetail.name}
            description="Description"
            valueDesc={dataDetail.description}
          />
        </>
      )}
    </>
  );
};

export default SinglePosition;
