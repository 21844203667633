import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ICodeStore } from "@typed/dailojol/Code";
import { getOjolCodeAPI } from "./CodeAPI";

export const getOjolCodeRequest = createAsyncThunk(
  "dailyojol-code/getOjolCodeRequest",
  async () => {
    const response = await getOjolCodeAPI();

    return response.data;
  }
);

const initialState: ICodeStore = {
  data: [],
  loading: false
};

const CodeSlice = createSlice({
  name: "dailyojol-code",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getOjolCodeRequest.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getOjolCodeRequest.fulfilled, (state, action) => {
      if (action.payload.code === 1000) {
        state.loading = false;
        state.data = action.payload.data;
      }
    });
    builder.addCase(getOjolCodeRequest.rejected, (state) => {
      state.loading = false;
    });
  }
});

export default CodeSlice.reducer;
