import React, { useEffect, useRef } from "react";
import { DatePicker, DatePickerProps } from "antd";
import moment from "moment";
import MyTable from "@components/Table/MyTable";
import { useAppDispatch, useAppSelector } from "@stores/store";
import { getUserAcquisitionReq } from "@stores/CE/CEXPSlice";

const UserAcquisition = () => {
  const dispatch = useAppDispatch();
  const { userAcquisition, isLoading } = useAppSelector((state) => state.CEcustomer);

  const startDateRef = useRef<any>(moment().startOf("month").format("YYYY-MM-DD"));
  const endDateRef = useRef<any>(moment().endOf("month").format("YYYY-MM-DD"));

  const handleStartDate: DatePickerProps["onChange"] = (date: any, dateString: any) => {
    startDateRef.current = dateString;
  };

  const handleEndDate: DatePickerProps["onChange"] = (date: any, dateString: any) => {
    endDateRef.current = dateString;
  };

  const handleFilter = () => {
    dispatch(
      getUserAcquisitionReq({
        startDate: startDateRef.current,
        endDate: endDateRef.current
      })
    );
  };

  useEffect(() => {
    dispatch(
      getUserAcquisitionReq({
        startDate: startDateRef.current,
        endDate: endDateRef.current
      })
    );
  }, []);

  return (
    <>
      <div className="flex gap-3 items-center">
        <h1>Filter By</h1>
        <DatePicker
          className="h-9"
          name="startDate"
          onChange={handleStartDate}
          placeholder="Start Date"
        />

        <DatePicker
          className="h-9"
          name="endDate"
          onChange={handleEndDate}
          placeholder="End Date"
        />

        <button
          onClick={handleFilter}
          className="flex items-center text-center outline-none h-9 w-[10rem] bg-blue-500 rounded justify-center font-semibold text-white hover:bg-blue-400"
        >
          Submit
        </button>
      </div>
      <div>
        <MyTable
          dataSource={userAcquisition}
          loading={isLoading}
          fileName={"User-Acquisition"}
          exportable
          select={[
            { label: "Outlet", value: "outletName" },
            { label: "Total", value: "total" }
          ]}
        />
      </div>
    </>
  );
};

export default UserAcquisition;
