import { useAppDispatch, useAppSelector } from "@stores/store";
import React, { useEffect, useRef } from "react";
import { CSVOriginpayment, getPaymentOriginRequest } from "@stores/dailycoin/ReportingSlice";
import moment from "moment";
import { DatePicker, DatePickerProps, Table } from "antd";
import Loading from "@components/loading";
import MyTable from "@components/Table/MyTable";
import DataTable from "react-data-table-component";
import { getOutletsRequest } from "@stores/dailyapp/dailyOutletSlice";

const handleColumn = (outlet: any) => {
  const columns = [
    {
      name: "Date",
      selector: (row: any) => row.createdAt,
      sortable: true,
      cell: (row: any) => moment(row.createdAt).format("MMMM Do YYYY, h:mm")
    },
    {
      name: "Transaction",
      selector: (row: any) => row.transaction,
      sortable: true,
      cell: (row: any) => row.transaction
    },
    {
      name: "Payment",
      selector: (row: any) => row.payment,
      sortable: true,
      cell: (row: any) => row.payment
    }
  ];

  return columns;
};

const PaymentOrigin = () => {
  const dispatch = useAppDispatch();
  const { paymentOriginCSV, isLoading } = useAppSelector((state) => state.dailyReport);
  const paymentOrigin = useAppSelector((state) =>
    state.dailyReport.paymentOrigin?.map((item) => ({ ...item, key: item._id?.id }))
  );
  const Outlet = useAppSelector((state) =>
    state.dailyappOutlet.AppOutlet.map((item) => ({ ...item, key: item._id }))
  );

  const startDate = useRef(moment().startOf("month").format("YYYY-MM-DD"));
  const endDate = useRef(moment().format("YYYY-MM-DD"));

  useEffect(() => {
    // dispatch(getPaymentOriginRequest({ startDate: startDate.current, endDate: endDate.current }));
    dispatch(CSVOriginpayment());
  }, []);

  const filterByDate = () => {
    dispatch(getPaymentOriginRequest({ startDate: startDate.current, endDate: endDate.current }));
    dispatch(CSVOriginpayment());
  };

  useEffect(() => {
    dispatch(CSVOriginpayment());
  }, [isLoading]);

  const handleStartDate: DatePickerProps["onChange"] = (date: any, dateString: any) => {
    startDate.current = dateString;
  };

  const handleEndDate: DatePickerProps["onChange"] = (date: any, dateString: any) => {
    endDate.current = dateString;
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <div className="flex justify-between items-center">
        <div className=" flex items-center gap-4">
          <h1 className="m-0">Filter</h1>

          <DatePicker
            className="h-9"
            name="startDate"
            onChange={handleStartDate}
            placeholder="Start date"
          />

          <DatePicker
            className="h-9"
            name="endDate"
            onChange={handleEndDate}
            placeholder="End date"
          />

          <button
            onClick={filterByDate}
            className="flex text-medium items-center gap-1 text-center outline-none h-9 w-[10rem] rounded bg-blue-500 hover:bg-blue-400 text-white justify-center"
          >
            Submit
          </button>
        </div>
      </div>
      <div className="mt-5">
        {/* <DataTable data={paymentOrigin} columns={handleColumn(Outlet)} /> */}
        <MyTable
          exportable
          fileName="payment-origin"
          select={[
            { label: "Date", value: "date" },
            { label: "Username", value: "userName" },
            { label: "Outlet", value: "outlet" },
            { label: "Outlet Code", value: "outletCode" },
            { label: "Transaction", value: "transaction" },
            { label: "Payment", value: "payment" },
            { label: "Payment Code", value: "paymentCode" }
          ]}
          dataSource={paymentOrigin}
        />
      </div>
    </>
  );
};

export default PaymentOrigin;
