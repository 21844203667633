import { getOutletsRequest } from "@stores/dailyojol/OutletSlice";
import { useAppDispatch, useAppSelector } from "@stores/store";
import React, { useEffect } from "react";
import { HiOutlineUserGroup } from "react-icons/hi";
import { FaStore } from "react-icons/fa";
import { IoCartOutline } from "react-icons/io5";
import { getUsersOjolRequest } from "@stores/dailyojol/UserSlice";
import { getOjolProductsRequest } from "@stores/dailyojol/ProductSlice";
import Skeleton from "@components/Skeleton";
import CardDashboard from "@components/CardDashboard";

const DailyojolDashboard = () => {
  const dispatch = useAppDispatch();
  const { listUsers, loading } = useAppSelector((state) => state.dailyojolUser);
  const { Outlet } = useAppSelector((state) => state.dailyojolOutlet);
  const { data } = useAppSelector((state) => state.dailyojolProduct);

  useEffect(() => {
    dispatch(getOutletsRequest({ skip: 0, limit: 50 }));
    dispatch(getUsersOjolRequest());
    dispatch(getOjolProductsRequest());
  }, [dispatch]);
  return (
    <>
      <div className="flex gap-2 w-full items-center flex-wrap">
        {loading ? (
          <div className="flex flex-wrap gap-2  w-full">
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </div>
        ) : (
          <div className="flex flex-wrap gap-2  w-full">
            <CardDashboard title="Total Users" value={listUsers.length} />
            <CardDashboard title="Total Outlet" value={Outlet.length} />
            <CardDashboard title="Total Product" value={data.length} />
          </div>
        )}
      </div>
    </>
  );
};

export default DailyojolDashboard;
