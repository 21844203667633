import React, { Fragment, useEffect, useRef, useState } from "react";

import { dateToString, filterByKeyword, flattenObject } from "@helpers/utils";
import {
  getTransactionByUserIdRequest,
  postRefundTransactionRequest
} from "@stores/dailyapp/TransactionSlice";
import {
  getUserActivityRequest,
  getUserRequest,
  postResetEmployeeReq
} from "@stores/dailyapp/UserSlice";
import { useAppDispatch, useAppSelector } from "@stores/store";
import { Card, Col, Descriptions, List, Popover, Row, Statistic, Table, Tag } from "antd";
import { useLocation, useParams } from "react-router-dom";
import { getCoinByIdRequest } from "@stores/dailyapp/CoinSlice";
import CardDetail from "@components/CardDetail";
import ReactModal from "@components/ReactModal";
import LoadingComponent from "@components/loading";
import swal from "sweetalert";
import Input from "@components/Input.components";
import { postResetEmployeeApi, putEmployeeID } from "@stores/dailyapp/UserAPI";
import DataTable from "react-data-table-component";
import useRole from "@hooks/useRole";
import Textarea from "@components/Textarea";
import Modal from "@components/Modal";
import Select from "react-select";
import Button from "@components/Button.component";
import Detail from "@components/Detail";
import { getCoinCustomerReq, postCutCoinRequest } from "@stores/CE/CEXPSlice";
import { Transition } from "@headlessui/react";
import MyTable from "@components/Table/MyTable";

const options = [
  { label: "Double payment", value: "double-payment" },
  { label: "Over payment", value: "over-payment" },
  { label: "Cancel payment", value: "cancel-payment" }
];

const optionsCutCoin = [
  { value: "DAILYCLICK", label: "DAILYCLICK" },
  { value: "BILL", label: "BILL" },
  { value: "PAYMENT", label: "PAYMENT" },
  { value: "TOPUP", label: "TOPUP" },
  { value: "BALANCING", label: "BALANCING" },
  { value: "TEST", label: "TEST" }
];

type TPropsModalCoin = {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  id: string | any;
};

const ModalCutCoin = ({ openModal, setOpenModal, id }: TPropsModalCoin) => {
  const dispatch = useAppDispatch();
  const [cutCoin, setCutCoin] = useState({
    coin: 0,
    message: ""
  });
  const [typeCutCoin, setTypeCutCoin] = useState<string>("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCutCoin({
      ...cutCoin,
      [name]: value
    });
  };

  const { coin, message } = cutCoin;

  const showAlert = () => {
    const data = {
      userId: id,
      point: coin,
      message: message,
      type: typeCutCoin
    };
    if (coin === 0 && message === " ") {
      swal("Ups", "Tidak boleh kosong", "error");
    } else {
      swal({
        title: "Apa kamu yakin ?",
        buttons: ["OK", "Cancel"],

        text: `Ingin memotong coin sebesar ${coin}`,
        icon: "warning",
        dangerMode: true
      }).then((willDelete) => {
        if (willDelete) {
          swal("Ups!!", {
            icon: "error"
          });
        } else {
          dispatch(postCutCoinRequest(data)).then((res) => {
            if (res.payload.code === 1000) {
              setCutCoin({
                coin: 0,
                message: ""
              });
              setOpenModal(false);
            }
          });
        }
      });
    }
  };

  return (
    <Modal isOpen={openModal} title="Potong Coin" closeModal={() => setOpenModal(false)}>
      <div className="h-[21rem] mt-16 flex flex-col gap-3">
        <div>
          <h1 className="pl-2 m-0 text-[#6A7380] font-medium after:content-['*'] after:text-red-500">
            Type
          </h1>
          <Select
            className="react-select-container"
            classNamePrefix="react-select"
            onChange={(option: any, actionMeta: any) => {
              setTypeCutCoin(option.value);
            }}
            placeholder="Type"
            options={optionsCutCoin}
          />
        </div>

        <Input
          value={coin}
          type="number"
          name="coin"
          required="after:content-['*'] after:text-red-500"
          onChange={handleChange}
          title="Coin"
          placeholder="Coin"
        />
        <Textarea
          value={message}
          required="after:content-['*'] after:text-red-500"
          name="message"
          onChange={handleChange}
          title="Notes"
          placeholder="Notes"
        />

        <div className={"absolute  bottom-5 right-6"}>
          <button
            onClick={showAlert}
            className="py-2 px-9 bg-blue-700 text-white   rounded-full font-poppins "
          >
            Submit
          </button>
        </div>
      </div>
    </Modal>
  );
};

interface IPropsRefund {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  idTrx: string;
  handleClose: () => void;
}

const ModalRefund = ({ openModal, setOpenModal, idTrx, handleClose }: IPropsRefund) => {
  const dispatch = useAppDispatch();

  const [createRefund, setCreateRefund] = useState({
    amount: "0",
    notes: "",
    reason: "",
    transactionNumberRef: ""
  });
  const { amount, notes, reason, transactionNumberRef } = createRefund;
  const { isLoading } = useAppSelector((state) => state.dailyTransaction);
  const tenLastTrx: any = useAppSelector((state) =>
    state.dailyTransaction.transactions.find((item: any) => item._id === idTrx)
  );

  const showAlert = () => {
    const data = {
      amount: Number(amount),
      notes,
      reason,
      transactionNumberRef: tenLastTrx?.trxNumber
    };
    if (Number(amount) < 0 && notes === "") {
      swal("Ups", "Something went wrong", "error");
    } else {
      swal({
        title: "Are you sure ?",
        buttons: ["OK", "Cancel"],

        text: ` you want to refund ${amount} coins`,
        icon: "warning",
        dangerMode: true
      }).then((willDelete) => {
        if (willDelete) {
          swal("Ups!!", {
            icon: "error"
          });
        } else {
          dispatch(postRefundTransactionRequest(data)).then((res) => {
            if (res.payload.code === 1000) {
              setCreateRefund({
                amount: "0",
                notes: "",
                reason: "",
                transactionNumberRef: ""
              });
              setOpenModal(false);
            }
          });
        }
      });
    }
  };
  return (
    <>
      <Modal isOpen={openModal} title="Refund" closeModal={handleClose}>
        <div className="h-[21rem] mt-16 flex flex-col gap-3">
          <div>
            <h1 className={` text-[#6A7380] after:content-['*'] after:text-red-600 m-0`}>Reason</h1>

            <Select
              onChange={(option: any, actionMeta: any) => {
                setCreateRefund({ ...createRefund, reason: option.value });
              }}
              placeholder="Reason"
              options={options}
            />
          </div>
          <Input
            value={amount}
            name="amount"
            required="after:content-['*'] after:text-red-500"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const { name, value } = e.target;

              setCreateRefund({
                ...createRefund,
                [name]: value
              });
            }}
            title="Amount"
            placeholder="Coin"
          />

          <Textarea
            value={notes}
            required="after:content-['*'] after:text-red-500"
            name="notes"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const { name, value } = e.target;
              setCreateRefund({ ...createRefund, [name]: value });
              // setCreateRefund({ ...createRefund, transactionNumberRef: tenLastTrx.trxNumber });
              // tenLastTrx.trxNumber;
            }}
            title="Notes"
            placeholder="Notes"
          />

          <div className={"absolute  bottom-5 right-6"}>
            <Button isLoading={isLoading} onClick={showAlert}>
              Submit
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

const Action = (row: any) => {
  const auth = useAppSelector((state) => state.auth);

  const isForbidden = useRole(auth.employeeData.roles, "super-dailycoin");
  const isPermissionCEXP = useRole(auth.employeeData.roles, "cexp");
  const idRefund = useRef("");
  const [openRefund, setOpenRefund] = useState<boolean>(false);

  return (
    <div>
      <ModalRefund
        handleClose={() => setOpenRefund(false)}
        idTrx={idRefund.current}
        openModal={openRefund}
        setOpenModal={setOpenRefund}
      />
      {isForbidden ? (
        <button
          onClick={() => {
            if (row.operator !== "DEBIT") {
              setOpenRefund(true);
              idRefund.current = row._id;
            }
          }}
          disabled={!isForbidden && row.operator === "DEBIT" ? true : false}
          className={`${
            isForbidden && row.operator === "CREDIT"
              ? "cursor-pointer bg-blue-500"
              : "bg-gray-500 cursor-not-allowed"
          } py-2 px-5 text-white hover:text-white rounded`}
        >
          Refund
        </button>
      ) : (
        <h1 className="font-medium m-0 bold text-gray-500 cursor-not-allowed">NO ACTION</h1>
      )}
    </div>
  );
};

const Single: React.FC = () => {
  const loc = useLocation();
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [addEmployeeID, setAddEmployeeID] = useState<string>("");
  const auth = useAppSelector((state) => state.auth);
  const isLoadingUser = useAppSelector((state) => state.dailyappUser.isLoading);
  const isLoadingTrx = useAppSelector((state) => state.dailyTransaction.isLoading);
  const isLoadingCoin = useAppSelector((state) => state.dailyCoin.isLoading);
  const isForbidden = useRole(auth.employeeData.roles, "super-dailycoin");
  const isPermissionCEXP = useRole(auth.employeeData.roles, "cexp");
  const [trxUser, setTrxUser] = useState([]);
  const user = useAppSelector((state) => state.dailyappUser.user);
  const { detailCustomer, coinCustomer } = useAppSelector((state) => state.CEcustomer);
  const { transactions, activities, userCoin } = useAppSelector((state) => {
    const transactions = state.dailyTransaction.transactions.map((x: any) => ({
      ...x,
      key: x._id
    }));

    const activities = state.dailyappUser.activities;

    const userCoin = state.dailyCoin.userCoin;
    return {
      // user,
      transactions,
      activities,
      userCoin
    };
  });

  const [showModal, setShowModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const idRefund = useRef("");
  const [openRefund, setOpenRefund] = useState<boolean>(false);
  const [cutCoin, setCutCoin] = useState<boolean>(false);
  const [keyword, setKeyword] = useState<string>("");

  useEffect(() => {
    if (id) {
      dispatch(getUserRequest({ id: id }));
      dispatch(getTransactionByUserIdRequest({ id: id }));
      dispatch(getUserActivityRequest({ id: id }));
      dispatch(getCoinCustomerReq(id));
    }
  }, [id]);

  useEffect(() => {
    setTrxUser(filterByKeyword(transactions, keyword));
  }, [keyword]);

  useEffect(() => {
    setTrxUser(filterByKeyword(transactions, keyword));
  }, [isLoadingTrx]);

  const handleOKModal = async () => {
    setIsLoading(true);
    const res = await putEmployeeID(id, addEmployeeID);
    if (res.code === 1000) {
      setIsLoading(false);
      swal("Success", "You update Employee ID!", "success");
    } else if (res.code !== 1000) {
      setIsLoading(false);
      swal("Ops!", `${res.code} : ${res.message}`, "error");
    }
    setAddEmployeeID("");
  };

  const handleResetPassword = (id: any) => {
    if (id) {
      swal({
        title: "Are you sure?",
        text: "Want to reset Employee Account",
        icon: "warning",
        buttons: ["Cancel", true],
        dangerMode: true
      }).then((willDelete) => {
        if (willDelete) {
          dispatch(postResetEmployeeReq(id));
          setTimeout(() => {
            dispatch(getUserRequest({ id: id }));
            dispatch(getUserActivityRequest({ id: id }));
          }, 500);
        } else {
          swal("Ops!", `Something went wrong`, "error");
        }
      });
    }
  };

  if (isLoadingUser) {
    return <LoadingComponent />;
  }

  return (
    <div className="flex flex-col h-auto">
      <Detail
        data={flattenObject(user)}
        title={user?.fullName}
        extra={
          <>
            {isForbidden ? (
              <div className="flex gap-5">
                <button
                  className="bg-blue-500 hover:bg-blue-400 p-2 rounded-md font-medium text-white hover:text-white"
                  onClick={() => setShowModal(true)}
                >
                  Add Employee ID
                </button>

                <button
                  className="bg-yellow-500 hover:bg-yellow-400 px-9 py-2 rounded-md font-medium text-white hover:text-white"
                  onClick={() => setOpenRefund(true)}
                >
                  Refund
                </button>

                <button
                  onClick={() => handleResetPassword(id)}
                  className="bg-red-500 text-center py-1 text-lg px-7 text-white rounded-md"
                >
                  Reset Employee
                </button>
              </div>
            ) : isPermissionCEXP ? (
              <button
                onClick={() => setCutCoin(!cutCoin)}
                className="bg-red-500 text-center py-1 text-lg px-7 text-white rounded-md"
              >
                Potong Coin
              </button>
            ) : undefined}
          </>
        }
      />

      <div className="mt-3">
        <Row gutter={16}>
          <ModalRefund
            handleClose={() => setOpenRefund(false)}
            idTrx={idRefund.current}
            openModal={openRefund}
            setOpenModal={setOpenRefund}
          />
          <ModalCutCoin id={id} openModal={cutCoin} setOpenModal={setCutCoin} />

          <ReactModal
            handleOkModal={handleOKModal}
            handleCancel={() => setShowModal(false)}
            title="Add Employee"
            isModalVisible={showModal}
            text={null}
          >
            <div>
              <Input
                title="ID Employe"
                type={"text "}
                value={addEmployeeID}
                placeholder={"EmployeID"}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setAddEmployeeID(e.target.value)
                }
                name={"id-employee"}
              />
            </div>
          </ReactModal>
          {isLoadingUser && isLoadingTrx && isLoadingCoin ? (
            <>
              <LoadingComponent />

              <Col span={4}>
                <CardDetail />
              </Col>
            </>
          ) : (
            <>
              <Col span={4}>
                <Card>
                  <Statistic
                    className="text-center"
                    value={coinCustomer.presentAmount}
                    title="Total Coin"
                  />
                  <div>&nbsp;</div>
                </Card>
              </Col>
            </>
          )}
        </Row>
      </div>
      <div>
        <div className="text-lg font-bold mt-5">Transactions</div>
      </div>
      <div>
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="bg-blue-500 text-center py-1 text-lg px-7 text-white rounded-md"
        >
          Transaction
        </button>
        <Transition
          appear
          show={isOpen}
          as={Fragment}
          enter="transform transition ease-in-out duration-[400ms]"
          enterFrom="opacity-0 "
          enterTo="opacity-100 rotate-0 scale-100"
          leave="transform duration-200 transition ease-in-out"
          leaveFrom="opacity-100  "
          leaveTo="opacity-0 scale-95 "
        >
          <div className="mt-5">
            <MyTable
              dataSource={transactions}
              fileName="transaction"
              exportable
              select={[
                { label: "Date", value: "createdAt" },
                { label: "No Transaction", value: "trxNumber" },
                { label: "Description", value: "type" },
                { label: "Operator", value: "operator" },
                { label: "Coin", value: "point" }
              ]}
              action={Action}
            />
          </div>
        </Transition>
      </div>
    </div>
  );
};

export default Single;
