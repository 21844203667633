import { getCustomerReq, getEventRequest } from "@stores/CE/CEXPSlice";
import { useAppDispatch, useAppSelector } from "@stores/store";

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import InputSearch from "@components/inputSearch";
import Loading from "@components/loading";

import moment from "moment";
import { filterByKeyword } from "@helpers/utils";
import MyTable from "@components/Table/MyTable";

const Action = {
  name: "Action",
  selector: (row: any) => row._id,

  cell: (row: any) => (
    <div className="flex gap-3 items-center justify-center">
      <Link className="hover:text-white rounded py-2 px-5 bg-blue-500 text-white" to={row._id}>
        Detail
      </Link>
    </div>
  )
};

const Event = () => {
  const dispatch = useAppDispatch();

  const dataEvent = useAppSelector((state) => state.CEcustomer.dataEvent);
  const [keyword, setKeyword] = useState("");
  const [okEvent, setOkEvent] = useState<any[] | []>([]);

  const { isLoading } = useAppSelector((state) => state.CEcustomer);

  useEffect(() => {
    dispatch(getEventRequest());
    setOkEvent(dataEvent);
  }, []);

  useEffect(() => {
    setOkEvent(filterByKeyword(dataEvent, keyword));
  }, [isLoading]);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex flex-col">
          <div className="mt-4">
            <MyTable
              searchable
              fileName="dailyclick-event"
              action={(row: any) => (
                <Link
                  className="hover:text-white rounded py-2 px-5 bg-blue-500 text-white"
                  to={row._id}
                >
                  Detail
                </Link>
              )}
              exportable
              dataSource={dataEvent}
              select={[
                "name",
                "image",
                "value",
                "coinClick",
                "startTime",
                "coinDeal",
                "totalClick"
              ]}
              // action={Action}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Event;
