import { dateToString } from "@helpers/utils";
import { getDetailOjolRedemRequest } from "@stores/dailyojol/RedemSlice";
import { useAppDispatch, useAppSelector } from "@stores/store";
import { Descriptions, Tag } from "antd";
import React from "react";
import { useParams } from "react-router-dom";

const SingleRegion = () => {
  const dispatch = useAppDispatch();
  const { RegionDetail } = useAppSelector((state) => state.dailyojolRedem);
  const { id } = useParams();

  React.useEffect(() => {
    if (id) {
      dispatch(getDetailOjolRedemRequest(id));
    }
  }, [id]);

  return (
    <>
      <Descriptions bordered title={RegionDetail.middleman?.username} size="small">
        <Descriptions.Item label="ID">{RegionDetail._id}</Descriptions.Item>
        <Descriptions.Item label="Date">{dateToString(RegionDetail.createdAt)}</Descriptions.Item>
        <Descriptions.Item label="Phone">
          <a target="_blank" rel="noreferrer" href={`https://wa.me/`}>
            {RegionDetail.middleman?.phoneNumber}
          </a>
        </Descriptions.Item>
        <Descriptions.Item label="Status">
          {RegionDetail.redeemStatus === "SUCCESS" ? (
            <Tag color="green">{RegionDetail.redeemStatus}</Tag>
          ) : (
            <Tag color="red">{RegionDetail.redeemStatus}</Tag>
          )}
        </Descriptions.Item>

        <Descriptions.Item label="Redem Code" className="font-bold">
          {RegionDetail.redeemNumber}
        </Descriptions.Item>
        <Descriptions.Item label="Outlet">{RegionDetail.outlet?.name}</Descriptions.Item>
        <Descriptions.Item label="Menu">{RegionDetail.menu?.name}</Descriptions.Item>
      </Descriptions>
    </>
  );
};

export default SingleRegion;
