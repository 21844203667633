import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  AuthPayload,
  AuthStoreState,
  EmployeeData,
  Overwrite,
  PayloadResponse,
  RefreshToken,
  Token
} from "@typed/global";
import { authRequestAPI } from "./AuthAPI";
import { updateToken } from "./TokenSlice";

type Payload = Overwrite<
  PayloadResponse,
  {
    data: {
      employeeData: EmployeeData;
      token: Token;
      refreshToken: RefreshToken;
    };
  }
>;

export const authRequest = createAsyncThunk(
  "auth/authRequest",
  async (payload: AuthPayload, thunkAPI) => {
    const response: Payload = await authRequestAPI(payload);
    if (response.code === 1000) {
      thunkAPI.dispatch(updateToken(response.data));
    }
    return response;
  }
);

const initialState: AuthStoreState = {
  employeeData: {},
  isLoggedIn: false,
  loginErrorMessage: "",
  isPageLoading: false
};

export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    logout: () => {
      return {
        employeeData: {},
        isLoggedIn: false,
        loginErrorMessage: "",
        isPageLoading: false
      };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(authRequest.pending, (state) => {
      state.isPageLoading = true;
    });
    builder.addCase(authRequest.fulfilled, (state, action) => {
      if (action.payload.code === 1000) {
        state.employeeData = action.payload.data.employeeData;
        state.isLoggedIn = true;
      } else {
        state.loginErrorMessage = "";
      }
      state.isPageLoading = false;
    });
    builder.addCase(authRequest.rejected, (state) => {
      state.isPageLoading = false;
    });
  }
});

// Action creators are generated for each case reducer function
export const { logout } = authSlice.actions;

export default authSlice.reducer;
