import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Table } from "antd";
import { useAppDispatch, useAppSelector } from "@stores/store";
import { getPersonRequest } from "@stores/HRIS/Person/PersonSlice";
import { dateToString } from "@helpers/utils";
import moment from "moment";

const column = [
  {
    title: "Date of Birth",
    dataIndex: "birthDate",
    key: "date",
    render: (Dates: string) => <div>{dateToString(Dates)}</div>
  },
  {
    title: "First Name",
    dataIndex: "firstName",
    key: "firstName"
  },
  {
    title: "Last Name",
    dataIndex: "lastName",
    key: "lastName",
    render: (lastName: string) => (
      <div>{lastName ? lastName : <p className="text-red-500">NULL</p>}</div>
    )
  },
  {
    title: "Age",
    dataIndex: "birthDate",
    key: "age",
    render: (Age: string) => {
      return <div>{moment().diff(Age, "years")}</div>;
    }
  },
  {
    title: "Gender",
    dataIndex: "gender",
    key: "gender"
  },
  {
    title: "Action",
    dataIndex: "_id",
    key: "action",
    render: (id: string) => {
      return (
        <div className="flex items-center gap-3">
          <Link
            className="bg-blue-500 text-white p-2 px-4 rounded hover:text-white hover:bg-blue-600"
            to={`${id}`}
          >
            Detail
          </Link>
          <Link
            className="bg-slate-500 text-white hover:text-white p-2 px-4 rounded hover:bg-slate-600"
            to={`edit/${id}`}
          >
            Edit
          </Link>
        </div>
      );
    }
  }
];

const Person = () => {
  const dispatch = useAppDispatch();
  const Persons = useAppSelector((state) =>
    state.hrisPerson.dataPersons?.map((x) => ({ ...x, key: x._id }))
  );
  useEffect(() => {
    dispatch(getPersonRequest());
  }, []);
  return (
    <>
      <div>
        <Link
          to="/hris/person/add"
          className="py-2 w-full px-2 rounded hover:bg-green-600 hover:text-white bg-green-500 text-white font-semibold"
        >
          Create
        </Link>

        <div className="mt-4">
          <Table columns={column} dataSource={Persons} />
        </div>
      </div>
    </>
  );
};

export default Person;
