import React, { FC, useEffect, useState } from "react";
import { useAppSelector } from "@stores/store";
import { IDetailRedem } from "@typed/dailyapp/Redem";
import { useParams } from "react-router-dom";
import { Descriptions } from "antd";
import { dateToString } from "@helpers/utils";

const SingleRedem: FC = () => {
  const [dataRedem, setDataRedem] = useState<IDetailRedem[]>([]);
  const { id } = useParams();
  const { data } = useAppSelector((state) => state.dailyRedem);

  useEffect(() => {
    const Data = data.filter((item) => item._id === id); // filter data redem for detail
    setDataRedem(Data);
  }, [id]);

  return (
    <div>
      <div>
        {dataRedem.map((item: IDetailRedem, idx: number) => (
          <div key={idx}>
            <div className="w-full">
              <img className="mx-auto w-[25rem] h-[25rem] rounded" src={item.menu?.image} />
            </div>

            {/* info detail start */}
            <Descriptions bordered title={item.address?.label} size="small">
              <Descriptions.Item label="Date">
                <div className="font-semibold">{dateToString(item.createdAt)}</div>
              </Descriptions.Item>
              <Descriptions.Item label="Redem Number">
                <div className="font-semibold">{item.redeemNumber}</div>
              </Descriptions.Item>
              <Descriptions.Item label="Redem Status">
                <div className="font-semibold">{item.redeemStatus}</div>
              </Descriptions.Item>
              <Descriptions.Item label="Transaction">
                <div className="font-semibold">{item.transaction}</div>
              </Descriptions.Item>
              <Descriptions.Item label="Address">
                <div className="font-semibold">{item.address?.address}</div>
              </Descriptions.Item>
              <Descriptions.Item label="Post Code">
                <div className="font-semibold">{item.address?.postCode}</div>
              </Descriptions.Item>
              <Descriptions.Item label="City">
                <div className="font-semibold">{item.address?.city}</div>
              </Descriptions.Item>
              <Descriptions.Item label="Province">
                <div className="font-semibold">{item.address?.province}</div>
              </Descriptions.Item>
              <Descriptions.Item label="SubDistrict">
                <div className="font-semibold">{item.address?.subDistrict}</div>
              </Descriptions.Item>

              <Descriptions.Item label="Coordinate">
                {item.address?.location.coordinates.map((item, idx) => (
                  <div key={idx} className="font-semibold flex flex-col ">
                    {item}
                  </div>
                ))}
              </Descriptions.Item>
              <Descriptions.Item label="Menu Name">
                <div className="font-semibold">{item.menu?.name}</div>
              </Descriptions.Item>
              <Descriptions.Item label="Menu Price">
                <div className="font-semibold">{item.menu?.price}</div>
              </Descriptions.Item>
            </Descriptions>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SingleRedem;
