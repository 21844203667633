import React, { useRef, useEffect } from "react";

import { useAppDispatch, useAppSelector } from "@stores/store";
import moment from "moment";

import { DatePicker, DatePickerProps, Table, Tabs } from "antd";
import ExportCSV from "@components/ExportCSV";
import { CSVCashbackRegister, getCashbackRegisRequest } from "@stores/dailycoin/ReportingSlice";
import { nf } from "@helpers/utils";
import DataTable from "react-data-table-component";

const columns = [
  {
    name: "Date",
    selector: (row: any) => row._id,
    id: "date"
  },
  {
    name: "Transaction",
    selector: (row: any) => row.transaction,
    id: "transaction",
    cell: (row: any) => <div>{nf.format(row.transaction)}</div>
  },
  {
    name: "Cashback",
    selector: (row: any) => row.cashback,
    id: "cashback",
    cell: (row: any) => <div>{nf.format(row.cashback)}</div>
  }
];

const CashbackRegister = () => {
  const dispatch = useAppDispatch();
  const { isLoading, dataCashback, CashbackCSV } = useAppSelector((state) => state.dailyReport);

  const startDate = useRef<any>(moment().startOf("month").format("YYYY-MM-DD"));
  const endDate = useRef<any>(moment().endOf("month"));

  useEffect(() => {
    dispatch(CSVCashbackRegister());
  }, [isLoading]);

  useEffect(() => {
    dispatch(
      getCashbackRegisRequest({
        startDate: moment(startDate.current).format("YYYY-MM-DD"),
        endDate: moment(endDate.current).format("YYYY-MM-DD")
      })
    );
  }, []);

  return (
    <>
      {/* <Table loading={isLoading} dataSource={dataCashback} columns={columns} /> */}
      <DataTable data={dataCashback} columns={columns} pagination persistTableHead />
    </>
  );
};

export default CashbackRegister;
