import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { Overwrite, PayloadResponse } from "@typed/global";
import { Product, ProductStoreState } from "@typed/dailyapp/product";
import {
  createProductApi,
  delProductApi,
  getProductApi,
  getProductsApi,
  putProductActiveApi,
  putProductApi
} from "./ProductAPI";
import toast from "react-hot-toast";
import { IAddProduct } from "@typed/dailojol/Product";

type Payload = Overwrite<
  PayloadResponse,
  {
    data: [Product];
  }
>;

export const getProductsRequest = createAsyncThunk(
  "dailyapp-products/getProductsRequest",
  async () => {
    const response: Payload = await getProductsApi();
    return response;
  }
);

type ResponseSingleProduct = Overwrite<
  PayloadResponse,
  {
    data: Product;
  }
>;

export const getProductRequest = createAsyncThunk(
  "dailyapp-products/getProductRequest",
  async (payload: { id: string }) => {
    const response: ResponseSingleProduct = await getProductApi(payload);
    return response;
  }
);

export const createProductRequest = createAsyncThunk(
  "dailyapp-products/createProductRequest",
  async ({ name, image, dailyPrice, flagDailyAppIsActive }: IAddProduct) => {
    const response = await createProductApi({ name, image, dailyPrice, flagDailyAppIsActive });
    return response;
  }
);

export const putProductRequest = createAsyncThunk(
  "dailyapp-products/putProductRequest",
  async ({ id, Data }: { id: string; Data: IAddProduct }) => {
    const response = await putProductApi({ id, Data });

    return response;
  }
);

export const delProductRequest = createAsyncThunk(
  "dailyapp-product/delProductRequest",
  async (id: string) => {
    const response = await delProductApi(id);
    return response;
  }
);

interface IPropsActive {
  Id: string;
  flagDailyAppIsActive?: boolean;
}
export const putProductActiveRequest = createAsyncThunk(
  "dailyapp-product/putProductActiveRequest",
  async ({ Id, flagDailyAppIsActive }: IPropsActive) => {
    const response = await putProductActiveApi(Id, flagDailyAppIsActive);

    return response;
  }
);

const initialState: ProductStoreState = {
  products: [],
  errorMessage: "",
  isLoading: false,
  lastCreatedProducts: {} as Product,
  singleProduct: {} as Product
};

interface IActive {
  flagDailyAppIsActive: boolean;
  Id: string;
}

export const productSlice = createSlice({
  name: "dailyapp-products",
  initialState: initialState,
  reducers: {
    resetLastProduct: (state: { lastCreatedProducts: Product }) => {
      state.lastCreatedProducts = {} as Product;
    },
    activatedProduct: (state: ProductStoreState, action: PayloadAction<any>) => {
      // console.log(action);
      state.products = state.products.map((item: Product) =>
        action.payload.Id === item._id
          ? {
              ...item,
              flagDailyAppIsActive: action.payload.flagDailyAppIsActive === false ? true : false
            }
          : item
      );
    }
    // deletedProduct: (state: any, action: PayloadAction<IActiondel>) => {
    //   state.products = state.products.filter(
    //     (item: { _id: string | undefined }) => item._id !== action.payload.id
    //   );
    // }
  },
  extraReducers: (builder) => {
    builder.addCase(getProductRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getProductRequest.fulfilled, (state, action) => {
      if (action.payload.code === 1000) {
        state.singleProduct = action.payload.data;
      } else {
        state.errorMessage = "";
      }
      state.isLoading = false;
    });
    builder.addCase(getProductRequest.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getProductsRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getProductsRequest.fulfilled, (state, action) => {
      if (action.payload.code === 1000) {
        state.products = action.payload.data;
      } else {
        state.errorMessage = "";
      }
      state.isLoading = false;
    });
    builder.addCase(getProductsRequest.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(createProductRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createProductRequest.fulfilled, (state, action) => {
      // console.log(action);
      if (action.payload.code === 1000) {
        state.lastCreatedProducts = action.payload.data;
        toast.success("Success");
      } else {
        state.errorMessage = "";
      }
      state.isLoading = false;
    });
    builder.addCase(createProductRequest.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(putProductRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(putProductRequest.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.code === 1000) {
        toast.success("Success Update Product");
        state.lastCreatedProducts = action.payload.data;
      } else {
        state.errorMessage = "";
      }
    });
    builder.addCase(putProductRequest.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(delProductRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(delProductRequest.fulfilled, (state, action) => {
      if (action.payload.code === 1000) {
        state.products = state.products.filter(
          (item: { _id: string }) => item._id !== action.meta.arg
        );
      }
      state.isLoading = false;
    });
    builder.addCase(delProductRequest.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(putProductActiveRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(putProductActiveRequest.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.code === 1000) {
        // state.products = state.products.map((item) => ...i)
      }
    });
    builder.addCase(putProductActiveRequest.rejected, (state) => {
      state.isLoading = false;
    });
  }
});

// Action creators are generated for each case reducer function
export const { resetLastProduct, activatedProduct } = productSlice.actions;

export default productSlice.reducer;
