import React from "react";

interface IProps {
  type?: React.HTMLInputTypeAttribute;
  title: string;
  required?: string;
  className?: string;
  placeholder: string;
  value: string | number | readonly string[];
  onChange: {
    (e: React.ChangeEvent<any>): void;
    <T = string | React.ChangeEvent<any>>(field: T): T extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  name: string;
  autoComplete?: string;
}

const Input: React.FC<IProps> = ({
  type,
  title,
  required,
  className,
  placeholder,
  value,
  onChange,
  name,
  autoComplete
}) => {
  return (
    <div
      className={`${className} border-[1px]  hover:shadow-4xl focus-within:shadow-4xl focus-within:border-[#1894ed] bg-Inputcol border-abuAbu rounded-md p-2`}
    >
      <h1 className={`${required} text-[#6A7380] `}>{title}</h1>
      <div>
        <input
          name={`${name}`}
          type={`${type}`}
          autoComplete={autoComplete}
          value={value}
          onChange={onChange}
          className="bg-transparent h-5 w-full resize-none  border-none outline-none"
          placeholder={placeholder}
        />
      </div>
    </div>
  );
};

export default Input;
