import React from "react";
import { Descriptions, Button, Tabs } from "antd";
import { useAppDispatch, useAppSelector } from "@stores/store";
import swal from "sweetalert";

import { useNavigate, useParams } from "react-router-dom";
import { IDataNameCard } from "@typed/HRIS/Namecard";
import { dateToString } from "@helpers/utils";
// import ConfirmAlert from "@components/AlertConfirm";
import { deleteNameCardRequest } from "@stores/HRIS/Name-card/NameCardSlice";

const SingleNameCard = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const singleData = useAppSelector((state) =>
    state.hrisNameCard.data.find((i: IDataNameCard) => i._id === id)
  );
  const navigate = useNavigate();

  const handleDelete = () => {
    id &&
      swal({
        title: "Are you sure?",
        text: "Want to delete this!",
        icon: "warning",
        buttons: ["Cancel", true],
        dangerMode: true
      }).then((willDelete) => {
        if (willDelete) {
          dispatch(deleteNameCardRequest(id));
          swal("Poof! this  has been deleted!", {
            icon: "success"
          });
          navigate("/hris/name-card");
          console.log(willDelete, "wildelete");
        } else {
          swal("Your imaginary file is safe!");
        }
      });
  };

  return (
    <div>
      <Descriptions
        bordered
        title={singleData?.firstname.toUpperCase()}
        size="small"
        className=""
        extra={
          <div className="flex items-center gap-5">
            <Button type="primary" onClick={() => navigate(`/hris/name-card/edit/${id}`)}>
              Edit
            </Button>
            <Button type="primary" danger onClick={handleDelete}>
              Delete
            </Button>
          </div>
        }
      >
        <Descriptions.Item label="Date" span={2}>
          {dateToString(singleData?.createdAt)}
        </Descriptions.Item>
        <Descriptions.Item label="Address" span={2}>
          {singleData?.address === null ? (
            <p className="text-red-500 p-0 m-0 font-semibold">NULL</p>
          ) : (
            singleData?.address
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Phone" span={2}>
          <a href={`https://wa.me/${singleData?.phone}`} target="_blank" rel="noreferrer">
            {singleData?.phone}
          </a>
        </Descriptions.Item>
        <Descriptions.Item label="Email" span={2}>
          {singleData?.email}
        </Descriptions.Item>
        <Descriptions.Item label="Company" span={2}>
          {singleData?.lastname}
        </Descriptions.Item>
      </Descriptions>

      <div>
        <Tabs>
          <Tabs.TabPane tab="Social Media" key="item-1">
            <div className="flex  flex-col gap-2">
              {singleData.socialMedia.map((item: any, idx: number) => (
                <div key={idx} className="flex items-center gap-4">
                  <p className="p-0 m-0 capitalize text-right">{item.name} :</p>
                  <h1 className="p-0 m-0">{item.sosmedID}</h1>
                </div>
              ))}
            </div>
          </Tabs.TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default SingleNameCard;
