import { dateToString } from "@helpers/utils";
import { useAppSelector } from "@stores/store";
import { Button, Descriptions } from "antd";
import React from "react";
import ReactMarkdown from "react-markdown";
import { useNavigate, useParams } from "react-router-dom";
import rehypeRaw from "rehype-raw";
import state from "sweetalert/typings/modules/state";

const SingleBannerProfile = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const detailBanner = useAppSelector((state) =>
    state.dailyBanner.data.find((item) => (item._id === id ? item : undefined))
  );

  return (
    <>
      <Descriptions
        bordered
        title={detailBanner?.title}
        size="small"
        extra={
          <Button type="primary" onClick={() => navigate(`/dailyapp/banner-profile/edit/${id}`)}>
            Edit
          </Button>
        }
      >
        <Descriptions.Item label="Date" span={2}>
          {dateToString(detailBanner?.createdAt)}
        </Descriptions.Item>
        <Descriptions.Item label="Description" span={2}>
          {detailBanner?.description}
        </Descriptions.Item>
        <Descriptions.Item label="Content" span={2}>
          <ReactMarkdown rehypePlugins={[rehypeRaw]}>{detailBanner?.content ?? ""}</ReactMarkdown>
        </Descriptions.Item>
      </Descriptions>

      <div className="mt-4">
        <img src={detailBanner?.image.mediaUrl} className="w-36 h-36 rounded object-cover" />
      </div>
    </>
  );
};

export default SingleBannerProfile;
