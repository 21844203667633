import React, { useEffect, useState, useRef } from "react";
import { useAppDispatch, useAppSelector } from "@stores/store";
import { getOutletsRequest } from "@stores/dailyapp/dailyOutletSlice";
import Loading from "@components/loading";
import { Table } from "antd";
import { Link } from "react-router-dom";
import InputSearch from "@components/inputSearch";
import Item from "antd/lib/list/Item";
import ExportCSV from "@components/ExportCSV";
import AddOutlet from "./AddOutlet";

const column = [
  {
    title: "ID",
    dataIndex: "_id",
    key: "_id"
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (name: string) => <div className="font-medium min-w-[18rem] max-w-[18rem]">{name}</div>
  },
  {
    title: "Address",
    dataIndex: "address",
    key: "address"
  },
  {
    title: "Action",
    dataIndex: "_id",
    key: "_id",
    render: (id: string) => (
      <div className="flex items-center gap-3">
        <Link
          className="bg-blue-600 hover:text-white text-white p-2 px-4 rounded hover:bg-blue-500"
          to={`/dailyapp/outlet/${id}`}
        >
          Detail
        </Link>
      </div>
    )
  }
];

const Outlets = () => {
  const dispatch = useAppDispatch();
  const Outlet = useAppSelector((state) =>
    state.dailyappOutlet.AppOutlet.map((item) => ({ ...item, key: item._id }))
  );
  const { isLoading } = useAppSelector((state) => state.dailyappOutlet);

  const [searchByName, setSearchByName] = useState("");
  const [okOutlet, setOkOutlet] = useState(Outlet);
  const outletCSV = useRef<any[] | []>([]);

  useEffect(() => {
    // search product by name
    let Data = Outlet.filter((item) => {
      return item.name?.toLowerCase().includes(searchByName.toLowerCase());
    });
    if (searchByName !== "") {
      setOkOutlet(Data);
    }
    if (searchByName === "") {
      setOkOutlet(Outlet);
    }
  }, [searchByName, isLoading]);

  useEffect(() => {
    dispatch(getOutletsRequest());
    setOkOutlet(Outlet);
    const dataXLS = Outlet.map((item) => ({
      ID: item._id,
      Name: item.name,
      Code: item.code,
      Used: `${item.usedAt?.[0]},${item.usedAt?.[1]}`,
      Brands: `${item.brands?.[0]}, ${item.brands?.[1]}`,
      Region: item.region,
      Address: item.address,
      "Lat/Long": `${item.lat}, ${item.long}`
    }));
    outletCSV.current = dataXLS;
  }, [dispatch]);

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="flex justify-between items-center">
            <div className=" flex justify-start items-center gap-4">
              <div>
                <InputSearch
                  value={searchByName}
                  handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setSearchByName(e.target.value);
                  }}
                  placeholder="Search by name of product"
                />
              </div>

              <div className="mb-2">
                <Link
                  to="/dailyapp/outlet/add"
                  className=" py-3 text-center px-6 bg-green-500 hover:bg-green-600 hover:text-white rounded hover:shadow-lg text-white font-semibold"
                >
                  Create Outlet
                </Link>
              </div>
            </div>
            <div className="mb-1">
              <ExportCSV csvData={outletCSV.current} fileName={"outlet-name"} />
            </div>
          </div>
          <div>
            <Table
              className="border-[1px] border-abuAbu rounded-md"
              dataSource={okOutlet}
              columns={column}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Outlets;
