import React, { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@stores/store";
import Modal from "@components/Modal";
import Input from "@components/Input.components";
import Button from "@components/Button.component";
import swal from "sweetalert";
import { postInjectVoucherReq } from "@stores/dailyapp/VoucherSlice";

interface IPropsInjectVoucher {
  isOpen: boolean;
  closeModal: () => void;
}

const VoucherInject: React.FC<IPropsInjectVoucher> = ({ isOpen, closeModal }) => {
  const dispatch = useAppDispatch();
  const { isLoadingInjectVoucher } = useAppSelector((state) => state.dailyVoucher);
  const [injectVoucher, setInjectVoucher] = useState({
    phoneNumber: "",
    brandCode: ""
  });
  const { phoneNumber, brandCode } = injectVoucher;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setInjectVoucher({ ...injectVoucher, [name]: value });
  };

  const handleSubmit = () => {
    dispatch(postInjectVoucherReq(injectVoucher)).then((res) => {
      if (res.payload.code === 1000) {
        swal("Success!", "Inject voucher success", "success");
        setInjectVoucher({
          ...injectVoucher,
          brandCode: ""
        });
      } else {
        swal("Ops!", `${res.payload.code}: ${res.payload.error}`, "error");
      }
    });
  };

  return (
    <>
      <Modal isOpen={isOpen} closeModal={closeModal} title="Inject Voucher">
        <div className="bg-white h-[17rem] pt-16 flex flex-col gap-3">
          <Input
            title={"Phone"}
            placeholder={"Phone"}
            required={`after:content-['*'] after:text-red-500`}
            value={phoneNumber}
            onChange={handleChange}
            name={"phoneNumber"}
          />

          <Input
            title={"Brand code"}
            required={`after:content-['*'] after:text-red-500`}
            placeholder={"Brand code"}
            value={brandCode}
            onChange={handleChange}
            name={"brandCode"}
          />
        </div>
        <div className="flex justify-end">
          <Button onClick={handleSubmit} isLoading={isLoadingInjectVoucher}>
            Submit
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default VoucherInject;
