import { Review } from "@typed/dailyapp/review";

export const nf = new Intl.NumberFormat(); // format number to comma

export const getRandomItems = (Arr: any[]) => {
  const randomIndex = Math.floor(Math.random() * Arr.length);

  const item = Arr[randomIndex];

  return item;
};

export const filterByKeyword = (array: any, keyword: string) => {
  let newArray = array;
  const mapped = newArray.map((item: any) => {
    let flatten = flattenObject(item);
    Object.keys(flatten).forEach((key) => {
      if (flatten[key] === null) {
        flatten[key] = "n/a";
      }
    });
    return flatten;
  });
  if (keyword) {
    return mapped.filter((o: any) => {
      return Object.values(o).concat().toString().toLowerCase().includes(keyword.toLowerCase());
    });
  } else {
    return array;
  }
};

const flattenRecursive = (
  obj: object,
  parentProperty?: string,
  propertyMap: Record<string, unknown> = {}
) => {
  for (const [key, value] of Object.entries(obj || {})) {
    let Upper = key?.slice(0, 1);
    let Lower = key?.slice(1);
    const property = parentProperty ? `${parentProperty}${Upper.toUpperCase()}${Lower}` : key;
    if (value && typeof value === "object") {
      flattenRecursive(value, property, propertyMap);
    } else {
      propertyMap[property] = value;
    }
  }
  return propertyMap;
};

export const flattenObject = (obj: object) => {
  return flattenRecursive(obj);
};

export const flattenArray = (arr: any[]) => {
  let flat: any[] = [];
  arr.map((x) => flat.push(flattenObject(x)));
  return flat;
};

export const status = [
  {
    label: "Single",
    value: "single"
  },
  {
    label: "Married",
    value: "married"
  }
];

export const dateToString = (d: string | undefined) => {
  const date = new Date(d ? d : "");
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];

  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  return `${day} ${monthNames[monthIndex]} ${year}`;
};

export const ratingAverage = (reviews: Array<Review>) => {
  const rating = reviews.reduce((acc, review) => {
    const rate = review.ratingLevel ? review.ratingLevel : 1;
    return acc + rate;
  }, 0);
  return (rating / reviews.length).toFixed(1);
};

export const validateInputNum = <T extends string>(arg: T) => {
  const validNumRegex = /^[0-9\b -]+$/;
  if (validNumRegex.test(arg) || arg === "") {
    return arg;
  }
};
