import React, { useEffect, useState, useRef } from "react";
import Input from "@components/Input.components";
import { Formik, Form } from "formik";
import Textarea from "@components/Textarea";
import { DatePicker, TimePicker, Switch } from "antd";
import {
  postProductClickRequest,
  putProductClickRequest
} from "@stores/Dailyclick/DailyclickSlice";
import { useAppDispatch, useAppSelector } from "@stores/store";
import moment, { Moment } from "moment";
import { useNavigate, useParams } from "react-router-dom";
import Button from "@components/Button.component";

interface IInitialVal {
  name: string;
  price: string;
  value: string;
  description: string;
  isActive: boolean;
  startCoin: number;
  image: File | string | object;
  coinClick: number;
  dateStart: string | any;
  dateEnd: string;
  timeStart: Moment | any;
  timeEnd: Moment | any;
}

const initialValues: IInitialVal = {
  name: "",
  value: "",
  price: "",
  description: "",
  isActive: false,
  startCoin: 0,
  image: {},
  coinClick: 0,
  dateStart: "",
  dateEnd: "",
  timeStart: "",
  timeEnd: ""
};

const AddProduct = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const imageRef = useRef<any>(null);
  const { singleProductClick, isLoading } = useAppSelector((state) => state.dailyClick);
  const [loading, setLoading] = useState(false);

  async function postProductClick(values: IInitialVal) {
    const fetchProduct = await dispatch(postProductClickRequest(values));
    if (fetchProduct.payload.code === 1000) {
      navigate("/dailyclick/product");
    }

    return fetchProduct;
  }

  async function putProductClick(id: string, values: IInitialVal) {
    const fetchProduct = await dispatch(putProductClickRequest({ id, data: values }));
    if (fetchProduct.payload.code === 1000) {
      navigate("/dailyclick/product");
    }

    return fetchProduct;
  }

  return (
    <>
      <div className="flex justify-center">
        <div className="p-3 shadow w-[75%] min-h-[63vh] bg-white rounded">
          <h1 className="font-bold text-[1.2rem] text-[#6A7380]">Add Product</h1>
          <Formik
            initialValues={initialValues}
            onSubmit={(values) => {
              if (id) {
                putProductClick(id, values);
              } else {
                postProductClick(values);
              }
            }}
          >
            {({ values, errors, handleChange, handleSubmit, setFieldValue }) => {
              useEffect(() => {
                if (id) {
                  setFieldValue("image", singleProductClick?.image);
                  setFieldValue("description", singleProductClick?.description);
                  setFieldValue("value", singleProductClick?.value);
                  setFieldValue("endTime", moment(singleProductClick?.endTime).format("HH:mm"));
                  setFieldValue("startTime", moment(singleProductClick?.startTime).format("HH:mm"));
                  setFieldValue("name", singleProductClick?.name);
                  setFieldValue("dateStart", singleProductClick.startTime);
                  setFieldValue("dateEnd", singleProductClick?.endTime);
                  setFieldValue("price", singleProductClick?.price);
                  setFieldValue("coinClick", singleProductClick?.coinClick);

                  // setFieldValue("dateEnd", singleProductClick?.dateEnd);
                  imageRef.current = singleProductClick?.image;
                }
              }, [id]);

              const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
                let file = e.target.files;
                if (file) {
                  setFieldValue("image", file[0]);
                  imageRef.current = URL.createObjectURL(file[0]);
                }
              };
              return (
                <Form>
                  <div className="flex  gap-3">
                    <div className="w-[50%] flex flex-col gap-4">
                      <Input
                        required="after:content-['*'] after:text-red-500"
                        placeholder="Name of Product"
                        title="Name of Product"
                        value={values.name}
                        onChange={handleChange}
                        name="name"
                      />

                      <Input
                        required="after:content-['*'] after:text-red-500"
                        placeholder="Value"
                        title="Value"
                        value={values.value}
                        onChange={handleChange}
                        name="value"
                      />

                      <div className=" flex items-center gap-4">
                        <div className="w-[70%]">
                          <Input
                            required="after:content-['*'] after:text-red-500"
                            placeholder="Start Coin"
                            title="Start Coin"
                            type="number"
                            value={values.startCoin}
                            onChange={handleChange}
                            name="startCoin"
                          />
                        </div>

                        <div>
                          <h1 className="font-semibold p-0 m-0 text-[#6A7380] after:text-red-500 after:content-['*']">
                            Active product
                          </h1>
                          <div className="pt-2 flex gap-2 items-center align-center">
                            <Switch
                              onChange={(checked: boolean) => {
                                setFieldValue("isActive", checked);
                              }}
                            />
                            <h1 className="m-0 font-medium text-blue-500">
                              {values.isActive ? "On" : "Off"}
                            </h1>
                          </div>
                        </div>
                      </div>

                      {!id ? (
                        <div className="flex gap-4">
                          <div className="">
                            <h1 className="text-[#6A7380] m-0 after:content-['*'] after:text-red-500">
                              Date Start
                            </h1>
                            <DatePicker
                              className="h-9"
                              name="dateStart"
                              onChange={(date: any, dateString: any) => {
                                setFieldValue("dateStart", dateString);
                              }}
                              placeholder="Start Date"
                            />
                          </div>

                          <div>
                            <h1 className="text-[#6A7380] m-0 after:content-['*'] after:text-red-500">
                              Date End
                            </h1>
                            <DatePicker
                              className="h-9"
                              name="dateEnd"
                              onChange={(date: any, dateString: any) => {
                                setFieldValue("dateEnd", dateString);
                              }}
                              placeholder="End Date"
                            />
                          </div>
                        </div>
                      ) : undefined}

                      {!id ? (
                        <div className="flex items-center gap-4">
                          <div className="w-[8.5rem]">
                            <h1 className="text-[#6A7380] m-0 after:content-['*'] after:text-red-500">
                              Time Start
                            </h1>
                            <TimePicker
                              name="timeStart"
                              className="w-[8.5rem]"
                              size="large"
                              defaultValue={id ? values.timeStart : null}
                              format={"HH:mm"}
                              onChange={(time: any, timeString: string) => {
                                setFieldValue("timeStart", timeString);
                              }}
                            />
                          </div>

                          <div className="w-[8.5rem]">
                            <h1 className="text-[#6A7380]  m-0 after:content-['*'] after:text-red-500">
                              Time End
                            </h1>
                            <TimePicker
                              name="timeEnd"
                              className="w-[8.5rem]"
                              // value={id ? null : values.timeEnd}
                              defaultValue={id ? values.timeEnd : null}
                              format={"HH:mm"}
                              size="large"
                              onChange={(time: any, timeString: string) => {
                                setFieldValue("timeEnd", timeString);
                              }}
                            />
                          </div>
                        </div>
                      ) : undefined}
                    </div>

                    <div className="w-[50%] flex flex-col gap-4">
                      <Textarea
                        title="Description"
                        placeholder="Description"
                        value={values.description}
                        style="h-[6.3rem]"
                        required="after:content-['*'] after:text-red-500"
                        onChange={handleChange}
                        name="description"
                      />

                      <Input
                        required="after:content-['*'] after:text-red-500"
                        placeholder="Price"
                        title="Price"
                        type="string"
                        value={values.price}
                        onChange={handleChange}
                        name="price"
                      />

                      <Input
                        required="after:content-['*'] after:text-red-500"
                        placeholder="Coin Click"
                        title="Coin Click"
                        value={values.coinClick}
                        onChange={handleChange}
                        name="coinClick"
                      />

                      <div>
                        <h1 className="font-semibold p-0 m-0 text-[#6A7380] after:text-red-500 after:content-['*']">
                          Image
                        </h1>
                        <div className="flex  items-center">
                          {imageRef.current === null ? (
                            <label className="w-[50%] h-[4.1rem]  dark:text-white flex flex-col justify-center  bg-white text-blue rounded-lg shadow tracking-wide  border border-blue cursor-pointer hover:bg-blue-400 hover:text-white">
                              <svg
                                className="h-6 w-6 mx-auto"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                              >
                                <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                              </svg>
                              <p className="text-left  m-auto">Select a file</p>
                              <input
                                name="image"
                                className="hidden"
                                onChange={handleUpload}
                                type="file"
                              />
                            </label>
                          ) : (
                            <div className="flex items-center gap-2">
                              <img src={imageRef.current} className=" w-40 h-40 rounded" />
                              <div>
                                <label className="w-auto flex flex-col justify-center  bg-white text-blue rounded shadow tracking-wide  border border-blue cursor-pointer   p-2">
                                  Change
                                  <input className="hidden" onChange={handleUpload} type="file" />
                                </label>
                              </div>
                              <div>
                                <button
                                  onClick={() => {
                                    imageRef.current = null;
                                    setFieldValue("image", null);
                                  }}
                                  className="border-none outline-none focus:outline-none focus:border-none text-red-500"
                                >
                                  Remove
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-end">
                    <Button isLoading={isLoading}>
                      <h1 className="m-0 font-medium text-white">Submit</h1>
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default AddProduct;
