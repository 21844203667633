import Loading from "@components/loading";
import { useAppDispatch, useAppSelector } from "@stores/store";
import Textarea from "@components/Textarea";
import Input from "@components/Input.components";
import { Formik, Form } from "formik";
import React, { useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { postBannerBrandRequest, putBannerBrandRequest } from "@stores/dailyapp/BannerSlice";
import swal from "sweetalert";
import SelectComponents from "@components/Select";
import Button from "@components/Button.component";
import Modal from "@components/Modal";

interface IValues {
  title: string;
  description: string;
  content: string;
  image: null | any;
  type: string;
}

const options = [
  { label: "Dailybox", value: "dailybox" },
  { label: "Shirato", value: "shirato" },
  { label: "Antarasa", value: "antarasa" },
  { label: "Breadlife", value: "breadlife" },
  { label: "Tiantea", value: "tiantea" },
  { label: "Lumiere", value: "lumiere" },
  { label: "Ayam Amanah", value: "ayam-amanah" }
];

interface IBrandProps {
  title: string;

  // uri: string;
}

const AddBannerBrand: React.FC<IBrandProps> = ({ title }) => {
  const dispatch = useAppDispatch();
  const { data, isLoading } = useAppSelector((state) => state.dailyBanner);
  const imageRef = useRef<any>(null);
  const navigate = useNavigate();
  const { id } = useParams();

  return (
    <>
      <>
        <div className="flex items-center justify-center">
          <Formik
            initialValues={{} as IValues}
            onSubmit={(values, { resetForm, setStatus }) => {
              const { title, image, content, description, type } = values;
              const formData = new FormData();
              formData.append("title", title);
              formData.append("image", image);
              formData.append("description", description);
              formData.append("content", content);

              return id
                ? dispatch(putBannerBrandRequest({ formData, id })).then(({ payload }) => {
                    if (payload.code === 1000) {
                      setStatus({ succes: true });
                      resetForm({});
                      swal({
                        title: "Succes!",
                        text: `You ${title} is update!`,
                        icon: "succes",
                        buttons: {
                          confirm: {
                            text: "OK"
                            // value: navigate(`${uri}`)
                          }
                        }
                      });
                    } else {
                      setStatus({ succes: false });
                      swal({
                        title: `Ups! : ${payload.code}`,
                        text: `Something went wrong`,
                        icon: "error"
                      });
                    }
                  })
                : dispatch(postBannerBrandRequest({ data: formData, type })).then(({ payload }) => {
                    if (payload.code === 1000) {
                      setStatus({ succes: true });
                      resetForm({});
                      swal({
                        title: "Success!",
                        text: `You ${title} is created!`,
                        icon: "success",
                        buttons: {
                          confirm: {
                            text: "OK"
                            // value: navigate(`${uri}`)
                          }
                        }
                      });
                    } else {
                      setStatus({ succes: false });
                      swal({
                        title: `Ups! : ${payload.code}`,
                        text: `Something went wrong`,
                        icon: "error"
                      });
                    }
                  });
            }}
          >
            {({ values, handleChange, setFieldValue, handleSubmit }) => {
              useEffect(() => {
                if (id) {
                  data.find((item) =>
                    item._id === id
                      ? (setFieldValue("title", item.title),
                        setFieldValue("content", item.content),
                        setFieldValue("image", item.image.mediaUrl),
                        setFieldValue("descrirption", item.description),
                        (imageRef.current = item.image.mediaUrl))
                      : undefined
                  );
                }
              }, [id]);

              const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
                let file = e.target.files;
                if (file) {
                  setFieldValue("image", file[0]);
                  imageRef.current = URL.createObjectURL(file[0]);
                }
              };

              return (
                <>
                  <Form className="w-[55%] p-2 bg-white gap-3 h-auto rounded shadow flex flex-col">
                    {/* <h1 className="text-center text-[1.1rem]">
                      {id ? "Edit" : "Create "}
                      {title}
                    </h1> */}

                    <Input
                      required={`after:content-['*'] after:text-red-500`}
                      placeholder="Title"
                      value={values.title}
                      onChange={handleChange}
                      name="title"
                      title="Title"
                    />

                    <Textarea
                      // required={`after:content-['*'] after:text-red-500`}
                      placeholder="Description"
                      value={values.description}
                      onChange={handleChange}
                      name="description"
                      title="Description"
                    />

                    <div>
                      <h1 className="font-semibold p-0 m-0 after:text-red-500 after:content-['*']">
                        Thumbnail
                      </h1>
                      <div className="flex  items-center">
                        {imageRef.current === null ? (
                          <label className="w-[30%] h-[100px] dark:text-white flex flex-col justify-center  bg-white text-blue rounded-lg shadow tracking-wide  border border-blue cursor-pointer hover:bg-blue-400 hover:text-white">
                            <div className="flex gap-1 justify-center items-center">
                              <svg
                                className="h-6 w-6 "
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                              >
                                <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                              </svg>
                              <p className="text-left  m-0">Select a file</p>
                              <input className="hidden" onChange={handleUpload} type="file" />
                            </div>
                          </label>
                        ) : (
                          <div className="flex items-center gap-2">
                            <img src={imageRef.current} className=" w-30 h-20 rounded" />
                            <div>
                              <label className="w-auto flex flex-col justify-center  bg-white text-blue rounded shadow tracking-wide  border border-blue cursor-pointer   p-2">
                                Change
                                <input className="hidden" onChange={handleUpload} type="file" />
                              </label>
                            </div>
                            <div>
                              <button
                                onClick={() => {
                                  imageRef.current = null;
                                  setFieldValue("image", null);
                                }}
                                className="border-none outline-none focus:outline-none focus:border-none text-red-500"
                              >
                                Remove
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div>
                      <h1 className="font-semibold p-0 m-0 after:text-red-500 after:content-['*']">
                        Type
                      </h1>
                      <SelectComponents
                        options={options}
                        onChange={(value: any) => {
                          setFieldValue("type", value.value);
                        }}
                      />
                    </div>
                    <Button isLoading={isLoading}>Submit</Button>
                  </Form>
                </>
              );
            }}
          </Formik>
        </div>
      </>
      {/* )} */}
    </>
  );
};

export default AddBannerBrand;
