import React, { useState } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import { postApprovalAPI } from "@stores/HRIS/Employe/EmployeAPI";
import { Toaster } from "react-hot-toast";
import Loading from "@components/loading";

interface IAddApproveAll {
  parentId: string;
  employeeId: string;
}

const EmployeApproval = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  return (
    <>
      <Toaster />
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex h-[80vh] items-center justify-center w-full">
          <Formik
            initialValues={{} as IAddApproveAll}
            onSubmit={(values) => {
              const { employeeId, parentId } = values;
              const data = {
                employeeId,
                parentId
              };
              postApprovalAPI(data, setIsLoading).catch((error) => {
                setIsLoading(false);
              });
            }}
          >
            {({ values, setFieldValue, handleChange, handleSubmit }) => {
              return (
                <Form className="flex w-[45%] bg-white h-[16.5rem] p-3 rounded flex-col gap-4">
                  <h1 className="text-bold text-center text-[1.1rem]">Create Employe Approval</h1>
                  <div className="flex flex-col justify-between gap-3">
                    <div className="w-full">
                      <label className="block  after:text-red-500 after:content-['*'] mb-[5px] text-gray-700 text-sm font-bold">
                        Employe ID
                      </label>
                      <input
                        value={values.employeeId ?? ""}
                        name="employeeId"
                        className="border-gray px-2 rounded focus:outline-none border-[1px] h-9 w-full"
                        type="text"
                        onChange={handleChange}
                        placeholder="Employe ID"
                      />
                    </div>

                    <div className="w-full">
                      <label className="block  after:text-red-500 after:content-['*'] mb-[5px] text-gray-700 text-sm font-bold">
                        Parent ID
                      </label>
                      <input
                        value={values.parentId ?? ""}
                        name="parentId"
                        className="border-gray px-2 rounded focus:outline-none border-[1px] h-9 w-full"
                        type="text"
                        onChange={handleChange}
                        placeholder="Parent ID"
                      />
                    </div>

                    <button
                      type="submit"
                      className="w-28 h-9 hover:bg-green-600 font-medium bg-green-500 text-white rounded"
                    >
                      Submit
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      )}
    </>
  );
};

export default EmployeApproval;
