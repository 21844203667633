import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { useAppSelector, useAppDispatch } from "@stores/store";
import { DatePicker, DatePickerProps } from "antd";
import {
  VoucherOutletExcel,
  VoucherReportExcel,
  getPhoneNumberUser,
  getVoucherHistoryOutletReq,
  getVoucherReportReq
} from "@stores/dailyapp/VoucherSlice";
import MyTable from "@components/Table/MyTable";
import ExportCSV from "@components/ExportCSV";
import VoucherInject from "./VoucherInject";
import { Tabs } from "antd";
import { Link, useNavigate } from "react-router-dom";
import Modal from "@components/Modal";
import Input from "@components/Input.components";

const VoucherReport = () => {
  const dispatch = useAppDispatch();
  const {
    voucherReport,
    VoucherHistoryByOutlet,
    isLoadingHistoryByOutlet,
    isLoadingVoucher,
    VoucherOutletCSV,
    VoucherReportCSV
  } = useAppSelector((state) => state.dailyVoucher);
  const [modalInject, setModalInject] = useState(false);

  const startDate = useRef(moment().startOf("month").format("YYYY-MM-DD"));
  const endDate = useRef(moment().format("YYYY-MM-DD"));

  const handleStartDate: DatePickerProps["onChange"] = (date: any, dateString: any) => {
    startDate.current = dateString;
  };

  const handleEndDate: DatePickerProps["onChange"] = (date: any, dateString: any) => {
    endDate.current = dateString;
  };

  const filterByDate = () => {
    const payload = {
      startDate: startDate.current,
      endDate: endDate.current
    };
    dispatch(getVoucherReportReq(payload));
  };

  const filterHistoryOutlet = () => {
    const payload = {
      startDate: startDate.current,
      endDate: endDate.current
    };
    dispatch(getVoucherHistoryOutletReq(payload));
  };

  useEffect(() => {
    filterByDate();
    filterHistoryOutlet();
  }, []);

  useEffect(() => {
    dispatch(VoucherOutletExcel());
  }, [isLoadingHistoryByOutlet]);

  useEffect(() => {
    dispatch(VoucherReportExcel());
  }, [isLoadingVoucher]);

  return (
    <>
      <Tabs
        defaultActiveKey="1"
        items={[
          {
            label: "Voucher Origin",
            key: "1",
            children: (
              <ReportOrigin
                isLoading={isLoadingVoucher}
                handleEndDate={handleEndDate}
                handleStartDate={handleStartDate}
                VoucherReportCSV={VoucherReportCSV}
                setModalInject={setModalInject}
                startDate={startDate.current}
                endDate={endDate.current}
                filterByDate={filterByDate}
                dataSource={voucherReport}
                modalInject={modalInject}
              />
            )
          },
          {
            label: "Voucher By Outlet",
            key: "2",
            children: (
              <ReportOutlet
                isLoading={isLoadingHistoryByOutlet}
                handleEndDate={handleEndDate}
                handleStartDate={handleStartDate}
                VoucherReportCSV={VoucherOutletCSV}
                setModalInject={setModalInject}
                startDate={startDate.current}
                endDate={endDate.current}
                filterByDate={filterHistoryOutlet}
                dataSource={VoucherHistoryByOutlet}
              />
            )
          }
        ]}
      />
      <VoucherInject isOpen={modalInject} closeModal={() => setModalInject(!modalInject)} />
    </>
  );
};

interface IPropsModalVOucher {
  isOpen: boolean;
  closeModal: () => void;
}

const ModalVoucherUser = ({ isOpen, closeModal }: IPropsModalVOucher) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setPhoneNumber(value);
  };

  const handleSubmit = () => {
    dispatch(getPhoneNumberUser(phoneNumber));
    navigate("/dailyapp/user-voucher");
  };

  return (
    <Modal isOpen={isOpen} closeModal={closeModal} title="User Voucher">
      <div className="bg-white h-[12rem] pt-16 flex flex-col gap-3">
        <Input
          required={`after:content-['*'] after:text-red-500`}
          name="phoneNumber"
          title="Phone"
          onChange={handleChange}
          type={"text"}
          placeholder={"Phone"}
          value={phoneNumber}
        />
        <div className="pt-5">
          <button
            onClick={handleSubmit}
            className="px-5 py-2 bg-blue-500 rounded-md font-bold hover:bg-blue-600 text-white hover:text-white"
          >
            Submit
          </button>
        </div>
      </div>
    </Modal>
  );
};

interface IPropsOrigin {
  handleStartDate: ((value: moment.Moment | null, dateString: string) => void) | undefined;
  handleEndDate: ((value: moment.Moment | null, dateString: string) => void) | undefined;
  filterByDate: () => void;
  VoucherReportCSV: any[];
  isLoading: boolean;
  dataSource: any[];
  modalInject?: boolean;
  startDate: any;
  endDate: any;
  setModalInject: React.Dispatch<React.SetStateAction<boolean>>;
}

const ReportOrigin = ({
  handleEndDate,
  handleStartDate,
  filterByDate,
  VoucherReportCSV,
  isLoading,
  dataSource,
  modalInject,
  setModalInject,
  startDate,
  endDate
}: IPropsOrigin) => {
  const [modalUserVoucher, setModalUserVoucher] = useState(false);
  return (
    <>
      <ModalVoucherUser
        isOpen={modalUserVoucher}
        closeModal={() => setModalUserVoucher(!modalUserVoucher)}
      />
      <div className="flex justify-between items-center">
        <div className=" flex items-center gap-4">
          <h1 className="m-0">Filter</h1>

          <DatePicker
            className="h-9"
            name="startDate"
            onChange={handleStartDate}
            placeholder="Start date"
          />

          <DatePicker
            className="h-9"
            name="endDate"
            onChange={handleEndDate}
            placeholder="End date"
          />

          <button
            onClick={filterByDate}
            className="flex text-medium items-center gap-1 text-center outline-none h-9 w-[10rem] rounded bg-blue-500 hover:bg-blue-400 text-white justify-center"
          >
            Submit
          </button>

          <button
            onClick={() => setModalInject(!modalInject)}
            className="flex text-medium items-center gap-1 text-center outline-none h-9 w-[10rem] rounded bg-green-500 hover:bg-green-400 text-white justify-center"
          >
            Inject Voucher
          </button>

          <button
            onClick={() => setModalUserVoucher(!modalUserVoucher)}
            className="flex text-medium items-center gap-1 text-center outline-none h-9 w-[10rem] rounded bg-gray-600 hover:bg-gray-500 hover:text-white text-white justify-center"
          >
            User Voucher
          </button>
        </div>

        <ExportCSV csvData={VoucherReportCSV} fileName={`voucherReport-${startDate}-${endDate}`} />
      </div>
      <div className="mt-5">
        <MyTable
          fileName="voucher-report"
          searchable
          loading={isLoading}
          select={[
            { label: "Date", value: "date" },
            { label: "Phone", value: "phone" },
            { label: "Username", value: "name" },
            { label: "Brand", value: "brand" },
            { label: "Outlet", value: "outlet" },
            { label: "Voucher Code", value: "voucherCode" },
            { label: "Cut Amount", value: "cutAmount" }
          ]}
          dataSource={dataSource}
        />
      </div>
    </>
  );
};

const ReportOutlet = ({
  handleEndDate,
  handleStartDate,
  filterByDate,
  VoucherReportCSV,
  isLoading,
  dataSource,
  setModalInject,
  startDate,
  endDate
}: IPropsOrigin) => {
  return (
    <>
      <div className="flex	 justify-between items-center ">
        <div className=" flex items-center gap-4">
          <h1 className="m-0">Filter</h1>

          <DatePicker
            className="h-9"
            name="startDate"
            onChange={handleStartDate}
            placeholder="Start date"
          />

          <DatePicker
            className="h-9"
            name="endDate"
            onChange={handleEndDate}
            placeholder="End date"
          />

          <button
            onClick={filterByDate}
            className="flex text-medium items-center gap-1 text-center outline-none h-9 w-[10rem] rounded bg-blue-500 hover:bg-blue-400 text-white justify-center"
          >
            Submit
          </button>
        </div>

        <ExportCSV
          csvData={VoucherReportCSV}
          fileName={`voucher-report-outlet-${startDate}-${endDate}`}
        />
      </div>
      <div className="mt-5 ">
        <MyTable
          fileName="voucher-report-outlet"
          loading={isLoading}
          select={[
            { label: "Date", value: "_idDate" },
            { label: "Outlet", value: "_idOutlet" },
            { label: "Antarasa", value: "antarasaCount" },
            { label: "Antarasa Cashback", value: "antarasaTotalCashback" },
            { label: "Ayam Amanah", value: "ayamamanahCount" },
            { label: "Ayam Amanah Cashback", value: "ayamamanahTotalCashback" },
            { label: "Breadlife", value: "breadlifeCount" },
            { label: "Breadlife Cashback", value: "breadlifeTotalCashback" },
            { label: "Dailybox", value: "dailyboxCount" },
            { label: "Dailybox Cashback", value: "dailyboxTotalCashback" },
            { label: "Lumiere", value: "lumiereCount" },
            { label: "Lumiere Cashback", value: "lumiereTotalCashback" },
            { label: "Shirato", value: "shiratoCount" },
            { label: "Shirato Cashback", value: "shiratoTotalCashback" },
            { label: "Tiantea", value: "tianteaCount" },
            { label: "Tiantea Cashback", value: "tianteaTotalCashback" }
          ]}
          dataSource={dataSource}
        />
      </div>
    </>
  );
};

export default VoucherReport;
