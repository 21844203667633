import API from "@constants/API";

const BASE_URL = "https://api.dev.daily.co.id/hris/namecard/dashboard/v1/emp-name-card";

export const getNameCardAPI = async () => {
  const response = await API.HTTP.get(BASE_URL + "s");
  return response.data;
};

export const detailNameCardAPI = async (id: string) => {
  const response = await API.HTTP.get(`${BASE_URL}/${id}`);
  return response.data;
};

export const deleteNameCardAPI = async (id: string) => {
  const response = await API.HTTP.delete(BASE_URL + "/" + id);
  return response.data;
};

export const putNameCardAPI = async (id: string, data: object) => {
  const response = await API.HTTP.put(`${BASE_URL}/${id}`, data);
  return response.data;
};

export const postNameCardAPI = async (data: object) => {
  const response = await API.HTTP.post(BASE_URL, data);
  return response.data;
};
