import { dateToString, nf } from "@helpers/utils";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { IStoreCEReport } from "@typed/CE/Report";
import moment from "moment";
import { getReportComplainApi, getReportReviewApi } from "./ReportAPI";

export const getReportReviewReq = createAsyncThunk(
  "report/getReportReviewReq",
  async ({ startDate, endDate }: { startDate: any; endDate: any }) => {
    const response = await getReportReviewApi(startDate, endDate);
    return response;
  }
);

export const getReportComplainReq = createAsyncThunk(
  "report/getReportComplainReq",
  async ({ startDate, endDate }: { startDate: any; endDate: any }) => {
    const response = await getReportComplainApi(startDate, endDate);
    return response;
  }
);

const initialState: IStoreCEReport = {
  reportReview: [],
  reportComplain: [],
  reportReviewCSV: [],
  reportComplainCSV: [],
  isLoading: false,
  startDate: moment().startOf("month").format("YYYY-MM-DD"),
  endDate: moment().format("YYYY-MM-DD")
};

const ReportSlice = createSlice({
  name: "report",
  initialState: initialState,
  reducers: {
    CSVReportReview: (state: IStoreCEReport) => {
      state.reportReviewCSV = state.reportReview?.map((item) => ({
        "Order Date": dateToString(item.orderDate),
        "Scan Date": dateToString(item.scanDate),
        "Review Date": dateToString(item.reviewDate),
        "Bill Number": item.billNum,
        Total: nf.format(item.total),
        Name: item.custName,
        Phone: item.custPhone,
        Content: item.content,
        Outlet: item.outlet,
        Rate: item.rate
      }));
    },
    CSVReportComplain: (state: IStoreCEReport) => {
      state.reportComplainCSV = state.reportComplain?.map((item) => ({
        "Order Date": dateToString(item.orderDate),
        "Scan Date": dateToString(item.scanDate),
        "Complain Date": dateToString(item.complainDate),
        "Bill Number": item.billNum,
        Total: nf.format(item.total),
        Name: item.custName,
        Phone: item.CustPhone,
        Content: item.content,
        Outlet: item.outlet,
        "Review Status": item.reviewStatus,
        "Compesation Product": item.compesationProduct,
        "Poin Compesation": item.poinCompesation,
        "Review Message": item.reviewMessage,
        Image: item.image[0]
      }));
    },

    setStartDate: (state: IStoreCEReport, action: PayloadAction<string>) => {
      state.startDate = action.payload;
    },
    setEndDate: (state: IStoreCEReport, action: PayloadAction<string>) => {
      state.endDate = action.payload;
    }
  },
  extraReducers(builder) {
    builder.addCase(getReportReviewReq.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getReportReviewReq.fulfilled, (state, action) => {
      state.reportReview = action.payload.data;
      state.isLoading = false;
    });
    builder.addCase(getReportReviewReq.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getReportComplainReq.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getReportComplainReq.fulfilled, (state, action) => {
      state.reportComplain = action.payload.data;
      state.isLoading = false;
    });
    builder.addCase(getReportComplainReq.rejected, (state) => {
      state.isLoading = false;
    });
  }
});

export default ReportSlice.reducer;
export const { CSVReportReview, CSVReportComplain, setStartDate, setEndDate } = ReportSlice.actions;
