import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@stores/store";
import { getNameCardRequest } from "@stores/HRIS/Name-card/NameCardSlice";
import { Table } from "antd";
import { Link } from "react-router-dom";
import { dateToString } from "@helpers/utils";

const column = [
  {
    title: "Date",
    dataIndex: "createdAt",
    render: (date: string) => <div>{dateToString(date)}</div>
  },
  {
    title: "Fullname",
    dataIndex: "firstname",
    render: (firstname: string) => <div>{firstname}</div>
  },
  {
    title: "Address",
    dataIndex: "address",
    render: (firstname: string) => <div>{firstname}</div>
  },
  {
    title: "Company",
    dataIndex: "lastname"
  },
  {
    title: "Phone",
    dataIndex: "phone",
    render: (phone: string) => (
      <a href={`https://wa.me/${phone}`} rel="noreferrer" target="_blank">
        {phone}
      </a>
    )
  },
  {
    title: "Action",
    dataIndex: "_id",
    render: (id: string) => (
      <div>
        <Link
          className="bg-blue-500 hover:text-white text-white p-2 px-4 rounded hover:bg-blue-600"
          to={id}
        >
          Detail
        </Link>
      </div>
    )
  }
];

const NameCard = () => {
  const dispatch = useAppDispatch();
  const data = useAppSelector((state) =>
    state.hrisNameCard.data?.map((i: any) => ({ ...i, key: i._id }))
  );

  useEffect(() => {
    dispatch(getNameCardRequest());
  }, []);
  return (
    <div>
      <div className="mb-4">
        <Link
          to="add"
          className=" py-2 text-center px-6 bg-green-500 hover:bg-green-600 hover:text-white rounded hover:shadow-lg text-white font-semibold"
        >
          Create Name Card
        </Link>
      </div>

      <div>
        <Table columns={column} dataSource={data} />
      </div>
    </div>
  );
};

export default NameCard;
