import API from "@constants/API";

const BASE_URL = "https://api.prod.daily.co.id/dashboard/news-banner/v3/dashboard-news-banner/";

export const getNewsAPI = async () => {
  const response = await API.HTTP.get(`${BASE_URL}?type=news`);
  return response.data;
};

export const detailNewsAPI = async (id: string) => {
  const response = await API.HTTP.get(`${BASE_URL}${id}?type=news`);
  return response.data;
};

export const postNewsAPI = async (data: object) => {
  const response = await API.HTTP.post(`${BASE_URL}?type=news`, data);
  return response.data;
};

export const putNewsAPI = async (data: object, id: string) => {
  const response = await API.HTTP.put(`${BASE_URL}${id}?type=news`, data);
  return response.data;
};

export const deleteNewsAPI = async (id: string) => {
  const response = await API.HTTP.delete(`${BASE_URL}${id}?type=news`);
  return response.data;
};
