import React, { FC, useState, useRef, PropsWithChildren } from "react";
import moment from "moment";
import Modal from "@components/Modal";
import { DatePicker, DatePickerProps } from "antd";
import ExportCSV from "@components/ExportCSV";

export interface IPropsModal {
  isOpen: boolean;
  title: string;
  closeModal: () => void;
}

const ModalMyTablePagination: FC<PropsWithChildren<IPropsModal>> = ({
  title,
  isOpen,
  closeModal,
  children
}) => {
  const [data, setData] = useState([]);
  const startDateRef = useRef<any>(moment().startOf("month"));
  const endDateRef = useRef<any>(moment().endOf("month"));

  const handleStartDate: DatePickerProps["onChange"] = (date: any, dateString: any) => {
    startDateRef.current = dateString;
  };

  const handleEndDate: DatePickerProps["onChange"] = (date: any, dateString: any) => {
    endDateRef.current = dateString;
  };
  return (
    <Modal isOpen={isOpen} title={title} closeModal={closeModal}>
      {children}
      {/* <div className="h-[7rem] mt-16 flex flex-col gap-3">
        <div className="flex mb-5 items gap-2 justify-center">
          <DatePicker
            className="h-9"
            name="startDate"
            onChange={handleStartDate}
            placeholder="Start date"
          />
          <DatePicker
            className="h-9"
            name="endDate"
            onChange={handleEndDate}
            placeholder="End date"
          />
        </div>
        <ExportCSV csvData={[{ nama: "testing" }]} fileName="DailyApp-users" />
      </div> */}
    </Modal>
  );
};

export default ModalMyTablePagination;
