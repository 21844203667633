import {
  GDetailOjolRegionRequest,
  postOjolRegionRequest,
  putOjolRegionRequest,
  reset
} from "@stores/dailyojol/RegionSlice";
import { useAppDispatch, useAppSelector } from "@stores/store";
import { IDataDetailRegion } from "@typed/dailojol/Region";
import Item from "antd/lib/list/Item";
import { isEmpty } from "ramda";
import React, { FC, useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
type ICreateRegion = {
  name?: string;
  authority: string;
};

const AddRegion: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { loading, getDetail, data } = useAppSelector((state) => state.dailyojolRegion);
  const [CRegion, setCRegion] = useState<ICreateRegion>({} as ICreateRegion);

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      data.filter(
        (item: IDataDetailRegion) =>
          item._id === id &&
          setCRegion({
            authority: item.authority,
            name: item.name
          })
      );
      console.log("Runnning");
      // dispatch(GDetailOjolRegionRequest(id));
    }
  }, [id]);

  const { name, authority } = CRegion;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setCRegion({ ...CRegion, [name]: value });
  };

  useEffect(() => {
    if (!isEmpty(getDetail)) {
      dispatch(reset());
      setCRegion({
        name: "",
        authority: ""
      });
      setTimeout(() => {
        navigate("/dailyojol/region");
      }, 500);
    }
  }, [getDetail]);

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (id) {
      const Data = {
        id,
        authority
      };
      dispatch(putOjolRegionRequest(Data));
    } else {
      const Data = {
        name,
        authority
      };
      dispatch(postOjolRegionRequest(Data));
      // setCRegion({
      //   name: "",
      //   authority: ""
      // });
    }
  };

  return (
    <div className="flex items-center justify-center">
      <Toaster />
      <div className={"w-[45%] min-h-[25vh] bg-white rounded-lg p-2"}>
        <h1 className="text-center font-semibold text-[1.3rem] ">
          {id ? "Edit" : "Create"} Region
        </h1>

        <div>
          <h1 className="font-semibold p-0 m-0">Name</h1>
          {id ? (
            <input
              name="name"
              value={name ?? ""}
              className="border border-slate-400 w-full rounded h-8 outline-none px-2"
              type="text"
              placeholder="City"
            />
          ) : (
            <input
              name="name"
              value={name ?? ""}
              onChange={handleChange}
              defaultValue={name}
              className="border border-slate-400 w-full rounded h-8 outline-none px-2"
              type="text"
              placeholder="City"
            />
          )}
        </div>

        <div className="mt-3">
          <h1 className="font-semibold p-0 m-0">Author</h1>
          <input
            className="border border-slate-400 w-full rounded h-8 outline-none px-2"
            type="text"
            name="authority"
            value={authority ?? ""}
            onChange={handleChange}
            placeholder="Author"
          />
        </div>
        <button
          onClick={handleSubmit}
          className={
            loading
              ? "cursor-wait w-full py-2 my-3 rounded hover:shadow text-white font-semibold bg-gray-500"
              : "w-full py-2 my-3 rounded hover:shadow text-white font-semibold bg-blue-500"
          }
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default AddRegion;
