import { Tabs } from "antd";
import React from "react";
import ReportComplain from "./ReportComplain";
import ReportReview from "./ReportReview";
import ReportRegister from "./ReportRegister";
const Report = () => {
  return (
    <>
      <Tabs
        defaultActiveKey="1"
        items={[
          {
            label: `Report Complain`,
            key: "1",
            children: <ReportComplain />
          },
          {
            label: `Report Review`,
            key: "2",
            children: <ReportReview />
          },
          {
            label: `Register Cashback`,
            key: "3",
            children: <ReportRegister />
          }
        ]}
      />
    </>
  );
};

export default Report;
