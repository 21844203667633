import React, { useEffect, useRef, useState } from "react";
import { ApexOptions } from "apexcharts";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "@stores/store";
import { getFinanceRequest } from "@stores/dailycoin/ReportingSlice";
import Chart from "react-apexcharts";
import RadioComponent from "@components/Radio.component";

interface IChartProps {
  date: any;
}

const ApexChart = ({ date }: IChartProps) => {
  const dispatch = useAppDispatch();
  const summaryTrx = useAppSelector((state) => state.dailyReport.reportFinance);
  const topUp = summaryTrx.map((item) => item.dailycoinTopup);
  const topUpTransaction = summaryTrx.map((item) => item.transactionTopup);
  const cashbackBill = summaryTrx.map((item) => item.dailycoinCashbackBill);
  const cashbackTopup = summaryTrx.map((item) => item.cashbackTopup);
  const paymentDailycoin = summaryTrx.map((item) => item.paymentTransaction);
  const payment = summaryTrx.map((item) => item.totalDailycoinPayment);
  const paymentDailyclick = summaryTrx.map((item) => item.totalDailycoinDailyclickPayment);

  const [selectedOption, setSelectedOption] = useState<string>("cashback");

  const startDate = useRef<any>(moment().startOf("month"));

  useEffect(() => {
    dispatch(
      getFinanceRequest({
        startDate:
          date !== ""
            ? moment(date.start).format("YYYY-MM-DD")
            : moment(startDate.current).format("YYYY-MM-DD"),
        endDate:
          date !== ""
            ? moment(date.allDay).format("YYYY-MM-DD")
            : moment(startDate.current).format("YYYY-MM-DD")
      })
    );
  }, [date]);

  const options: ApexOptions = {
    labels: summaryTrx.map((item) => item._id),
    xaxis: {
      type: "datetime",
      labels: {
        // format: "dd mm" // Format the date labels as 'MMM yyyy'
        datetimeFormatter: {
          year: "yyyy",
          month: "MMM",
          day: "dd",
          hour: "HH:mm"
        }
      }
    },
    chart: {
      type: "area",
      height: 350,
      zoom: {
        enabled: true
      }
    }
  };

  const Cashback = [
    {
      name: "Cashback - Scanned Bill",
      data: cashbackBill
    },
    {
      name: "Cashback - Top up",
      data: cashbackTopup
    }
  ];

  const Topup = [
    {
      name: "Top Up - Dailycoin",
      data: topUp
    },
    {
      name: "Top Up - Transaction",
      data: topUpTransaction
    }
  ];

  const Payment = [
    {
      name: "Payment - Transaction Dailycoin",
      data: paymentDailycoin
    },
    {
      name: "Payment - Dailycoin",
      data: payment
    },
    {
      name: "Payment Dailycoin - Dailyclick",
      data: paymentDailyclick
    }
  ];

  const dataSource = ["topup", "cashback", "payment"];

  return (
    <>
      <div className="flex items-center gap-10 p-2">
        <div className="pl-12">
          <RadioComponent
            dataSource={dataSource}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
        </div>

        <h1 className="font-bold text-[2rem] m-0">Dailycoin</h1>
      </div>
      <Chart
        options={options}
        series={
          selectedOption === "payment" ? Payment : selectedOption === "topup" ? Topup : Cashback
        }
        type="area"
      />
    </>
  );
};
export default ApexChart;
