import React, { useEffect } from "react";
import { Table } from "antd";

import { useAppSelector, useAppDispatch } from "@stores/store";
import { getReportBillRequest } from "@stores/dailycoin/ReportingSlice";
import { nf } from "@helpers/utils";
import DataTable from "react-data-table-component";

import Loading from "@components/loading";

const columns = [
  {
    name: "Date",
    selector: (row: any) => row._id,
    id: "_id"
  },
  {
    name: "Transaction",
    id: "transaction",
    selector: (row: any) => row.transaction,
    cell: (row: any) => <div>{nf.format(row.transaction)}</div>
  },
  {
    name: "Cashback",
    id: "cashback",
    selector: (row: any) => row.cashback,
    cell: (row: any) => <div>{nf.format(row.cashback)}</div>
  }
];

interface IProps {
  startDate: string;
  endDate: string;
}

const BillReport: React.FC<IProps> = ({ startDate, endDate }) => {
  const { isLoading } = useAppSelector((state) => state.dailyReport);
  const reportBill = useAppSelector((state) =>
    state.dailyReport.reportBill.map((i) => ({ ...i, key: i._id }))
  );

  // if()

  return (
    <>
      {/* <Table dataSource={reportBill} loading={isLoading} columns={columns} /> */}
      <DataTable data={reportBill} columns={columns} pagination />
    </>
  );
};

export default BillReport;
