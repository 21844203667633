import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RefreshTokenPayload, TokenStoreState } from "@typed/global";
import { refreshTokenRequestAPI } from "./TokenAPI";

const initialState: TokenStoreState = {
  token: "",
  refreshToken: "",
  tokenIssueDate: new Date().toDateString()
};

export const refreshToken = createAsyncThunk(
  "token/refreshTokenRequest",
  async (payload: RefreshTokenPayload) => {
    const response = await refreshTokenRequestAPI(payload);
    return response;
  }
);

export const authSlice = createSlice({
  name: "token",
  initialState: initialState,
  reducers: {
    updateToken: (state: TokenStoreState, action: { payload: any }) => {
      return {
        ...state,
        token: action.payload.token,
        refreshToken: action.payload.tokenRefresh,
        tokenIssueDate: new Date().toDateString()
      };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(refreshToken.pending, (state, action) => {});
    builder.addCase(refreshToken.fulfilled, (state, action) => {
      if (action.payload.code === 1000) {
        state.token = "";
        state.refreshToken = "";
        state.tokenIssueDate = new Date().toDateString();
      }
    });
    builder.addCase(refreshToken.rejected, (state, action) => {});
  }
});

// Action creators are generated for each case reducer function
export const { updateToken } = authSlice.actions;

export default authSlice.reducer;
