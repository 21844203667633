import API from "@constants/API";
import { ICreateOutlet } from "@typed/dailojol/Outlet";
const BASE_URL = "https://api.dev.daily.co.id/dashboard/account/outlet-account";

// get outlet
export const getOutletAPI = async () => {
  const response = await API.HTTP.get(`${BASE_URL}`);
  return response;
};

export const getDetailOutletAccAPI = async (id: string) => {
  const response = await await API.HTTP.get(`${BASE_URL}/${id}`);
  return response;
};

export const postOutletAccountAPI = async (payload: object) => {
  const response = await API.HTTP.post(`${BASE_URL}`, payload);
  return response.data;
};

export const putOutletAccountAPI = async (Data: object, id: string) => {
  const response = await API.HTTP.put(`${BASE_URL}/${id}`, Data);

  return response.data;
};
