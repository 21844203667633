import { useAppDispatch, useAppSelector } from "@stores/store";
import React, { useEffect, useRef, useState } from "react";
import { Table, DatePicker } from "antd";
import { nf } from "@helpers/utils";

import { getOutletTrxRequest } from "@stores/dailycoin/dailyCoinSlice";
import { useParams } from "react-router-dom";
import moment from "moment";
import ExportCSV from "@components/ExportCSV";
import type { DatePickerProps } from "antd";
import Loading from "@components/loading";
import { getOutletsRequest } from "@stores/dailyojol/OutletSlice";

import { setEndDate, setStartDate } from "@stores/dailycoin/ReportingSlice";

import DataTable from "react-data-table-component";

const columns = [
  {
    name: "Date",
    selector: (row: any) => row.date
  },
  {
    name: "Dailycoin",
    selector: (row: any) => row.dailycoin
  },
  {
    name: "Dailycoin Currency",
    selector: (row: any) => row.dailycoinCurrency,
    cell: (row: any) => <div>{nf.format(row.dailycoinCurrency)}</div>
  }
];

const ReviewOutlet = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector((state) => state.dailyCoins);
  const outletTransaction = useAppSelector((state) => state.dailyCoins.outletTransaction);

  const Outlet = useAppSelector((state) =>
    state.dailyojolOutlet?.Outlet?.map((item) => ({ label: item.name, value: item._id }))
  );

  const { startDate, endDate } = useAppSelector((state) => state.dailyReport);

  const dataCSV = useRef<any>();

  const downloadOutletTrx = () => {
    if (id) {
      const dataExcel = outletTransaction?.data?.map((item) => ({
        Date: item.date,
        Dailycoin: item.dailycoin,
        DailycoinCurrency: item.dailycoinCurrency
      }));
      dataCSV.current = dataExcel;
    }
  };

  const filterOutletTrx = () => {
    dispatch(getOutletTrxRequest({ id, startDate: startDate, endDate: endDate }));
    downloadOutletTrx();
  };

  useEffect(() => {
    dispatch(getOutletsRequest({ skip: 0, limit: 20 }));
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(
        getOutletTrxRequest({
          id,
          startDate: moment(startDate).format("YYYY-MM-DD"),
          endDate: moment(endDate).format("YYYY-MM-DD")
        })
      );
      downloadOutletTrx();
    }
  }, [id]);

  const handleStartDate: DatePickerProps["onChange"] = (date: any, dateString: any) => {
    dispatch(setStartDate(dateString));
  };

  const handleEndDate: DatePickerProps["onChange"] = (date: any, dateString: any) => {
    dispatch(setEndDate(dateString));
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <h1 className="text-[1.2rem]">{outletTransaction.outlet}</h1>
          <div className="flex  justify-between items-center">
            <div className="flex gap-4 items-center ">
              <h1 className="m-0">Filter By</h1>

              <DatePicker
                className="h-9"
                name="startDate"
                onChange={handleStartDate}
                placeholder="Start date"
              />
              <DatePicker
                className="h-9"
                name="startDate"
                onChange={handleEndDate}
                placeholder="End date"
              />
              <button
                onClick={filterOutletTrx}
                className="flex text-medium items-center gap-1 text-center outline-none h-9 w-[6rem] rounded bg-blue-500 hover:bg-blue-400 text-white justify-center"
              >
                Submit
              </button>
            </div>
            <ExportCSV
              csvData={dataCSV.current}
              fileName={`Dailycoint-transaction-${outletTransaction?.outlet}`}
            />
          </div>
          <div className="mt-5">
            <DataTable
              data={outletTransaction.data}
              columns={columns}
              pagination
              persistTableHead
            />
          </div>
        </>
      )}
    </>
  );
};

export default ReviewOutlet;
