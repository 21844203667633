import {
  createProductRequest,
  putProductRequest,
  resetLastProduct
} from "@stores/dailyapp/ProductSlice";
import { useAppDispatch, useAppSelector } from "@stores/store";
import { PayloadProductCreate } from "@typed/dailyapp/product";
import { Button, Col, Switch, Input, Row, Upload, message } from "antd";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as R from "ramda";
import { Toaster } from "react-hot-toast";
import { IAddProduct } from "@typed/dailojol/Product";

const AddProduct = () => {
  const { id } = useParams();

  const navigate = useNavigate();
  const [imgPrev, setImgPrev] = useState("");
  const { singleProduct, products, isLoading } = useAppSelector((state) => state.dailyappProduct);
  const dispatch = useAppDispatch();
  const [addProduct, setAddProduct] = useState<IAddProduct>({} as IAddProduct);

  const { name, image, dailyPrice, flagDailyAppIsActive, imagePrev } = addProduct;
  const { lastProducts } = useAppSelector((state) => {
    return {
      lastProducts: state.dailyappProduct.lastCreatedProducts
    };
  });

  // is edit ?
  useEffect(() => {
    if (id) {
      {
        products.filter(
          (item: { _id: string; name: any; dailyPrice: any; image: any }) =>
            item._id === id &&
            setAddProduct({
              ...addProduct,
              name: item.name,
              dailyPrice: item.dailyPrice,
              image: item.image,
              imagePrev: item.image
            })
        );
      }
    }
  }, [id]);

  useEffect(() => {
    // reset products
    if (!R.isEmpty(lastProducts)) {
      dispatch(resetLastProduct());
      setTimeout(() => {
        navigate("/dailyapp/products");
      }, 600);
    }
  }, [lastProducts]);

  // GET CHANGE
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setAddProduct({ ...addProduct, [name]: value });
  };

  // GET CHANGE UPLOAD IMAGE
  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    let file = e.target.files;
    let files: any;
    if (file) {
      files = file[0];
    }
    setAddProduct({ ...addProduct, image: files, imagePrev: URL.createObjectURL(files) });
  };

  const handlePost = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (id) {
      const Data = {
        name,
        image,
        dailyPrice,
        flagDailyAppIsActive
      };
      dispatch(putProductRequest({ id, Data }));
    } else {
      dispatch(
        createProductRequest({
          name,
          image,
          dailyPrice,
          flagDailyAppIsActive
        })
      );
    }
  };

  return (
    <div>
      <Toaster />
      <form className="flex justify-center ">
        <div className="p-2 shadow w-[50%] min-h-[30vh] bg-white">
          <h1 className="font-bold text-[1.2rem] text-center">Add Product</h1>
          <div>
            <h1 className="m-0 after:text-red-500 after:content-['*']">Name</h1>
            <input
              className="border-gray px-2 rounded focus:outline-none border-[1px] h-9 w-full"
              type="name"
              name="name"
              value={name ?? ""}
              onChange={handleChange}
              placeholder="Name"
            />
          </div>

          <div className="mt-3">
            <h1 className="m-0 after:text-red-500 after:content-['*']">Price</h1>
            <input
              className="border-gray px-2 rounded focus:outline-none border-[1px] h-9 w-full"
              type="number"
              name="dailyPrice"
              value={dailyPrice ?? ""}
              onChange={handleChange}
              placeholder="Price"
            />
          </div>

          <div className="mt-3">
            <h1 className="m-0 after:text-red-500 after:content-['*']">Image</h1>
            <div className="flex justify-between">
              <input
                className="h-8  outline-none focus:outline-none focus:border-none px-2 rounded"
                type="file"
                onChange={handleUpload}
                placeholder="Price"
              />
              {imagePrev && <img className="w-36 h-36 rounded-lg object-cover" src={imagePrev} />}
            </div>
          </div>

          <Button
            type="primary"
            // disabled={false}
            onClick={handlePost}
            loading={isLoading}
            className={
              // isLoading
              //   ? "animate-spin my-4 h-9 rounded w-full bg-blue-500 hover:bg-blue-600 text-white "
              "my-4 flex justify-center h-9 rounded w-full bg-blue-500 hover:bg-blue-600 text-white "
            }
          >
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
};
export default AddProduct;
