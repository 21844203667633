import API from "@constants/API";
const BASE_URL = "https://api.dev.daily.co.id/dashboard/middleman/middleman";

// const ACTIVITY_URL = "https://api.dev.daily.co.id/dashboard/middleman/middleman/";
export const getOjolUsersAPI = async () => {
  const response = await API.HTTP.get(BASE_URL);
  return response.data;
};

export const getUserDetail = async ({ id }: { id: string | undefined }) => {
  const response = await API.HTTP.get(`${BASE_URL}/${id}`);

  return response.data;
};

type TDataPut = {
  id: string;
  Data: object;
};

export const putUserAPI = async ({ id, Data }: TDataPut) => {
  const response = await API.HTTP.put(`${BASE_URL}-suspend/${id}`);

  return response.data;
};
