import { createSlice, createAsyncThunk, PayloadAction, CaseReducer } from "@reduxjs/toolkit";
import { Overwrite, PayloadResponse } from "@typed/global";
import {
  getAllTransactionAPI,
  getTransactionByUserIdApi,
  postRefundCoinAPI
} from "./TransactionAPI";
import swal from "sweetalert";

// type Payload = Overwrite<
//   PayloadResponse,
//   {
//     data: [IDataTransaction];
//   }
// >;

export const getTransactionByUserIdRequest = createAsyncThunk(
  "dailyapp-transaction/getTransactionByUserIdRequest",
  async (payload: { id: string | undefined }) => {
    const response = await getTransactionByUserIdApi(payload.id || "");
    return response;
  }
);

export const getAllTransactionRequest = createAsyncThunk(
  "dailyapp-transaction/getAllTransactionRequest",
  async (page: number) => {
    const response = await getAllTransactionAPI(page);

    return response;
  }
);

export const postRefundTransactionRequest = createAsyncThunk(
  "dailyapp-transaction/",
  async (data: object) => {
    const response = await postRefundCoinAPI(data);
    return response;
  }
);

const initialState = {
  transactions: [],
  isLoading: false,
  erorMessage: ""
};

export const transactionSlice = createSlice({
  name: "dailyapp-transaction",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllTransactionRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllTransactionRequest.fulfilled, (state, action) => {
      if (action.payload.code === 1000) {
        state.transactions = action.payload.data;
      }
      state.isLoading = false;
    });
    builder.addCase(getAllTransactionRequest.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getTransactionByUserIdRequest.pending, (state) => {
      state.transactions = [];
      state.isLoading = true;
    });
    builder.addCase(getTransactionByUserIdRequest.fulfilled, (state, action) => {
      if (action.payload.code === 1000) {
        state.transactions = action.payload.data;
      } else {
        state.erorMessage = "Error :: with status code" + action.payload.code;
      }
      state.isLoading = false;
    });
    builder.addCase(getTransactionByUserIdRequest.rejected, (state) => {
      state.erorMessage = "Error :: Case rejected";
      state.isLoading = false;
    });
    builder.addCase(postRefundTransactionRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(postRefundTransactionRequest.fulfilled, (state, action) => {
      if (action.payload.code === 1000) {
        swal("success", "Refund Success", "success");
      } else {
        swal("Ups", "Something went wrong", "error");
      }
      state.isLoading = false;
    });
    builder.addCase(postRefundTransactionRequest.rejected, (state) => {
      state.isLoading = false;
    });
  }
});

// Action creators are generated for each case reducer function
export const {} = transactionSlice.actions;

export default transactionSlice.reducer;
