import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { IPerson, IPersonStore } from "@typed/HRIS/Person";
import { addPersonAPI, getPersonAPI, putPersonAPI } from "./PersonAPI";

export const postPersonRequest = createAsyncThunk(
  "hris-person/postPersonRequest",
  async (Data: object) => {
    const response = await addPersonAPI(Data);

    return response;
  }
);

export const getPersonRequest = createAsyncThunk("hris-person/getPersonRequest", async () => {
  const response = await getPersonAPI();

  return response;
});

export const putPersonRequest = createAsyncThunk(
  "hris-person/putPersonRequest",
  async ({ id, Data }: { id: string; Data: object }) => {
    const response = await putPersonAPI(id, Data);
    return response;
  }
);

const initialState: IPersonStore = {
  initialPerson: {} as IPerson,
  isLoading: false,
  dataPersons: []
};

const PersonSlice = createSlice({
  name: "hris-person",
  initialState: initialState,
  reducers: {
    resetPerson: (state: { initialPerson: IPerson }) => {
      state.initialPerson = {} as IPerson;
    }
  },

  extraReducers(builder) {
    builder.addCase(getPersonRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getPersonRequest.fulfilled, (state, action) => {
      state.dataPersons = action.payload.data;
      state.isLoading = false;
    });
    builder.addCase(getPersonRequest.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(postPersonRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(postPersonRequest.fulfilled, (state, action) => {
      if (action.payload.code === 1000) {
        state.initialPerson.firstName = action.payload.data.firstName;
        state.initialPerson.lastName = action.payload.data.lastName;
        state.initialPerson.id = action.payload.data._id;
        state.initialPerson.code = action.payload.code;
      }
      state.isLoading = false;
    });
    builder.addCase(postPersonRequest.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(putPersonRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(putPersonRequest.fulfilled, (state, action) => {
      if (action.payload.code === 1000) {
        state.initialPerson.firstName = action.payload.data.firstName;
        state.initialPerson.lastName = action.payload.data.lastName;
        state.initialPerson.id = action.payload.data._id;
        state.initialPerson.code = action.payload.code;
      }
      state.isLoading = false;
    });
    builder.addCase(putPersonRequest.rejected, (state) => {
      state.isLoading = false;
    });
  }
});

export default PersonSlice.reducer;
export const { resetPerson } = PersonSlice.actions;
