import React, { useState, FC, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { HiPlusCircle } from "react-icons/hi";
import { AiFillMinusCircle } from "react-icons/ai";
import { useAppDispatch, useAppSelector } from "@stores/store";
import { postNameCardRequest, putNameCardRequest } from "@stores/HRIS/Name-card/NameCardSlice";
import { useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";

import Loading from "@components/loading";

const initialValues = {
  firstName: "",
  company: "",
  email: "",
  address: "",
  phone: ""
};

let key = 0;

const AddNameCard: FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { isLoading } = useAppSelector((state) => state.hrisNameCard);
  const findNameCard = useAppSelector((state) =>
    state.hrisNameCard.data?.find((i: any) => i._id === id)
  );
  const [nameSocialMedia, setNameSocialMedia] = useState({
    key: "",
    name: "",
    sosmedID: ""
  });

  const [socialMedia, setSocialMedia] = useState<any[]>([]);

  const handleAddSosmed = () => {
    setSocialMedia([...socialMedia, { ...nameSocialMedia, key: ++key }]);
  };

  const handleChangeSosmed = (e: React.ChangeEvent<HTMLInputElement>, idx: number) => {
    const { name, value } = e.target;
    const list = [...socialMedia];
    list[idx][name] = value.toLowerCase();
    setSocialMedia(list);
  };

  const handleDeleteInput = (idx: number) => {
    const list = [...socialMedia];
    list.splice(idx, 1);
    setSocialMedia(list);
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="flex items-center justify-center">
            <Formik
              initialValues={initialValues}
              onSubmit={(values) => {
                const { firstName, phone, email, company } = values;
                const data = {
                  email,
                  phone: phone.replace("0", "62"),
                  firstname: firstName,
                  lastname: company,
                  socialMedia: socialMedia
                };
                if (id) {
                  const datas = {
                    id: id,
                    data: data
                  };
                  dispatch(putNameCardRequest(datas)).then(({ payload }) => {
                    if (payload.code === 1000) {
                      navigate("/hris/name-card");
                      swal("Success!", "You name card is update!", "success");
                    }
                  });
                } else
                  dispatch(postNameCardRequest(data)).then(({ payload }) => {
                    payload.code === 1000
                      ? swal({
                          title: "Success!",
                          text: "You name card is created",
                          icon: "success",
                          buttons: {
                            roll: {
                              text: "OK",
                              value: navigate("/hris/name-card")
                            }
                          }
                        })
                      : swal("Ups!", "Something went wrong!", "error");
                  });
              }}
            >
              {({ values, setFieldValue, handleChange, handleSubmit }) => {
                useEffect(() => {
                  if (id) {
                    let sosmed = findNameCard.socialMedia;
                    setFieldValue("firstName", findNameCard.firstname),
                      setFieldValue("company", findNameCard.lastname),
                      setFieldValue("phone", findNameCard.phone),
                      setFieldValue("email", findNameCard.email),
                      setFieldValue("address", findNameCard.address),
                      setSocialMedia(sosmed);
                  }
                }, []);

                return (
                  <Form className="bg-white w-[45%] h-auto rounded">
                    <h1 className="text-center text-[1.2rem] font-semibold">
                      {id ? "Update" : "Create"} Name Card
                    </h1>

                    <div className="flex flex-col gap-3 p-3">
                      <div className="flex gap-3 items-center justify-between">
                        <div className="w-[50%]">
                          <h1 className="p-0 m-0 after:text-red-500 after:content-['*'] font-semibold">
                            Fullname
                          </h1>
                          <input
                            placeholder="Fullname"
                            name="firstName"
                            value={values.firstName ?? ""}
                            onChange={handleChange}
                            className="border-gray px-2 rounded focus:outline-none border-[1px] h-9 w-full"
                          />
                        </div>
                        <div className="w-[50%]">
                          <h1 className="p-0 m-0 font-semibold after:text-red-500 after:content-['*']">
                            Company Name
                          </h1>
                          <input
                            placeholder="Company Name"
                            className="border-gray px-2 rounded focus:outline-none border-[1px] h-9 w-full"
                            name="company"
                            value={values.company ?? ""}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="flex gap-3 items-center justify-between">
                        <div className="w-[50%]">
                          <h1 className="p-0 m-0 after:text-red-500 after:content-['*'] font-semibold">
                            Email
                          </h1>
                          <input
                            placeholder="FirstName"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            className="border-gray px-2 rounded focus:outline-none border-[1px] h-9 w-full"
                          />
                        </div>
                        <div className="w-[50%]">
                          <h1 className="p-0 m-0 font-semibold after:text-red-500 after:content-['*']">
                            Phone
                          </h1>
                          <input
                            placeholder="Phone"
                            name="phone"
                            value={values.phone}
                            onChange={handleChange}
                            className="border-gray px-2 rounded focus:outline-none border-[1px] h-9 w-full"
                          />
                        </div>
                      </div>
                      <div>
                        <h1 className="p-0 m-0 font-semibold after:text-red-500 after:content-['*']">
                          Address
                        </h1>
                        <textarea
                          placeholder="Address"
                          name="address"
                          value={values.address}
                          onChange={handleChange}
                          className="border-gray resize-none overflow-y-scroll px-2 rounded focus:outline-none border-[1px] h-14 w-full"
                        />
                      </div>
                      <div>
                        <div className="flex items-center justify-between">
                          <h1 className="p-0 m-0 font-semibold">Social Media</h1>
                          <HiPlusCircle
                            onClick={handleAddSosmed}
                            size={37}
                            className=" text-blue-500 hover:text-blue-600  p-2 cursor-pointer rounded-full "
                          />
                        </div>
                      </div>

                      {socialMedia?.map((item, idx) => (
                        <div key={idx} className="flex gap-3 items-center justify-between">
                          <div className="w-[50%]">
                            <h1 className="p-0 m-0  font-semibold">Name</h1>
                            <input
                              placeholder="Name"
                              name="name"
                              value={item.name}
                              onChange={(e) => handleChangeSosmed(e, idx)}
                              className="border-gray px-2 rounded focus:outline-none border-[1px] h-9 w-full"
                            />
                          </div>
                          <div className="w-[50%]">
                            <div className="flex items-center justify-between">
                              <h1 className="p-0 m-0 font-semibold">Social Media ID</h1>
                              <AiFillMinusCircle
                                size={18}
                                onClick={() => handleDeleteInput(idx)}
                                color="red"
                                className="cursor-pointer"
                              />
                            </div>
                            <input
                              placeholder="Profile ID"
                              name="sosmedID"
                              value={item.sosmedID}
                              onChange={(e) => handleChangeSosmed(e, idx)}
                              className="border-gray px-2 rounded focus:outline-none border-[1px] h-9 w-full"
                            />
                          </div>
                        </div>
                      ))}

                      <button
                        type="submit"
                        className="py-2 px-5 bg-green-500 hover:bg-green-600 text-white rounded font-semibold"
                      >
                        Submit
                      </button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </>
      )}
    </>
  );
};

export default AddNameCard;
