import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { IHRISStore, IPayloadAdd, IPayloadHRIS, IDataHRIS } from "@typed/HRIS/allHris";
import toast from "react-hot-toast";
import {
  getDetailStatusAPI,
  getStatusAPI,
  postStatusAPI,
  putActiveStatusAPI,
  putDeleteStatusAPI,
  putStatusAPI
} from "./StatusAPI";

export const getStatusRequest = createAsyncThunk("hris-status/getStatusRequest", async () => {
  const response = await getStatusAPI();
  return response;
});

export const getDetailStatusRequest = createAsyncThunk(
  "hris-status/getDetailStatusRequest",
  async (id: string) => {
    const response = await getDetailStatusAPI(id);
    return response;
  }
);

export const postStatusRequest = createAsyncThunk(
  "hris-status/postStatusRequest",
  async (Data: object) => {
    const response = await postStatusAPI(Data);
    return response;
  }
);

export const putStatusRequest = createAsyncThunk(
  "hris-status/putStatusRequest",
  async ({ id, Data }: IPayloadHRIS) => {
    const response = await putStatusAPI({ id, Data });
    return response;
  }
);

export const putActiveStatusRequest = createAsyncThunk(
  "hris-status/putActiveStatusRequest",
  async ({ id, isActive }: { id: string; isActive: boolean }) => {
    const response = await putActiveStatusAPI(id, isActive);
    return response;
  }
);

export const putDeleteStatusRequest = createAsyncThunk(
  "hris-status/putDeleteStatusRequest",
  async ({ id, isDelete }: { id: string; isDelete: boolean }) => {
    const response = await putDeleteStatusAPI(id, isDelete);
    return response;
  }
);

const initialState: IHRISStore = {
  data: [],
  isLoading: false,
  dataDetail: {} as IDataHRIS,
  lastCreateData: {} as IDataHRIS,
  deletedRedirect: false
};

const StatusSlice = createSlice({
  name: "hris-status",
  initialState: initialState,
  reducers: {
    resetStatus: (state: { lastCreateData: IDataHRIS }) => {
      state.lastCreateData = {} as IDataHRIS;
    },
    deletedStatus: (state: { deletedRedirect: boolean }) => {
      state.deletedRedirect = false;
    }
  },
  extraReducers(builder) {
    builder.addCase(getStatusRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getStatusRequest.fulfilled, (state, action) => {
      if (action.payload.code === 1000) {
        state.data = action.payload.data;
      }
      state.isLoading = false;
    });
    builder.addCase(getStatusRequest.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getDetailStatusRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getDetailStatusRequest.fulfilled, (state, action) => {
      if (action.payload.code === 1000) {
        state.dataDetail = action.payload.data;
      }
      state.isLoading = false;
    });
    builder.addCase(getDetailStatusRequest.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(postStatusRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(postStatusRequest.fulfilled, (state, action) => {
      if (action.payload.code === 1000) {
        state.lastCreateData = action.payload.data;
        toast.success("Success");
      }
      if (action.payload.code === 2004) {
        toast.error("Please enter an empty field");
      }
      state.isLoading = false;
    });
    builder.addCase(postStatusRequest.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(putStatusRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(putStatusRequest.fulfilled, (state, action) => {
      if (action.payload.code === 1000) {
        state.lastCreateData = action.payload.data;
        toast.success("Update Success");
      }
      state.isLoading = false;
    });
    builder.addCase(putStatusRequest.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(putActiveStatusRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(putActiveStatusRequest.fulfilled, (state, action) => {
      if (action.payload.code === 1000) {
        state.dataDetail.isActived = action.payload.data.isActived;
        const stats = state.dataDetail.isActived;
        toast.success(stats ? "Active Success" : "Inactive Success");
      }
    });
    builder.addCase(putActiveStatusRequest.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(putDeleteStatusRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(putDeleteStatusRequest.fulfilled, (state, action) => {
      if (action.payload.code === 1000) {
        toast.success("Delete Success");
        state.deletedRedirect = true;
      }
      state.isLoading = false;
    });
    builder.addCase(putDeleteStatusRequest.rejected, (state) => {
      state.isLoading = false;
    });
  }
});

export default StatusSlice.reducer;
export const { resetStatus, deletedStatus } = StatusSlice.actions;
