import React, { useState, useEffect } from "react";
import Modal from "@components/Modal";
import Input from "@components/Input.components";
import Button from "@components/Button.component";
import { useAppDispatch, useAppSelector } from "@stores/store";
import { postMembershipReq } from "@stores/dailyapp/MembershipSlice";
import swal from "sweetalert";

const AddMembership = () => {
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector((state) => state.dailyMembership);

  const [dataMembership, setDataMembership] = useState({
    level: 0,
    phoneNumber: ""
  });
  let openModal = true;
  const { level, phoneNumber } = dataMembership;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setDataMembership({ ...dataMembership, [name]: value });
  };

  const handleSubmitMembership = () => {
    let formatPhoneNumber;
    if (phoneNumber.slice(0, 1) === "0") {
      formatPhoneNumber = `62${phoneNumber.slice(1)}`;
    }
    const payload = {
      phoneNumber: formatPhoneNumber,
      level: Number(level)
    };

    dispatch(postMembershipReq(payload)).then((res) => {
      if (res.payload.code === 1000) {
        swal("success", "success", "success");
        setDataMembership({ level: 0, phoneNumber: "" });
      } else {
        swal("error", `${res.payload.code}: Something went wrong`, "error");
      }
    });
  };

  return (
    <>
      <Modal isOpen={openModal} closeModal={() => {}} title="Add Membership">
        <div className="bg-white h-[18rem] pt-16 flex flex-col gap-3">
          <Input
            required={`after:content-['*'] after:text-red-500`}
            name="phoneNumber"
            title="Phone"
            onChange={handleChange}
            type={"text"}
            placeholder={"Phone"}
            value={phoneNumber}
          />

          <Input
            required={`after:content-['*'] after:text-red-500`}
            name="level"
            title="Level"
            onChange={handleChange}
            type={"text"}
            placeholder={"Level"}
            value={level}
          />
          <div className="flex justify-end pt-8">
            <Button isLoading={isLoading} onClick={handleSubmitMembership}>
              Submit
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddMembership;
