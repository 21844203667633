import React, { useEffect } from "react";
import "react-pro-sidebar/dist/css/styles.css";
import { Link, Outlet } from "react-router-dom";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { PieChartOutlined } from "@ant-design/icons";
import { TbReport } from "react-icons/tb";

import { IoPeopleOutline } from "react-icons/io5";

import Forbidden from "@components/forbidden";
import { useAppSelector } from "@stores/store";
import useRole from "@hooks/useRole";

const index = () => {
  const auth = useAppSelector((state) => state.auth);

  const isForbidden = !useRole(auth.employeeData.roles, "dailycoin");
  return isForbidden ? (
    <Forbidden />
  ) : (
    <>
      <div className="flex mt-[70px] flex-row">
        <div className="w-[200px]">
          <ProSidebar width={200} className="fixed">
            <Menu iconShape="square">
              <MenuItem key="dailycoint" icon={<PieChartOutlined />}>
                <Link to="/daily-coin/">Dashboard</Link>
              </MenuItem>
              <MenuItem key="daily-report" icon={<TbReport />}>
                <Link to="/daily-coin/report-payment">Report</Link>
              </MenuItem>
              <MenuItem key="daily-user" icon={<IoPeopleOutline />}>
                <Link to="/daily-coin/users">User</Link>
              </MenuItem>
            </Menu>
          </ProSidebar>
        </div>
        <div className="flex-1 p-5 bg-slate-100 min-h-screen w-[50%]">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default index;
