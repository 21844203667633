import { dateToString, ratingAverage } from "@helpers/utils";
import { getComplainsRequest } from "@stores/dailyapp/ComplainSlice";
import * as R from "remeda";
import { useAppDispatch, useAppSelector } from "@stores/store";
import { Complain, Media } from "@typed/dailyapp/complain";
import { User } from "@typed/dailyapp/user";
import { Button, Card, Col, Popover, Row, Space, Statistic, Table, Tag } from "antd";
import React, { useEffect } from "react";
import { IoThumbsDownOutline, IoThumbsUpOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import CardDetail from "@components/CardDetail";

const columns = [
  {
    title: "Date",
    dataIndex: "createdAt",
    key: "date",
    render: (text: string, record: Complain) => <div>{dateToString(text)}</div>
  },
  {
    title: "User",
    dataIndex: "userId",
    key: "userId",
    render: (text: User, record: Complain) => <div>{text?.fullName || ""}</div>
  },
  {
    title: "Content",
    dataIndex: "content",
    key: "content"
  },
  {
    title: "Attachment",
    dataIndex: "media",
    key: "attachment",
    render: (text: Media[], record: Complain) => (
      <div>{text?.length ? text?.length + "files" : "-"}</div>
    )
  },
  {
    title: "Status",
    dataIndex: "reviewStatus",
    filters: [
      {
        text: "Review",
        value: "reviewed"
      },
      {
        text: "Unreview",
        value: "un-review"
      }
    ],
    // filterSearch: true,
    onFilter: (value: any, record: any) => record.reviewStatus === value,
    key: "status",
    render: (text: string, record: Complain) => (
      <div>
        {text === "reviewed" ? <Tag color="green">Done</Tag> : <Tag color="red">Unsolved</Tag>}
      </div>
    )
  },
  {
    title: "Based On",
    dataIndex: "orderId",
    key: "orderId",
    render: (text: any, record: Complain) => {
      const content = () => {
        return (
          <div className="w-40">
            <div className="flex flex-row">
              <div className="pr-3 font-bold">Spend</div>
              <div>IDR {text?.totalAmount}</div>
            </div>
          </div>
        );
      };
      return (
        <Popover content={content} title={text?.orderNumber} trigger="click">
          <Button>{text?.orderNumber}</Button>
        </Popover>
      );
    }
  },
  {
    title: "Action",
    key: "action",
    render: (text: string, record: Complain) => (
      <Link className="hover:text-white rounded py-2 px-5 bg-blue-500 text-white" to={record._id}>
        Detail
      </Link>
    )
  }
];

// const columns = [
//   {
//     name: "Date",
//     selector: (row: Complain) => row.createdAt,
//     id: "date",
//     cell: (row: Complain) => <div>{dateToString(row.createdAt)}</div>
//   },
//   {
//     name: "User",
//     selector: (row: Complain) => row.userId,
//     id: "userId",
//     cell: (row: Complain) => <div>{row.userId?.fullName || ""}</div>
//   },
//   {
//     name: "Content",
//     selector: (row: Complain) => row.content,
//     id: "content"
//   },
//   {
//     name: "Attachment",
//     selector: (row: any) => row.media,
//     id: "attachment",
//     cell: (row: any) => <div>{row.media?.length ? row.media?.length + "files" : "-"}</div>
//   },
//   {
//     name: "Status",
//     selector: (row: Complain) => row.reviewStatus,
//     id: "status",
//     cell: (row: Complain) => (
//       <div>
//         {row.reviewStatus === "reviewed" ? (
//           <Tag color="green">Done</Tag>
//         ) : (
//           <Tag color="red">Unsolved</Tag>
//         )}
//       </div>
//     )
//   },
//   {
//     name: "Based On",
//     selector: (row: any) => row.orderId,
//     id: "orderId",
//     cell: (row: any) => {
//       const content = () => {
//         return (
//           <div className="w-40">
//             <div className="flex flex-row">
//               <div className="pr-3 font-bold">Spend</div>
//               <div>IDR {row.orderId?.totalAmount}</div>
//             </div>
//           </div>
//         );
//       };
//       return (
//         <Popover content={content} title={row.orderId?.orderNumber} trigger="click">
//           <Button>{row.orderId?.orderNumber}</Button>
//         </Popover>
//       );
//     }
//   }
//   {
//     name: "Action",
//     id: "action",
//     selector: (row: Complain) => row._id,
//     cell: (record: Complain) => (
//       <Link className="hover:text-white rounded py-2 px-5 bg-blue-500 text-white" to={record._id}>
//         Detail
//       </Link>
//     )
//   }
// ];

const Complains = () => {
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector((state) => state.dailyComplain);
  const complains = useAppSelector((state) => {
    const d = state.dailyComplain.complains.map((x: Complain) => ({ ...x, key: x._id }));
    return d;
  });

  useEffect(() => {
    dispatch(getComplainsRequest());
  }, []);

  return (
    <div>
      <div className="mb-4">
        <Row gutter={16}>
          {isLoading ? (
            <>
              <Col span={4}>
                <CardDetail />
              </Col>

              <Col span={4}>
                <CardDetail />
              </Col>

              <Col span={4}>
                <CardDetail />
              </Col>
            </>
          ) : (
            <>
              <Col span={4}>
                <Card>
                  <Statistic
                    title="Total Complain"
                    value={complains.length}
                    prefix={complains.length < 1 ? <IoThumbsUpOutline /> : <IoThumbsDownOutline />}
                  />
                </Card>
              </Col>
              <Col span={4}>
                <Card>
                  <Statistic
                    title="Unsolved"
                    value={complains.filter((x: Complain) => x.reviewStatus === "un-review").length}
                  />
                </Card>
              </Col>
              <Col span={4}>
                <Card>
                  <Statistic
                    title="Solved"
                    value={complains.filter((x: Complain) => x.reviewStatus !== "un-review").length}
                  />
                </Card>
              </Col>
            </>
          )}
        </Row>
      </div>
      <Table dataSource={complains} columns={columns} />
      {/* <DataTable data={complains} columns={columns} pagination persistTableHead /> */}
    </div>
  );
};

export default Complains;
