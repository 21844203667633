import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "@stores/store";
import { IDataPersons } from "@typed/HRIS/Person";

import { Descriptions, Button } from "antd";
import { dateToString } from "@helpers/utils";

const SinglePerson = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const detailPerson = useAppSelector((state) =>
    state.hrisPerson.dataPersons.find((item) => item._id === id)
  );

  return (
    <>
      <Descriptions
        bordered
        title={`${detailPerson?.firstName} ${detailPerson?.lastName ?? ""}`}
        size="small"
        extra={
          <Button type="primary" onClick={() => navigate(`/dailyapp/news/edit/${id}`)}>
            Edit
          </Button>
        }
      >
        <Descriptions.Item label="Birth date" span={2}>
          {dateToString(detailPerson?.birthDate)}
        </Descriptions.Item>
        <Descriptions.Item label="ID" span={2}>
          {detailPerson?.citizenId}
        </Descriptions.Item>
        <Descriptions.Item label="Religion" span={2}>
          {detailPerson?.religion}
        </Descriptions.Item>
        <Descriptions.Item label="Gender" span={2}>
          {detailPerson?.gender}
        </Descriptions.Item>
        <Descriptions.Item label="Martial status" span={2}>
          {detailPerson?.martialStatus}
        </Descriptions.Item>
        <Descriptions.Item label="Address" span={2}>
          {detailPerson?.identityAddress}
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};

export default SinglePerson;
