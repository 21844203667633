import React from "react";
import { Card, Col } from "antd";

type TProps = {
  w?: number;
};

const Skeleton: React.FC<TProps> = ({ w }) => {
  return (
    <>
      <div
        className={`w-[${w}%]  flex flex-col items-center justify-center  h-[15vh] bg-white rounded`}
      >
        <div className="animate-pulse gap-1 flex flex-col items-center justify-center">
          <div className={`w-32 h-4 rounded-sm bg-gray-300`}></div>
          <div className="w-10  h-4 rounded bg-gray-300"></div>
        </div>
      </div>
    </>
  );
};

export default Skeleton;
