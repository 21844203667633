import API from "@constants/API";

const BASE_URL =
  "https://api.prod.daily.co.id/dashboard/dailycoin-summaries/v3/dailycoin-summaries";

const ACTIVITY_URL = "https://api.prod.daily.co.id/dashboard/dailycoin-payment/v1/transaction/?";
const OUTLET_TRX =
  "https://api.prod.daily.co.id/dashboard/dailycoin-payment/v1/outlet/transaction/?";

export const getDailyCoinsAPI = async () => {
  const response = await API.HTTP.get(BASE_URL);
  return response.data;
};

export const getCoinPaymenAPI = async (startDate: string, endDate: string) => {
  const response = await API.HTTP.get(`${ACTIVITY_URL}startdate=${startDate}&enddate=${endDate}`);
  return response.data;
};

export const getTrxOutletAPI = async (
  id: string | undefined,
  startDate: string,
  endDate: string
) => {
  const response = await API.HTTP.get(
    `${OUTLET_TRX}startdate=${startDate}&enddate=${endDate}&outletId=${id}`
  );
  return response.data;
};
