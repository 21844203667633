import React from "react";
import { Tag } from "antd";
import { Link } from "react-router-dom";

interface IProps {
  name: string;
  description: string;
  valueDesc?: string;
  value?: string;
  linkTo: string;
  active: boolean;
  handleActive: () => void;
  handleDelete?: () => void;
}

const CardDetail: React.FC<IProps> = ({
  handleActive,
  handleDelete,
  active,
  linkTo,
  name,
  valueDesc,
  description,
  value
}) => {
  return (
    <>
      <div className="flex ">
        <div className="w-[40%] p-3 min-h-[10rem] bg-white rounded hover:shadow">
          <div className="flex gap-3">
            <div className="w-28">
              <h1 className="p-0 text-[1rem] text-right">{name}:</h1>
            </div>
            <p className="font-bold text-[1rem] p-0">{value}</p>
          </div>

          <div className="flex gap-3">
            <div className="w-28">
              <h1 className="p-0 text-[1rem] text-right">{description}:</h1>
            </div>
            <div className="w-[65%] min-h-auto">
              <p className=" text-[1rem] p-0">{valueDesc}</p>
            </div>
          </div>
          <div className="flex my-3 gap-3">
            <Link
              className="text-left ml-3 mt-4 hover:bg-blue-600 hover:text-white rounded py-2 px-5 bg-blue-500 text-white"
              to={linkTo}
            >
              Edit
            </Link>
            <button
              onClick={handleActive}
              className="text-left ml-3 mt-4 hover:text-white rounded py-2 px-5 bg-yellow-400 hover:bg-yellow-500 text-white"
            >
              {active ? "Inactive" : "Active"}
            </button>

            <button
              onClick={handleDelete}
              className="text-left ml-3 mt-4 hover:text-white rounded py-2 px-5 bg-red-500 hover:bg-red-600 text-white"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(CardDetail);
