import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import "@styles/tailwind.css";
import "@styles/global.less";
import store, { persistor } from "@stores/store";
import { PersistGate } from "redux-persist/integration/react";

import "antd/dist/antd.variable.min.css";
// import "antd/dist/antd.css";    // URL is not supported

import { suppressWarning } from "@helpers/supressWarning";
import setup from "@helpers/axiosInstance";

setup.setup(store);

suppressWarning();
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
