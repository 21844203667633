import React, { useEffect } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import { IDataHRIS } from "@typed/HRIS/allHris";

export interface IValue {
  name: string;
  description: string;
}

interface IProps {
  initialValue: IValue;
  onSubmit: (values: any) => void;
  id?: string;
  title: string;
  isLoading: boolean;
  data: IDataHRIS[];
}

const InputFormik: React.FC<IProps> = ({ data, isLoading, title, id, onSubmit, initialValue }) => {
  const renderError = (error: any) => <p className="help is-danger">{error}</p>;

  return (
    <>
      <div className="flex items-center justify-center">
        <div className="bg-white w-[45%] shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <Formik onSubmit={(values) => onSubmit(values)} initialValues={initialValue}>
            {({ values, errors, setFieldValue, handleChange, handleSubmit }) => {
              useEffect(() => {
                if (id) {
                  {
                    data.filter(
                      (item: any) =>
                        item._id === id &&
                        (setFieldValue("name", item.name),
                        setFieldValue("description", item.description))
                    );
                  }
                }
              }, [id]);
              return (
                <Form className="flex flex-col gap-3" onSubmit={handleSubmit}>
                  <h1 className="font-bold text-center text-[1.1rem]">
                    {id ? "Update" : "Create"} {title}{" "}
                  </h1>
                  <div className="">
                    <label className="block text-gray-700 text-sm font-bold">Name</label>
                    <input
                      onChange={handleChange}
                      value={values.name ?? ""}
                      placeholder="Name"
                      name="name"
                      className="border border-slate-400 w-full h-8 rounded px-3"
                    />
                    {/* <ErrorMessage name="name" render={errors} /> */}
                  </div>
                  <div>
                    <label className="block text-gray-700 text-sm font-bold">Description</label>
                    <input
                      onChange={handleChange}
                      value={values.description ?? ""}
                      placeholder="Description"
                      name="description"
                      className="border border-slate-400 w-full h-8 rounded px-3"
                    />
                  </div>

                  <input
                    type="Submit"
                    className={
                      isLoading
                        ? "cursor-not-allowed flex gap-2 items-center justify-center bg-blue-500 active:bg-blue-400 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        : "flex gap-2 items-center justify-center bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    }
                  />
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default InputFormik;
