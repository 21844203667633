import React, { useEffect, useState } from "react";
import { Table, Popover } from "antd";
import { delOjolProductRequest, getOjolProductsRequest } from "@stores/dailyojol/ProductSlice";
import { useAppDispatch, useAppSelector } from "@stores/store";
import { Link } from "react-router-dom";
import Loading from "@components/loading";
import { FiTrash2 } from "react-icons/fi";
import { AiOutlineSearch } from "react-icons/ai";
import { IDataDetailProduct } from "@typed/dailojol/Product";

import { HiOutlinePencilAlt } from "react-icons/hi";
import InputSearch from "@components/inputSearch";

const Products = () => {
  const dispatch = useAppDispatch();

  const column = [
    {
      key: "name",
      dataIndex: "name",
      title: "Name"
    },
    {
      key: "image",
      dataIndex: "image",
      title: "Image",
      render: (image: string) => <img src={image} className="w-14 h-14 object-cover rounded" />
    },
    {
      key: "price",
      dataIndex: "price",
      title: "Price",
      sorter: {
        compare: (dataA: IDataDetailProduct, dataB: IDataDetailProduct) =>
          dataA.price - dataB.price,
        multiple: 2
      }
    },
    {
      key: "action",
      title: "Action",
      dataIndex: "_id",
      render: (_id: string) => {
        return (
          <div className="flex items-center gap-2">
            <Link
              className="bg-gray-500 hover:text-white text-white p-2 px-4 rounded hover:bg-gray-600"
              to={`/dailyojol/product/${_id}`}
            >
              Detail
            </Link>
            <Popover content="Delete">
              <button
                onClick={() => {
                  dispatch(delOjolProductRequest(_id));
                }}
                className="bg-red-500 text-white py-3 px-4 rounded hover:bg-red-600"
              >
                <FiTrash2 className="text-[16px]" />
              </button>
            </Popover>

            <Popover content="Edit">
              <Link
                to={`/dailyojol/product/edit-product/${_id}`}
                className="bg-blue-500 text-white py-3 px-4 rounded hover:bg-blue-600"
              >
                <HiOutlinePencilAlt className="text-[16px]" />
              </Link>
            </Popover>
          </div>
        );
      }
    }
  ];
  const { loading } = useAppSelector((state) => state.dailyojolProduct);
  const Product = useAppSelector((state) =>
    state.dailyojolProduct.data.map((x) => ({ ...x, key: x._id }))
  );
  // const Products = data.map((x) => ({ ...x, key: x._id }));
  const [okProduct, setOkProducts] = useState(Product);
  const [searchByName, setSearchByName] = useState<string>("");

  useEffect(() => {
    let Data = Product.filter((item) => {
      return item.name.toLowerCase().includes(searchByName.toLowerCase());
    });
    if (searchByName !== "") {
      setOkProducts(Data);
    } else if (searchByName === "") {
      setOkProducts(Product);
    }
  }, [searchByName, loading, dispatch]);

  useEffect(() => {
    dispatch(getOjolProductsRequest());
  }, [dispatch]);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="flex items-center gap-4">
            <InputSearch
              value={searchByName}
              placeholder="Search By Name of Product"
              handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSearchByName(e.target.value)
              }
            />
            <div className="mb-4">
              <Link
                to="/dailyojol/product/create-product"
                className="py-2 w-full px-2 rounded hover:bg-green-600 hover:text-white bg-green-500 text-white font-semibold"
              >
                Create Product
              </Link>
            </div>
          </div>
          <Table columns={column} dataSource={okProduct} />
        </>
      )}
    </>
  );
};

export default Products;
