import React from "react";
import { IoInformationCircleSharp } from "react-icons/io5";
import { IDetailEmployeID } from "@typed/HRIS/Employe";
import Loading from "@components/loading";
import { Spin } from "antd";
import { Toaster } from "react-hot-toast";

interface IProps {
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  visible: boolean;
  handleOk: () => void;
  isLoading: boolean;
  loadingConfirm: boolean; // loading modal confirm if user click OK
  detailEmploye: IDetailEmployeID;
  details: React.MutableRefObject<{
    employeID: string;
    bankName: string;
  }>;
}

const ModalAddBank: React.FC<IProps> = ({
  detailEmploye,
  isLoading,
  handleOk,
  setVisible,
  visible,
  details,
  loadingConfirm
}) => {
  const handleCancel = () => {
    setVisible(false);
  };
  return (
    <>
      <Toaster />
      {loadingConfirm ? (
        <Loading />
      ) : (
        <>
          {visible && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm">
              <div className="w-[42%] h-[16rem] bg-white rounded ">
                <div className="flex p-3 items-center gap-1 border-b-[1px] border-slate-300">
                  <IoInformationCircleSharp className="text-[1.2rem]" />
                  <h1 className="font-medium m-0 p-0 text-[1.2rem]">
                    Are you sure want to create bank account with this user ?
                  </h1>
                </div>

                {/* === CONTENT ===  */}
                {isLoading ? (
                  <Spin className="block m-auto w-full h-screen" />
                ) : (
                  <>
                    <div className="p-3">
                      <div className="flex gap-1">
                        <h2>Name :</h2>
                        <div className="flex gap-1 uppercase">
                          <p className="font-medium ">{detailEmploye.personId?.firstName}</p>
                          <p className="font-medium">{detailEmploye.personId?.lastName}</p>
                        </div>
                      </div>

                      <div className="flex gap-1">
                        <h2>Employe ID :</h2>
                        <div className="flex gap-1 uppercase">
                          <p className="font-medium">{details.current.employeID}</p>
                        </div>
                      </div>

                      <div className="flex gap-1 ">
                        <h2>Bank Name :</h2>
                        <div className="flex gap-1 uppercase">
                          <p className="font-medium">{details.current.bankName}</p>
                        </div>
                      </div>
                    </div>
                    <div className="flex mt-3 px-3 border-t-[1px] border-slate-300 items-center justify-between">
                      <button
                        onClick={handleCancel}
                        key="back"
                        className="mt-2 py-1 px-6 bg-red-500 text-white hover:bg-red-400"
                      >
                        Cancel
                      </button>
                      <button
                        onClick={handleOk}
                        key="submit"
                        className="mt-2 py-1 px-6 bg-green-500 text-white hover:bg-green-400"
                      >
                        Ok
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default React.memo(ModalAddBank);
