import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useAppSelector, useAppDispatch } from "@stores/store";
import { detailNewsRequest } from "@stores/dailyapp/NewsSlice";

import { Descriptions, Button } from "antd";
import { dateToString } from "@helpers/utils";

import rehypeRaw from "rehype-raw";
import ReactMarkdown from "react-markdown";

const SingleNews: React.FC = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const detailNews = useAppSelector((state) =>
    state.dailyNews.data.find((item) => item._id === id && item)
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      dispatch(detailNewsRequest(id));
    }
  }, []);
  return (
    <>
      <Descriptions
        bordered
        title={detailNews?.title}
        size="small"
        extra={
          <Button type="primary" onClick={() => navigate(`/dailyapp/news/edit/${id}`)}>
            Edit
          </Button>
        }
      >
        <Descriptions.Item label="Date" span={2}>
          {dateToString(detailNews?.createdAt)}
        </Descriptions.Item>
        <Descriptions.Item label="Description" span={2}>
          {detailNews?.description}
        </Descriptions.Item>
        <Descriptions.Item label="Content" span={2}>
          <ReactMarkdown rehypePlugins={[rehypeRaw]}>{detailNews?.content ?? ""}</ReactMarkdown>
        </Descriptions.Item>
      </Descriptions>

      <div className="mt-4">
        <img src={detailNews?.image.mediaUrl} className="w-36 h-36 rounded object-cover" />
      </div>
    </>
  );
};

export default SingleNews;
