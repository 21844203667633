import React from "react";
import useRole from "@hooks/useRole";
import { useAppSelector } from "@stores/store";
import Forbidden from "@components/forbidden";
import { Layout } from "antd";
import { Link, Outlet } from "react-router-dom";
import {
  IoCartOutline,
  IoSettingsOutline,
  IoPeopleOutline,
  IoStorefrontOutline
} from "react-icons/io5";
import { BiMap } from "react-icons/bi";
import { GiPayMoney } from "react-icons/gi";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
const { Sider } = Layout;

const Dailyojol = () => {
  const auth = useAppSelector((state) => state.auth);
  const isForbidden = !useRole(auth.employeeData.roles, "dailyojol");
  const { Header, Content, Footer, Sider } = Layout;

  // return isForbidden ? <Forbidden /> : <div>Dailyojol</div>;
  return (
    <>
      {isForbidden ? (
        <Forbidden />
      ) : (
        <div className="flex mt-[70px] flex-row ">
          <div className={"w-[200px]"}>
            <ProSidebar width={200} className="fixed">
              <Menu iconShape="square">
                <MenuItem key="dailyojol-user" icon={<IoPeopleOutline />}>
                  <Link to="/dailyojol/users">Users</Link>
                </MenuItem>
                <SubMenu title="Outlet" icon={<IoStorefrontOutline />}>
                  <MenuItem key="dailyojol-outlet">
                    <Link to="/dailyojol/outlet">List </Link>
                  </MenuItem>
                  <MenuItem key="dailyojol-outlet-account">
                    <Link to="/dailyojol/outlet-account"> Account</Link>
                  </MenuItem>
                </SubMenu>
                <MenuItem key="dailyojol-product" icon={<IoCartOutline />}>
                  <Link to="/dailyojol/product">Product</Link>
                </MenuItem>
                <MenuItem key="dailyojol-region" icon={<BiMap />}>
                  <Link to="/dailyojol/region">Region</Link>
                </MenuItem>
                <MenuItem key="dailyojol-code" icon={<IoSettingsOutline />}>
                  <Link to="/dailyojol/code">Code</Link>
                </MenuItem>
                <MenuItem key="dailyojol-redem" icon={<GiPayMoney />}>
                  <Link to="/dailyojol/redem">Redem</Link>
                </MenuItem>
              </Menu>
            </ProSidebar>
          </div>
          <div className="flex-1 p-5 bg-slate-100 min-h-screen w-[50%]">
            <Outlet />
          </div>
        </div>
      )}
    </>
  );
};

export default Dailyojol;
