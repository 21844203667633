import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { IHRISStore, IDataHRIS, IPayloadHRIS } from "@typed/HRIS/allHris";
import {
  getDetailLevelAPI,
  getLevelAPI,
  postLevelAPI,
  putActiveLevelAPI,
  putDeletedLevelAPI,
  putLevelAPI
} from "./LevelAPI";
import toast from "react-hot-toast";

export const getLevelRequest = createAsyncThunk("hris-level/getLevelRequest", async () => {
  const response = await getLevelAPI();
  return response;
});

export const getDetailLevelRequest = createAsyncThunk(
  "hris-level/getDetailLevelRequest",
  async (id: string) => {
    const response = await getDetailLevelAPI(id);
    return response;
  }
);

export const postLevelRequest = createAsyncThunk(
  "hris-level/postLevelRequest",
  async (Data: object) => {
    const response = await postLevelAPI(Data);
    return response;
  }
);

export const putLevelRequest = createAsyncThunk(
  "hris-level/putLevelRequest",
  async ({ id, Data }: IPayloadHRIS) => {
    const response = await putLevelAPI({ id, Data });
    return response;
  }
);

export const putActiveLevelRequest = createAsyncThunk(
  "hris-level/putActiveLevelRequest",
  async ({ id, isActive }: { id: string; isActive: boolean }) => {
    const response = await putActiveLevelAPI(id, isActive);
    return response;
  }
);

export const putDeletedLevelRequest = createAsyncThunk(
  "hris-level/putDeletedRequest",
  async ({ id, isDelete }: { id: string; isDelete: boolean }) => {
    const respose = await putDeletedLevelAPI(id, isDelete);
    return respose;
  }
);

const initialState: IHRISStore = {
  data: [],
  isLoading: false,
  dataDetail: {} as IDataHRIS,
  lastCreateData: {} as IDataHRIS,
  deletedRedirect: false
};

const LevelSlice = createSlice({
  name: "level-position",
  initialState: initialState,
  reducers: {
    resetLevel: (state: { lastCreateData: IDataHRIS }) => {
      state.lastCreateData = {} as IDataHRIS;
    },
    deletedLevel: (state: { deletedRedirect: boolean }) => {
      state.deletedRedirect = false;
    }
  },
  extraReducers(builder) {
    builder.addCase(getLevelRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getLevelRequest.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.code === 1000) {
        state.data = action.payload.data;
      }
    });
    builder.addCase(getLevelRequest.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getDetailLevelRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getDetailLevelRequest.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.code === 1000) {
        state.dataDetail = action.payload.data;
      }
    });
    builder.addCase(getDetailLevelRequest.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(postLevelRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(postLevelRequest.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.code === 1000) {
        state.lastCreateData = action.payload.data;
        toast.success("Success");
      }
      if (action.payload.code === 2004) {
        toast.error("Please enter an empty field");
      }
    });
    builder.addCase(postLevelRequest.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(putLevelRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(putLevelRequest.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.code === 1000) {
        state.lastCreateData = action.payload.data;
        toast.success("Update Success");
      }
    });
    builder.addCase(putLevelRequest.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(putActiveLevelRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(putActiveLevelRequest.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.code === 1000) {
        state.data = state.data.map((item: IDataHRIS) =>
          action.meta.arg.id === item._id
            ? {
                ...item,
                isActived: action.payload.data.isActived
              }
            : item
        );
        state.dataDetail.isActived = action.payload.data.isActived;
        const stats = state.dataDetail.isActived;
        // const forNotif = stats ? "Active Success" : "Inactive Success";
        if (stats) {
          toast.success("Active Success");
        } else {
          toast.success("Inactive Success");
        }
        // toast.success(forNotif);
        // toast.success(stats ? "Active Success" : "Inactive Success");
      }
    });
    builder.addCase(putActiveLevelRequest.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(putDeletedLevelRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(putDeletedLevelRequest.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.code === 1000) {
        toast.success("Delete Success");
        state.deletedRedirect = true;
      }
    });
    builder.addCase(putDeletedLevelRequest.rejected, (state) => {
      state.isLoading = false;
    });
  }
});

export default LevelSlice.reducer;
export const { deletedLevel, resetLevel } = LevelSlice.actions;
