import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RoleData } from "typed/global";

function useRole(roles: [RoleData], roleName: string) {
  const [permit, setPermit] = useState<boolean>(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (roles && roles.some((role) => role.apps.toLowerCase() === roleName.toLowerCase())) {
      setPermit(true);
    } else if (roles && roles.some((role) => role.name.toLowerCase() === "super-dailycoin")) {
      setPermit(true);
    }
  }, [roles, roleName, navigate]);
  return permit;
}

export default useRole;
