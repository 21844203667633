import React from "react";
import { Descriptions } from "antd";
import { useAppSelector } from "@stores/store";
import { useParams } from "react-router-dom";
import { dateToString, nf } from "@helpers/utils";
import { Button, Image, Space } from "antd";

const DetailComplain = () => {
  const { id } = useParams();
  const reportComplain = useAppSelector((state) =>
    state.CEReport.reportComplain.find((item) => item._id?.id === id)
  );

  console.log(reportComplain);
  return (
    <>
      <Descriptions bordered title={reportComplain.custName} size="small">
        <Descriptions.Item label="Order Date">
          {reportComplain?.complainDate ? (
            dateToString(reportComplain.orderDate)
          ) : (
            <h1 className="text-red-500">Empty</h1>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Scan Date">
          {reportComplain?.scanDate ? (
            dateToString(reportComplain.scanDate)
          ) : (
            <h1 className="text-red-500">Empty</h1>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Complain Date">
          {reportComplain?.complainDate ? (
            dateToString(reportComplain.complainDate)
          ) : (
            <h1 className="text-red-500">Empty</h1>
          )}
        </Descriptions.Item>

        <Descriptions.Item label="Phone">
          <a target={"_blank"} rel="noreferrer" href={`https://wa.me/${reportComplain?.custPhone}`}>
            +{reportComplain?.custPhone}
          </a>
        </Descriptions.Item>
        <Descriptions.Item label="Bill Number">
          {reportComplain?.billNum ? (
            <h1 className="text-bold p-0 m-0">{reportComplain.billNum}</h1>
          ) : (
            <h1 className="text-red-500">Empty</h1>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Menu">
          <ul>
            {reportComplain?.menus[0].map((item: string, idx: number) => (
              <li key={idx}>{item}</li>
            )) || <h1 className="text-red-500">Empty</h1>}
          </ul>
        </Descriptions.Item>
        <Descriptions.Item label="Outlet">
          {reportComplain?.outlet || <h1 className="text-red-500">Empty</h1>}
        </Descriptions.Item>

        <Descriptions.Item label="Compesation Product">
          {reportComplain?.compesationProduct || <h1 className="text-red-500">Empty</h1>}
        </Descriptions.Item>

        <Descriptions.Item label="Compesation Coin">
          <h1 className="font-bold m-0"> {reportComplain?.poinCompesation}</h1>
        </Descriptions.Item>
        <Descriptions.Item label="Content">
          {reportComplain?.content ? (
            <p className=" m-0">{reportComplain?.content}</p>
          ) : (
            <h1 className="text-red-500">Empty</h1>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Review Status">
          {reportComplain?.reviewStatus ? (
            <h1
              className={`font-bold uppercase ${
                reportComplain?.reviewStatus === "reviewed" ? "text-green-500" : "text-red-500"
              }`}
            >
              {reportComplain?.reviewStatus}
            </h1>
          ) : undefined}
        </Descriptions.Item>
        <Descriptions.Item label="Review Message">
          <h1
            className={`font-bold p-0 uppercase ${
              reportComplain?.reviewMessage === "done" ? "text-green-500" : "text-yellow-500"
            }`}
          >
            {reportComplain?.reviewMessage}
          </h1>
        </Descriptions.Item>
        <Descriptions.Item label="Total">Rp.{nf.format(reportComplain.total)}</Descriptions.Item>
      </Descriptions>

      <div className="mt-5">
        <h1 className="text-xl font-bold p-0">Attachment</h1>
        <div className="flex gap-5">
          {reportComplain.image.map((item: string, idx: number) => (
            <Space key={idx} size={12}>
              <Image
                width={300}
                className="rounded-md"
                height={300}
                src={item}
                placeholder={<Image className="rounded-md" preview={true} src={item} width={300} />}
              />
            </Space>
          ))}
        </div>
      </div>
    </>
  );
};

export default DetailComplain;
