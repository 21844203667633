import React from "react";
import LogoDailybox from "../assets/images/dailybox.jpeg";

const PageNotFound = () => {
  return (
    <div className="w-full bg-slate-100 h-screen flex items-center flex-col justify-center gap-3">
      <div>
        <img src={LogoDailybox} alt="logo" className="rounded-full" />
      </div>
      <div>
        <h1 className="font-bold text-[2rem]">Page Not Found!</h1>
      </div>
    </div>
  );
};

export default PageNotFound;
