import React, { useRef, useState } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import ModalAddBank from "@components/ModalAddBank";
import { getDetailEmployeAPI } from "@stores/HRIS/Employe/EmployeAPI";
import { IDetailEmployeID } from "@typed/HRIS/Employe";
import { postBankAccountAPI } from "@stores/HRIS/Bank/BankAPI";
import toast, { Toaster } from "react-hot-toast";

interface IAddBankAcc {
  // initialState add bank account
  employeeId: string;
  accountNumber: string;
  nameHolder: string;
  bankName: string;
}

const validationSchema = Yup.object({
  nameHolder: Yup.string().required(),
  employeeId: Yup.string().required(),
  accountNumber: Yup.string().required(),
  bankName: Yup.string().required()
});

const AddBankAccount = () => {
  const [visible, setVisible] = useState<boolean>(false);
  const details = useRef({
    employeID: "",
    bankName: ""
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadingConfirm, setLoadingConfirm] = useState<boolean>(false); // loading confirm modal send to API
  const [detailEmploye, setDetailEmploye] = useState<IDetailEmployeID>({} as IDetailEmployeID);

  //if input field is empty return
  const renderError = (message: string) => (
    <p className="text-red-500 is-danger capitalize">{message}</p>
  );
  return (
    <>
      <Formik
        initialValues={{} as IAddBankAcc}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          const { nameHolder, employeeId, accountNumber, bankName } = values;
          details.current = { ...details, employeID: employeeId, bankName: bankName };
          if (details.current.employeID) {
            let id = details.current.employeID;
            getDetailEmployeAPI({ id, setVisible, setIsLoading, setDetailEmploye });
          }
          setVisible(true);
        }}
      >
        {({ values, handleChange, handleSubmit, setFieldValue }) => {
          const handleOk = async () => {
            const { nameHolder, employeeId, accountNumber, bankName } = values;

            const data = {
              nameHolder,
              employeeId,
              accountNumber,
              bankName
            };
            const code: any = await postBankAccountAPI(data, setLoadingConfirm);
            if (code.code === 2009) {
              toast.error(code.error);
            } else if (code.code === 1000) {
              setVisible(false);
              toast.success("Success Create Bank Account");
            }
          };

          return (
            <>
              <Toaster />
              <ModalAddBank
                detailEmploye={detailEmploye}
                details={details}
                isLoading={isLoading}
                loadingConfirm={loadingConfirm}
                handleOk={handleOk}
                visible={visible}
                setVisible={setVisible}
                // setId={setId}
              />
              <div className="flex justify-center">
                <Form className="bg-white w-[50%] p-3 gap-3 rounded h-[63vh] flex flex-col">
                  <h1 className="font-medium text-[1.1rem] text-center my-3">
                    Create Bank Account
                  </h1>

                  <div>
                    <label className="after:content-['*'] after:text-red-500 font-bold">Name</label>
                    <input
                      placeholder="Name"
                      name="nameHolder"
                      onChange={handleChange}
                      className="border-gray px-2 rounded focus:outline-none border-[1px] h-9 w-full"
                    />
                    {/* <ErrorMessage name="nameHolder" render={renderError} /> */}
                  </div>

                  <div>
                    <label className="after:content-['*'] after:text-red-500 font-bold">
                      Employe ID
                    </label>
                    <input
                      placeholder="Employe ID"
                      name="employeeId"
                      onChange={handleChange}
                      className="border-gray px-2 rounded focus:outline-none border-[1px] h-9 w-full"
                    />
                    {/* <ErrorMessage name="employeeId" render={renderError} /> */}
                  </div>

                  <div>
                    <label className="after:content-['*'] after:text-red-500 font-bold">
                      Bank Name
                    </label>
                    <input
                      placeholder="Bank Name"
                      name="bankName"
                      onChange={handleChange}
                      value={values.bankName ?? ""}
                      className="border-gray px-2 rounded focus:outline-none border-[1px] h-9 w-full"
                    />
                    {/* <ErrorMessage name="bankName" render={renderError} /> */}
                  </div>

                  <div>
                    <label className="after:content-['*'] after:text-red-500 font-bold">
                      Account Number
                    </label>
                    <input
                      placeholder="Account Number"
                      value={values.accountNumber ?? ""}
                      name="accountNumber"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const validNumber = /^[0-9\b]+$/;
                        if (e.target.value === "" || validNumber.test(e.target.value)) {
                          setFieldValue("accountNumber", e.target.value);
                        }
                      }}
                      className="border-gray px-2 rounded focus:outline-none border-[1px] h-9 w-full"
                    />
                    {/* <ErrorMessage name="accountNumber" render={renderError} /> */}
                  </div>

                  <button
                    type="submit"
                    className=" w-[130px] my-3  items-center hover:bg-green-700 justify-center bg-green-500 text-white font-semibold py-2 px-4 rounded "
                  >
                    Submit
                  </button>
                </Form>
              </div>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default AddBankAccount;
