import React, { useState, useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "@stores/store";
import moment from "moment";
import { DatePickerProps, DatePicker } from "antd";
import Loading from "@components/loading";
import { CSVFinance, getFinanceRequest } from "@stores/dailycoin/ReportingSlice";
import { Link } from "react-router-dom";
import MyTable from "@components/Table/MyTable";
import ExportCSV from "@components/ExportCSV";

const SummaryTransaction: React.FC = () => {
  const dispatch = useAppDispatch();

  const { isLoading, FinanceCSV } = useAppSelector((state) => state.dailyReport);
  const summaryTrx = useAppSelector((state) =>
    state.dailyReport.reportFinance?.map((item) => ({ ...item, key: item._id }))
  );

  const startDate = useRef<any>(moment().startOf("month"));
  const endDate = useRef<any>(moment().endOf("month"));

  useEffect(() => {
    dispatch(
      getFinanceRequest({
        startDate: moment(startDate.current).format("YYYY-MM-DD"),
        endDate: moment(endDate.current).format("YYYY-MM-DD")
      })
    );
    dispatch(CSVFinance());
  }, []);

  const filterOutletTrx = () => {
    dispatch(
      getFinanceRequest({
        startDate: startDate.current,
        endDate: endDate.current
      })
    );
    dispatch(CSVFinance());
  };

  useEffect(() => {
    dispatch(CSVFinance());
  }, [isLoading]);

  const handleStartDate: DatePickerProps["onChange"] = (date: any, dateString: any) => {
    startDate.current = dateString;
  };

  const handleEndDate: DatePickerProps["onChange"] = (date: any, dateString: any) => {
    endDate.current = dateString;
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <div className="flex justify-between items-center">
        <div className=" flex items-center gap-4">
          <h1 className="m-0">Filter </h1>

          <DatePicker
            className="h-9"
            name="startDate"
            onChange={handleStartDate}
            placeholder="Start date"
          />

          <DatePicker
            className="h-9"
            name="endDate"
            onChange={handleEndDate}
            placeholder="End date"
          />

          <button
            onClick={filterOutletTrx}
            className="flex text-medium items-center gap-1 text-center outline-none h-9 w-[10rem] rounded bg-blue-500 hover:bg-blue-400 text-white justify-center"
          >
            Submit
          </button>
        </div>
        <ExportCSV
          csvData={FinanceCSV}
          fileName={`summary-transaction-${startDate.current}-${endDate.current}`}
        />
      </div>

      <div className="mt-5">
        <MyTable
          dataSource={summaryTrx}
          fileName="summaries-transactionfileName"
          select={[
            { label: "Date", value: "_id" },
            { label: "Number of Transaction - Topup", value: "transactionTopup" },
            { label: "Number of Transaction - Scanned Bill", value: "transactionBill" },
            { label: "Number of Transaction Dailycoin Payment", value: "paymentTransaction" },
            { label: "Dailycoin Topup ", value: "dailycoinTopup" },
            { label: "Dailycoin Cashback - Topup", value: "cashbackTopup" },
            { label: "Dailycoin Cashback - Scanned Bill", value: "dailycoinCashbackBill" },
            { label: "Total Dailycoin Created", value: "dailycoinTopupCreated" },
            { label: "Dailycoin Payment ", value: "totalDailycoinPayment" },
            { label: "Dailycoin Payment - Dailyclick ", value: "totalDailycoinDailyclickPayment" }
          ]}
          action={(row: any) => (
            <Link
              className="bg-blue-500 hover:bg-blue-400 font-medium text-white hover:text-white p-2 rounded-md"
              to={`/daily-coin/review/${row._id}`}
            >
              Review
            </Link>
          )}
        />
      </div>
    </>
  );
};

export default SummaryTransaction;
