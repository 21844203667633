import React from "react";
import { Tabs } from "antd";
import UserAcquisition from "../UserAcquisition";
import UserReport from "./UserReport";
import CashbackReport from "./CashbackReport";
import CashbackOriginReport from "./CashbackOriginReport";

const DailyCashier = () => {
  return (
    <>
      <Tabs
        defaultActiveKey="1"
        items={[
          { label: "User Acquisition", key: "1", children: <UserAcquisition /> },
          { label: "User Report", key: "2", children: <UserReport /> },
          { label: "Cashback Report", key: "3", children: <CashbackReport /> },
          { label: "Cashback Origin Report", key: "4", children: <CashbackOriginReport /> }
        ]}
      />
    </>
  );
};

export default DailyCashier;
