import { getDetailOutletRequest } from "@stores/dailyojol/OutlietAccountSlice";
import { useAppDispatch } from "@stores/store";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

const DetailOutletAccount = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (id) {
      dispatch(getDetailOutletRequest(id));
    }
  }, [id]);
  return <div>DetailOutletAccount</div>;
};

export default DetailOutletAccount;
