import React, { useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "@stores/store";
import DataTable from "react-data-table-component";
import { DatePicker, DatePickerProps } from "antd";
import moment from "moment";
import SelectComponents from "@components/Select";
import { getTopupProviderDetailReq } from "@stores/dailycoin/ReportingSlice";
import { useNavigate } from "react-router-dom";

const columns = [
  {
    name: "Date",
    selector: (row: any) => row.date,
    id: "date"
  },
  {
    name: "Provider",
    selector: (row: any) => row.provider,
    id: "provider"
  },
  {
    name: "Coin Cashback",
    selector: (row: any) => row.coinCashback,
    id: "coinCashback"
  },
  {
    name: "Coin Created",
    selector: (row: any) => row.coinCreated,
    id: "coinCreated"
  },
  {
    name: "Coin Purchase",
    selector: (row: any) => row.coinPurchase,
    id: "coinPurchase"
  },
  {
    name: "Total Payment",
    selector: (row: any) => row.totalPayment,
    id: "totalPayment"
  },
  {
    name: "Action",
    selector: (row: any) => row.totalPayment,
    id: "date",
    cell: (row: any) => {
      const dispatch = useAppDispatch();
      const navigate = useNavigate();
      const handleByProvider = () => {
        dispatch(getTopupProviderDetailReq({ date: row.date, provider: row.provider }));
        // setTimeout(navigate(`/daily-coin/report-topup/${row.Date}`), 5000);
        setTimeout(() => {
          navigate(`/daily-coin/report-topup/${row.date}`);
        }, 500);
      };
      return (
        <button
          onClick={handleByProvider}
          className="flex text-medium items-center gap-1 text-center outline-none h-9 w-[10rem] rounded bg-blue-500 hover:bg-blue-400 text-white justify-center"
        >
          Review
        </button>
      );
    }
  }
];

const option = [
  {
    value: "xendit",
    label: "xendit"
  },
  {
    value: "midtrans",
    label: "midtrans"
  }
];

const TopupProvider = () => {
  const dispatch = useAppDispatch();
  const { isLoading, topupProvider } = useAppSelector((state) => state.dailyReport);
  const date = useRef(moment().startOf("month").format("YYYY-MM-DD"));
  const [getProvider, setGetProvider] = useState("");

  //   const handleStartDate: DatePickerProps["onChange"] = (date: any, dateString: any) => {
  //     date.current = dateString;
  //   };

  //   const handleGetProvider = (value?: any, actionMeta?: any) => {
  //     setGetProvider(value.value);
  //   };

  //   const handleByProvider = () => {
  //     dispatch(getTopupProviderDetailReq({ date: date.current, provider: getProvider }));
  //   };
  return (
    <div>
      {/* <div className="flex gap-3 items-center mb-3">
        <DatePicker className="h-9" name="date" onChange={handleStartDate} placeholder="Date" />

        <div className="w-40">
          <SelectComponents
            defaultValue={getProvider ?? undefined}
            onChange={handleGetProvider}
            placeholder="Provider"
            options={option}
          />
        </div>

       
      </div> */}
      <DataTable
        progressPending={isLoading}
        data={topupProvider}
        columns={columns}
        pagination
        persistTableHead
      />
    </div>
  );
};

export default TopupProvider;
