import React, { useState, useEffect } from "react";
import InputForm from "@components/HRIS/InputForm";
import { IDataHRIS, IPayloadAdd } from "@typed/HRIS/allHris";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@stores/store";
import { postLevelRequest, putLevelRequest, resetLevel } from "@stores/HRIS/Level/LevelSlice";
import * as R from "ramda";
import swal from "sweetalert";

const AddLevel = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [addLevel, setAddLevel] = useState<IPayloadAdd>({} as IPayloadAdd);
  const { data, isLoading, lastCreateData } = useAppSelector((state) => state.hrisLevel);
  const dispatch = useAppDispatch();
  const { name, description } = addLevel;

  useEffect(() => {
    if (id) {
      data.filter(
        (item: IDataHRIS) =>
          item._id === id &&
          setAddLevel({ ...addLevel, name: item.name, description: item.description })
      );
    }
  }, [id]);

  useEffect(() => {
    if (!R.isEmpty(lastCreateData)) {
      dispatch(resetLevel());
      setTimeout(() => {
        navigate("/hris/level");
      }, 600);
    }
  }, [lastCreateData]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setAddLevel({ ...addLevel, [name]: value });
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    const Data = {
      name,
      description
    };
    if (id) {
      dispatch(putLevelRequest({ id, Data })).then(({ payload }) => {
        if (payload.code === 1000) {
          navigate("/hris/level");
          swal("Success!", "You level is update!", "success");
        }
      });
    } else {
      dispatch(postLevelRequest(Data)).then(({ payload }) => {
        if (payload.code === 1000) {
          navigate("/hris/level");
          swal("Success!", "You level is created!", "success");
        }
      });
    }
  };

  return (
    <>
      {/* <Toaster /> */}
      <InputForm
        id={id}
        isLoading={isLoading}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        value={addLevel}
        title="Level"
      />
    </>
  );
};

export default AddLevel;
