import React, { FunctionComponent, PropsWithChildren } from "react";
import { Modal } from "antd";

type TyProps = {
  isModalVisible?: boolean;
  handleOkModal: () => void;
  handleCancel: () => void;
  text: string | null;
  children?: any;
  title: string;
};

const ReactModal: FunctionComponent<TyProps> = ({
  text,
  children,
  isModalVisible,
  handleCancel,
  handleOkModal,
  title
}) => {
  return (
    <>
      <Modal title={title} open={isModalVisible} onOk={handleOkModal} onCancel={handleCancel}>
        {children}
      </Modal>
    </>
  );
};

export default React.memo(ReactModal);
