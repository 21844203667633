import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
  ICreateOutlet,
  IDataOutlet,
  IDetailOutletAcc,
  IOutletAccStore,
  IPutData
} from "@typed/dailojol/Outlet";
import { Overwrite, PayloadResponse } from "@typed/global";
import toast from "react-hot-toast";
import {
  postOutletAccountAPI,
  getDetailOutletAccAPI,
  getOutletAPI,
  putOutletAccountAPI
} from "./OutletAccountAPI";
type Payload = Overwrite<
  PayloadResponse,
  {
    data: IDataOutlet[];
  }
>;

export const getOutletRequest = createAsyncThunk("dailyojol-outlet/getOutletRequest", async () => {
  const response = await getOutletAPI();
  return response.data;
});

export const createOutletAccountRequest = createAsyncThunk(
  "dailyojol-outlet/createOutletAccount",
  async (payload: object) => {
    const response = await postOutletAccountAPI(payload);
    return response;
  }
);

export const getDetailOutletRequest = createAsyncThunk(
  "dailyojol-outlet/getDetailOutletAPI",
  async (id: string) => {
    const response = await getDetailOutletAccAPI(id);
    return response.data;
  }
);

export const putOutletAccountRequest = createAsyncThunk(
  "dailyojol/outlet/putOutletAccountRequest",
  async ({ Data, id }: IPutData) => {
    const response = await putOutletAccountAPI(Data, id);

    return response;
  }
);

const initialState: IOutletAccStore = {
  data: [],
  status: 0,
  loading: false,
  detailOutletAccount: {} as IDetailOutletAcc,
  createOutlet: {} as IDetailOutletAcc
};

type TPayloadChange = {
  name: string;
  value: string;
};

export const OutletAccountSlice = createSlice({
  name: "dailyojol-outlet-account",
  initialState: initialState,
  reducers: {
    reset: (state: { status: number }) => {
      state.status = 0;
    },
    changeOutlet: (
      state: { createOutlet: IDetailOutletAcc },
      action: PayloadAction<TPayloadChange>
    ) => {
      // state.createOutlet =
    }
  },
  extraReducers(builder) {
    builder.addCase(getOutletRequest.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getOutletRequest.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.loading = false;
    });
    builder.addCase(getOutletRequest.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getDetailOutletRequest.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getDetailOutletRequest.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.code === 1000) {
        state.detailOutletAccount = action.payload.data;
      }
    });
    builder.addCase(getDetailOutletRequest.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(createOutletAccountRequest.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createOutletAccountRequest.fulfilled, (state, action) => {
      if (action.payload.code === 2002) {
        toast.error(action.payload.error);
        state.loading = false;
      } else if (action.payload.code === 2004) {
        state.loading = false;

        toast.error(action.payload.error);
      } else if (action.payload.code === 1000) {
        state.status = action.payload.code;
        state.loading = false;
        toast.success("Success Create Outlet Account");
      }
      state.loading = false;
    });
    builder.addCase(createOutletAccountRequest.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(putOutletAccountRequest.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(putOutletAccountRequest.fulfilled, (state, action) => {
      // console.log(action, "< outlet reducer");
      state.loading = false;
      if (action.payload.code === 1000) {
        toast.success("Success");
        state.status = action.payload.code;
        state.createOutlet = action.payload.data;
      }
    });
    builder.addCase(putOutletAccountRequest.rejected, (state) => {
      state.loading = false;
    });
  }
});

export default OutletAccountSlice.reducer;
export const { reset } = OutletAccountSlice.actions;
