import React, { useEffect, useRef, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { useAppDispatch, useAppSelector } from "@stores/store";
import { getPaymentOriginRequest } from "@stores/dailycoin/ReportingSlice";
import moment from "moment";
import { getOutletsRequest } from "@stores/dailyapp/dailyOutletSlice";
import RadioComponent from "@components/Radio.component";

interface IPropsPayment {
  date: any;
  setDate: React.Dispatch<any>;
  selectedOption: string;
  setSelectedOption: React.Dispatch<React.SetStateAction<string>>;
}

const PaymentOriginChart: React.FC<IPropsPayment> = ({
  date,
  setDate,
  selectedOption,
  setSelectedOption
}) => {
  const dispatch = useAppDispatch();
  const { dataChartPO } = useAppSelector((state) => state.dailyReport);
  const paymentOrigin = useAppSelector((state) =>
    state.dailyReport.paymentOrigin.map((i) => ({ ...i, key: i._id }))
  );

  const Outlet = useAppSelector((state) => state.dailyappOutlet.AppOutlet.map((item) => item.name));

  const [secondSeries, setSecondSeries] = useState<any[]>([]);
  useEffect(() => {
    dispatch(getOutletsRequest());
  }, []);

  const groupedData = paymentOrigin?.reduce((result, item) => {
    const date = item.date.split("T")[0]; // Extract the date portion

    if (!result[date]) {
      result[date] = {};
    }

    if (!result[date][item.outlet]) {
      result[date][item.outlet] = 0;
    }

    result[date][item.outlet] += item.payment;

    return result;
  }, {});
  const arrayOfObjects = Object.entries(groupedData).map(([key, value]) => ({ value, key }));

  const options: ApexOptions = {
    labels: Object.keys(groupedData),
    xaxis: {
      type: "datetime",
      labels: {
        datetimeFormatter: {
          year: "yyyy",
          month: "MMM",
          day: "dd",
          hour: "HH:mm"
        }
      }
    },
    chart: {
      type: "bar",
      height: 350,
      width: 350,
      zoom: {
        enabled: true
      }
    },
    plotOptions: {
      bar: {
        columnWidth: "80%",
        dataLabels: {
          position: "top" // top, center, bottom
        }
      }
    }
  };

  const series = [
    {
      name: "Payment Origin",
      data: arrayOfObjects.map((item: any) => item?.value[selectedOption] ?? 0)
    }
  ];

  return (
    <div>
      <div className="flex p-2 justify-between items-center">
        <div className="pl-[46px]">
          <h1 className="font-bold text-[2rem] leading-9 m-0">Payment Origin</h1>
        </div>
        <div className="z-10">
          <RadioComponent
            dataSource={Outlet}
            className="w-[15rem]"
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
        </div>
      </div>

      <ReactApexChart options={options} type="bar" series={series} height={250} />
    </div>
  );
};

export default PaymentOriginChart;
