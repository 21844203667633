import React, { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "@stores/store";
import {
  getPhoneNumberUser,
  getVoucherUserReq,
  updateVoucherExpiredReq
} from "@stores/dailyapp/VoucherSlice";
import InputSearch from "@components/inputSearch";
import Loading from "@components/loading";
import DataTable from "react-data-table-component";
import moment from "moment";
import ExportCSV from "@components/ExportCSV";

interface IPropsTable {
  dataSource: any[] | [];
  fileName: string;
  loading: boolean;
}

const CostumTable = ({ dataSource, fileName, loading }: IPropsTable) => {
  const dispatch = useAppDispatch();
  const { phoneNumberUser } = useAppSelector((state) => state.dailyVoucher);
  const [phoneNumber, setPhoneNumber] = useState("");

  const handleUpdateExp = (row: any) => {
    dispatch(updateVoucherExpiredReq(row._id));
    dispatch(getVoucherUserReq(phoneNumberUser));
  };

  const columnVoucher = [
    {
      name: "Voucher Date",
      selector: (row: any) => row.voucherDate,
      cell: (row: any) => moment(row.voucherDate).format("MMMM Do YYYY, h:mm"),
      sortable: true
    },
    {
      name: "Voucher Expired",
      selector: (row: any) => row.voucherExpired,
      sortable: true,
      cell: (row: any) => {
        return (
          <>
            <div
              className={`
          w-full h-full	text-white flex items-center
           ${
             moment(row.voucherExpired).format("YYYY-MM-DD") < moment().format("YYYY-MM-DD")
               ? "text-red-500"
               : "text-green-500"
           }`}
            >
              {moment(row.voucherExpired).format("MMMM Do YYYY, h:mm")}
            </div>
          </>
        );
      }
    },
    {
      name: "Brand",
      sortable: true,
      selector: (row: any) => row.voucherBrand,
      cell: (row: any) => <div className="uppercase">{row.voucherBrand}</div>
    },
    {
      sortable: true,
      name: "Code",
      selector: (row: any) => row.vocherCode
    },
    {
      name: "Price",
      sortable: true,
      selector: (row: any) => row.voucherPrice
    },
    {
      name: "Used",
      sortable: true,
      selector: (row: any) => row.usedAt,
      cell: (row: any) =>
        row.usedAt !== null ? moment(row.usedAt).format("MMMM Do YYYY, h:mm") : "-"
    },
    {
      name: "Action",
      selector: (row: any) => row._id,
      cell: (row: any) => {
        return (
          <>
            {moment(row.voucherExpired).format("YYYY-MM-DD") > moment().format("YYYY-MM-DD") ? (
              <button
                className="hover:text-white rounded py-2 px-5 bg-blue-500 text-white"
                onClick={() => handleUpdateExp(row)}
              >
                Update Expired
              </button>
            ) : (
              <button className="hover:text-white rounded py-2 px-5 bg-gray-600 text-white cursor-not-allowed	">
                Update Expired
              </button>
            )}
          </>
        );
      }
    }
  ];

  return (
    <>
      <div className="flex justify-between">
        <FetchByPhone phone={phoneNumber} setPhone={setPhoneNumber} />
        <div>
          <ExportCSV csvData={dataSource} fileName={`${fileName}`} />
        </div>
      </div>
      <div className="mt-5 w-full overflow-scroll">
        <DataTable
          data={dataSource}
          highlightOnHover
          pagination
          progressPending={loading}
          columns={columnVoucher}
        />
      </div>
    </>
  );
};

interface IPropsFetchPhone {
  phone: string;
  setPhone: React.Dispatch<React.SetStateAction<string>>;
}

const FetchByPhone = ({ phone, setPhone }: IPropsFetchPhone) => {
  const dispatch = useAppDispatch();
  const handleSubmit = () => {
    if (phone !== "") {
      dispatch(getPhoneNumberUser(phone));
      dispatch(getVoucherUserReq(phone));
    }
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };
  return (
    <div className="flex items-center gap-2">
      <InputSearch
        value={phone}
        onKeyDown={handleKeyDown}
        placeholder="Search Voucher User"
        handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setPhone(e.target.value);
        }}
      />
      <div>
        <button
          className="flex text-medium items-center gap-1 text-center outline-none h-9 w-[10rem] rounded bg-blue-500 hover:bg-blue-400 text-white justify-center mb-4"
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

const VoucherUser = () => {
  const dispatch = useAppDispatch();
  const { VoucherUser, isLoadingVoucher, phoneNumberUser } = useAppSelector(
    (state) => state.dailyVoucher
  );

  useEffect(() => {
    dispatch(getVoucherUserReq(phoneNumberUser));
  }, []);

  return (
    <div>
      <div>
        <h1 className="font-bold text-[1.2rem]">{phoneNumberUser}</h1>
      </div>
      {VoucherUser.length > 0 ? (
        <>
          <CostumTable
            dataSource={VoucherUser}
            loading={isLoadingVoucher}
            fileName="dailyapp-voucher-user"
          />
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default VoucherUser;
