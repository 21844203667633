import React, { useEffect, useRef, useState } from "react";
import { useAppSelector, useAppDispatch } from "@stores/store";
import { CSVReportReview, getReportReviewReq } from "@stores/dailycoin/report/ReportSlice";
import { Link } from "react-router-dom";
import { dateToString, nf } from "@helpers/utils";
import InputSearch from "@components/inputSearch";
import { DatePicker, DatePickerProps, Table } from "antd";
import Loading from "@components/loading";
import moment from "moment";
import { setEndDate, setStartDate } from "@stores/dailycoin/ReportingSlice";
import DataTable from "react-data-table-component";

import ExportCSV from "@components/ExportCSV";
import MyTable from "@components/Table/MyTable";

const columns = [
  {
    name: "Order Date",
    selector: (row: any) => row.orderDate,
    id: "orderDate",
    cell: (row: any) => <div>{dateToString(row.orderDate)}</div>
  },
  {
    name: "Scan Date",
    selector: (row: any) => row.scanDate,
    id: "scanDate",
    cell: (row: any) => <div>{dateToString(row.scanDate)}</div>
  },
  {
    name: "Review Date",
    selector: (row: any) => row.reviewDate,
    id: "reviewDate",
    cell: (row: any) => <div>{dateToString(row.reviewDate)}</div>
  },
  {
    name: "Name",
    selector: (row: any) => row.custName,
    id: "name"
  },
  {
    name: "Rate",
    selector: (row: any) => row.rate,
    id: "rate",
    width: "80px",
    sortable: true,
    cell: (row: any) => <h1 className="font-medium bold">{row.rate}</h1>
  },
  {
    name: "Content",
    selector: (row: any) => row.content,
    id: "content",
    wrap: true
  },

  {
    name: "Phone",
    selector: (row: any) => row.custPhone,
    id: "phone",
    wrap: true,
    cell: (row: any) => (
      <a target={"_blank"} rel="noreferrer" href={`https://wa.me/${row.custPhone}`}>
        +{row.custPhone}
      </a>
    )
  },
  {
    name: "Action",
    selector: (row: any) => row._id.id,
    id: "id",
    width: "120px",
    cell: (row: any) => (
      <div className="flex gap-3 items-center justify-center">
        <Link
          className="hover:text-white rounded py-2 px-5 bg-blue-500 text-white"
          to={`/costumer-experience/report-review/${row._id.id}`}
        >
          Detail
        </Link>
      </div>
    )
  }
];

const ReportReview = () => {
  const dispatch = useAppDispatch();
  const { reportReview, reportReviewCSV, isLoading } = useAppSelector((state) => state.CEReport);
  const [searchByName, setSearchByName] = useState<string>("");

  const startDate = useRef(moment().startOf("month").format("YYYY-MM-DD"));
  const endDate = useRef(moment().format("YYYY-MM-DD"));

  useEffect(() => {
    dispatch(CSVReportReview());
  }, [isLoading]);

  useEffect(() => {
    dispatch(getReportReviewReq({ startDate: startDate.current, endDate: endDate.current }));
    dispatch(CSVReportReview());
  }, []);

  const filterByDate = () => {
    dispatch(getReportReviewReq({ startDate: startDate.current, endDate: endDate.current }));
    dispatch(CSVReportReview());
  };

  const handleStartDate: DatePickerProps["onChange"] = (date: any, dateString: any) => {
    startDate.current = dateString;
  };

  const handleEndDate: DatePickerProps["onChange"] = (date: any, dateString: any) => {
    endDate.current = dateString;
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="flex justify-between items-center">
            <div className=" flex items-center gap-4">
              <h1 className="m-0">Filter </h1>

              <DatePicker
                className="h-9"
                name="startDate"
                onChange={handleStartDate}
                placeholder="Start date"
              />

              <DatePicker
                className="h-9"
                name="endDate"
                onChange={handleEndDate}
                placeholder="End date"
              />

              <button
                onClick={filterByDate}
                className="flex text-medium items-center gap-1 text-center outline-none h-9 w-[10rem] rounded bg-blue-500 hover:bg-blue-400 text-white justify-center"
              >
                Submit
              </button>
            </div>
            <ExportCSV
              csvData={reportReviewCSV}
              fileName={`report-review-${startDate.current}-${endDate.current}`}
            />
          </div>

          <div className="mt-5">
            <DataTable data={reportReview} columns={columns} pagination persistTableHead />
          </div>
        </>
      )}
    </>
  );
};

export default ReportReview;
