import React, { FC, useEffect, useState } from "react";
import { Table, Input, TableProps } from "antd";
import { useAppDispatch, useAppSelector } from "@stores/store";
import { getUsersOjolRequest } from "@stores/dailyojol/UserSlice";
import { Link } from "react-router-dom";
import { DataUser } from "@typed/dailojol/listUser";
import { LoadingOutlined } from "@ant-design/icons";
import InputSearch from "@components/inputSearch";

const ListUser: FC = () => {
  const listUsers = useAppSelector((state) =>
    state.dailyojolUser.listUsers.map((x) => ({ ...x, key: x._id }))
  ); // data Users
  const dispatch = useAppDispatch();
  const [searchByName, setSearchByName] = useState<string>("");
  const [okUsers, setOkUsers] = useState(listUsers);
  const { Search } = Input;

  useEffect(() => {
    dispatch(getUsersOjolRequest());
  }, []);

  useEffect(() => {
    let Data = listUsers.filter((item) => {
      return item.username.toLowerCase().includes(searchByName.toLowerCase());
    });
    if (searchByName !== "") {
      setOkUsers(Data);
    } else if (searchByName === "") {
      setOkUsers(listUsers);
    }
  }, [searchByName]);

  const column = [
    {
      key: "username",
      title: "Name",
      dataIndex: "username",
      render: (fullName: string) => (
        <div>
          {fullName === "" ? <div className="text-red-500">No data available</div> : fullName}
        </div>
      )
    },
    {
      key: "gender",
      title: "Gender",
      dataIndex: "gender",
      render: (gender: string) => (
        <div> {gender === "" ? <div className="text-red-500">No data available</div> : gender}</div>
      )
    },
    {
      key: "code",
      title: "Code",
      dataIndex: "code",
      render: (code: string | null) => (
        <div> {code === null ? <div className="text-red-500">No data available</div> : code}</div>
      )
    },
    {
      key: "phoneNumber",
      title: "Phone ✆",
      dataIndex: "phoneNumber",
      render: (phoneNumber: string) => <a href={`https://wa.me/${phoneNumber}`}>+{phoneNumber}</a>
    },
    {
      key: "action",
      title: "Action",
      render: (data: DataUser) => {
        return (
          <div>
            <Link to={`/dailyojol/user/${data._id}`}>
              <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                Detail
              </button>
            </Link>
          </div>
        );
      }
    }
  ];

  const antIcon = <LoadingOutlined style={{ fontSize: 100 }} spin />;
  return (
    <div>
      {/* {loading ? (
        <div className="m-auto block">
          <Spin className="" indicator={antIcon} />
        </div>
      ) : */}
      {/* <div className="flex-1 mb-4"> */}
      <InputSearch
        value={searchByName}
        placeholder="Search by Name"
        handleChange={(e) => setSearchByName(e.target.value)}
      />
      {/* </div> */}

      <Table columns={column} dataSource={okUsers} />
    </div>
  );
};

export default ListUser;
