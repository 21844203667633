import React from "react";
import useRole from "@hooks/useRole";
import { Link, Outlet } from "react-router-dom";
import Forbidden from "@components/forbidden";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { useAppSelector } from "@stores/store";
import { MdOutlineDiscount, MdDiscount } from "react-icons/md";

const MenuPromo = () => {
  const auth = useAppSelector((state) => state.auth);

  const isForbidden = !useRole(auth.employeeData.roles, "PROMO");

  return isForbidden ? (
    <Forbidden />
  ) : (
    <>
      <div className="flex mt-[70px]   flex-row">
        <div className={"w-[200px]"}>
          <ProSidebar width={200} className="fixed">
            <Menu iconShape="square">
              <MenuItem key="/promo-brand/" icon={<MdOutlineDiscount />}>
                <Link to="/promo/promo-brand/">Promo</Link>
              </MenuItem>
            </Menu>
          </ProSidebar>
        </div>
        <div className="flex-1 p-5 bg-slate-100 min-h-screen">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default MenuPromo;
