import { getDetailEventRequest } from "@stores/CE/CEXPSlice";
import { useAppDispatch, useAppSelector } from "@stores/store";
import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Descriptions, Table } from "antd";
import Loading from "@components/loading";
const columns = [
  {
    title: "Username",
    dataIndex: "name",
    key: "name"
  },
  {
    title: "Deal Coin",
    dataIndex: "dealCoin",
    key: "dealCoin"
  },
  {
    title: "Phone",
    dataIndex: "phone",
    key: "phone",
    render: (phone: string) => (
      <a target={"_blank"} rel="noreferrer" href={`https://wa.me/${phone}`}>
        +{phone}
      </a>
    )
  },
  {
    title: "Action",
    dataIndex: "user",
    key: "id",
    render: (id: string) => (
      <div className="flex gap-3 items-center justify-center">
        <Link
          className="hover:text-white rounded py-2 px-5 bg-blue-500 text-white"
          to={`/costumer-experience/customer/${id}`}
        >
          Detail
        </Link>
      </div>
    )
  }
];

const DetailEvent = () => {
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector((state) => state.CEcustomer);
  const detailEvent = useAppSelector((state) => state.CEcustomer.detailEvent);

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      dispatch(getDetailEventRequest(id));
    }
  }, [id]);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Descriptions bordered title={detailEvent?.nameProduct} size="small">
            {/* <div className="mt-3">
              <img className="w-[20%] h-[20%] rounded-md" src={detailEvent?.image} />
            </div> */}
            {/* <Descriptions.Item label="Image"> */}
            {/* </Descriptions.Item> */}
            <Descriptions.Item label="Base Rate">
              {detailEvent?.baseRate ? (
                <h1 className="font-bold">{detailEvent?.baseRate}</h1>
              ) : null}
            </Descriptions.Item>
            <Descriptions.Item label="Image">
              <a target="_blank" rel="noreferrer" href={detailEvent.image}>
                View Image
              </a>
            </Descriptions.Item>
            <Descriptions.Item label="Coin Every Click">
              {detailEvent?.coinEveryClick ? (
                <h1 className="font-bold">+{detailEvent?.coinEveryClick}</h1>
              ) : null}
            </Descriptions.Item>
            {/* <Descriptions.Item label="Base Rate">
          <img className="w-5 h-5" src={detailEvent.image} />
        </Descriptions.Item> */}
          </Descriptions>
          {/* <div className="mt-1">
            <img className="w-[25%] h-[25%]" src={detailEvent?.image} />
          </div> */}

          <div className="mt-5">
            <Table columns={columns} dataSource={detailEvent?.bidders} />
          </div>
        </>
      )}
    </>
  );
};

export default DetailEvent;
