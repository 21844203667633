import API from "@constants/API";

const QNA_BATCH = "https://api.prod.dailyapp.id/dashboard-v1-qna-batch/qna-batch";
const QUESTIONS = "https://api.prod.dailyapp.id/dashboard-v1-qna/question";
const ANSWER = "https://api.prod.dailyapp.id/dashboard-v1-qna/answer";

export interface IPostQABatch {
  sequence: number;
}
export interface IPutQABatch {
  sequence: number;
  id: string;
}
export interface IStatusQABatch {
  isActive: boolean;
  id: string;
}

export const getQandABatchAPI = async () => {
  const response = await API.HTTP.get(QNA_BATCH);

  return response.data;
};

export const getDetailQandABatchAPI = async (id: string) => {
  const response = await API.HTTP.get(`${QNA_BATCH}/${id}`);

  return response.data;
};

export const postQandABatchAPI = async (payload: IPostQABatch) => {
  const response = await API.HTTP.post(QNA_BATCH, payload);

  return response.data;
};

export const putQandABatchAPI = async (payload: IPutQABatch) => {
  const response = await API.HTTP.put(`${QNA_BATCH}/${payload.id}`, { sequence: payload.sequence });

  return response.data;
};

export const statusQandABatchAPI = async (payload: IStatusQABatch) => {
  const response = await API.HTTP.put(`${QNA_BATCH}/${payload.id}`, { isActive: payload.isActive });

  return response.data;
};

// QUESTIONS
export const getQuestionAPI = async () => {
  const response = await API.HTTP.get(QUESTIONS);

  return response.data;
};

export const getDetailQuestionAPI = async (id: string) => {
  const response = await API.HTTP.get(`${QUESTIONS}/${id}`);

  return response.data;
};

export interface ICreateQuestions {
  question: string;
  type: string;
  answer: string[] | any;
  squence: number;
  batchId: string;
}

export const postQuestionAPI = async (payload: ICreateQuestions) => {
  const response = await API.HTTP.post(`${QUESTIONS}`, payload);

  return response.data;
};

export interface IEditQuestions extends ICreateQuestions {
  id: string;
}

export const putQuestionAPI = async (payload: IEditQuestions) => {
  const { type, id, answer, squence, batchId, question } = payload;

  const response = await API.HTTP.put(`${QUESTIONS}/${id}`, {
    answer,
    question,
    type,
    squence,
    batchId
  });

  return response.data;
};

//  ANSWER

export const getAnswerAPI = async () => {
  const response = await API.HTTP.get(ANSWER);

  return response.data;
};
