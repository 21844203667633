import React, { useRef, useEffect } from "react";

import { useAppDispatch, useAppSelector } from "@stores/store";
import moment from "moment";

import { DatePicker, DatePickerProps, Table, Tabs } from "antd";
import ExportCSV from "@components/ExportCSV";
import { CSVCashbackRegister, getCashbackRegisRequest } from "@stores/dailycoin/ReportingSlice";
import { nf } from "@helpers/utils";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import MyTable from "@components/Table/MyTable";

const columns = [
  {
    name: "Date",
    selector: (row: any) => row._id,
    id: "date"
  },
  {
    name: "Total Register",
    selector: (row: any) => row.transaction,
    id: "transaction",
    cell: (row: any) => <div>{nf.format(row.transaction)}</div>
  },
  {
    name: "Cashback",
    selector: (row: any) => row.cashback,
    id: "cashback",
    cell: (row: any) => <div>{nf.format(row.cashback)}</div>
  },
  {
    name: "Action",
    selector: (row: any) => row._id.id,
    id: "action",
    cell: (row: any) => (
      <div className="flex gap-3 items-center justify-start">
        <Link
          className="hover:text-white rounded py-2 px-5 bg-blue-500 text-white"
          to={"register/" + row._id?.id}
        >
          Detail
        </Link>
      </div>
    )
  }
];

const CashbackRegister = () => {
  const dispatch = useAppDispatch();
  const { isLoading, dataCashback, CashbackCSV } = useAppSelector((state) => state.dailyReport);

  const startDate = useRef(moment().startOf("month").format("YYYY-MM-DD"));
  const endDate = useRef(moment().format("YYYY-MM-DD"));

  useEffect(() => {
    dispatch(CSVCashbackRegister());
  }, [isLoading]);

  useEffect(() => {
    dispatch(
      getCashbackRegisRequest({
        startDate: moment(startDate.current).format("YYYY-MM-DD"),
        endDate: moment(endDate.current).format("YYYY-MM-DD")
      })
    );
    dispatch(CSVCashbackRegister());
  }, []);

  const filterByDate = () => {
    dispatch(
      getCashbackRegisRequest({
        startDate: moment(startDate.current).format("YYYY-MM-DD"),
        endDate: moment(endDate.current).format("YYYY-MM-DD")
      })
    );
    dispatch(CSVCashbackRegister());
  };

  const handleStartDate: DatePickerProps["onChange"] = (date: any, dateString: any) => {
    startDate.current = dateString;
  };

  const handleEndDate: DatePickerProps["onChange"] = (date: any, dateString: any) => {
    endDate.current = dateString;
  };

  return (
    <>
      <div className="flex overflow-scroll justify-between items-center">
        <div className=" flex items-center gap-4">
          <h1 className="m-0">Filter</h1>

          <DatePicker
            className="h-9"
            name="startDate"
            onChange={handleStartDate}
            placeholder="Start date"
          />

          <DatePicker
            className="h-9"
            name="endDate"
            onChange={handleEndDate}
            placeholder="End date"
          />

          <button
            onClick={filterByDate}
            className="flex text-medium items-center gap-1 text-center outline-none h-9 w-[10rem] rounded bg-blue-500 hover:bg-blue-400 text-white justify-center"
          >
            Submit
          </button>
        </div>
        <ExportCSV
          csvData={CashbackCSV}
          fileName={`report-register-${startDate.current}-${endDate.current}`}
        />
      </div>

      <div className="mt-5">
        <DataTable data={dataCashback} columns={columns} pagination persistTableHead />
        <MyTable
          fileName="cashback"
          dataSource={dataCashback}
          select={[
            { label: "Date", value: "_id" },
            { label: "Total Register", value: "transaction" },
            { label: "Cashback", value: "cashback" }
          ]}
        />
      </div>
    </>
  );
};

export default CashbackRegister;
