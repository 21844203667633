import React from "react";
import { AiOutlineSearch } from "react-icons/ai";

interface IProps {
  value: string;
  placeholder: string;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement> | undefined;
  style?: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputSearch: React.FC<IProps> = ({ placeholder, style, value, handleChange, onKeyDown }) => {
  return (
    <div className={` relative `}>
      <input
        onKeyDown={onKeyDown}
        className="pl-9 pr-7 outline-none h-12 w-[15rem] focus-within:shadow-4xl focus-within:border-[#1894ed] border-gray-200 border rounded mb-4"
        type="text"
        value={value ?? ""}
        onChange={handleChange}
        placeholder={placeholder}
      />
      <AiOutlineSearch className="text-blue-500 absolute left-1 text-[1.5rem] top-3 cursor-pointer" />
    </div>
  );
};

export default InputSearch;
