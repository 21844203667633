import API from "@constants/API";

const BASE_URL = "https://api.prod.daily.co.id/dashboard/dailycoin/reporting/v1/";

const ACTIVITY_URL =
  "https://api.prod.daily.co.id/dashboard/dailycoin/reporting/v1/summary-report/trx";

const REPORT_FINANCE =
  "https://api.prod.daily.co.id/dashboard/dailycoin/reporting/v1/fnc-report/trx?";

const TOPUP_EMPLOYE =
  "https://api.prod.daily.co.id/dashboard/dailycoin/reporting/v1/fnc-report/employee-topup?";

const CASHBACK_REGIS =
  "https://api.prod.daily.co.id/dashboard/dailycoin/reporting/v1/register-cashback/trx?";

const PAYMENT_ORIGIN =
  "https://api.prod.daily.co.id/dashboard/dailycoin/reporting/v1/payment/origin/trx?";

const SUMMARY_MUTASI =
  "https://api.prod.daily.co.id/dashboard/dailycoin/reporting/v1/summary-mutasi/trx";

const TOPUP_PROVIDER =
  "https://api.prod.daily.co.id/dashboard/dailycoin/reporting/v1/topup/trx-provider/?";

const DETAIL_TOPUP =
  "https://api.prod.daily.co.id/dashboard/dailycoin/reporting/v1/topup/detail-trx-provider/?";

export const getReportFinanceAPI = async (startDate: string, endDate: string) => {
  const response = await API.HTTP.get(`${REPORT_FINANCE}startdate=${startDate}&enddate=${endDate}`);
  return response.data;
};

export const getReportPaymentAPI = async (startDate: string, endDate: string) => {
  const response = await API.HTTP.get(
    `${BASE_URL}payment/trx?startdate=${startDate}&enddate=${endDate}`
  );
  return response.data;
};

export const getReportTopupAPI = async (startDate: string, endDate: string) => {
  const response = await API.HTTP.get(
    `${BASE_URL}topup/trx?startdate=${startDate}&enddate=${endDate}`
  );
  return response.data;
};

export const getReportBillAPI = async (startDate: string, endDate: string) => {
  const response = await API.HTTP.get(
    `${BASE_URL}bill-cashback/trx?startdate=${startDate}&enddate=${endDate}`
  );
  return response.data;
};

export const getSummariesAPI = async () => {
  const response = await API.HTTP.get(ACTIVITY_URL);
  return response.data;
};

export const getTopupEmployeAPI = async (startDate: string, endDate: string) => {
  const response = await API.HTTP.get(`${TOPUP_EMPLOYE}startdate=${startDate}&enddate=${endDate}`);
  return response.data;
};

export const getCashbackRegisAPI = async (startDate: string, endDate: string) => {
  const response = await API.HTTP.get(`${CASHBACK_REGIS}startdate=${startDate}&enddate=${endDate}`);
  return response.data;
};

export const getPaymentOriginAPI = async (startDate: string, endDate: string) => {
  const response = await API.HTTP.get(`${PAYMENT_ORIGIN}startdate=${startDate}&enddate=${endDate}`);
  return response.data;
};

export const getCutoffOriginAPI = async () => {
  const response = await API.HTTP.get(
    `https://api.prod.daily.co.id/dashboard/dailycoin/reporting/v1/cutoff/origin/trx`
  );
  return response.data;
};

export const getCutoffByPeriode = async (startDate: string, endDate: string) => {
  const response = await API.HTTP.get(
    `https://api.prod.daily.co.id/dashboard/dailycoin/reporting/v1/cutoff/origin/trx?startdate=${startDate}&enddate=${endDate}`
  );
  return response.data;
};

export const getSummaryMutasiAPI = async () => {
  const response = await API.HTTP.get(`${SUMMARY_MUTASI}`);
  return response.data;
};

export const topupByProviderAPI = async (startDate: any, endDate: any) => {
  const res = await API.HTTP.get(`${TOPUP_PROVIDER}startdate=${startDate}&enddate=${endDate}`);
  return res.data;
};

export const gettopupByProviderDetailAPI = async (date: any, provider: any) => {
  const res = await API.HTTP.get(`${DETAIL_TOPUP}date=${date}&provider=${provider}`);
  return res.data;
};
