import React from "react";

type IProps = {
  title?: string;
  value?: number;
};

const CardDashboard: React.FC<IProps> = ({ title, value }) => {
  return (
    <>
      <div className="w-[23%] flex flex-col items-center justify-center h-[15vh] bg-white rounded">
        <p className="text-[1rem] p-0 m-0 text-slate-500">{title}</p>
        <h1 className="font-medium text-xl m-0">{value}</h1>
      </div>
    </>
  );
};

export default CardDashboard;
