import React, { useState, useEffect, ChangeEvent } from "react";
import { useAppDispatch, useAppSelector } from "@stores/store";
import { getOutletsRequest } from "@stores/dailyapp/dailyOutletSlice";
import Modal from "@components/Modal";
import Input from "@components/Input.components";
import SelectComponents from "@components/Select";
import type { DatePickerProps } from "antd";
import { DatePicker, TimePicker } from "antd";

import Button from "@components/Button.component";
import moment from "moment";
import { postVerifyVoucherReq } from "@stores/dailyapp/VoucherSlice";
import swal from "sweetalert";

interface IPropsVerify {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}
const format = "HH:mm";

const VoucherVerify: React.FC<IPropsVerify> = ({ openModal, setOpenModal }) => {
  const dispatch = useAppDispatch();
  const Outlet = useAppSelector((state) =>
    state.dailyappOutlet.AppOutlet.map((item) => ({ label: item.name, value: item._id }))
  );
  const { isLoadingVerify } = useAppSelector((state) => state.dailyVoucher);

  const [postVerifyVoucher, setPostVerifyVoucher] = useState({
    code: "",
    date: "",
    time: "",
    outletId: ""
  });
  const { code, date, time, outletId } = postVerifyVoucher;

  const onChangeDay: DatePickerProps["onChange"] = (date, dateString) => {
    setPostVerifyVoucher({ ...postVerifyVoucher, date: moment(date).format("YYYY-MM-DD") });
  };

  const onChangeTime = (time: any, timeString: string) => {
    setPostVerifyVoucher({ ...postVerifyVoucher, time: time });
  };

  useEffect(() => {
    dispatch(getOutletsRequest());
  }, []);

  const closeModal = () => {
    setOpenModal(!openModal);

    setPostVerifyVoucher({
      code: "",
      date: "",
      time: "",
      outletId: ""
    });
  };

  const handleVerifyVoucher = () => {
    dispatch(postVerifyVoucherReq(postVerifyVoucher)).then((res) => {
      if (res.payload.code === 1000) {
        swal("Good job!", "You have successfully created a Question!", "success");

        setPostVerifyVoucher({
          code: "",
          date: "",
          time: "",
          outletId: ""
        });
      } else {
        swal("Ops!", `${res.payload?.code}: ${res.payload?.message}`, "error");
      }
    });
  };

  return (
    <>
      <Modal isOpen={openModal} closeModal={closeModal} title="Verify Voucher">
        <div className="bg-white h-[20rem] pt-16 flex flex-col gap-3">
          <Input
            required="after:text-red-500 after:content-['*']"
            title={"Code"}
            placeholder={"Code"}
            value={code}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setPostVerifyVoucher({ ...postVerifyVoucher, code: e.target.value });
            }}
            name={""}
          />
          <div>
            <h1 className="text-gray-600 after:content-['*'] after:text-red-500 m-0 ">Outlet</h1>
            <SelectComponents
              options={Outlet}
              onChange={(option: any, actionMeta: any) => {
                setPostVerifyVoucher({ ...postVerifyVoucher, outletId: option.value });
              }}
            />
          </div>

          <div>
            <h1 className="text-gray-600 after:content-['*'] after:text-red-500 m-0 ">Date</h1>

            <div className="flex items-center gap-4">
              <DatePicker size="large" onChange={onChangeDay} />
              <TimePicker size="large" onChange={onChangeTime} format={format} />
            </div>
          </div>
        </div>
        <div className="flex justify-end">
          <Button isLoading={isLoadingVerify} onClick={handleVerifyVoucher}>
            Submit
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default VoucherVerify;
