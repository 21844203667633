import API from "@constants/API";
import { IAddProduct } from "@typed/dailojol/Product";
import { PayloadProductCreate } from "@typed/dailyapp/product";
const BASE_URL = "https://api.prod.daily.co.id/dashboard/product/v3/dashboard-products";
const BASE_URL_SINGLE = "https://api.prod.daily.co.id/dashboard/product/v3/dashboard-product/";
const BASE_URL_ACTIVE =
  "https://api.prod.daily.co.id/dashboard/product/v3/dashboard-product-activation/";
const BASE_URL_DEACTIVATE =
  "https://api.prod.daily.co.id/dashboard/product/v3/dashboard-product-inactivation/";

export const getProductsApi = async () => {
  const response = await API.HTTP.get(BASE_URL);
  return response.data;
};
export const getProductApi = async ({ id }: { id: string }) => {
  const response = await API.HTTP.get(BASE_URL_SINGLE + id);
  return response.data;
};

export const createProductApi = async ({
  name,
  image,
  dailyPrice,
  flagDailyAppIsActive
}: IAddProduct) => {
  const bodyFormData = new FormData();
  bodyFormData.append("name", name);
  bodyFormData.append("dailyPrice", dailyPrice as any);
  bodyFormData.append("image", image as any);
  // bodyFormData.append("flagDailyAppIsActive", flagDailyAppIsActive as any); // what type ??

  const response = await API.HTTP.post(BASE_URL, bodyFormData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });

  return response.data;
};

export const putProductApi = async ({ id, Data }: { id: string; Data: IAddProduct }) => {
  const bodyData = new FormData();
  const { name, dailyPrice, image, flagDailyAppIsActive } = Data;
  bodyData.append("name", name);
  bodyData.append("dailyPrice", dailyPrice as any);
  bodyData.append("image", image as any);
  // bodyData.append("flagDailyApp", "false"); // what type ??

  const response = await API.HTTP.put(`${BASE_URL}/${id}`, bodyData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
  return response.data;
};

export const delProductApi = async (id: string) => {
  const response = await API.HTTP.delete(`${BASE_URL_SINGLE}${id}`);
  return response.data;
};

export const putProductActiveApi = async (Id: string, flagDailyAppIsActive?: boolean) => {
  const APIS = flagDailyAppIsActive ? BASE_URL_DEACTIVATE : BASE_URL_ACTIVE;
  const response = await API.HTTP.put(`${APIS}${Id}`);

  return response.data;
};
