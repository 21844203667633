import React from "react";
import { nf } from "@helpers/utils";
import { useAppSelector } from "@stores/store";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

const ApexChart = () => {
  const { data } = useAppSelector((state) => state.dailyCoins);
  const options: ApexOptions = {
    xaxis: {
      categories: ["Cashback", "Top up", "Payment"]
    },
    chart: {
      height: 350,
      zoom: {
        enabled: true
      }
    },
    title: {
      text: "Transactions",
      align: "center",
      style: {
        fontSize: "25px"
      }
    }
  };

  const series = [
    {
      name: "Cashback",
      data: [data.monthly?.dailycoinTopupcashback],
      color: "#7c3fad"
    },
    {
      name: "Top up",
      data: [data.monthly?.dailycoinTopup],
      color: "#2d8bd9"
    },
    {
      name: "Payment",
      data: [data.monthly?.dailycoinTotal],
      color: "#65e5cc"
    }
  ];

  return (
    <div className="p-2">
      <Chart options={options} height={380} series={series} type="bar" />
      <div className="flex gap-2 flex-col items-center">
        <div className="flex flex-col mb-5">
          <h1 className="text-[2rem]  items-center text-center m-0 font-bold">Total</h1>
          <div className="text-[2rem]  items-center text-center m-0 leading-6 font-bold">
            Transactions
          </div>
        </div>

        <div className="text-center">
          <p className="text-[#7c3fad] m-0 font-bold text-[1.1rem]">Cashback</p>
          <h1 className="text-[#7c3fad] m-0  font-bold text-[1.5rem]">
            {nf.format(data.monthly?.dailycoinTopupcashback ?? 0)}
          </h1>
        </div>

        <div className="text-center">
          <p className="text-[#2d8bd9] m-0 font-bold text-[1.1rem]">Top up</p>
          <h1 className="text-[#2d8bd9] m-0  font-bold text-[1.5rem]">
            {nf.format(data.monthly?.dailycoinTopup ?? 0)}
          </h1>
        </div>

        <div className="text-center">
          <p className="text-[#65e5cc] m-0 font-bold text-[1.1rem]">Payment</p>
          <h1 className="text-[#65e5cc] m-0  font-bold text-[1.5rem]">
            {nf.format(data.monthly?.dailycoinTotal ?? 0)}
          </h1>
        </div>
      </div>
    </div>
  );
};
export default ApexChart;
