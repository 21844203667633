import API from "@constants/API";
import { PayloadReviewComplain } from "@typed/dailyapp/complain";

const BASE_URL = "https://api.prod.daily.co.id/dashboard/complain/v3/";

export const getComplainsApi = async () => {
  const response = await API.HTTP.get(BASE_URL + "complain/?skip=0&limit=200");
  return response.data;
};

export const sendReviewComplainApi = async (payload: PayloadReviewComplain) => {
  const response = await API.HTTP.post(BASE_URL + "complain-review", payload);
  return response.data;
};
