import React, { useState, useRef } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import "@fullcalendar/daygrid/main.css";
import { Calendar, DatePicker } from "antd";
import moment from "moment";

const { RangePicker } = DatePicker;

import Modal from "./Modal";
import Button from "./Button.component";
import { useAppDispatch } from "@stores/store";
import { getFinanceRequest, getPaymentOriginRequest } from "@stores/dailycoin/ReportingSlice";

interface ICalendarProps {
  date: any;
  setDate: any;
}

const ModalDate: React.FC<ICalendarProps> = ({ date, setDate }) => {
  const dispatch = useAppDispatch();
  const [showModal, setShowModal] = useState(true);

  const handleChange = (date: any, dateString: any) => {
    setDate({
      start: moment(dateString[0]).format("YYYY-MM-DD"),
      allDay: moment(dateString[1]).format("YYYY-MM-DD")
    });
  };

  const handleSubmit = () => {
    setShowModal(!showModal);
    dispatch(getPaymentOriginRequest({ startDate: date.start, endDate: date.allDay }));
    dispatch(
      getFinanceRequest({
        startDate: date.start,
        endDate: date.allDay
      })
    );
  };

  return (
    <Modal isOpen={showModal} closeModal={() => setShowModal(!showModal)} title="Custom Date">
      <div className="bg-white h-[10rem] pt-16 flex flex-col gap-3">
        <RangePicker size={"middle"} onChange={handleChange} />
      </div>
    </Modal>
  );
};

export const MyCalendar: React.FC<ICalendarProps> = ({ date, setDate }) => {
  const [canDrop, setCanDrop] = useState(true);
  const [showDate, setShowDate] = useState(false);
  const [calendarWeekends, setCalendarWeekends] = useState(true);
  const [calendarEvents, setCalendarEvents] = useState([
    { title: "Event Now", start: new Date(), allDay: undefined }
  ]);
  const [customDate, setCustomDate] = useState(false);
  const calendarComponentRef = useRef<any>(null);

  const toggleWeekends = () => {
    setCalendarWeekends(!calendarWeekends);
  };

  const gotoPast = () => {
    const calendarApi = calendarComponentRef.current?.getApi();
    if (calendarApi) {
      calendarApi.gotoDate("2000-01-01");
    }
  };

  const handleCustom = () => {
    setCustomDate(!customDate);
  };

  const handleDateClick = (arg: any) => {
    if (window.confirm("Would you like to add an event to " + arg.dateStr + " ?")) {
      date.current = {
        start: arg.date,
        end: arg.allDay,
        active: true
      };

      setDate({
        start: arg.date,
        allDay: arg.allDay
      });

      setCalendarEvents([
        ...calendarEvents,
        {
          title: "New Event",
          start: arg.date,
          allDay: arg.allDay
        }
      ]);
    }
  };

  const handleEventClick = (arg: any) => {
    alert(arg.event.title);
  };

  const handleEventDrop = (arg: any) => {
    const { start, end } = arg.oldEvent._instance.range;
    const { start: newStart, end: newEnd } = arg.event._instance.range;

    setDate({
      start: newStart,
      allDay: newEnd
    });

    if (new Date(start).getDate() === new Date(newStart).getDate()) {
      arg.revert();
    }
  };

  return (
    <>
      <div className="demo-app p-2 bg-white rounded-md w-[17rem] relative">
        {/* <div className="bg-[#2471db] bg-gradient-to-l  from-[#ffd100] w-[17rem] h-[8rem] rounded-md p-3">
        <button className="border-white border-2 p-1 text-center rounded-md">
          <h1 className="text-center font-bold text-white"> Select Date</h1>
        </button>
        {date.start}
      </div> */}
        <FullCalendar
          editable={true}
          height={392}
          headerToolbar={{
            // start: "prev,next",
            start: "",
            // start: "title",
            center: "title",
            right: "customEnd"
          }}
          customButtons={{
            customEnd: {
              text: "custom!",
              click: handleCustom
            }
          }}
          plugins={[dayGridPlugin, interactionPlugin]}
          eventColor="#3B82F6"
          ref={calendarComponentRef}
          weekends={calendarWeekends}
          events={calendarEvents}
          dateClick={handleDateClick}
          eventClick={handleEventClick}
          eventDrop={handleEventDrop}
        />
        {customDate && <ModalDate date={date} setDate={setDate} />}
      </div>
    </>
  );
};
