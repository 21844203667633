import React, { useEffect, useState } from "react";
import MyTable from "@components/Table/MyPaginationTable";
import { useAppDispatch, useAppSelector } from "@stores/store";
import { getInboxWebRequest } from "@stores/CE/CEXPSlice";

const InboxWeb = () => {
  const dispatch = useAppDispatch();
  const { inboxWeb, isLoading, totalInboxWeb } = useAppSelector((state) => state.CEcustomer);
  const [page, setPage] = useState(0);

  useEffect(() => {
    const payload = {
      page: 0
    };
    dispatch(getInboxWebRequest(payload));
  }, []);

  return (
    <>
      <MyTable
        exportable
        fileName="Inbox-web"
        dataSource={inboxWeb}
        setPage={setPage}
        fetchingPagination={getInboxWebRequest}
        loading={isLoading}
        skip={page}
        select={[
          { label: "Date", value: "attributesCreatedAt" },
          { label: "Name", value: "attributesName" },
          { label: "Email", value: "attributesEmail" },
          { label: "Message", value: "attributesMessage" },
          { label: "Subject", value: "attributesSubject" }
        ]}
        totalUser={totalInboxWeb}
      />
    </>
  );
};

export default InboxWeb;
