import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { Overwrite, PayloadResponse } from "@typed/global";
import { getComplainsApi, sendReviewComplainApi } from "./ComplainAPI";
import { Complain, ComplainStoreState, PayloadReviewComplain } from "@typed/dailyapp/complain";
import toast from "react-hot-toast";
import { totalmem } from "os";

type Payload = Overwrite<PayloadResponse, { data: [Complain] }>;

export const getComplainsRequest = createAsyncThunk(
  "dailyapp-complain/getComplainsRequest",
  async () => {
    const response: Payload = await getComplainsApi();
    return response;
  }
);

export const sendReviewComplainRequest = createAsyncThunk(
  "dailyapp-complain/sendReviewComplainRequest",
  async (payload: PayloadReviewComplain) => {
    const response = await sendReviewComplainApi(payload);
    return response;
  }
);

const initialState: ComplainStoreState = {
  complains: [],
  lastReview: {},
  errorMessage: "",
  isLoading: false
};
interface IPayload {
  id: string;
}
export const complainSlice = createSlice({
  name: "dailyapp-complain",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getComplainsRequest.pending, (state) => {
      state.errorMessage = "";
      state.isLoading = true;
    });
    builder.addCase(getComplainsRequest.fulfilled, (state, action) => {
      if (action.payload.code === 1000) {
        state.complains = action.payload.data;
      } else {
        state.errorMessage = "Error :: with status code" + action.payload.code;
      }
      state.isLoading = false;
    });
    builder.addCase(getComplainsRequest.rejected, (state) => {
      state.errorMessage = "Error :: Case rejected";
      state.isLoading = false;
    });

    builder.addCase(sendReviewComplainRequest.pending, (state) => {
      state.errorMessage = "";
      state.isLoading = true;
    });
    builder.addCase(sendReviewComplainRequest.fulfilled, (state, action) => {
      // console.log(action);
      if (action.payload.code === 1000) {
        state.lastReview = action.payload.data;
        toast.success("Success");
        state.complains = state.complains.map((item: Complain) =>
          item._id === action.meta.arg.complainId
            ? {
                ...item,
                reviewStatus: action.payload.data.reviewStatus
              }
            : item
        );
      }
      if (action.payload.code === 2004) {
        toast.error("Ups Bad Request");
      } else if (action.payload.code === 2001) {
        toast.error("Ups error Database (call support)");
      } else if (action.payload.code === 2003) {
        toast.error("Ups, point yang dimasukan tidak boleh lebih besar dari total belanja");
      } else if (action.payload.code === 2002) {
        toast.error("Complain sudah di Review");
      } else if (action.payload.code === 2000) {
        toast.error("Proses Review gagal");
      }
      //  else {
      //   state.errorMessage = "Error :: with status code" + action.payload.code;
      // }
      state.isLoading = false;
    });
    builder.addCase(sendReviewComplainRequest.rejected, (state) => {
      state.errorMessage = "Error :: Case rejected";
      state.isLoading = false;
    });
  }
});

// Action creators are generated for each case reducer function
export const {} = complainSlice.actions;

export default complainSlice.reducer;
