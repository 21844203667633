import { OptionType } from "@typed/HRIS/Employe";
import React from "react";
import Select, { ActionMeta, SingleValue } from "react-select";

interface IProps {
  options?: any;
  defaultValue?: string;
  name?: string;
  className?: string;
  value?: string;
  onChange?:
    | ((newValue: SingleValue<OptionType>, actionMeta: ActionMeta<OptionType>) => void)
    | undefined;
  placeholder?: string | undefined;
}

const SelectComponents: React.FC<IProps> = ({
  options,
  name,
  defaultValue,
  placeholder,
  onChange,
  className,
  value
}) => {
  return (
    <Select
      options={options}
      name={name}
      placeholder={placeholder}
      value={value}
      defaultValue={defaultValue}
      className={`react-select-container `}
      classNamePrefix="react-select"
      onChange={onChange}
    />
  );
};

export default SelectComponents;
