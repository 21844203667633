import React, { Children, useState } from "react";
import useRole from "@hooks/useRole";
import { useAppSelector } from "@stores/store";
import Forbidden from "@components/forbidden";
import "react-pro-sidebar/dist/css/styles.css";
import { Link, Outlet } from "react-router-dom";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { PieChartOutlined } from "@ant-design/icons";
import {
  IoBeerOutline,
  IoMegaphoneOutline,
  IoPeopleOutline,
  IoStorefrontOutline,
  IoTicketOutline
} from "react-icons/io5";
import { GiTakeMyMoney } from "react-icons/gi";
import { MdSwitchAccount, MdOutlineNotificationsActive } from "react-icons/md";
import { BsNewspaper, BsFillPinFill } from "react-icons/bs";
import { CgProfile } from "react-icons/cg";
import { TbBrandReactNative } from "react-icons/tb";
import { RiCopperCoinFill, RiQuestionnaireLine } from "react-icons/ri";

const Dailyapp = () => {
  const auth = useAppSelector((state) => state.auth);

  const isForbidden = !useRole(auth.employeeData.roles, "dailyapp");
  const [collapsed, setCollapsed] = useState(false);

  return isForbidden ? (
    <Forbidden />
  ) : (
    <div className="flex mt-[70px]   flex-row">
      <div className={"w-[200px]"}>
        <ProSidebar width={200} className="fixed">
          <Menu iconShape="square">
            <SubMenu title="Products" icon={<IoBeerOutline />}>
              <MenuItem>
                <Link to="products/add">Add a product</Link>
              </MenuItem>
              <MenuItem>
                <Link to="products">Products</Link>
              </MenuItem>
            </SubMenu>
            <SubMenu title="Outlet" icon={<IoStorefrontOutline />}>
              <MenuItem icon={<IoStorefrontOutline />}>
                <Link to={"outlets"}>Outlet</Link>
              </MenuItem>
              <MenuItem icon={<MdSwitchAccount />}>
                <Link to={"outlet-account"}>Outlet Account</Link>
              </MenuItem>
            </SubMenu>
            <SubMenu title="Users" icon={<IoPeopleOutline />}>
              <MenuItem>
                <Link to="users">Lists</Link>
              </MenuItem>
              <MenuItem>
                <Link to="membership">Membership</Link>
              </MenuItem>
            </SubMenu>
            <SubMenu title="Q&A" icon={<RiQuestionnaireLine />}>
              <MenuItem>
                <Link to="questions-batch">Q&A Batch</Link>
              </MenuItem>

              <MenuItem>
                <Link to="questions">Questions</Link>
              </MenuItem>

              <MenuItem>
                <Link to="answer">Answer</Link>
              </MenuItem>
            </SubMenu>

            <SubMenu title="Voucher" icon={<IoTicketOutline />}>
              <MenuItem key="vouchers">
                <Link to="vouchers">Vouchers Available</Link>
              </MenuItem>
              <MenuItem key="voucher-history">
                <Link to="voucher-history">Voucher History</Link>
              </MenuItem>
              <MenuItem key="voucher-report">
                <Link to="voucher-report">Voucher Report</Link>
              </MenuItem>
            </SubMenu>
            <SubMenu title="Feedback" icon={<IoMegaphoneOutline />}>
              <MenuItem>
                <Link to="reviews">Reviews</Link>
              </MenuItem>
              <MenuItem>
                <Link to="complains">Complains</Link>
              </MenuItem>
            </SubMenu>

            <MenuItem key="transaction" icon={<GiTakeMyMoney />}>
              <Link to="transaction">Transactions</Link>
            </MenuItem>
            <MenuItem key="news" icon={<BsNewspaper />}>
              <Link to="news">News</Link>
            </MenuItem>
            <MenuItem key="banner" icon={<BsFillPinFill />}>
              <Link to="banner">Banner</Link>
            </MenuItem>
            <MenuItem key="bannerprofile" icon={<CgProfile />}>
              <Link to="banner-profile">Banner Profile</Link>
            </MenuItem>
            <MenuItem key="banner-dailycoin" icon={<RiCopperCoinFill />}>
              <Link to="banner-dailycoin">Banner Dailycoin</Link>
            </MenuItem>
            <MenuItem key="bannerbrand" icon={<TbBrandReactNative />}>
              <Link to="banner-brand">Banner Brand</Link>
            </MenuItem>

            <MenuItem key="push-notification" icon={<MdOutlineNotificationsActive />}>
              <Link to="push-notification">Push Notification</Link>
            </MenuItem>
          </Menu>
        </ProSidebar>
      </div>
      <div className="flex-1 overflow-hidden p-5 bg-slate-100 min-h-screen">
        <Outlet />
      </div>
    </div>
  );
};

export default React.memo(Dailyapp);
