import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "@stores/store";
import { DatePicker, DatePickerProps, Table } from "antd";
import moment from "moment";
import {
  getCutOffOriginRequest,
  getCutOffByPeriodeRequest
} from "@stores/dailycoin/ReportingSlice";
import MyTable from "@components/Table/MyTable";

const CutOffOrigin = () => {
  const dispatch = useAppDispatch();
  const storeCuttOff = useAppSelector((state) => state.dailyReport.cutOffOrigin);
  const startDate = useRef(moment().startOf("month").format("YYYY-MM-DD"));
  const endDate = useRef(moment().format("YYYY-MM-DD"));

  const handleStartDate: DatePickerProps["onChange"] = (date: any, dateString: any) => {
    startDate.current = dateString;
  };

  const handleEndDate: DatePickerProps["onChange"] = (date: any, dateString: any) => {
    endDate.current = dateString;
  };

  useEffect(() => {
    dispatch(getCutOffByPeriodeRequest({ startDate: startDate.current, endDate: endDate.current }));
  }, []);

  const filterByDate = () => {
    dispatch(getCutOffByPeriodeRequest({ startDate: startDate.current, endDate: endDate.current }));
  };

  return (
    <>
      <div className=" flex items-center gap-4">
        <h1 className="m-0">Filter</h1>

        <DatePicker
          className="h-9"
          name="startDate"
          onChange={handleStartDate}
          placeholder="Start date"
        />

        <DatePicker
          className="h-9"
          name="endDate"
          onChange={handleEndDate}
          placeholder="End date"
        />

        <button
          onClick={filterByDate}
          className="flex text-medium items-center gap-1 text-center outline-none h-9 w-[10rem] rounded bg-blue-500 hover:bg-blue-400 text-white justify-center"
        >
          Submit
        </button>
      </div>
      <div className="mt-5">
        <MyTable
          dataSource={storeCuttOff}
          fileName="cut-off-coin"
          searchable
          exportable
          select={[
            { label: "Date", value: "date" },
            { label: "Customer", value: "customer" },
            { label: "Transaction", value: "transaction" },
            { label: "Total Coin", value: "totalCoin" },
            { label: "Notes", value: "notes" }
          ]}
        />
      </div>
    </>
  );
};

export default CutOffOrigin;
