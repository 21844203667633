import React, { useEffect } from "react";
import { Button } from "antd";
import { useAppDispatch } from "@stores/store";
import { getAnswerReq } from "@stores/dailyapp/QuestionsAnwerSlice";

const Dashboard = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAnswerReq());
  }, []);
  return <div></div>;
};

export default Dashboard;
