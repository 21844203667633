import API from "@constants/API";
import { IPayloadHRIS } from "@typed/HRIS/allHris";

const BASE_URL = "https://api.dev.daily.co.id/hris/status/dashboard/v1/status";

export const getStatusAPI = async () => {
  const response = await API.HTTP.get(BASE_URL + "es");
  return response.data;
};

export const getDetailStatusAPI = async (id: string) => {
  const response = await API.HTTP.get(`${BASE_URL}/${id}`);
  return response.data;
};

export const postStatusAPI = async (Data: object) => {
  const response = await API.HTTP.post(`${BASE_URL}`, Data);
  return response.data;
};

export const putStatusAPI = async ({ id, Data }: IPayloadHRIS) => {
  const response = await API.HTTP.put(`${BASE_URL}/${id}`, Data);
  return response.data;
};

export const putActiveStatusAPI = async (id: string, isActive: boolean) => {
  const Data = {
    isActived: isActive ? false : true
  };
  const response = await API.HTTP.put(`${BASE_URL}/${id}`, Data);
  return response.data;
};

export const putDeleteStatusAPI = async (id: string, isDelete: boolean) => {
  const Data = {
    isDeleted: isDelete === false && true
  };
  const response = await API.HTTP.put(`${BASE_URL}/${id}`, Data);
  return response.data;
};
