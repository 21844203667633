import React from "react";

const SkeletonImage = () => {
  return (
    <>
      <div className="flex justify-center">
        <div className="w-[20rem] h-[20rem] animate-pulse rounded-md bg-gray-300"></div>
      </div>
    </>
  );
};

export default SkeletonImage;
