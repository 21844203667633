import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
  getAllCustomerAPI,
  getDetailCustomerAPI,
  getCoinCustomerAPI,
  getTransactionCustomerAPI,
  postCutDailycoinAPI,
  getInboxWebAPI,
  getuserSPBAPI,
  getUserAcquisitionAPI,
  getUserReportAPI,
  getCashbackReportAPI,
  getCashbackOriginReportAPI
} from "./CEXPAPI";
import { ICoinCustomer, ICustomer, IDetailCustomer } from "@typed/Customer";
import { getDetailEventAPI, getEventAPI } from "./EventAPI";
import swal from "sweetalert";
import { any } from "ramda";
import state from "sweetalert/typings/modules/state";

export const getCustomerReq = createAsyncThunk("CEXP/getCustomerReq", async () => {
  const response = await getAllCustomerAPI();
  return response;
});

export const getDetailCustomerReq = createAsyncThunk(
  "CEXP/getDetailCustomerReq",
  async (id: string) => {
    const response = await getDetailCustomerAPI(id);
    return response;
  }
);

export const getCoinCustomerReq = createAsyncThunk(
  "CEXP/getCoinCustomerReq",
  async (id: string) => {
    const response = await getCoinCustomerAPI(id);
    return response;
  }
);

export const getTrxCostumerReq = createAsyncThunk("CEXP/getTrxCostumerReq", async (id: string) => {
  const response = await getTransactionCustomerAPI(id);
  return response;
});

// ==== EVENT CLICK
export const getEventRequest = createAsyncThunk("CEXP/getEventRequest", async () => {
  const response = await getEventAPI();
  return response;
});

export const getDetailEventRequest = createAsyncThunk(
  "CEXP/getDetailEventRequest",
  async (id: string) => {
    const response = await getDetailEventAPI(id);
    return response;
  }
);

// === CUTOFFDAILY
export const postCutCoinRequest = createAsyncThunk(
  "CEXP/postCutCoinRequest",
  async (data: object) => {
    const response = await postCutDailycoinAPI(data);
    return response;
  }
);

//INBOX WEB IN COMPANY PROFILE
export const getInboxWebRequest = createAsyncThunk(
  "dailyapp-report/getInboxWebRequest",
  async (payload: any) => {
    const response = await getInboxWebAPI(payload.page);
    return response;
  }
);

export const getUserSPBRequest = createAsyncThunk(
  "dailyapp-report/getUserSPBRequest",
  async ({ startDate, endDate }: { startDate: any; endDate: any }) => {
    const response = await getuserSPBAPI(startDate, endDate);
    return response;
  }
);

// User Acquisition
export const getUserAcquisitionReq = createAsyncThunk(
  "dailyapp-report/getUserAcquisitionReq",
  async ({ startDate, endDate }: { startDate: any; endDate: any }) => {
    const response = await getUserAcquisitionAPI(startDate, endDate);
    return response;
  }
);

// User Report
export const getUserReportReq = createAsyncThunk(
  "dailyapp-report/getUserReportReq",
  async ({ startDate, endDate }: { startDate: any; endDate: any }) => {
    const response = await getUserReportAPI(startDate, endDate);
    return response;
  }
);

// Cashback Report
export const getCashbackReportReq = createAsyncThunk(
  "dailyapp-report/getCashbackReportReq",
  async ({ startDate, endDate }: { startDate: any; endDate: any }) => {
    const response = await getCashbackReportAPI(startDate, endDate);
    return response;
  }
);

// Cashback Origin Report
export const getCashbackOriginReportReq = createAsyncThunk(
  "dailyapp-report/getCashbackOriginReportReq",
  async ({ startDate, endDate }: { startDate: any; endDate: any }) => {
    const response = await getCashbackOriginReportAPI(startDate, endDate);
    return response;
  }
);

interface IBidders {
  dealCoin: number;
  id: string;
  name: string;
  phone: string;
  user: string;
  _id: string;
}

interface IDetailEvent {
  baseRate: number;
  coinEveryClick: number;
  bidders: IBidders[];
  image: string;
  nameProduct: string;
  _id: { id: string };
}

interface IStoreCE {
  isLoading: boolean;
  inboxWeb: any[];
  totalInboxWeb: number;
  dataCustomer: ICustomer[];
  detailCustomer: IDetailCustomer;
  coinCustomer: ICoinCustomer;
  tenLastTrx: [] | any[];
  dataEvent: [] | any[];
  cutCoin: any;
  detailEvent: IDetailEvent;
  userSPB: any[] | [];
  userAcquisition: any[] | [];
  userReport: any[] | [];
  cashbackReport: any[] | [];
  cashbackOriginReport: any[] | [];
}

const initialState: IStoreCE = {
  isLoading: false,
  inboxWeb: [],
  totalInboxWeb: 0,
  dataCustomer: [],
  detailCustomer: {} as IDetailCustomer,
  coinCustomer: {} as ICoinCustomer,
  tenLastTrx: [],
  dataEvent: [],
  detailEvent: {} as IDetailEvent,
  cutCoin: 0,
  userSPB: [],
  userAcquisition: [],
  userReport: [],
  cashbackReport: [],
  cashbackOriginReport: []
};

const CExpReducer = createSlice({
  name: "CEXP",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getCustomerReq.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCustomerReq.fulfilled, (state, action) => {
      action.payload.code === 1000 ? (state.dataCustomer = action.payload.data) : undefined;
      state.isLoading = false;
    });
    builder.addCase(getCustomerReq.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getInboxWebRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getInboxWebRequest.fulfilled, (state, action) => {
      state.inboxWeb = action.payload.data;
      state.totalInboxWeb = action.payload.meta.pagination.total;
      state.isLoading = false;
    });
    builder.addCase(getInboxWebRequest.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getDetailCustomerReq.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getDetailCustomerReq.fulfilled, (state, action) => {
      action.payload.code === 1000 ? (state.detailCustomer = action.payload.data) : undefined;
      state.isLoading = false;
    });
    builder.addCase(getDetailCustomerReq.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getCoinCustomerReq.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCoinCustomerReq.fulfilled, (state, action) => {
      action.payload.code === 1000 ? (state.coinCustomer = action.payload.data) : undefined;
      state.isLoading = false;
    });
    builder.addCase(getCoinCustomerReq.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getTrxCostumerReq.pending, (state) => {});
    builder.addCase(getTrxCostumerReq.fulfilled, (state, action) => {
      action.payload.code === 1000 ? (state.tenLastTrx = action.payload.data) : undefined;
    });
    builder.addCase(getTrxCostumerReq.rejected, (state) => {});

    // ===== STORE EVENT CLICK
    builder.addCase(getEventRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getEventRequest.fulfilled, (state, action) => {
      state.dataEvent = action.payload.data;
      state.isLoading = false;
    });
    builder.addCase(getEventRequest.rejected, (state) => {
      state.isLoading = false;
    });
    //
    builder.addCase(getDetailEventRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getDetailEventRequest.fulfilled, (state, action) => {
      state.detailEvent = action.payload.data;
      state.isLoading = false;
    });
    builder.addCase(getDetailEventRequest.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(postCutCoinRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(postCutCoinRequest.fulfilled, (state, action) => {
      if (action.payload.code === 1000) {
        swal("Sukses", "Coin telah terpotong", "success");
      } else if (action.payload.code === 2002) {
        swal("Ups!", "Terjadi error di sisi server", "error");
      } else if (action.payload.code === 2004) {
        swal("Ups!", "Coin tidak cukup", "error");
      } else {
        swal("Ups!", "Terjadi kesalahan", "error");
      }
      state.isLoading = false;
    });
    builder.addCase(postCutCoinRequest.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getUserSPBRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getUserSPBRequest.fulfilled, (state, action) => {
      state.userSPB = action.payload.data;
      state.isLoading = false;
    });
    builder.addCase(getUserSPBRequest.rejected, (state) => {
      state.isLoading = false;
    });

    // User Acquisition
    builder
      .addCase(getUserAcquisitionReq.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserAcquisitionReq.fulfilled, (state, action) => {
        state.userAcquisition = action.payload.data;
        state.isLoading = false;
      })
      .addCase(getUserAcquisitionReq.rejected, (state) => {
        state.isLoading = false;
      });

    // User Report
    builder
      .addCase(getUserReportReq.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserReportReq.fulfilled, (state, action) => {
        state.userReport = action.payload.data;
        state.isLoading = false;
      })
      .addCase(getUserReportReq.rejected, (state) => {
        state.isLoading = false;
      });

    //  Cashback Report
    builder
      .addCase(getCashbackReportReq.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCashbackReportReq.fulfilled, (state, action) => {
        state.cashbackReport = action.payload.data;
        state.isLoading = false;
      })
      .addCase(getCashbackReportReq.rejected, (state) => {
        state.isLoading = false;
      });

    // Cashback Origin Report
    builder
      .addCase(getCashbackOriginReportReq.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCashbackOriginReportReq.fulfilled, (state, action) => {
        state.cashbackOriginReport = action.payload.data;
        state.isLoading = false;
      })
      .addCase(getCashbackOriginReportReq.rejected, (state) => {
        state.isLoading = false;
      });
  }
});

export default CExpReducer.reducer;
