import API from "@constants/API";
import { IPayloadHRIS } from "@typed/HRIS/allHris";

const BASE_URL = "https://api.dev.daily.co.id/hris/ptkp/dashboard/v1/ptkp";

export const getPtkpAPI = async () => {
  const response = await API.HTTP.get(BASE_URL);
  return response.data;
};

export const getDetailPtkpAPI = async (id: string) => {
  const response = await API.HTTP.get(`${BASE_URL}/${id}`);
  return response.data;
};

export const postPtkpAPI = async (Data: object) => {
  const response = await API.HTTP.post(BASE_URL, Data);
  return response.data;
};

export const putPtkpAPI = async ({ id, Data }: IPayloadHRIS) => {
  const response = await API.HTTP.put(`${BASE_URL}/${id}`, Data);
  return response.data;
};

export const putActivePtkpAPI = async (id: string, isActive: boolean) => {
  const Data = {
    isActived: isActive ? false : true
  };
  const response = await API.HTTP.put(`${BASE_URL}/${id}`, Data);
  return response.data;
};

export const putDeletePtkpAPI = async (id: string, isDelete: boolean) => {
  const Data = {
    isDeleted: isDelete === false && true
  };
  const response = await API.HTTP.put(`${BASE_URL}/${id}`, Data);
  return response.data;
};
