import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import { IDataHRIS, IHRISStore, IPayloadHRIS } from "@typed/HRIS/allHris";
import toast from "react-hot-toast";
import {
  getPositionsAPI,
  getDetailPositionAPI,
  postPositionAPI,
  putPositionAPI,
  putActivePositionAPI,
  putDeletedPositionAPI
} from "./PositionAPI";

export const getPositionRequest = createAsyncThunk("hris-position/getPositionRequest", async () => {
  const response = await getPositionsAPI();

  return response;
});

export const getDetailPositionRequest = createAsyncThunk(
  "hris-position/getDetailPositionRequest",
  async (id: string) => {
    const response = await getDetailPositionAPI(id);

    return response;
  }
);

export const postPositionRequest = createAsyncThunk(
  "hris-position/postPositionRequest",
  async (Data: object) => {
    const response = await postPositionAPI(Data);

    return response;
  }
);

export const putPositionRequest = createAsyncThunk(
  "hris-position/pustPositionRequest",
  async ({ id, Data }: IPayloadHRIS) => {
    const response = await putPositionAPI({ id, Data });

    return response;
  }
);

export const putActivePositionRequest = createAsyncThunk(
  "hris-position/putActivePositionRequest",
  async ({ id, isActive }: { id: string; isActive: boolean }) => {
    const response = await putActivePositionAPI(id, isActive);
    return response;
  }
);

export const putDeletePositionRequest = createAsyncThunk(
  "hris-position/putDeletePositionRequest",
  async ({ id, isDelete }: { id: string; isDelete: boolean }) => {
    const response = await putDeletedPositionAPI(id, isDelete);
    return response;
  }
);

const initialState: IHRISStore = {
  data: [],
  isLoading: false,
  dataDetail: {} as IDataHRIS,
  lastCreateData: {} as IDataHRIS,
  deletedRedirect: false
  // dataDetail:
};

const PositionSlice = createSlice({
  name: "hris-position",
  initialState: initialState,
  reducers: {
    resetPosition: (state: { lastCreateData: IDataHRIS }) => {
      state.lastCreateData = {} as IDataHRIS;
    },
    deletedPosition: (state: { deletedRedirect: boolean }) => {
      state.deletedRedirect = false;
    }
  },
  extraReducers(builder) {
    builder.addCase(getPositionRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getPositionRequest.fulfilled, (state, action) => {
      if (action.payload.code === 1000) {
        state.data = action.payload.data;
      }
      state.isLoading = false;
    });
    builder.addCase(getPositionRequest.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getDetailPositionRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getDetailPositionRequest.fulfilled, (state, action) => {
      if (action.payload.code === 1000) {
        state.dataDetail = action.payload.data;
      }
      state.isLoading = false;
    });
    builder.addCase(getDetailPositionRequest.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(postPositionRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(postPositionRequest.fulfilled, (state, action) => {
      if (action.payload.code === 1000) {
        state.lastCreateData = action.payload.data;
        toast.success("Success");
      }
      if (action.payload.code === 2004) {
        toast.error("Please enter an empty field");
      }
      state.isLoading = false;
    });
    builder.addCase(postPositionRequest.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(putPositionRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(putPositionRequest.fulfilled, (state, action) => {
      if (action.payload.code === 1000) {
        state.lastCreateData = action.payload.data;
        toast.success("Update Success");
      }
      state.isLoading = false;
    });
    builder.addCase(putPositionRequest.rejected, (state) => {
      state.isLoading = false;
    });

    //
    builder.addCase(putActivePositionRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(putActivePositionRequest.fulfilled, (state, action) => {
      if (action.payload.code === 1000) {
        state.dataDetail.isActived = action.payload.data.isActived;
        const stats = state.dataDetail.isActived;
        toast.success(stats ? "Active Success" : "Inactive Success");
      }
      state.isLoading = false;
    });
    builder.addCase(putActivePositionRequest.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(putDeletePositionRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(putDeletePositionRequest.fulfilled, (state, action) => {
      if (action.payload.code === 1000) {
        toast.success("Delete Success");
        state.deletedRedirect = true;
      }
      state.isLoading = false;
    });
    builder.addCase(putDeletePositionRequest.rejected, (state) => {
      state.isLoading = false;
    });
  }
});

export default PositionSlice.reducer;
export const { deletedPosition, resetPosition } = PositionSlice.actions;
