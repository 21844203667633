import API from "@constants/API";

const BASE_URL = "https://api.prod.daily.co.id/daily/event/v1/click-event";

export const getEventAPI = async () => {
  const response = await API.HTTP.get(
    "https://api.prod.daily.co.id/click/product/no/image/dashboard/v1/click-product/dashboard"
  );
  return response.data;
};

export const getDetailEventAPI = async (id: string) => {
  const response = await API.HTTP.get(`${BASE_URL}/${id}`);
  return response.data;
};
