import API from "@constants/API";
import { IDetailEmployeID } from "@typed/HRIS/Employe";
import { IInfoAPI } from "@typed/HRIS/Person";
import React from "react";
import toast from "react-hot-toast";
const BASE_URL = "https://api.dev.daily.co.id/hris/employee/dashboard/v1/employee";

const BASE_URL_SINGLE =
  "https://api.dev.daily.co.id/hris/detail-employee/dashboard/v1/employee-person/?employeeId=";

const BASE_URL_APPROVE =
  "https://api.dev.daily.co.id/hris/employee-parent/dashboard/v1/employee-parent";

export const getEmployeAPI = async () => {
  const response = await API.HTTP.get(BASE_URL + "s");
  return response.data;
};

export const postEmployeAPI = async (
  Data: object,
  setInfoAPI: React.Dispatch<React.SetStateAction<IInfoAPI>>
) => {
  try {
    // setInfoAPI(true);
    setInfoAPI({
      isLoading: true,
      isCode: 0
    });
    await API.HTTP.post(BASE_URL, Data).then(({ data }) => {
      if (data.code === 1000) {
        setInfoAPI({
          isLoading: false,
          isCode: data.code
        });
      } else if (data.code !== 1000) {
        setInfoAPI({ isLoading: false, isCode: data.code });
      }
    });
  } catch (err) {
    setInfoAPI({
      isLoading: false,
      isCode: 0
    });
  }
};

interface IPayloadDetail {
  id: string;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setDetailEmploye: React.Dispatch<React.SetStateAction<IDetailEmployeID>>;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export const getDetailEmployeAPI = async ({
  id,
  setIsLoading,
  setDetailEmploye,
  setVisible
}: IPayloadDetail) => {
  try {
    setIsLoading(true);
    await API.HTTP.get(`${BASE_URL_SINGLE}${id}`).then((res) => {
      if (res.data.code === 1000) {
        setDetailEmploye(res.data.data);
      } else if (res.data.code === 2004) {
        toast.error(res.data.error);
        setVisible(false);
      }
      setIsLoading(false);
    });
  } catch (err: any) {
    setIsLoading(false);
  }
};

export const postApprovalAPI = async (
  data: object,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setIsLoading(true);

  const response = await API.HTTP.post(BASE_URL_APPROVE, data);
  const Data = response.data;
  if (Data.code === 1000) {
    toast.success("Success");
  } else if (Data.code === 2004) {
    toast.error(Data.error);
  }
  setIsLoading(false);
};

export const putEmployeAPI = async (id: string, data: object) => {
  const response = await API.HTTP.put(`${BASE_URL}/${id}`, data);
  return response.data;
};
