import API from "@constants/API";

const BASE_URL = "https://api.prod.daily.co.id/dashboard/news-banner/v3/dashboard-news-banner/";

export const getBannerAPI = async () => {
  const response = await API.HTTP.get(BASE_URL + "?type=banner");
  return response.data;
};

export const detailBannerAPI = async (id: string) => {
  const response = await API.HTTP.get(`${BASE_URL}${id}?type=banner`);
  return response.data;
};

export const postBannerAPI = async (data: object) => {
  const response = await API.HTTP.post(`${BASE_URL}?type=banner`, data);
  return response.data;
};

export const putBannerAPI = async (data: object, id: string) => {
  const response = await API.HTTP.put(`${BASE_URL}${id}?type=banner`, data);
  return response.data;
};

// ==== || ====

export const getBannerProfileAPI = async () => {
  const response = await API.HTTP.get(BASE_URL + "?type=banner-profile");
  return response.data;
};

export const postBannerProfileAPI = async (data: object) => {
  const response = await API.HTTP.post(`${BASE_URL}?type=banner-profile`, data);
  return response.data;
};

export const putBannerProfileAPI = async (data: object, id: string) => {
  const response = await API.HTTP.put(`${BASE_URL}${id}?type=banner-profile`, data);
  return response.data;
};

export const deleteBannerProfileAPI = async (id: string) => {
  const response = await API.HTTP.delete(`${BASE_URL}${id}?type=banner-profile`);
  return response.data;
};

export const detailBannerProfileAPI = async (id: string) => {
  const response = await API.HTTP.get(`${BASE_URL}${id}?type=banner-profile`);
  return response.data;
};

// ==== || ====

export const getBannerBrandAPI = async (type: string) => {
  const response = await API.HTTP.get(`${BASE_URL}?type=${type}`);
  return response.data;
};

export const postBannerBrandAPI = async (data: object, type: string) => {
  const response = await API.HTTP.post(`${BASE_URL}?type=${type}`, data);
  return response.data;
};

export const putBannerBrandAPI = async (data: object, id: string) => {
  const response = await API.HTTP.put(`${BASE_URL}${id}?type=banner-brand`, data);
  return response.data;
};

export const deleteBannerBrandAPI = async (id: string) => {
  const response = await API.HTTP.delete(`${BASE_URL}${id}?type=banner-brand`);
  return response.data;
};

export const detailBannerBrandAPI = async (id: string) => {
  const response = await API.HTTP.get(`${BASE_URL}${id}?type=banner-brand`);
  return response.data;
};

// ==== || ===

export const getBannerDialycoinAPI = async () => {
  const response = await API.HTTP.get(BASE_URL + "?type=banner-dailycoin");
  return response.data;
};

export const postBannerDailycoinAPI = async (data: object) => {
  const response = await API.HTTP.post(`${BASE_URL}?type=banner-dailycoin`, data);
  return response.data;
};

export const putBannerDailycoinAPI = async (data: object, id: string) => {
  const response = await API.HTTP.put(`${BASE_URL}${id}?type=banner-dailycoin`, data);
  return response.data;
};

export const deleteBannerDailycoinAPI = async (id: string) => {
  const response = await API.HTTP.delete(`${BASE_URL}${id}?type=banner-dailycoin`);
  return response.data;
};

export const detailBannerDailycoinAPI = async (id: string) => {
  const response = await API.HTTP.get(`${BASE_URL}${id}?type=banner-dailycoin`);
  return response.data;
};
