import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getVoucherAPI,
  postVoucherAPI,
  ICreateVoucher,
  getIdProductAPI,
  getVoucherHistoryAPI,
  getVoucherReportAPI,
  postConfirmVoucherAPI,
  IVoucherReport,
  getVoucherAvailableAPI,
  getVoucherTakenAPI,
  getVoucherRedeemedAPI,
  getVoucherRegisterAPI,
  getVoucherCreatedAPI,
  postRegisterVoucherAPI,
  postVerifyVoucherAPI,
  postInjectVoucherAPI,
  getVoucherOnRegisterAPI,
  getVoucherProductAPI,
  getVoucherHistoryOutletAPI,
  getVoucherUserAPI,
  updateVoucherExpiredAPI
} from "./VoucherAPI";
import swal from "sweetalert";

export const getVoucherReq = createAsyncThunk(
  "dailyapp-voucher/getVoucherReq",
  async (payload: string) => {
    const response = await getVoucherAPI(payload);
    return response;
  }
);

export const postVoucherReq = createAsyncThunk(
  "dailyapp-voucher/postVoucherReq",
  async (payload: ICreateVoucher) => {
    const response = await postVoucherAPI(payload);
    return response;
  }
);

export const getIdProductReq = createAsyncThunk("dailyapp-voucher/getIdProductReq", async () => {
  const res = await getIdProductAPI();
  return res;
});

export const getVoucherHistoryReq = createAsyncThunk(
  "dailyapp-voucher/getVoucherHistoryReq",
  async () => {
    const res = await getVoucherHistoryAPI();
    return res;
  }
);

export const getVoucherReportReq = createAsyncThunk(
  "dailyapp-voucher/getVoucherReportReq",
  async (payload: IVoucherReport) => {
    const res = await getVoucherReportAPI(payload);
    return res;
  }
);

export const postConfirmVoucherReq = createAsyncThunk(
  "dailyapp-voucher/postConfirmVoucherReq",
  async () => {
    const res = await postConfirmVoucherAPI();
    return res;
  }
);

export const getVoucherAvailableReq = createAsyncThunk(
  "dailyapp-voucher/getVoucherAvailableReq",
  async () => {
    const res = await getVoucherAvailableAPI();
    return res;
  }
);

export const getVoucherTakenReq = createAsyncThunk(
  "dailyapp-voucher/getVoucherTakenReq",
  async () => {
    const res = await getVoucherTakenAPI();
    return res;
  }
);

export const getVoucherRedeemedReq = createAsyncThunk(
  "dailyapp-voucher/getVoucherRedeemedReq",
  async () => {
    const res = await getVoucherRedeemedAPI();
    return res;
  }
);

export const getVoucherRegisterESBReq = createAsyncThunk(
  "dailyapp-voucher/getVoucherRegisterESBReq",
  async () => {
    const res = await getVoucherRegisterAPI();
    return res;
  }
);

export const getVoucherCreatedReq = createAsyncThunk(
  "dailyapp-voucher/getVoucherCreatedReq",
  async () => {
    const res = await getVoucherCreatedAPI();
    return res;
  }
);

export const postRegisterVoucherReq = createAsyncThunk(
  "dailyapp-voucher/postRegisterVoucherReq",
  async () => {
    const res = await postRegisterVoucherAPI();
    return res;
  }
);

export const postVerifyVoucherReq = createAsyncThunk(
  "dailyapp-voucher/postVerifyVoucherReq",
  async (payload: any) => {
    const res = await postVerifyVoucherAPI(payload);
    return res;
  }
);

export const postInjectVoucherReq = createAsyncThunk(
  "dailyapp-voucher/postInjectVoucherReq",
  async (payload: any) => {
    const res = await postInjectVoucherAPI(payload);
    return res;
  }
);

export const getVoucherOnRegisterReq = createAsyncThunk(
  "dailyapp-voucher/getVoucherOnRegisterReq",
  async (id: string) => {
    const res = await getVoucherOnRegisterAPI(id);
    return res;
  }
);

export const getVoucherProductReq = createAsyncThunk(
  "dailyapp-voucher/getVoucherProduct",
  async () => {
    const res = await getVoucherProductAPI();
    return res;
  }
);

export const getVoucherHistoryOutletReq = createAsyncThunk(
  "dailyapp-voucher/getVoucherHistoryOutletReq",
  async (payload: IVoucherReport) => {
    const res = await getVoucherHistoryOutletAPI(payload);
    return res;
  }
);

export const getVoucherUserReq = createAsyncThunk(
  "dailyapp-voucher/getVoucherUserReq",
  async (phone: string, thunkAPI) => {
    const res = await getVoucherUserAPI(phone);
    thunkAPI.dispatch(getPhoneNumberUser(phone));
    return res;
  }
);

export const updateVoucherExpiredReq = createAsyncThunk(
  "dailyapp-voucher/updateVoucherExpiredReq",
  async (salesId: string) => {
    const res = await updateVoucherExpiredAPI(salesId);
    return res;
  }
);

interface IVoucherHistory {
  available: any[];
  created: any[];
  taken: any[];
}

interface IInitialStore {
  voucherHistory: IVoucherHistory | any;
  allVoucher: any[] | [];
  idProduct: any[] | [];
  filterBrands: any[] | [];
  codeCreateVoucher: number;
  isLoadingVoucher: boolean;
  VoucherCSV: any[] | [];
  voucherReport: any[] | [];
  VoucherReportCSV: any[] | [];
  voucherAvailable: any[] | [];
  voucherTaken: any[] | [];
  voucherRedeemed: any[] | [];
  voucherRegister: any[] | [];
  voucherCreated: any[] | [];
  isLoadingAvailable: boolean;
  isLoadingTaken: boolean;
  isLoadingRedeemed: boolean;
  isLoadingRegister: boolean;
  isLoadingCreated: boolean;
  isLoadingVerify: boolean;
  isLoadingInjectVoucher: boolean;
  isLoadingVoucherOnReg: boolean;
  VoucherOnRegCSV: any[] | [];
  VoucherOnReg: any[] | [];
  VoucherHistoryByOutlet: any[] | [];
  VoucherOutletCSV: any[] | [];
  VoucherUser: any[] | [];
  phoneNumberUser: string;
  isLoadingHistoryByOutlet: boolean;
}

const initialState: IInitialStore = {
  voucherHistory: [],
  allVoucher: [],
  idProduct: [],
  filterBrands: [],
  codeCreateVoucher: 0,
  isLoadingVoucher: false,
  VoucherCSV: [],
  voucherReport: [],
  VoucherReportCSV: [],
  isLoadingAvailable: false,
  isLoadingTaken: false,
  isLoadingRedeemed: false,
  isLoadingRegister: false,
  isLoadingCreated: false,
  isLoadingInjectVoucher: false,
  isLoadingVoucherOnReg: false,
  voucherAvailable: [],
  voucherTaken: [],
  voucherRedeemed: [],
  voucherRegister: [],
  voucherCreated: [],
  isLoadingVerify: false,
  VoucherOnRegCSV: [],
  VoucherOnReg: [],
  VoucherHistoryByOutlet: [],
  VoucherOutletCSV: [],
  VoucherUser: [],
  phoneNumberUser: "",
  isLoadingHistoryByOutlet: false
};

export const voucherSlice = createSlice({
  name: "dailyapp-voucher",
  initialState: initialState,
  reducers: {
    getPhoneNumberUser: (state: IInitialStore, action: { payload: any }) => {
      state.phoneNumberUser = action.payload;
    },
    resetCodeVoucher: (state: any) => {
      state.codeCreateVoucher = 0;
    },
    VoucherExcel: (state: any, action: any) => {
      state.filterBrands = state.data.filter(
        (item: any, idx: number, Array: any[]) =>
          item.productId.filterField === action.payload && item.onRegister === true
      );
      state.VoucherCSV = state.filterBrands.map((item: any) => ({
        Code: item.code,
        Price: item.productId.price * 1000,
        Product: item.productId.filterField
      }));
    },
    VoucherOutletExcel: (state: IInitialStore) => {
      state.VoucherOutletCSV = state.VoucherHistoryByOutlet.map((item: any) => ({
        Date: item._id.date,
        Outlet: item._id.outlet,
        Antarasa: item.antarasaCount,
        "Antarasa Cashback": item.antarasaTotalCashback,
        "Ayam-amanah": item.ayamamanahCount,
        "Ayam-amanah Cashback": item.ayamamanahTotalCashback,
        Breadlife: item.breadlifeCount,
        "Breadlife Cashback": item.breadlifeTotalCashback,
        Dailybox: item.dailyboxCount,
        "Dailybox Cashback": item.dailyboxTotalCashback,
        Lumiere: item.lumiereCount,
        "Lumiere Cashback": item.lumiereTotalCashback,
        Shirato: item.shiratoCount,
        "Shirato Cashback": item.shiratoTotalCashback,
        Tiante: item.tianteaCount,
        "Tiantea Cashback": item.tianteaTotalCashback
      }));
    },
    VoucherReportExcel: (state: any) => {
      state.VoucherReportCSV = state.voucherReport.map((item: any) => ({
        Date: item.date,
        Username: item.name,
        Phone: item.phone,
        Brand: item.brand,
        Outlet: item.outlet,
        "Voucher Code": item.voucherCode,
        "Cut Amount": item.cutAmount
      }));
    },
    VoucherOnRegsiterCSV: (state: IInitialStore) => {
      state.VoucherOnRegCSV = state.VoucherOnReg?.map((item: any) => ({
        Id: item._id,
        Code: item.code,
        Price: item.price * 1000,
        Brand: item.brand
      }));
    }
  },
  extraReducers(builder) {
    builder.addCase(getVoucherReq.pending, (state) => {
      state.isLoadingVoucher = true;
    });
    builder.addCase(getVoucherReq.rejected, (state) => {
      state.isLoadingVoucher = false;
    });
    builder.addCase(getVoucherReq.fulfilled, (state, action) => {
      action.payload.code === 1000 ? (state.allVoucher = action.payload.data) : undefined;

      state.isLoadingVoucher = false;
    });
    builder.addCase(postVoucherReq.pending, (state) => {
      state.isLoadingVoucher = true;
    });
    builder.addCase(postVoucherReq.rejected, (state) => {
      state.isLoadingVoucher = false;
    });
    builder.addCase(postVoucherReq.fulfilled, (state, action) => {
      action.payload.code === 1000 ? (state.allVoucher = action.payload.data) : undefined;

      state.isLoadingVoucher = false;
    });
    builder.addCase(getIdProductReq.pending, (state) => {
      state.isLoadingVoucher = true;
    });
    builder.addCase(getIdProductReq.rejected, (state) => {
      state.isLoadingVoucher = false;
    });
    builder.addCase(getIdProductReq.fulfilled, (state, action) => {
      action.payload.code === 1000 ? (state.idProduct = action.payload.data) : undefined;
      state.codeCreateVoucher = action.payload.code;
      state.isLoadingVoucher = false;
    });
    builder.addCase(getVoucherHistoryReq.pending, (state) => {
      state.isLoadingVoucher = true;
    });
    builder.addCase(getVoucherHistoryReq.fulfilled, (state, action) => {
      action.payload.code === 1000 ? (state.voucherHistory = action.payload.data) : undefined;

      state.isLoadingVoucher = false;
    });
    builder.addCase(getVoucherHistoryReq.rejected, (state) => {
      state.isLoadingVoucher = false;
    });
    builder.addCase(getVoucherReportReq.pending, (state) => {
      state.isLoadingVoucher = true;
    });
    builder.addCase(getVoucherReportReq.fulfilled, (state, action) => {
      action.payload.code === 1000 ? (state.voucherReport = action.payload.data) : undefined;

      state.isLoadingVoucher = false;
    });
    builder.addCase(getVoucherReportReq.rejected, (state) => {
      state.isLoadingVoucher = false;
    });
    builder.addCase(getVoucherAvailableReq.pending, (state) => {
      state.isLoadingAvailable = true;
    });
    builder.addCase(getVoucherAvailableReq.fulfilled, (state, action) => {
      action.payload.code === 1000 ? (state.voucherAvailable = action.payload.data) : undefined;

      state.isLoadingAvailable = false;
    });
    builder.addCase(getVoucherAvailableReq.rejected, (state) => {
      state.isLoadingAvailable = false;
    });
    builder.addCase(getVoucherTakenReq.pending, (state) => {
      state.isLoadingTaken = true;
    });
    builder.addCase(getVoucherTakenReq.fulfilled, (state, action) => {
      action.payload.code === 1000 ? (state.voucherTaken = action.payload.data) : undefined;

      state.isLoadingTaken = false;
    });
    builder.addCase(getVoucherTakenReq.rejected, (state) => {
      state.isLoadingTaken = false;
    });
    builder.addCase(getVoucherRedeemedReq.pending, (state) => {
      state.isLoadingRedeemed = true;
    });
    builder.addCase(getVoucherRedeemedReq.fulfilled, (state, action) => {
      action.payload.code === 1000 ? (state.voucherRedeemed = action.payload.data) : undefined;

      state.isLoadingRedeemed = false;
    });
    builder.addCase(getVoucherRedeemedReq.rejected, (state) => {
      state.isLoadingRedeemed = false;
    });
    builder.addCase(getVoucherRegisterESBReq.pending, (state) => {
      state.isLoadingRegister = true;
    });
    builder.addCase(getVoucherRegisterESBReq.fulfilled, (state, action) => {
      action.payload.code === 1000 ? (state.voucherRegister = action.payload.data) : undefined;

      state.isLoadingRegister = false;
    });
    builder.addCase(getVoucherRegisterESBReq.rejected, (state) => {
      state.isLoadingRegister = false;
    });
    builder.addCase(getVoucherCreatedReq.pending, (state) => {
      state.isLoadingCreated = true;
    });
    builder.addCase(getVoucherCreatedReq.fulfilled, (state, action) => {
      action.payload.code === 1000 ? (state.voucherCreated = action.payload.data) : undefined;

      state.isLoadingCreated = false;
    });
    builder.addCase(getVoucherCreatedReq.rejected, (state) => {
      state.isLoadingCreated = false;
    });
    builder.addCase(postRegisterVoucherReq.pending, (state) => {
      state.isLoadingRegister = true;
    });
    builder.addCase(postRegisterVoucherReq.fulfilled, (state, action) => {
      state.isLoadingRegister = false;
    });
    builder.addCase(postRegisterVoucherReq.rejected, (state) => {
      state.isLoadingRegister = false;
    });
    builder.addCase(postVerifyVoucherReq.pending, (state) => {
      state.isLoadingVerify = true;
    });
    builder.addCase(postVerifyVoucherReq.fulfilled, (state, action) => {
      state.isLoadingVerify = false;
    });
    builder.addCase(postVerifyVoucherReq.rejected, (state) => {
      state.isLoadingVerify = false;
    });
    builder.addCase(postInjectVoucherReq.pending, (state) => {
      state.isLoadingInjectVoucher = true;
    });
    builder.addCase(postInjectVoucherReq.fulfilled, (state, action) => {
      state.isLoadingInjectVoucher = false;
    });
    builder.addCase(postInjectVoucherReq.rejected, (state) => {
      state.isLoadingInjectVoucher = false;
    });
    builder.addCase(getVoucherOnRegisterReq.pending, (state) => {
      state.isLoadingVoucherOnReg = true;
    });
    builder.addCase(getVoucherOnRegisterReq.fulfilled, (state, action) => {
      state.isLoadingVoucherOnReg = false;
      state.VoucherOnReg = action.payload.data;
      action.payload.code === 1000 ? VoucherOnRegsiterCSV : undefined;
    });
    builder.addCase(getVoucherOnRegisterReq.rejected, (state) => {
      state.isLoadingVoucherOnReg = false;
    });
    builder.addCase(getVoucherHistoryOutletReq.pending, (state) => {
      state.isLoadingHistoryByOutlet = true;
    });
    builder.addCase(getVoucherHistoryOutletReq.fulfilled, (state, action) => {
      state.isLoadingHistoryByOutlet = false;
      state.VoucherHistoryByOutlet = action.payload.data;
    });
    builder.addCase(getVoucherHistoryOutletReq.rejected, (state) => {
      state.isLoadingHistoryByOutlet = false;
    });
    builder.addCase(getVoucherUserReq.pending, (state) => {
      state.isLoadingVoucher = true;
    });
    builder.addCase(getVoucherUserReq.fulfilled, (state, action) => {
      state.isLoadingVoucher = false;
      state.VoucherUser = action.payload.data;
    });
    builder.addCase(getVoucherUserReq.rejected, (state) => {
      state.isLoadingVoucher = false;
    });
    builder.addCase(updateVoucherExpiredReq.pending, (state) => {
      state.isLoadingVoucher = true;
    });
    builder.addCase(updateVoucherExpiredReq.fulfilled, (state, action) => {
      state.isLoadingVoucher = false;
      action.payload.code === 1000
        ? swal("Success", "You update Voucher Expired", "success")
        : swal("Ops!", `${action.payload.code} : Something went wrong`, "error");
    });
    builder.addCase(updateVoucherExpiredReq.rejected, (state) => {
      state.isLoadingVoucher = false;
    });
  }
});

export const {
  resetCodeVoucher,
  VoucherExcel,
  VoucherReportExcel,
  VoucherOnRegsiterCSV,
  VoucherOutletExcel,
  getPhoneNumberUser
} = voucherSlice.actions;
export default voucherSlice.reducer;
