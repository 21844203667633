import React, { useEffect, useState } from "react";
import * as R from "ramda";
import Images from "@constants/images";
import apps from "@constants/apps";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "@stores/store";
import { IoBicycleOutline, IoLogOutOutline } from "react-icons/io5";
import { useAppDispatch } from "@stores/store";
import { logout } from "@stores/AuthSlice";
import { RiCoinLine } from "react-icons/ri";
// @description
const Secure: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { employeeData, isLoggedIn } = useAppSelector((state) => state.auth);

  useEffect(() => {
    if (R.isEmpty(employeeData)) {
      navigate("/login");
    }
  }, [employeeData]);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    }
  }, [isLoggedIn]);
  // let name = apps.map((i) => i.uri);
  // console.log(name === pathname);

  return (
    <div className="flex flex-col ">
      <div
        className={` flex justify-between p-5 z-[99] bg-slate-800 items-center w-full h-[70px] fixed`}
      >
        <Link to="/">
          <img src={Images.LogoHorizontal} alt="logo dailybox" className="w-[50%]" />
        </Link>
        <div className="flex gap-2    text-white justify-end">
          {apps.map((x) => (
            <Link
              to={x.uri}
              key={x.uri}
              className={`after:absolute after:contents after:bg-slate-500 flex flex-row justify-center items-center text-white hover:bg-slate-600 hover:text-white p-3 `}
            >
              <x.icon size={15} className={`mr-1 `} />
              {x.title}
            </Link>
          ))}

          {/* <Link
            to={"/brand-settlement"}
            key={"brand-settlement"}
            className="after:absolute after:contents after:bg-slate-500 flex flex-row justify-center items-center text-white hover:bg-slate-600 hover:text-white p-3"
          >
            <TbSpeakerphone size={15} className="mr-1" /> Brand Settlement
          </Link> */}

          <Link
            to={"/daily-coin"}
            key={"coin"}
            className="after:absolute after:contents after:bg-slate-500 flex flex-row justify-center items-center text-white hover:bg-slate-600 hover:text-white p-3"
          >
            <RiCoinLine size={15} className="mr-1" /> Dailycoin
          </Link>

          <a
            className="flex flex-row justify-center items-center text-white hover:bg-slate-600 hover:text-white p-3"
            onClick={() => {
              dispatch(logout());
              // window.location.reload();
            }}
          >
            <IoLogOutOutline size={15} className="mr-1" /> Logout
          </a>
        </div>
      </div>

      <div className="flex-1 over">
        <Outlet />
      </div>
    </div>
  );
};

export default Secure;
