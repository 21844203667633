import axios from "axios";
import { AuthPayload } from "@typed/global";

const BASE_URL = "https://c7ao4wxfv8.execute-api.ap-southeast-1.amazonaws.com/Prod";

export const authRequestAPI = async ({ employeeId, password }: AuthPayload) => {
  const response = await axios.post(`${BASE_URL}/login-employee`, {
    employeeId: employeeId,
    password: password
  });
  return response.data;
};
