import { getDetailQandABatchReq } from "@stores/dailyapp/QuestionsAnwerSlice";
import { useAppDispatch, useAppSelector } from "@stores/store";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

const SingleQandA = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();

  return (
    <>
      <div className="flex justify-end">
        <div>
          <div className="h-4 w-4 bg-red-500 rounded-full"></div>
          <span>Not Active</span>
        </div>
      </div>
      <div>SingleQandA</div>
    </>
  );
};

export default SingleQandA;
