import API from "@constants/API";

const BASE_URL = "https://api.dev.daily.co.id/dashboard/outlet/outlet/";

export const getOutletsAPI = async ({ skip, limit }: { skip?: number; limit?: number }) => {
  // const response = await API.HTTP.get(`${BASE_URL}`);
  const response = await API.HTTP.get(`${BASE_URL}?skip=${skip}&limit=${limit}`);

  return response.data;
};

export const getOutletDetailAPI = async (id: string | undefined) => {
  const response = await API.HTTP.get(`${BASE_URL}${id}`);

  return response;
};

export const createOutletAPI = async (Data: object) => {
  const response = await API.HTTP.post(`${BASE_URL}`, Data);
  return response.data;
};

// edit
export type IEditData = {
  lat: number | undefined;
  long: number | undefined;
  name: string | undefined;
  id: string;
};

export const editOutletAPI = async (Data: IEditData) => {
  const data = {
    name: Data.name,
    lat: Data.lat,
    long: Data.long
  };
  const response = await API.HTTP.put(`${BASE_URL}${Data.id}`, data);
  return response.data;
};
