import React, { useEffect } from "react";
import { getUsersRequest, CSVUsers, setSkip, setPage } from "@stores/dailyapp/UserSlice";
import { useAppDispatch, useAppSelector } from "@stores/store";
import { Link } from "react-router-dom";
import MyTable from "@components/Table/MyPaginationTable";
import SelectComponents from "@components/Select";

const options = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "2", label: "3" }
];

const ModalExportCSV = () => {
  return (
    <div className="h-[8.5rem] mt-16 flex flex-col gap-3">
      <div className="flex flex-col mb-5 items gap-2 justify-center">
        <div className="w-full">
          <h1 className="after:text-red-500 after:content-['*'] m-0 text-gray-700">
            Select Periode
          </h1>
          <SelectComponents options={options} className="w-full" />
        </div>
      </div>
      <button className="flex text-medium items-center gap-1 text-center outline-none h-9 w-[10rem] rounded bg-blue-500 hover:bg-blue-400 text-white justify-center">
        <h1 className="m-0 text-white text-[1rem] p-0">Export</h1>
      </button>
    </div>
  );
};

const Action = (row: any) => {
  return (
    <>
      <div>
        <Link
          className="hover:text-white rounded py-2 px-5 bg-blue-500 text-white"
          to={row._id}
          state={row}
        >
          Detail
        </Link>
      </div>
    </>
  );
};

const Users = () => {
  const dispatch = useAppDispatch();

  const { users, isLoading, page, totalUser, skip } = useAppSelector((state) => state.dailyappUser);

  useEffect(() => {
    dispatch(CSVUsers());
  }, [isLoading]);

  useEffect(() => {
    const payload = {
      page: 0
    };
    dispatch(getUsersRequest(payload));
  }, []);

  return (
    <div>
      <MyTable
        skip={skip}
        funcResetPage={setPage}
        setSkip={setSkip}
        fileName="users"
        titleModal="Export CSV Users"
        ModalChildren={<ModalExportCSV />}
        loading={isLoading}
        searchable
        totalUser={totalUser}
        exportable
        fetchingPagination={getUsersRequest}
        dataSource={users}
        action={Action}
        select={[
          { label: "Name", value: "fullName" },
          { label: "Phone", value: "phoneNumber" },
          "gender",
          { label: "Register Date", value: "createdAt" },
          "employeeId"
        ]}
      />
    </div>
  );
};

export default Users;
