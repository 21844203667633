/* eslint-disable react/jsx-key */
import React, { useEffect } from "react";
import { Descriptions } from "antd";
import { useAppDispatch, useAppSelector } from "@stores/store";
import { Link, useParams } from "react-router-dom";
import { getDetailOutletRequest, postOutletRequest } from "@stores/dailyapp/dailyOutletSlice";
import Loading from "@components/loading";
import API from "@constants/API";

const SingleOutlet = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { AppOutletDetail, isLoading } = useAppSelector((state) => state.dailyappOutlet);

  useEffect(() => {
    if (id) {
      dispatch(getDetailOutletRequest(id));
    }
  }, [id]);

  const updateUseOutlet = async () => {
    const BASE_URL = "https://api.prod.daily.co.id/expansion/outlet/dashboard/v1/exp-outlet";
    const response = await API.HTTP.put(`${BASE_URL}/${id}`, {
      usedAt: ["dailyojol"]
    });
    return response.data;
    // dispatch(postOutletRequest({ id, { usedAt: ["dailyojol"] } }));
  };
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Descriptions
          key={id}
          bordered
          title={AppOutletDetail?.name || <h1 className="text-red-500 font-medium">No Name</h1>}
          size="small"
          extra={
            <div className="flex gap-3">
              <button
                onClick={updateUseOutlet}
                className="py-2 px-7 bg-green-500 text-white hover:text-white rounded-md font-medium"
              >
                Activate Dailyojol
              </button>

              <Link
                className="py-2 px-7 rounded-md bg-gray-500 hover:bg-blue-500 text-white hover:text-white"
                to={`/dailyapp/add-outlet-account/${id}`}
              >
                Create outlet account
              </Link>

              <Link
                className="py-2 px-7 rounded-md bg-blue-500 text-white hover:text-white"
                to={`/dailyapp/outlet/edit/${id}`}
              >
                Edit
              </Link>
            </div>
          }
        >
          <Descriptions.Item label="ID">
            {/* {AppOutletDetail?._id || <h1 className="text-red-500">Empty</h1>} */}
            <h1 className="font-medium">
              {AppOutletDetail?._id || <h1 className="text-red-500">Empty</h1>}
            </h1>
          </Descriptions.Item>

          <Descriptions.Item label="Address">
            <h1 className="max-w-[25rem] font-medium">
              {AppOutletDetail?.address || <h1 className="text-red-500">Empty</h1>}
            </h1>
          </Descriptions.Item>

          <Descriptions.Item label="Code">
            <h1 className="font-medium">
              {AppOutletDetail?.code || <h1 className="text-red-500">Empty</h1>}
            </h1>
          </Descriptions.Item>

          <Descriptions.Item label="Region">
            <h1 className="font-medium">
              {AppOutletDetail?.region || <h1 className="text-red-500">Empty</h1>}
            </h1>
          </Descriptions.Item>

          <Descriptions.Item label="Brands">
            <h1 className="font-medium">
              {AppOutletDetail?.brands?.map((item) => <h1 className="m-0">{item}</h1>) || (
                <h1 className="text-red-500">Empty</h1>
              )}
            </h1>
          </Descriptions.Item>

          <Descriptions.Item label="Used At">
            {AppOutletDetail.usedAt !== undefined ? (
              AppOutletDetail.usedAt?.map((item) => <h1 className="m-0">{item}</h1>)
            ) : (
              <h1 className="text-red-500">Empty</h1>
            )}
          </Descriptions.Item>

          <Descriptions.Item label="Long/Lat">
            <h1 className="font-medium">
              {AppOutletDetail.location?.coordinates?.map((item) => (
                <h1 className="m-0">{item}</h1>
              )) || <h1 className="text-red-500">Empty</h1>}
            </h1>
          </Descriptions.Item>
        </Descriptions>
      )}
    </>
  );
};

export default SingleOutlet;
