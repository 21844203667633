import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { Overwrite, PayloadResponse } from "@typed/global";
import { CoinStoreState } from "@typed/dailyapp/coin";
import { getDashboardDataApi } from "./DashboardAPI";
import { DashboardStoreState } from "@typed/dailyapp/dashboard";

type Payload = Overwrite<PayloadResponse, { data: any }>;

export const getDashboardDataRequest = createAsyncThunk(
  "dailyapp-dashboard/getDashboardDataRequest",
  async () => {
    const response: Payload = await getDashboardDataApi();
    return response;
  }
);

const initialState: DashboardStoreState = {
  data: {},
  errorMessage: "",
  isLoading: false
};

export const dashboardSlice = createSlice({
  name: "dailyapp-dashboard",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDashboardDataRequest.pending, (state) => {
      state.errorMessage = "";
      state.isLoading = true;
    });
    builder.addCase(getDashboardDataRequest.fulfilled, (state, action) => {
      if (action.payload.code === 1000) {
        state.data = action.payload.data;
      } else {
        state.errorMessage = "Error :: with status code" + action.payload.code;
      }
      state.isLoading = false;
    });
    builder.addCase(getDashboardDataRequest.rejected, (state) => {
      state.errorMessage = "Error :: Case rejected";
      state.isLoading = false;
    });
  }
});

// Action creators are generated for each case reducer function
export const {} = dashboardSlice.actions;

export default dashboardSlice.reducer;
