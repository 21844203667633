import {
  deletedOrganization,
  getDetailOrganizationRequest,
  putActiveOrganizationRequest,
  putDeleteOrganizationRequest
} from "@stores/HRIS/OrganizationSlice";
import { useAppDispatch, useAppSelector } from "@stores/store";
import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import CardDetail from "@components/HRIS/CardDetail";
import Loading from "@components/loading";
import { Toaster } from "react-hot-toast";
const SingleOrganization = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { dataDetail, deletedRedirect, isLoading } = useAppSelector(
    (state) => state.hrisOrganization
  );

  useEffect(() => {
    if (deletedRedirect === true) {
      dispatch(deletedOrganization());
      setTimeout(() => {
        navigate("/hris/organization");
      }, 600);
    }
  }, [deletedRedirect]);

  useEffect(() => {
    if (id) {
      dispatch(getDetailOrganizationRequest(id));
    }
  }, [id]);

  const handleActive = () => {
    const id = dataDetail._id;
    const isActive = dataDetail.isActived;
    dispatch(putActiveOrganizationRequest({ id, isActive }));
  };

  const handleDelete = () => {
    const id = dataDetail._id;
    const isDelete = dataDetail.isDeleted;
    dispatch(putDeleteOrganizationRequest({ id, isDelete }));
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Toaster />
          <CardDetail
            handleDelete={handleDelete}
            handleActive={handleActive}
            name="Name"
            active={dataDetail.isActived}
            linkTo={`/hris/organization/edit/${id}`}
            value={dataDetail.name}
            description="Description"
            valueDesc={dataDetail.description}
          />
        </>
      )}
    </>
  );
};

export default SingleOrganization;
