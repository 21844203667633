import API from "@constants/API";

const BASE_URL = "https://api.prod.daily.co.id/user/dashboard/v3/user";
const ACTIVITY_URL = "https://api.prod.daily.co.id/user/dashboard/v3/users-activity/";
const UPDATE_EMPLOYEE = "https://api.prod.dailyapp.id/dashboard-v1-user/user-to-employee/";
const RESET_EMPLOYEE = "https://api.prod.dailyapp.id/dashboard-v1-user/employee-reset";
const CUSTOMER = "https://api.new.v1.daily.co.id/dashboard/user/1.0.0/users";
export const LIMIT = 10;

export const getUsersApi = async ({ page, keyword = "" }: { page: number; keyword: string }) => {
  const response = await API.HTTP.get(
    // `${CUSTOMER}?skip=${0}&limit=${LIMIT}&s=${keyword}`
    `${CUSTOMER}?skip=${page * LIMIT}&limit=${LIMIT}&s=${keyword}`
  );
  return response.data;
};

export const getUserLimit = async (limit?: number, skip?: number) => {
  const response = await API.HTTP.get(`${CUSTOMER}?skip=${skip}&limit=${limit}`);
  return response.data;
};

export const getUserApi = async ({ id }: { id: string }) => {
  const response = await API.HTTP.get(`${BASE_URL}/${id}`);
  return response.data;
};

export const getUserActivityApi = async ({ id }: { id: string | undefined }) => {
  const response = await API.HTTP.get(ACTIVITY_URL + "?userId=" + id);
  return response.data;
};

export const putEmployeeID = async (id: string | undefined, employeeID: string) => {
  const response = await API.HTTP.put(`${UPDATE_EMPLOYEE}${id}`, { employeeId: employeeID });
  return response.data;
};

export const postResetEmployeeApi = async (id: string) => {
  const response = await API.HTTP.post(`${RESET_EMPLOYEE}`, {
    userId: id
  });
  return response.data;
};
