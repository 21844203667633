import React, { useEffect } from "react";
import Detail from "@components/Detail";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@stores/store";
import { getDetailQuestionsReq } from "@stores/dailyapp/QuestionsAnwerSlice";
import { flattenObject } from "@helpers/utils";
import Loading from "@components/loading";

const SingleQuestion = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { singleQuestion, isLoadingBatch } = useAppSelector((state) => state.dailyQuestionsBatch);

  useEffect(() => {
    if (id) {
      dispatch(getDetailQuestionsReq(id));
    }
  }, [id]);

  if (isLoadingBatch) {
    return <Loading />;
  }
  return (
    <>
      <div className="flex flex-col h-auto">
        <Detail
          data={flattenObject(singleQuestion)}
          title={singleQuestion.squence}
          extra={undefined}
        />
      </div>
    </>
  );
};

export default SingleQuestion;
