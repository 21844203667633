import { getOutletRequest } from "@stores/dailyojol/OutlietAccountSlice";
import { useAppDispatch, useAppSelector } from "@stores/store";
import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { Link } from "react-router-dom";
import { dateToString } from "@helpers/utils";
import { AiOutlineSearch } from "react-icons/ai";
import Loading from "@components/loading";
import InputSearch from "@components/inputSearch";

const outletAccount = () => {
  const { loading } = useAppSelector((state) => state.dailyojolOutletAccount);
  const OutletsAccount = useAppSelector((state) =>
    state.dailyojolOutletAccount.data.map((x) => ({ ...x, key: x._id }))
  );
  const [searchByName, setSearchByName] = useState<string>("");
  const [okData, setOkData] = useState(OutletsAccount);
  const dispatch = useAppDispatch();

  useEffect(() => {
    let Data = OutletsAccount.filter((item) => {
      return item.name.toLowerCase().includes(searchByName.toLowerCase());
    });
    if (searchByName !== "") {
      setOkData(Data);
    } else if (searchByName === "") {
      setOkData(OutletsAccount);
    }
  }, [searchByName, dispatch, loading]);

  const column = [
    {
      key: "name",
      title: "Username",
      dataIndex: "name"
    },
    {
      key: "email",
      title: "Email",
      dataIndex: "email"
    },
    {
      key: "outletName",
      title: "Outlet Name",
      dataIndex: ["outlet", "name"]
    },
    {
      key: "date",
      dataIndex: "createdAt",
      title: "Date",
      render: (createdAt: string) => <div>{dateToString(createdAt)}</div>
    },
    {
      index: "action",
      title: "Action",
      dataIndex: "_id",
      render: (_id: string) => {
        return (
          <div>
            <Link
              className="py-2 px-4 rounded text-white hover:text-white bg-blue-500 hover:bg-blue-600"
              to={`/dailyojol/outlet-account/edit-outlet/${_id}`}
            >
              Edit
            </Link>

            {/* <Link
              to={`/dailyojol/outlet-account/${_id}`}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Detail
            </Link> */}
          </div>
        );
      }
    }
  ];

  useEffect(() => {
    dispatch(getOutletRequest());
  }, [dispatch]);
  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="flex items-center gap-4">
            <InputSearch
              value={searchByName}
              placeholder="Search by username"
              handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSearchByName(e.target.value)
              }
            />
            <div className="mb-4">
              <Link
                to="/dailyojol/outlet-account/create-outlet"
                className="bg-green-500  hover:bg-green-600 text-white hover:text-white font-bold py-2 px-4 rounded"
              >
                Create Outlet Account
              </Link>
            </div>
          </div>

          <Table className="mt-4" columns={column} dataSource={okData} />
        </>
      )}
    </div>
  );
};

export default outletAccount;
