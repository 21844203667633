import React from "react";
import "./loading.css";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const antIcon = <LoadingOutlined style={{ fontSize: 100 }} spin />;
const loading = () => {
  return (
    <div className=" fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center">
      <Spin indicator={antIcon} />
      <h2 className="text-center text-white text-xl font-semibold">Loading...</h2>
      <p className="w-1/3 text-center text-white">
        This may take a few seconds, please dont close this page.
      </p>
    </div>
  );
};

export default loading;
