import CardDashboard from "@components/CardDashboard";
import Skeleton from "@components/Skeleton";
import useRole from "@hooks/useRole";
import { getDashboardDataRequest } from "@stores/dailyapp/DashboardSlice";
import { useAppDispatch, useAppSelector } from "@stores/store";
import { Card, Col, Row, Statistic } from "antd";
import React, { useEffect } from "react";
import { IoStar } from "react-icons/io5";

const Dashboard = () => {
  const { totalUser } = useAppSelector((state) => state.dailyappUser);
  const { data, isLoading } = useAppSelector((state) => state.dailyDashboard);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getDashboardDataRequest());
  }, []);

  return (
    <>
      <Row gutter={10}>
        {isLoading ? (
          <>
            <div className="flex flex-wrap gap-2  w-full">
              <Skeleton w={23} />
              <Skeleton w={23} />
              <Skeleton w={23} />

              <Skeleton w={23} />
              <Skeleton w={23} />
              <Skeleton w={23} />
            </div>
          </>
        ) : (
          <div className="flex flex-wrap gap-2  w-full">
            <CardDashboard title="Total Users" value={data.totalUser} />
            <CardDashboard title="Total Redeems" value={data.lotalRedeem} />
            <CardDashboard title="Total Point Created" value={data.totalPointCreated} />

            <CardDashboard title="Total Transactions" value={data.totalTransaction} />
            <CardDashboard title="Review (avg)" value={4.5} />
            <CardDashboard title="Unresolve Complain" value={12} />
          </div>
        )}
      </Row>
    </>
  );
};

export default Dashboard;
