import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getDetailOutletAppAPI,
  getOutletAppAPI,
  postCreateOutletAppAPI,
  putUpdateOutletAppAPI,
  postOutletAccountAPI,
  getOutletAccountAPI,
  putOutletAccountAPI
} from "./dailyOutletAPI";

import swal from "sweetalert";

import { IDataOutlets } from "@typed/dailojol/Outlet";

export const getOutletsRequest = createAsyncThunk("dailyapp-outlet/getOutletsRequest", async () => {
  const response = await getOutletAppAPI();
  return response;
});

export const getDetailOutletRequest = createAsyncThunk(
  "dailyapp-outlet/getDetailOutletRequest",
  async (id: string) => {
    const response = await getDetailOutletAppAPI(id);
    return response;
  }
);

export const postOutletRequest = createAsyncThunk(
  "dailyapp-outlet/postOutletRequest",
  async (data: object) => {
    const response = await postCreateOutletAppAPI(data);
    return response;
  }
);

export const putOutletRequest = createAsyncThunk(
  "dailyapp-outlet/putOutletRequest",
  async ({ id, data }: { id: string; data: object }) => {
    const response = await putUpdateOutletAppAPI(id, data);
    return response;
  }
);

export const postOutletAccountRequest = createAsyncThunk(
  "dailyapp-outlet/postOutletAccountRequest",
  async (data: object) => {
    const response = await postOutletAccountAPI(data);
    return response;
  }
);

// OUTLET ACCOUNT
export const getOutletAccountRequest = createAsyncThunk(
  "dailyapp-outlet/getOutletAccountRequest",
  async () => {
    const response = await getOutletAccountAPI();
    return response;
  }
);

export const putOutletAccountRequest = createAsyncThunk(
  "dailyapp-outlet/putOutletAccountRequest",
  async ({ id, data }: { id: string; data: object }) => {
    const response = await putOutletAccountAPI(id, data);
    return response;
  }
);

interface IInitOutlet {
  AppOutlet: IDataOutlets[] | [];
  AppOutletDetail: IDataOutlets;
  isLoading: boolean;
  AppOutletAccount: [] | any[];
}

const initialState: IInitOutlet = {
  AppOutlet: [],
  AppOutletDetail: {} as IDataOutlets,
  isLoading: false,
  AppOutletAccount: []
};

export const OutletDailyapp = createSlice({
  name: "dailyapp-outlet",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getOutletsRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getOutletsRequest.fulfilled, (state, action) => {
      action.payload.code === 1000 ? (state.AppOutlet = action.payload.data) : undefined;
      state.isLoading = false;
    });
    builder.addCase(getOutletsRequest.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getDetailOutletRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getDetailOutletRequest.fulfilled, (state, action) => {
      action.payload.code === 1000 ? (state.AppOutletDetail = action.payload.data) : undefined;
      state.isLoading = false;
    });
    builder.addCase(getDetailOutletRequest.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(postOutletRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(postOutletRequest.fulfilled, (state, action) => {
      action.payload.code === 1000
        ? swal("Good job!", "You have successfully created a new outlet!", "success")
        : undefined;
      state.isLoading = false;
    });
    builder.addCase(postOutletRequest.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(putOutletRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(putOutletRequest.fulfilled, (state, action) => {
      action.payload.code === 1000
        ? swal("Good job!", "You have successfully update the new outlet!", "success")
        : undefined;
      state.isLoading = false;
    });
    builder.addCase(putOutletRequest.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(postOutletAccountRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(postOutletAccountRequest.fulfilled, (state, action) => {
      action.payload.code === 1000
        ? swal("Good job!", "You have successfully create account!", "success")
        : swal("Ups!", "Account is already", "error");
      state.isLoading = false;
    });
    builder.addCase(postOutletAccountRequest.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getOutletAccountRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getOutletAccountRequest.fulfilled, (state, action) => {
      action.payload.code === 1000 ? (state.AppOutletAccount = action.payload.data) : undefined;
      state.isLoading = false;
    });
    builder.addCase(getOutletAccountRequest.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(putOutletAccountRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(putOutletAccountRequest.fulfilled, (state, action) => {
      action.payload.code === 1000
        ? swal("Good job!", "You have successfully update password!", "success")
        : swal("Ups!", "Something went wrong", "error");
      state.isLoading = false;
    });
    builder.addCase(putOutletAccountRequest.rejected, (state) => {
      state.isLoading = false;
    });
  }
});

export default OutletDailyapp.reducer;
