import React from "react";

interface IProps {
  title: string;
  required?: string;
  className?: string;
  placeholder: string;
  value: string | number | readonly string[];
  onChange: {
    (e: React.ChangeEvent<any>): void;
    <T = string | React.ChangeEvent<any>>(field: T): T extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  name: string;
  autoComplete?: string;
  style?: string;
}

const Textarea: React.FC<IProps> = ({
  title,
  required,
  className,
  placeholder,
  value,
  onChange,
  name,
  autoComplete,
  style
}) => {
  return (
    <div
      className={`${className} border-[1px]  hover:shadow-4xl focus-within:shadow-4xl focus-within:border-[#1894ed] bg-Inputcol border-abuAbu rounded-md p-2`}
    >
      <h1 className={`${required} text-[#6A7380] `}>{title}</h1>
      <div>
        <textarea
          name={name}
          autoComplete={autoComplete}
          value={value}
          onChange={onChange}
          className={`${style} resize-none bg-transparent h-[5rem] w-full  border-none outline-none`}
          placeholder={placeholder}
        />
      </div>
    </div>
  );
};

export default Textarea;
