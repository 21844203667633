import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@stores/store";
import { Link, useParams } from "react-router-dom";
import Loading from "@components/loading";
import { Descriptions } from "antd";
import moment from "moment";
import {
  putActiveProductRequest,
  putProductClickRequest,
  singleProductClickRequest
} from "@stores/Dailyclick/DailyclickSlice";
import { dateToString } from "@helpers/utils";

const SingleProduct = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { singleProductClick, isLoading } = useAppSelector((state) => state.dailyClick);

  useEffect(() => {
    if (id) {
      dispatch(singleProductClickRequest(id));
    }
  }, [id]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Descriptions
        bordered
        title={singleProductClick?.name}
        size="small"
        extra={
          //   <div className="flex gap-4 items-center">
          //     <button
          //       onClick={handleActiveProduct}
          //       className={`${
          //         singleProductClick?.isActive ? "bg-red-500" : "bg-green-500"
          //       } p-2 rounded-md font-medium text-white hover:text-white`}
          //     >
          //       {singleProductClick?.isActive ? "In Active" : "Active"} Product
          //     </button>

          <Link
            to={`/dailyclick/product/update/${singleProductClick?._id}`}
            className={`bg-blue-500 p-2 rounded-md font-medium text-white hover:text-white`}
          >
            Update Product
          </Link>
          //   </div>
        }
      >
        <Descriptions.Item label="Date" span={2}>
          {dateToString(singleProductClick?.createdAt)}
        </Descriptions.Item>
        <Descriptions.Item className="w-[20rem]" label="Description" span={2}>
          {singleProductClick?.description}
        </Descriptions.Item>

        <Descriptions.Item label="Start Time" span={2}>
          {/* {dateToString(singleProductClick?.startTime)} */}
          {moment(singleProductClick?.startTime).format("MMMM Do YYYY, h:mm:ss")}
        </Descriptions.Item>
        <Descriptions.Item label="End Time" span={2}>
          {/* {dateToString(singleProductClick?.endTime)} */}
          {moment(singleProductClick?.endTime).format("MMMM Do YYYY, h:mm:ss")}
        </Descriptions.Item>
        <Descriptions.Item label="Value" span={2}>
          {singleProductClick?.value}
        </Descriptions.Item>
        <Descriptions.Item label="Price" span={2}>
          {singleProductClick?.price || <h1 className="text-red-500 font-bold m-0">Empty</h1>}
        </Descriptions.Item>
        <Descriptions.Item label="Start Coin" span={2}>
          {singleProductClick?.startCoin}
        </Descriptions.Item>
        <Descriptions.Item label="Coin Every Click" span={2}>
          <h1 className="m-0 font-medium">+{singleProductClick?.coinClick}</h1>
        </Descriptions.Item>
        <Descriptions.Item label="Is Active" span={2}>
          <h1 className="m-0 font-medium">
            {singleProductClick?.isActive ? "Active" : "InActive"}
          </h1>
        </Descriptions.Item>
        <Descriptions.Item label="End Event" span={2}>
          <h1 className="m-0 ">{singleProductClick?.isEndEvent ? "True" : "False"}</h1>
        </Descriptions.Item>

        <Descriptions.Item label="Redeem" span={2}>
          <h1 className="m-0 ">{singleProductClick?.isRedeemed ? "True" : "False"}</h1>
        </Descriptions.Item>
      </Descriptions>
      <div className="mt-3">
        <img src={singleProductClick?.image} className="w-40 h-40 rounded-md" />
      </div>
    </>
  );
};

export default SingleProduct;
