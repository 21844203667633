import React, { useEffect, useRef } from "react";

import { useAppDispatch, useAppSelector } from "@stores/store";

import { Tabs, DatePicker, DatePickerProps } from "antd";
import {
  getReportPaymentRequest,
  getReportBillRequest,
  getReportTopupRequest,
  getSummariesRequest,
  getCashbackRegisRequest,
  CSVCashbackRegister,
  getPaymentOriginRequest,
  getCutOffByPeriodeRequest
} from "@stores/dailycoin/ReportingSlice";
import moment from "moment";
import PaymentReport from "./PaymentReport";
import TopupReport from "./TopupReport";
import BillReport from "./BillReport";
import SummariesReport from "./SummariesReport";
import ReportCSV from "./ReportCSV";
import CashbackRegister from "./CashbackRegister";
import { getTopupProviderReq } from "@stores/dailycoin/ReportingSlice";
import TopupProvider from "./Topup/TopupProvider";

const Reporting: React.FC = () => {
  const dispatch = useAppDispatch();
  const {
    reportTopup,
    reportBill,
    reportPayment,
    reportSummaries,
    CashbackCSV,
    dataCashback,
    topupProvider
  } = useAppSelector((state) => state.dailyReport);

  const startDateRef = useRef<any>(moment().startOf("month"));
  const endDateRef = useRef<any>(moment().endOf("month"));

  // START DATA REPORT REF
  const topupCSV = useRef<any[]>(reportTopup);
  const billCSV = useRef<any[]>(reportBill);
  const paymentCSV = useRef<any[]>(reportPayment);
  const CashbackRegis = useRef<any[]>(CashbackCSV);
  const summariesCSV = useRef<any[]>(reportSummaries);
  const topupProviderCSV = useRef<any[]>(topupProvider);
  // END DATA REPORT REF

  const handleStartDate: DatePickerProps["onChange"] = (date: any, dateString: any) => {
    startDateRef.current = dateString;
  };

  const handleEndDate: DatePickerProps["onChange"] = (date: any, dateString: any) => {
    endDateRef.current = dateString;
  };

  const dataXLS = () => {
    const dataPayment = reportPayment.map((item: { _id: any; transaction: any; payment: any }) => ({
      Date: item._id,
      Transaction: item.transaction,
      Payment: item.payment
    }));

    const dataBill = reportBill.map((item) => ({
      Date: item._id,
      Transaction: item.transaction,
      Cashback: item.cashback
    }));

    const dataTopup = reportTopup.map((item) => ({
      Date: item._id,
      Transaction: item.transaction,
      Topup: item.topup,
      Cashback: item.cashback,
      "Coin Created": item.dailycoinCreated
    }));

    const dataSummaries = reportSummaries.map((item) => ({
      Type: item.dailycoinType,
      Total: item.total
    }));

    const dataCashbackRegis = dataCashback.map((item) => ({
      Date: item._id,
      Transaction: item.transaction,
      Cashback: item.cashback
    }));

    const TopupByProvider = topupProvider.map((item) => ({
      Date: item.date,
      Provider: item.provider,
      "Coin Cashback": item.coinCashback,
      "Coin Created": item.coinCreated,
      "Coin Purchase": item.coinPurchase,
      "Total Payment": item.totalPayment
    }));

    billCSV.current = dataBill;
    topupCSV.current = dataTopup;
    paymentCSV.current = dataPayment;
    summariesCSV.current = dataSummaries;
    CashbackRegis.current = dataCashbackRegis;
    topupProviderCSV.current = TopupByProvider;
    dispatch(CSVCashbackRegister());
  };

  useEffect(() => {
    handleReport();
    dataXLS();
  }, []);

  const handleReport = () => {
    dispatch(
      getReportTopupRequest({ startDate: startDateRef.current, endDate: endDateRef.current })
    );
    dispatch(
      getReportTopupRequest({ startDate: startDateRef.current, endDate: endDateRef.current })
    );
    dispatch(getSummariesRequest());
    dispatch(
      getReportBillRequest({ startDate: startDateRef.current, endDate: endDateRef.current })
    );
    dispatch(
      getReportPaymentRequest({ startDate: startDateRef.current, endDate: endDateRef.current })
    );
    dispatch(
      getCashbackRegisRequest({ startDate: startDateRef.current, endDate: endDateRef.current })
    );
    dispatch(
      getPaymentOriginRequest({ startDate: startDateRef.current, endDate: endDateRef.current })
    );
    dispatch(
      getCutOffByPeriodeRequest({ startDate: startDateRef.current, endDate: endDateRef.current })
    );
    dispatch(getTopupProviderReq({ startDate: startDateRef.current, endDate: endDateRef.current }));
  };

  useEffect(() => {
    dataXLS();
  }, [handleReport]);

  return (
    <>
      <div className="flex justify-between items-center">
        <div className="flex mb-3 items-center gap-2">
          <h1 className="m-0">Filter by</h1>
          <DatePicker
            className="h-9"
            // value={startDateRef.current}
            name="startDate"
            onChange={handleStartDate}
            placeholder="Start date"
            // defaultValue={moment().startOf("month")}
          />

          <DatePicker
            className="h-9"
            name="endDate"
            // value={endDateRef.current}
            onChange={handleEndDate}
            placeholder="End date"
            // defaultValue={moment().endOf("month")}
          />
          <button
            onClick={handleReport}
            className="flex text-medium items-center gap-1 text-center outline-none h-9 w-[10rem] rounded bg-blue-500 hover:bg-blue-400 text-white justify-center"
          >
            Submit
          </button>
        </div>
        <ReportCSV
          csvBill={billCSV.current}
          csvPayment={paymentCSV.current}
          csvTopup={topupCSV.current}
          csvSummaries={summariesCSV.current}
          csvCashback={CashbackCSV}
          csvTopupProvider={topupProviderCSV.current}
          fileName={`Report-dailycoin-${moment(startDateRef.current).format(
            "YYYY-MM-DD"
          )}||${moment(endDateRef.current).format("YYYY-MM-DD")}`}
        />
      </div>
      <Tabs
        defaultActiveKey="1"
        items={[
          {
            label: `Payment Report`,
            key: "1",
            children: (
              <PaymentReport endDate={endDateRef.current} startDate={startDateRef.current} />
            )
          },
          {
            label: `Topup Report`,
            key: "2",
            children: (
              <TopupReport
                dataCSV={topupCSV.current}
                endDate={endDateRef.current}
                startDate={startDateRef.current}
              />
            )
          },
          {
            label: ` Bill Cashback Report`,
            key: "3",
            children: <BillReport endDate={endDateRef.current} startDate={startDateRef.current} />
          },
          {
            label: `Register Cashback `,
            key: "4",
            children: <CashbackRegister />
          },
          {
            label: `Summaries Report `,
            key: "5",
            children: <SummariesReport />
          },
          {
            label: `Topup `,
            key: "6",
            children: <TopupProvider />
          }
        ]}
      />
    </>
  );
};

export default Reporting;
