import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import ConfirmRedem from "@screens/dailyojol/confirmRedem";
import { IConfirmRedem, TRedemStore } from "@typed/dailyapp/Redem";
import React from "react";
import toast from "react-hot-toast";

import { getRedemDailyappAPI, postRedemVerifAPI } from "./RedemAPI";
export const getRedemDailyappRequest = createAsyncThunk(
  "dailyapp-redem/getRedemDailyappRequest",
  async () => {
    const response = await getRedemDailyappAPI();

    return response;
  }
);

export type TPayloadPost = {
  redeemNumber: string;
  ConfirmCode: number;
  outletId: string;
  creatorId: string;
  setConfirm: React.Dispatch<React.SetStateAction<IConfirmRedem>>;
};

export const postRedemVerifRequest = createAsyncThunk(
  "dailyapp/redem/postRedemVerifRequest",
  async (Data: TPayloadPost) => {
    const { setConfirm } = Data;
    const response = await postRedemVerifAPI(Data);
    if (response.code === 1000) {
      setConfirm({
        redeemNumber: "",
        outletId: "",
        confirmCode: 0,
        creatorId: ""
      });
    }
    return response;
  }
);

const initialState: TRedemStore = {
  data: [],
  loading: false,
  confirmRedem: {} as IConfirmRedem
};

type typeGetChange = {
  name: string;
  value: any;
};

type IPayload = {
  payload: typeGetChange;
};

const RedemAppSlice = createSlice({
  name: "dailyapp-redem",
  initialState: initialState,
  reducers: {
    resetConfirmRedem: (state: { confirmRedem: IConfirmRedem }) => {
      state.confirmRedem = {} as IConfirmRedem;
    }
  },
  extraReducers(builder) {
    builder.addCase(getRedemDailyappRequest.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getRedemDailyappRequest.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.loading = false;
    });
    builder.addCase(getRedemDailyappRequest.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(postRedemVerifRequest.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(postRedemVerifRequest.fulfilled, (state, action) => {
      if (action.payload.code === 2002) {
        state.loading = false;
        toast.error(action.payload.error);
        state.loading = false;
      } else if (action.payload.code === 2000) {
        toast.error(action.payload.error);
        state.loading = false;
      } else if (action.payload.code === 2009) {
        toast.error(action.payload.error);
        state.loading = false;
      } else if (action.payload.code === 1000) {
        toast.success(action.payload.message);
        state.confirmRedem = action.payload.data;
        state.loading = false;
      } else {
        state.loading = false;
      }
      state.loading = false;
    });
    builder.addCase(postRedemVerifRequest.rejected, (state) => {
      state.loading = false;
    });
  }
});

export default RedemAppSlice.reducer;
export const { resetConfirmRedem } = RedemAppSlice.actions;
