import API from "@constants/API";

const BASE_URL = "https://api.prod.dailyapp.id/api-voucher-0.0.1/vouchers";
const ID_PRODUCT = "https://api.prod.dailyapp.id/api-voucher-0.0.1/voucher-product";
const HISTORY_VOUCHER = "https://api.prod.dailyapp.id/api-voucher-0.0.1/voucher-histories";
const VOUCHER_REPORT = "https://api.prod.dailyapp.id/api-voucher-0.0.1/voucher-report?";
const REGISTER_ESB = "https://api.prod.dailyapp.id/api-voucher-0.0.1/voucher-register-false";
const VOUCHER_AVAILABLE = "https://api.prod.dailyapp.id/api-voucher-0.0.1/voucher-available";
const VOUCHER_TAKEN = "https://api.prod.dailyapp.id/api-voucher-0.0.1/voucher-takened";
const VOUCHER_REDEEMED = "https://api.prod.dailyapp.id/api-voucher-0.0.1/voucher-redeemed";
const VOUCHER_REGISTER = "https://api.prod.dailyapp.id/api-voucher-0.0.1/voucher-esb-register";
const VOUCHER_CREATED = "https://api.prod.dailyapp.id/api-voucher-0.0.1/voucher-created";
const COMPLETE_REGISTER = "https://api.prod.dailyapp.id/api-voucher-0.0.1/voucher-register-false";
const VERIFY_VOUCHER = "https://api.prod.dailyapp.id/api-voucher-0.0.1/voucher-verify";
const VOUCHER_INJECT = "https://api.prod.dailyapp.id/api-voucher-0.0.1/voucher-taken";
const VOUCHER_ON_REGISTER =
  "https://api.prod.dailyapp.id/api-voucher-0.0.1/code-voucher-esb-register";
const VOUCHER_PRODUCT = "https://api.prod.dailyapp.id/api-voucher-0.0.1/voucher-product";
const VOUCHER_HISTORY_OUTLET =
  "https://portal.api.dailyapp.id/v1/prod/voucher/portal-voucher-outlet?";
const VOUCHER_USER = "https://api.prod.dailyapp.id/api-voucher-0.0.1/user-voucher?phone=";
const VOUCHER_USER_EXPIRED_UPDATE =
  "https://api.prod.dailyapp.id/api-voucher-0.0.1/update-user-voucher?salesId=";

export interface ICreateVoucher {
  productId: string;
  countList: number;
  brandCode: string;
}

export interface IVoucherReport {
  startDate: string;
  endDate: string;
}

export const getVoucherAPI = async (payload: string) => {
  const res = await API.HTTP.get(
    `https://api.prod.dailyapp.id/api-voucher-0.0.1/vouchers?code=${payload}&skip=0&limit=5000`
  );

  return res.data;
};

export const postVoucherAPI = async (payload: ICreateVoucher) => {
  const res = await API.HTTP.post(BASE_URL, payload);
  return res.data;
};

export const getIdProductAPI = async () => {
  const res = await API.HTTP.get(ID_PRODUCT);
  return res.data;
};

export const getVoucherHistoryAPI = async () => {
  const res = await API.HTTP.get(HISTORY_VOUCHER);
  return res.data;
};

export const getVoucherAvailableAPI = async () => {
  const res = await API.HTTP.get(VOUCHER_AVAILABLE);
  return res.data;
};

export const getVoucherTakenAPI = async () => {
  const res = await API.HTTP.get(VOUCHER_TAKEN);
  return res.data;
};

export const getVoucherRedeemedAPI = async () => {
  const res = await API.HTTP.get(VOUCHER_REDEEMED);
  return res.data;
};

export const getVoucherRegisterAPI = async () => {
  const res = await API.HTTP.get(VOUCHER_REGISTER);
  return res.data;
};

export const getVoucherCreatedAPI = async () => {
  const res = await API.HTTP.get(VOUCHER_CREATED);
  return res.data;
};

export const getVoucherReportAPI = async (payload: IVoucherReport) => {
  const res = await API.HTTP.get(
    `${VOUCHER_REPORT}startdate=${payload.startDate}&enddate=${payload.endDate}`
  );
  return res.data;
};

export const postConfirmVoucherAPI = async () => {
  const res = await API.HTTP.post(REGISTER_ESB);
  return res.data;
};

export const postRegisterVoucherAPI = async () => {
  const res = await API.HTTP.post(COMPLETE_REGISTER);
  return res.data;
};

export const postVerifyVoucherAPI = async (payload: any) => {
  const res = await API.HTTP.post(VERIFY_VOUCHER, payload);
  return res.data;
};

export const postInjectVoucherAPI = async (payload: any) => {
  const res = await API.HTTP.post(VOUCHER_INJECT, payload);
  return res.data;
};

export const getVoucherOnRegisterAPI = async (id: string) => {
  const res = await API.HTTP.get(`${VOUCHER_ON_REGISTER}?productId=${id}`);
  return res.data;
};

export const getVoucherProductAPI = async () => {
  const res = await API.HTTP.get(VOUCHER_PRODUCT);
  return res.data;
};

export const getVoucherHistoryOutletAPI = async (payload: IVoucherReport) => {
  const res = await API.HTTP.get(
    `${VOUCHER_HISTORY_OUTLET}startdate=${payload.startDate}&enddate=${payload.endDate}`
  );
  return res.data;
};

export const getVoucherUserAPI = async (phone: string) => {
  const res = await API.HTTP.get(`${VOUCHER_USER}${phone}`);
  return res.data;
};

export const updateVoucherExpiredAPI = async (saledId: string) => {
  const res = await API.HTTP.put(`${VOUCHER_USER_EXPIRED_UPDATE}${saledId}`);

  return res.data;
};
