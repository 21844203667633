import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getPaymentBrandAPI } from "./BrandPaymentAPI";

interface IPaymentBrand {
  startDate: string;
  endDate: string;
}

export const getPaymentBrandReq = createAsyncThunk(
  "brand/getPaymentBrandReq",
  async (payload: IPaymentBrand) => {
    const response = await getPaymentBrandAPI(payload.startDate, payload.endDate);
    return response;
  }
);

const initialState = {
  brandPayment: [],
  paymentBrandLoading: false,
  brandPaymentCSV: []
};

export const BrandPaymentSlice = createSlice({
  name: "brand",
  initialState: initialState,
  reducers: {
    getPaymentBrandCSV: (state: any) => {
      state.brandPaymentCSV = state.brandPayment.map((item: any) => ({
        Date: item.date,
        Username: item.userName,
        Outlet: item.outlet,
        "Outlet Code": item.outletCode,
        Transaction: item.transaction,
        Payment: item.payment,
        "Payment Code": item.paymentCode
      }));
    }
  },
  extraReducers(builder) {
    builder.addCase(getPaymentBrandReq.pending, (state, action) => {
      state.paymentBrandLoading = true;
    });
    builder.addCase(getPaymentBrandReq.fulfilled, (state, action) => {
      action.payload.code === 1000 ? (state.brandPayment = action.payload.data) : undefined;
      state.paymentBrandLoading = false;
    });
    builder.addCase(getPaymentBrandReq.rejected, (state, action) => {
      state.paymentBrandLoading = false;
    });
  }
});

export const { getPaymentBrandCSV } = BrandPaymentSlice.actions;

export default BrandPaymentSlice.reducer;
