import API from "@constants/API";

const BASE_URL = "https://api.dev.daily.co.id/dashboard/product/product";

const POST_URL = "https://api.dev.daily.co.id/dashboard/product/upload/product";

export const getOjolProductsAPI = async () => {
  const response = await API.HTTP.get(`${BASE_URL}s`);
  return response;
};

export const getOjolDetailProductAPI = async (id: string) => {
  const response = await API.HTTP.get(`${BASE_URL}/${id}`);
  return response;
};

export const delOjolProductAPI = async (id: string) => {
  const response = await API.HTTP.delete(`${BASE_URL}/${id}`);

  return response.data;
};

export type IPayloadProduct = {
  name: string;
  price: number;
  image: File;
};

export const postOjolProductAPI = async (Data: object) => {
  const response = await API.HTTP.post(`${POST_URL}`, Data, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });

  return response.data;
};

export const putOjolProductAPI = async (Data: object, id: string) => {
  const response = await API.HTTP.put(`${POST_URL}/${id}`, Data, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });

  return response.data;
};
