import API from "@constants/API";

const BASE_URL = "https://api.dev.daily.co.id/dashboard/region/cdp-region";

export const getOjolRegionAPI = async () => {
  const response = await API.HTTP.get(`${BASE_URL}?skip=0&limit=5`);

  return response;
};

export const delOjolRegionAPI = async (_id: string | undefined) => {
  const response = await API.HTTP.delete(`${BASE_URL}/${_id}`);

  return response.data;
};

export const getDetailOjolRegionAPI = async (id: string | undefined) => {
  const response = await API.HTTP.get(`${BASE_URL}/${id}`);

  return response.data;
};

export const postOjolRegionAPI = async (Data: object) => {
  const response = await API.HTTP.post(`${BASE_URL}`, Data);

  return response.data;
};
export type DataPutRegion = {
  id: string;
  authority: string;
};
export const putOjolRegionAPI = async (Data: DataPutRegion) => {
  // const data = {
  //   authority: Data.
  // }
  const response = await API.HTTP.put(`${BASE_URL}/${Data.id}`, { authority: Data.authority });

  return response.data;
};
