import { createSlice, createAsyncThunk, PayloadAction, CaseReducer } from "@reduxjs/toolkit";
import { Overwrite, PayloadResponse } from "@typed/global";
import { User, UserStoreState } from "@typed/dailyapp/user";
import swal from "sweetalert";
import {
  getUserActivityApi,
  getUserApi,
  getUserLimit,
  getUsersApi,
  postResetEmployeeApi
} from "./UserAPI";

import { dateToString } from "@helpers/utils";

type Payload = Overwrite<
  PayloadResponse,
  {
    data: {
      users: User[];
      totalUsers: number;
    };
  }
>;

export const getUsersRequest = createAsyncThunk(
  "dailyapp-users/getUsersRequest",
  async (payload: any, thunk) => {
    const response: Payload = await getUsersApi(payload);
    // response.code === 1000 && thunk.dispatch(setSkip(page));

    return response;
  }
);

export const getUserByLimit = createAsyncThunk(
  "dailyapp-user/getUserByLimit",
  async (payload: any) => {
    const response = await getUserLimit(payload.limit, payload.skip);
    return response;
  }
);

export const getUserRequest = createAsyncThunk(
  "dailyapp-users/getUserRequest",
  async (payload: { id: string }) => {
    const response = await getUserApi(payload);
    return response;
  }
);

export const getUserActivityRequest = createAsyncThunk(
  "dailyapp-users/getUserActivityRequest",
  async (payload: { id: string | undefined }) => {
    const response: Overwrite<PayloadResponse, {}> = await getUserActivityApi(payload);
    return response;
  }
);
const initialState: UserStoreState = {
  users: [],
  user: {} as User,
  skip: 1000,
  page: 0,
  errorMessage: "",
  isLoading: false,
  totalUser: 0,
  activities: {
    codeVerify: 0,
    redeemed: 0,
    reported: 0,
    reviewed: 0,
    pointObtained: 0,
    pointUsage: 0
  },
  usersCSV: [],
  isLoadingEmployee: false,
  reportUsers: []
};

export const getReportUsersReq = createAsyncThunk(
  "dailyapp-users/getUsersApi",
  async (payload: any) => {
    const response = await getUsersApi(payload);
    return response;
  }
);

export const postResetEmployeeReq = createAsyncThunk(
  "dailyapp-users/postResetEmployeeReq",
  async (id: string) => {
    const response = await postResetEmployeeApi(id);
    return response;
  }
);

export const userSlice = createSlice({
  name: "dailyapp-users",
  initialState: initialState,
  reducers: {
    setSkip: (state: UserStoreState) => {
      state.skip = state.skip + 1000;
    },
    setPage: (state: UserStoreState) => {
      state.page = 0;
    },
    getUsersByLimit: (state: { users: User[] }, action: any) => {
      state.users = [...state.users, ...action.payload];
    },
    resetUser: (state: { users: User[] }) => {
      state.users = [];
    },
    filterCSV: (state: UserStoreState) => {
      state.usersCSV = state.users.map((item) => ({
        "Phone Number": item.phone?.number,
        "Full Name": item.fullName,
        "Customer Name": item.fullName,
        Company: "Dailybox Group"
      }));
    },
    CSVUsers: (state: any) => {
      state.usersCSV = state.users.map((item: any) => ({
        Name: item.fullName,
        Gender: item.gender,
        Phone: item.phone?.number,
        "Register Date": dateToString(item.createdAt),
        "Birth Date": dateToString(item.birthDate),
        "Employee Id": item.employeeId
      }));
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getUsersRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getUsersRequest.fulfilled, (state, action) => {
      if (action.payload.code === 1000) {
        state.users = action.payload.data.users;
        state.totalUser = action.payload.data.totalUsers;
      } else {
        state.errorMessage = "";
      }
      state.isLoading = false;
    });
    builder.addCase(getUsersRequest.rejected, (state) => {
      state.isLoading = false;
    });
    // getUserByLimit
    builder.addCase(getUserByLimit.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getUserByLimit.fulfilled, (state, action) => {
      if (action.payload.code === 1000) {
        state.users = [...state.users, ...action.payload.data.users];
        state.totalUser = action.payload.data.totalUsers;
      } else {
        state.errorMessage = "";
      }
      state.isLoading = false;
    });
    builder.addCase(getUserByLimit.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getUserActivityRequest.pending, (state) => {
      state.isLoading = true;
    });
    // getUserRequest
    builder.addCase(getUserRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getUserRequest.fulfilled, (state, action) => {
      if (action.payload.code === 1000) {
        state.user = action.payload.data;
      }

      state.isLoading = false;
    });
    builder.addCase(getUserRequest.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getUserActivityRequest.fulfilled, (state, action) => {
      if (action.payload.code === 1000) {
        state.activities = action.payload.data;
      } else {
        state.errorMessage = "";
      }
      state.isLoading = false;
    });
    builder.addCase(getUserActivityRequest.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getReportUsersReq.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getReportUsersReq.fulfilled, (state, action) => {
      state.reportUsers = action.payload.data;
      state.isLoading = false;
    });
    builder.addCase(getReportUsersReq.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(postResetEmployeeReq.pending, (state) => {
      state.isLoadingEmployee = true;
    });
    builder.addCase(postResetEmployeeReq.fulfilled, (state, action) => {
      action.payload.code === 1000
        ? swal("Good job!", "You reset Employee Account!", "success")
        : swal("Ops!", `${action.payload.code} : Something went wrong`, "success");
      state.isLoadingEmployee = false;
    });
    builder.addCase(postResetEmployeeReq.rejected, (state) => {
      state.isLoadingEmployee = false;
    });
  }
});

// Action creators are generated for each case reducer function
export const { filterCSV, getUsersByLimit, resetUser, CSVUsers, setSkip, setPage } =
  userSlice.actions;

export default userSlice.reducer;
