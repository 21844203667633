import React, { useEffect } from "react";
import Loading from "@components/loading";
import CardDetail from "@components/HRIS/CardDetail";
import { useAppDispatch, useAppSelector } from "@stores/store";
import { useNavigate, useParams } from "react-router-dom";
import {
  getDetailStatusRequest,
  putActiveStatusRequest,
  putDeleteStatusRequest,
  deletedStatus
} from "@stores/HRIS/Status/StatusSlice";
import { Toaster } from "react-hot-toast";

const SingleStatus = () => {
  const dispatch = useAppDispatch();
  const { isLoading, dataDetail, deletedRedirect } = useAppSelector((state) => state.hrisStatus);
  const navigate = useNavigate();

  useEffect(() => {
    if (deletedRedirect) {
      dispatch(deletedStatus());
      setTimeout(() => {
        navigate("/hris/status");
      }, 600);
    }
  }, [deletedRedirect]);

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      dispatch(getDetailStatusRequest(id));
    }
  }, [id]);

  const handleActive = () => {
    const id = dataDetail._id;
    const isActive = dataDetail.isActived;
    dispatch(putActiveStatusRequest({ id, isActive }));
  };

  const handleDelete = () => {
    const id = dataDetail._id;
    const isDelete = dataDetail.isDeleted;
    dispatch(putDeleteStatusRequest({ id, isDelete }));
  };
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Toaster />
          <CardDetail
            name="Name"
            handleDelete={handleDelete}
            handleActive={handleActive}
            active={dataDetail.isActived}
            linkTo={`/hris/status/edit/${id}`}
            value={dataDetail.name}
            description="Description"
            valueDesc={dataDetail.description}
          />
        </>
      )}
    </>
  );
};

export default SingleStatus;
