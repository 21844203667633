import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, PropsWithChildren, useRef } from "react";

const Close = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
      <path
        id="icon_close"
        d="M-1329.813-917.5a1.682,1.682,0,0,1,1.193.494l6.119,6.119,6.119-6.119a1.687,1.687,0,0,1,2.386,0,1.682,1.682,0,0,1,.494,1.193,1.682,1.682,0,0,1-.494,1.193l-6.119,6.119,6.119,6.119a1.682,1.682,0,0,1,.494,1.193,1.682,1.682,0,0,1-.494,1.193,1.687,1.687,0,0,1-2.386,0l-6.119-6.119-6.119,6.119a1.688,1.688,0,0,1-2.386,0,1.683,1.683,0,0,1-.494-1.193,1.683,1.683,0,0,1,.494-1.193l6.119-6.119-6.119-6.119a1.683,1.683,0,0,1-.494-1.193,1.683,1.683,0,0,1,.494-1.193A1.683,1.683,0,0,1-1329.813-917.5Z"
        transform="translate(1331.5 917.5)"
        fill="#fff"
      />
    </svg>
  );
};

type ModalProps = {
  isOpen: boolean;
  title: string;
  closeModal: () => void;
};

const Modal = ({ isOpen, children, closeModal, title }: PropsWithChildren<ModalProps>) => {
  const refDiv = useRef<any>();

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-[55]" onClose={closeModal} initialFocus={refDiv}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-50" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 ">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className="w-full max-w-md transform overflow-hidden rounded-2xl
                bg-white p-6  shadow-xl transition-all"
              >
                <div className="w-full flex items-center justify-center bg-blue-500 absolute top-0 right-0 p-2 font-poppins text-white">
                  <p className="text-xl text-center font-bold">{title}</p>
                  <div className="absolute top-3 right-8">
                    <button
                      className="outline-none border-none"
                      type="button"
                      ref={refDiv}
                      onClick={closeModal}
                    >
                      <Close />
                    </button>
                  </div>
                </div>
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export const ChildModal = ({ closeModal, isOpen, children }: PropsWithChildren<ModalProps>) => {
  const refDiv = useRef(null);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal} initialFocus={refDiv}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto" ref={refDiv}>
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className="w-full max-w-md transform overflow-hidden rounded-2xl
                bg-white p-6 text-left align-middle shadow-xl transition-all"
              >
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Modal;
