import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { IStoreBanner } from "@typed/dailyapp/Banner";
import {
  deleteBannerBrandAPI,
  deleteBannerDailycoinAPI,
  deleteBannerProfileAPI,
  detailBannerBrandAPI,
  detailBannerDailycoinAPI,
  detailBannerProfileAPI,
  getBannerAPI,
  getBannerBrandAPI,
  getBannerDialycoinAPI,
  getBannerProfileAPI,
  postBannerAPI,
  postBannerBrandAPI,
  postBannerDailycoinAPI,
  postBannerProfileAPI,
  putBannerAPI,
  putBannerBrandAPI,
  putBannerDailycoinAPI,
  putBannerProfileAPI
} from "./BannerAPI";
import { data } from "autoprefixer";
import state from "sweetalert/typings/modules/state";
import { Action } from "@remix-run/router";
import { string } from "yup";
import { any } from "ramda";

export const getBannerRequest = createAsyncThunk("dailyapp-banner/getBannerRequest", async () => {
  const response = await getBannerAPI();
  return response;
});

export const postBannerRequest = createAsyncThunk(
  "dailyapp-banner/postBannerRequest",
  async (data: any) => {
    const response = await postBannerAPI(data);
    return response;
  }
);

export const putBannerRequest = createAsyncThunk(
  "dailyapp-banner/putBannerRequest",
  async ({ formData, id }: IPayloadBanner) => {
    const response = await putBannerAPI(formData, id);
    return response;
  }
);

// === || ===

export const getBannerProfileRequest = createAsyncThunk(
  "dailyapp-banner/getBannerProfileRequest",
  async () => {
    const response = await getBannerProfileAPI();
    return response;
  }
);

export const postBannerProfileRequest = createAsyncThunk(
  "dailyapp-banner/postBannerProfileRequest",
  async (data: object) => {
    const response = await postBannerProfileAPI(data);
    return response;
  }
);

export const putBannerProfileRequest = createAsyncThunk(
  "dailyapp-banner/putBannerProfileRequest",
  async ({ formData, id }: IPayloadBanner) => {
    const response = await putBannerProfileAPI(formData, id);
    return response;
  }
);

export const deleteBannerProfileRequest = createAsyncThunk(
  "dailyapp-banner/deleteBannerProfileRequest",
  async (id: string) => {
    const response = await deleteBannerProfileAPI(id);
    return response;
  }
);

export const detailBannerProfileRequest = createAsyncThunk(
  "dailyapp-banner/detailBannerProfileRequest",
  async (id: string) => {
    const response = await detailBannerProfileAPI(id);
    return response;
  }
);

// === || ===

export const getBannerBrandRequest = createAsyncThunk(
  "dailyapp-banner/getBannerBrandRequest",
  async (type: string) => {
    const response = await getBannerBrandAPI(type);
    return response;
  }
);

export const postBannerBrandRequest = createAsyncThunk(
  "dailyapp-banner/postBannerBrandRequest",
  async ({ data, type }: { data: object; type: any }) => {
    const response = await postBannerBrandAPI(data, type);
    return response;
  }
);

export const putBannerBrandRequest = createAsyncThunk(
  "dailyapp-banner/putBannerBrandeRequest",
  async ({ formData, id }: IPayloadBanner) => {
    const response = await putBannerBrandAPI(formData, id);
    return response;
  }
);

export const deleteBannerBrandRequest = createAsyncThunk(
  "dailyapp-banner/deleteBannerBrandRequest",
  async (id: string) => {
    const response = await deleteBannerBrandAPI(id);
    return response;
  }
);

export const detailBannerBrandRequest = createAsyncThunk(
  "dailyapp-banner/detailBannerBrandRequest",
  async (id: string) => {
    const response = await detailBannerBrandAPI(id);
    return response;
  }
);

// === || ===

export const getBannerDialycoinRequest = createAsyncThunk(
  "dailyapp-banner/getBannerDailycoinRequest",
  async () => {
    const response = await getBannerDialycoinAPI();
    return response;
  }
);

export const postBannerDailycoinRequest = createAsyncThunk(
  "dailyapp-banner/postBannerDailycoinRequest",
  async (data: object) => {
    const response = await postBannerDailycoinAPI(data);
    return response;
  }
);

export const putBannerDailycoinRequest = createAsyncThunk(
  "dailyapp-banner/putBannerDailycoinRequest",
  async ({ formData, id }: IPayloadBanner) => {
    const response = await putBannerDailycoinAPI(formData, id);
    return response;
  }
);

export const deleteBannerDailycoinRequest = createAsyncThunk(
  "dailyapp-banner/deleteBannerDailycoinRequest",
  async (id: string) => {
    const response = await deleteBannerDailycoinAPI(id);
    return response;
  }
);

export const detailBannerDailycoinRequest = createAsyncThunk(
  "dailyapp-banner/detailBannerDailycoinRequest",
  async (id: string) => {
    const response = await detailBannerDailycoinAPI(id);
    return response;
  }
);

interface IPayloadBanner {
  formData: object;
  id: string;
}

const initialState: IStoreBanner = {
  data: [],
  isLoading: false,
  type: ""
};

const BannerSlice = createSlice({
  name: "dailyapp-banner",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getBannerRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getBannerRequest.fulfilled, (state, action) => {
      state.data = action.payload.data;

      state.isLoading = false;
    });
    builder.addCase(getBannerRequest.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(postBannerRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(postBannerRequest.fulfilled, (state, action) => {
      action.payload.code === 1000
        ? (state.data = [action.payload.data, ...state.data])
        : toast.error("Something went wrong");
      state.isLoading = false;
    });
    builder.addCase(postBannerRequest.rejected, (state) => {
      state.isLoading = false;
    });

    // ==== || ====
    builder
      .addCase(getBannerProfileRequest.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBannerProfileRequest.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.isLoading = false;
      })
      .addCase(getBannerProfileRequest.rejected, (state) => {
        state.isLoading = false;
      });

    builder
      .addCase(postBannerProfileRequest.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postBannerProfileRequest.fulfilled, (state, action) => {
        action.payload.code === 1000
          ? (state.data = [action.payload.data, ...state.data])
          : toast.error("Something went wrong");
        state.isLoading = false;
      })
      .addCase(postBannerProfileRequest.rejected, (state) => {
        state.isLoading = false;
      });

    builder
      .addCase(deleteBannerProfileRequest.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteBannerProfileRequest.fulfilled, (state) => {
        // state.data = action.payload.data;
        state.isLoading = false;
      })
      .addCase(deleteBannerProfileRequest.rejected, (state) => {
        state.isLoading = false;
      });

    builder
      .addCase(detailBannerProfileRequest.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(detailBannerProfileRequest.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(detailBannerProfileRequest.rejected, (state) => {
        state.isLoading = false;
      });

    // === || ===
    builder
      .addCase(getBannerBrandRequest.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBannerBrandRequest.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.isLoading = false;
      })
      .addCase(getBannerBrandRequest.rejected, (state) => {
        state.isLoading = false;
      });

    builder
      .addCase(postBannerBrandRequest.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postBannerBrandRequest.fulfilled, (state, action) => {
        action.payload.code === 1000
          ? (state.data = [action.payload.data, ...state.data])
          : toast.error("Something went wrong");
        state.isLoading = false;
      })
      .addCase(postBannerBrandRequest.rejected, (state) => {
        state.isLoading = false;
      });

    builder
      .addCase(deleteBannerBrandRequest.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteBannerBrandRequest.fulfilled, (state) => {
        // state.data = action.payload.data;
        state.isLoading = false;
      })
      .addCase(deleteBannerBrandRequest.rejected, (state) => {
        state.isLoading = false;
      });

    builder
      .addCase(detailBannerBrandRequest.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(detailBannerBrandRequest.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(detailBannerBrandRequest.rejected, (state) => {
        state.isLoading = false;
      });

    // === || ===
    builder
      .addCase(getBannerDialycoinRequest.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBannerDialycoinRequest.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.isLoading = false;
      })
      .addCase(getBannerDialycoinRequest.rejected, (state) => {
        state.isLoading = false;
      });

    builder
      .addCase(postBannerDailycoinRequest.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postBannerDailycoinRequest.fulfilled, (state, action) => {
        action.payload.code === 1000
          ? (state.data = [action.payload.data, ...state.data])
          : toast.error("Something went wrong");
        state.isLoading = false;
      })
      .addCase(postBannerDailycoinRequest.rejected, (state) => {
        state.isLoading = false;
      });

    builder
      .addCase(deleteBannerDailycoinRequest.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteBannerDailycoinRequest.fulfilled, (state) => {
        // state.data = action.payload.data;
        state.isLoading = false;
      })
      .addCase(deleteBannerDailycoinRequest.rejected, (state) => {
        state.isLoading = false;
      });

    builder
      .addCase(detailBannerDailycoinRequest.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(detailBannerDailycoinRequest.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(detailBannerDailycoinRequest.rejected, (state) => {
        state.isLoading = false;
      });
  }
});

export default BannerSlice.reducer;
