import { getOjolDetailProductRequest } from "@stores/dailyojol/ProductSlice";
import { useAppDispatch, useAppSelector } from "@stores/store";
import React, { useEffect } from "react";
import { Descriptions } from "antd";

import { useParams } from "react-router-dom";
import "./productdetail.css";
import { dateToString } from "@helpers/utils";

const ProductDetail = () => {
  const { detailProduct } = useAppSelector((state) => state.dailyojolProduct);
  const { id } = useParams();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (id) {
      dispatch(getOjolDetailProductRequest(id));
    }
  }, [id]);

  return (
    <>
      <div>
        <div className="container rounded-lg h-[16rem] w-[16rem]">
          <img
            src={detailProduct.image}
            className="w-[16rem] mx-auto hover:shadow-xl hover:scale-[.1] h-[16rem] rounded-lg"
          />
        </div>
        <Descriptions bordered title={detailProduct.name} size="small">
          <Descriptions.Item label="Date">
            {dateToString(detailProduct.createdAt)}
          </Descriptions.Item>
          <Descriptions.Item label="ID">{detailProduct._id}</Descriptions.Item>
          <Descriptions.Item label="Price">{detailProduct.price}</Descriptions.Item>
          {/* <Descriptions.Item label="Daily Price">{detailProduct.price}</Descriptions.Item> */}
          <Descriptions.Item label="Daily BoxPrice">
            {detailProduct.dailyboxPrice}
          </Descriptions.Item>
          <Descriptions.Item label="Active">{detailProduct.flagActive}</Descriptions.Item>
        </Descriptions>
      </div>
    </>
  );
};

export default ProductDetail;
