import React, { useState, useEffect, FC } from "react";
import {
  createOutletRequest,
  editOutletRequest,
  getOutletDetailRequest,
  resetOutlet
} from "@stores/dailyojol/OutletSlice";
import { useAppDispatch, useAppSelector } from "@stores/store";
import { ICreateOutletData } from "@typed/dailojol/Outlet";
import { isEmpty } from "ramda";
import { Toaster } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
// import { Formik, Form } from "formik";
// import * as Yup from "yup";
import swal from "sweetalert";

const AddOutlet: FC = () => {
  const [createOutlet, setCreateOutlet] = useState<ICreateOutletData>({} as ICreateOutletData);
  const { loading, Outlet, updateOutlet } = useAppSelector((state) => state.dailyojolOutlet);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { name, lat, long, address, code } = createOutlet;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCreateOutlet({ ...createOutlet, [name]: value });
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (id) {
      const Data = {
        name: name,
        lat: Number(lat),
        long: Number(long),
        id: id
      };
      dispatch(editOutletRequest(Data)).then(({ payload }) => {
        if (payload.code === 1000) {
          navigate("/dailyojol/outlet");
          swal("Success!", "You outlet is update!", "success");
        }
      });
    } else {
      const Data = {
        name: name,
        lat: Number(lat),
        long: Number(long),
        code: code,
        address: address
      };
      dispatch(createOutletRequest(Data)).then(({ payload }) => {
        if (payload.code === 1000) {
          navigate("/dailyojol/outlet");
          swal("Success!", "You outlet is created!", "success");
        }
      });
    }
  };

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      Outlet.filter(
        (item) =>
          item._id === id &&
          setCreateOutlet({
            name: item.name,
            lat: item.lat,
            long: item.long,
            address: item.address,
            code: item.code
          })
      );
    }
  }, [id]);

  // useEffect(() => {
  //   if (!isEmpty(updateOutlet)) {
  //     dispatch(resetOutlet());
  //     setCreateOutlet({} as ICreateOutletData);
  //     setTimeout(() => {
  //       navigate("/dailyojol/outlet");
  //     }, 500);
  //   }
  // }, [updateOutlet]);

  return (
    <div className="flex min-h-screen items-center justify-center">
      <Toaster />

      <form className="shadow rounded flex flex-col gap-4 w-[50%] min-h[30vh] bg-white p-2">
        <h1 className="p-0 m-0 font-semibold text-[1.4rem] text-center">
          {id ? "Edit" : "Create"} Outlet
        </h1>
        <label>
          <h1 className=" text-bold p-0 m-0 ">Name</h1>
          <input
            placeholder="Name"
            value={name}
            name="name"
            onChange={handleChange}
            type="text"
            className="h-8  w-full focus:outline-none outline-none pl-2 rounded border border-slate-400"
          />
        </label>

        <label className={id ? "hidden" : "block"}>
          <h1 className=" text-bold p-0 m-0 ">Code</h1>
          <input
            placeholder="Name"
            name="code"
            value={code}
            type="text"
            onChange={handleChange}
            className="h-8  w-full focus:outline-none outline-none pl-2 rounded border border-slate-400"
          />
        </label>

        <label className={id ? "hidden" : "block"}>
          <h1 className=" text-bold p-0 m-0 ">Address</h1>
          <input
            placeholder="Name"
            name="address"
            value={address}
            onChange={handleChange}
            type="text"
            className="h-8  w-full focus:outline-none outline-none pl-2 rounded border border-slate-400"
          />
        </label>

        <label>
          <h1 className=" text-bold p-0 m-0 ">Lat</h1>
          <input
            placeholder="Lat"
            name="lat"
            value={lat}
            onChange={handleChange}
            type="number"
            className="h-8  w-full focus:outline-none outline-none pl-2 rounded border border-slate-400"
          />
        </label>

        <label>
          <h1 className=" text-bold p-0 m-0 ">Lot</h1>
          <input
            placeholder="Long"
            name="long"
            value={long}
            onChange={handleChange}
            type="number"
            className="h-8  w-full focus:outline-none outline-none pl-2 rounded border border-slate-400"
          />
        </label>

        <button
          onClick={handleSubmit}
          className={
            loading
              ? "bg-gray-500 cursor-wait  text-white w-full h-9 rounded font-medium "
              : "bg-blue-500 text-white w-full h-9 rounded font-medium hover:bg-blue-600"
          }
        >
          {id ? "Edit" : "Submit"}
        </button>
      </form>
    </div>
  );
};

export default AddOutlet;
