import API from "@constants/API";

const BASE_URL = "https://api.prod.daily.co.id/dashboard/transaction/v3/trx/";

export const getAllTransactionAPI = async (page: number) => {
  const response = await API.HTTP.get(
    `https://api.new.v1.daily.co.id/dashboard/transaction/1.0.0/transactions/?skip=0&limit=2000`
  );

  return response.data;
};

export const getTransactionByUserIdApi = async (id: string) => {
  const response = await API.HTTP.get(
    "https://api.new.v1.daily.co.id/dashboard/transaction/1.0.0/user-transactions/" + id
  );
  return response.data;
};

export const postRefundCoinAPI = async (data: object) => {
  const response = await API.HTTP.post(
    "https://api.new.v1.daily.co.id/dashboard/refund/1.0.0/dailycoin-refund",
    data
  );
  return response.data;
};
