import API from "@constants/API";

const BASE_URL = "https://api.prod.dailyapp.id/api-dashboard-v1-notif";

export const postNotifBlastAPI = async (payload: any) => {
  const res = await API.HTTP.post(`${BASE_URL}/notification`, payload);
  return res.data;
};

export const postNotifSelectionBlastAPI = async (payload: any) => {
  const res = await API.HTTP.post(`${BASE_URL}/selection-notification`, payload);
  return res.data;
};
