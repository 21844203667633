import SkeletonImage from "@components/SkletonImage";
import { getProductRequest } from "@stores/dailyapp/ProductSlice";
import { useAppSelector } from "@stores/store";
import { Button, Descriptions, Tag } from "antd";
import React, { FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams, Link } from "react-router-dom";

const Product: FC = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { singleProduct, isLoading } = useAppSelector((state) => state.dailyappProduct);

  useEffect(() => {
    if (id) {
      // console.log(id);
      dispatch(getProductRequest({ id: id }));
    }
  }, [id]);

  return (
    <div>
      {isLoading ? (
        <SkeletonImage />
      ) : (
        <>
          <div>
            <img
              className="w-[20rem] object-cover h-[20rem] mx-auto rounded-lg shadow"
              src={singleProduct.image}
            />
          </div>

          <Descriptions
            bordered
            title={singleProduct?.name}
            size="middle"
            extra={
              <Link to={`/dailyapp/products/edit/${id}`} type="primary">
                Edit
              </Link>
            }
          >
            {/* <Descriptions.Item span={3} label="Image"> */}
            {/* </Descriptions.Item> */}
            <Descriptions.Item span={3} label="Is Active ?">
              {singleProduct.flagDailyAppIsActive ? (
                <Tag color="green">Active</Tag>
              ) : (
                <Tag color="red">Inactive</Tag>
              )}
            </Descriptions.Item>
            <Descriptions.Item span={3} label="Price in Coins">
              {singleProduct.dailyPrice}
            </Descriptions.Item>
          </Descriptions>
        </>
      )}
    </div>
  );
};

export default Product;
