import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { IHRISStore, IPayloadAdd, IPayloadHRIS, IDataHRIS } from "@typed/HRIS/allHris";
import toast from "react-hot-toast";
import {
  getDetailPtkpAPI,
  getPtkpAPI,
  postPtkpAPI,
  putActivePtkpAPI,
  putDeletePtkpAPI,
  putPtkpAPI
} from "./PtkpAPI";

export const getPtkpRequest = createAsyncThunk("hris-ptkp/getPtkpRequest", async () => {
  const response = await getPtkpAPI();
  return response;
});

export const getDetailPtkpRequest = createAsyncThunk(
  "hris-ptkp/getDetailPtkpRequest",
  async (id: string) => {
    const response = await getDetailPtkpAPI(id);
    return response;
  }
);

export const postPtkpRequest = createAsyncThunk(
  "hris-ptkp/postPtkpRequest",
  async (Data: object) => {
    const response = await postPtkpAPI(Data);
    return response;
  }
);

export const putPtkpRequest = createAsyncThunk(
  "hris-ptkp/putPtkpRequest",
  async ({ id, Data }: IPayloadHRIS) => {
    const response = await putPtkpAPI({ id, Data });
    return response;
  }
);

export const putActivePtkpRequest = createAsyncThunk(
  "hris-ptkp/putActivePtkpRequest",
  async ({ id, isActive }: { id: string; isActive: boolean }) => {
    const response = await putActivePtkpAPI(id, isActive);
    return response;
  }
);

export const putDeletePtkpRequest = createAsyncThunk(
  "hris-ptkp/putDeletePtkpRequest",
  async ({ id, isDelete }: { id: string; isDelete: boolean }) => {
    const response = await putDeletePtkpAPI(id, isDelete);
    return response;
  }
);

const initialState: IHRISStore = {
  data: [],
  isLoading: false,
  dataDetail: {} as IDataHRIS,
  lastCreateData: {} as IDataHRIS,
  deletedRedirect: false
};

const PtkpSlice = createSlice({
  name: "hris-ptkp",
  initialState: initialState,
  reducers: {
    resetPtkp: (state: { lastCreateData: IDataHRIS }) => {
      state.lastCreateData = {} as IDataHRIS;
    },
    deletedPtkp: (state: { deletedRedirect: boolean }) => {
      state.deletedRedirect = false;
    }
  },
  extraReducers(builder) {
    builder.addCase(getPtkpRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getPtkpRequest.fulfilled, (state, action) => {
      if (action.payload.code === 1000) {
        state.data = action.payload.data;
      }
      state.isLoading = false;
    });
    builder.addCase(getPtkpRequest.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getDetailPtkpRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getDetailPtkpRequest.fulfilled, (state, action) => {
      if (action.payload.code === 1000) {
        state.dataDetail = action.payload.data;
      }
      state.isLoading = false;
    });
    builder.addCase(getDetailPtkpRequest.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(postPtkpRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(postPtkpRequest.fulfilled, (state, action) => {
      if (action.payload.code === 1000) {
        state.lastCreateData = action.payload.data;
        toast.success("Success");
      }
      if (action.payload.code === 2004) {
        toast.error("Please enter an empty field");
      }
      state.isLoading = false;
    });
    builder.addCase(postPtkpRequest.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(putPtkpRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(putPtkpRequest.fulfilled, (state, action) => {
      if (action.payload.code === 1000) {
        state.lastCreateData = action.payload.data;
        toast.success("Update Success");
      }
      state.isLoading = false;
    });
    builder.addCase(putPtkpRequest.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(putActivePtkpRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(putActivePtkpRequest.fulfilled, (state, action) => {
      if (action.payload.code === 1000) {
        state.dataDetail.isActived = action.payload.data.isActived;
        const stats = state.dataDetail.isActived;
        toast.success(stats ? "Active Success" : "Inactive Success");
      }
      state.isLoading = false;
    });
    builder.addCase(putActivePtkpRequest.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(putDeletePtkpRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(putDeletePtkpRequest.fulfilled, (state, action) => {
      if (action.payload.code === 1000) {
        toast.success("Delete Success");
        state.deletedRedirect = true;
      }
      state.isLoading = false;
    });
    builder.addCase(putDeletePtkpRequest.rejected, (state) => {
      state.isLoading = false;
    });
  }
});

export default PtkpSlice.reducer;
export const { resetPtkp, deletedPtkp } = PtkpSlice.actions;
