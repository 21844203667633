import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { IStoreNews, IDataNews } from "@typed/dailyapp/News";
import { getNewsAPI, deleteNewsAPI, postNewsAPI, putNewsAPI, detailNewsAPI } from "./NewsAPI";
import toast from "react-hot-toast";

export const getNewsRequest = createAsyncThunk("dailyapp-news/getNewsRequest", async () => {
  const response = await getNewsAPI();
  return response;
});

export const detailNewsRequest = createAsyncThunk(
  "dailyapp-news/detailNewsRequest",
  async (id: string) => {
    const response = await detailNewsAPI(id);
    return response;
  }
);

export const deleteNewsRequest = createAsyncThunk(
  "dailyapp-news/deleteNewsRequest",
  async (id: string) => {
    const response = await deleteNewsAPI(id);
    return response;
  }
);

export const postNewsRequest = createAsyncThunk(
  "dailyapp-news/postNewsRequest",
  async (data: object) => {
    const response = await postNewsAPI(data);
    return response;
  }
);

export const putNewsRequest = createAsyncThunk(
  "dailyapp-news/putNewsRequest",
  async ({ formData, id }: { formData: object; id: string }) => {
    const response = await putNewsAPI(formData, id);
    return response;
  }
);

const initialState: IStoreNews = {
  data: [],
  isLoading: false
};

const NewsSlice = createSlice({
  name: "dailyapp-news",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getNewsRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getNewsRequest.fulfilled, (state, action) => {
      action.payload.code === 1000
        ? (state.data = action.payload.data)
        : toast.error("Something went wrong");
      state.isLoading = false;
    });
    builder.addCase(getNewsRequest.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(postNewsRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(postNewsRequest.fulfilled, (state, action) => {
      action.payload.code === 1000
        ? (state.data = [action.payload.data, ...state.data])
        : toast.error("Something went wrong");
      state.isLoading = false;
    });
    builder.addCase(postNewsRequest.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(putNewsRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(putNewsRequest.fulfilled, (state, action) => {
      action.payload.code === 1000
        ? toast.success("Update Success")
        : toast.error("Something went wrong");
      state.isLoading = false;
    });
    builder.addCase(putNewsRequest.rejected, (state) => {
      state.isLoading = false;
    });
  }
});

export default NewsSlice.reducer;
