import React from "react";
import useRole from "@hooks/useRole";
import { useAppSelector } from "@stores/store";
import "react-pro-sidebar/dist/css/styles.css";
import Forbidden from "@components/forbidden";
import { GoOrganization } from "react-icons/go";
import { Link, Outlet } from "react-router-dom";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";

import { AiOutlineBank, AiOutlineIdcard } from "react-icons/ai";

const Hris = () => {
  const employeeData = useAppSelector((state) => state.auth.employeeData);
  const isForbidden = !useRole(employeeData.roles, "hris");
  return isForbidden ? (
    <Forbidden />
  ) : (
    <div className="flex mt-[70px]   flex-row">
      <div className={"w-[200px]"}>
        <ProSidebar width={200} className="fixed">
          <Menu iconShape="square">
            {/* <MenuItem icon={<PieChartOutlined />}>Dashboard</MenuItem> */}
            <SubMenu title="Employe" icon={<GoOrganization />}>
              <MenuItem key="hris-person-identity">
                <Link to="/hris/person">Person Identity</Link>
              </MenuItem>
              <MenuItem key="hris-employe-approval">
                <Link to="/hris/employe-approval">Employe Approval</Link>
              </MenuItem>
            </SubMenu>
            <MenuItem icon={<AiOutlineBank />}>
              <Link to="/hris/bank/add">Bank Account</Link>
            </MenuItem>
            <MenuItem icon={<AiOutlineIdcard />}>
              <Link to="/hris/name-card">Name Card</Link>
            </MenuItem>

            <MenuItem>
              <Link to="/hris/position">Position</Link>
            </MenuItem>

            <MenuItem>
              <Link to="/hris/level">Level</Link>
            </MenuItem>

            <MenuItem>
              <Link to="/hris/organization">Organization</Link>
            </MenuItem>

            <MenuItem>
              <Link to="/hris/ptkp">PTKP</Link>
            </MenuItem>

            <MenuItem>
              <Link to="/hris/status">Job Status</Link>
            </MenuItem>
          </Menu>
        </ProSidebar>
      </div>
      <div className="flex-1 p-5 bg-slate-100 min-h-screen">
        <Outlet />
      </div>
    </div>
  );
};

export default Hris;
