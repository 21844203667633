import { postRedemVerifRequest, resetConfirmRedem } from "@stores/dailyapp/RedemSlice";
import { useAppDispatch, useAppSelector } from "@stores/store";
import { IConfirmRedem } from "@typed/dailyapp/Redem";
import React, { FC, useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { Button } from "antd";
import { getOutletsRequest } from "@stores/dailyojol/OutletSlice";
import { isUint16Array } from "util/types";
import R, { isEmpty } from "ramda";
import { useNavigate } from "react-router-dom";

const ConfirmRedem: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { Outlet } = useAppSelector((state) => state.dailyojolOutlet);
  const { loading, confirmRedem } = useAppSelector((state) => state.dailyRedem);
  const [confirm, setConfirm] = useState<IConfirmRedem>({} as IConfirmRedem);
  const { redeemNumber, confirmCode, outletId, creatorId } = confirm;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target; //destructring assignment
    setConfirm({ ...confirm, [name]: value });
  };

  useEffect(() => {
    dispatch(getOutletsRequest({ skip: 0, limit: 50 }));
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(confirmRedem)) {
      dispatch(resetConfirmRedem());
      navigate("/dailyapp/redem");
    }
  }, [confirmRedem]);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const ConfirmCode = Number(confirmCode);
    const Data = {
      redeemNumber,
      ConfirmCode,
      outletId,
      creatorId,
      setConfirm
    };
    dispatch(postRedemVerifRequest(Data));
  };

  return (
    <>
      <div className="flex justify-center">
        <Toaster /> {/* get notif error message  */}
        <div className="p-2 bg-white w-[55%] min-h-44 rounded">
          <h1 className="text-[1.3rem] font-semibold text-center">Confirm Redem</h1>

          <div className="">
            <h1 className="font-semibold p-0 m-0">Redem Number</h1>
            <input
              type="text"
              value={redeemNumber ?? ""}
              onChange={handleChange}
              name="redeemNumber"
              placeholder="Redem Number"
              className="w-full border h-8 rounded p-2 border-slate-400 outline-none focus:outline-none"
            />
          </div>

          <div className="mt-3">
            <h1 className="font-semibold p-0 m-0">Confirm Code</h1>
            <input
              onChange={handleChange}
              type="number"
              name="confirmCode"
              value={confirmCode ?? ""}
              placeholder="Confirm Code "
              className="w-full border h-8 rounded p-2 border-slate-400 outline-none focus:outline-none"
            />
          </div>

          <label>
            <h1 className="text-bold p-0 m-0 ">Outlet</h1>
            <select
              className="h-8  w-full focus:outline-none outline-none px-2 rounded border border-slate-400"
              onChange={handleChange}
              value={outletId ?? ""}
              name="outletId"
            >
              <option
                className="h-8  w-full focus:outline-none outline-none px-2 rounded border border-slate-400"
                value=""
              >
                Choose a outlet
              </option>
              {Outlet.map((outlet: any) => (
                <option
                  className="h-8 text-lg  w-full focus:outline-none outline-none px-2 rounded border border-slate-400"
                  key={outlet._id}
                  value={outlet._id ?? ""}
                >
                  {outlet.name}
                </option>
              ))}
            </select>
          </label>

          <div className="mt-3">
            <h1 className="font-semibold p-0 m-0">Creator ID</h1>
            <input
              type="text"
              name="creatorId"
              value={creatorId ?? ""}
              onChange={handleChange}
              placeholder="Creator ID"
              className="w-full border h-8 rounded p-2 border-slate-400 outline-none focus:outline-none"
            />
          </div>

          <button
            onClick={handleClick}
            className={
              loading
                ? "cursor-wait py-1 w-full bg-blue-500 hover:bg-blue-600 text-white rounded mt-4 "
                : "py-1 w-full bg-blue-500 hover:bg-blue-600 text-white rounded mt-4 "
            }
          >
            Submit
          </button>
        </div>
      </div>
    </>
  );
};

export default ConfirmRedem;
