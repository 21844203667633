import React, { useEffect, useRef, useState } from "react";
import { getDailyCoinsRequest } from "@stores/dailycoin/dailyCoinSlice";
import { useAppDispatch, useAppSelector } from "@stores/store";
import Seatlement from "./Settlement";
import { nf } from "@helpers/utils";
import { MyCalendar } from "@components/FullCalendar";
import DailyChart from "@components/Chart/DailyChart";
import DailycoinChart from "@components/Chart/DailycoinChart";
import PaymentOriginChart from "@components/Chart/PaymentOriginChart";
import moment from "moment";
import { getFinanceRequest, getPaymentOriginRequest } from "@stores/dailycoin/ReportingSlice";

const DashboardCoin = () => {
  const dispatch = useAppDispatch();
  const summaryTrx: any[] = useAppSelector((state) => state.dailyReport.reportFinance);
  const startDate = useRef(moment().startOf("month").format("YYYY-MM-DD"));
  const endDate = useRef(moment().format("YYYY-MM-DD"));
  const [selectedOption, setSelectedOption] = useState<string>("CANTEEN DB AVENUE");
  const dataDailycoin = useRef<any[]>(summaryTrx);

  const [date, setDate] = useState<any>({
    start: moment().startOf("month").format("YYYY-MM-DD"),
    allDay: moment().format("YYYY-MM-DD")
  });

  useEffect(() => {
    dispatch(getDailyCoinsRequest());
    dispatch(getPaymentOriginRequest({ startDate: startDate.current, endDate: endDate.current }));
    dispatch(
      getFinanceRequest({
        startDate: startDate.current,
        endDate: endDate.current
      })
    );
    dataDailycoin.current = summaryTrx;
  }, []);

  useEffect(() => {
    if (date.allDay !== "") {
      dispatch(getPaymentOriginRequest({ startDate: date.start, endDate: date.allDay }));
      dispatch(
        getFinanceRequest({
          startDate: date.start,
          endDate: date.allDay
        })
      );
      dataDailycoin.current = summaryTrx;
    }
  }, [date, selectedOption]);

  return (
    <div>
      <div className="flex gap-3">
        <div className="flex flex-col gap-3">
          <div>
            <div className="bg-[#2d88d7] bg-gradient-to-l  from-[#7545ae] w-[17rem] h-[8rem] rounded-md p-3">
              <div>
                <p className="m-0 text-[1rem] text-white">Total Dailycoin</p>
                <h1 className="m-0 text-white leading-4 font-bold text-[2rem]">Created</h1>
              </div>
              <h1 className="text-[2.3rem] text-white font-bold m-0 pt-3">
                {dataDailycoin.current.reduce(
                  (total, obj) => total + obj.dailycoinTopupCreated,
                  0
                ) ?? 0}
              </h1>
            </div>
          </div>

          <div>
            <div className="bg-[#60e3ce] bg-gradient-to-l  from-[#2f8fd9] w-[17rem] h-[8rem] rounded-md p-3">
              <h1 className="m-0 leading-[25px] text-white  font-bold text-[2rem]">Usable Coins</h1>
              <h1 className="text-[2.3rem] text-white font-bold m-0 pt-3">
                {dataDailycoin.current.reduce((total, obj) => total + obj.transactionTopup, 0) ?? 0}
              </h1>
            </div>
          </div>

          <div>
            <div className="bg-[#efa90d] bg-gradient-to-l  from-[#67e3c9] w-[17rem] h-[8rem] rounded-md p-3">
              <div>
                <p className="m-0 text-[1rem] text-white">Total Dailycoin</p>
                <h1 className="m-0 text-white leading-4 font-bold text-[2rem]">Used</h1>
              </div>
              <h1 className="text-[2.3rem] text-white font-bold m-0 pt-3">
                {dataDailycoin.current.reduce(
                  (total, obj) => total + obj.totalDailycoinPayment,
                  0
                ) ?? 0}
              </h1>
            </div>
          </div>

          <div>
            <MyCalendar date={date} setDate={setDate} />
          </div>
        </div>
        <div className="bg-white w-[21rem]  rounded-md">
          <DailyChart />
        </div>

        <div className="flex flex-col gap-3">
          <div className="bg-white w-[32rem] h-[50vh] rounded-md">
            <DailycoinChart date={date} />
          </div>

          <div className="bg-white  w-[32rem] h-[50vh] rounded-md">
            <PaymentOriginChart
              date={date}
              setDate={setDate}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
            />
          </div>
        </div>
      </div>
      {/* <Row gutter={15}>
        <Col span={6}>
          <h1 className="p-0 m-0 font-medium text-[1rem] pb-2">All Transaction</h1>
          <Card>
            <div className="flex flex-col gap-3">
              <div className="flex items-center gap-3">
                <h1 className="p-0 m-0 text-[1.1rem] text-gray-400 font-normal w-32 ">Topup</h1>
                <h1 className="p-0 m-0 font-medium text-gray-500 text-[1.1rem]">
                  {nf.format(data.allPeriodes?.dailycoinTopup)}
                </h1>
              </div>

              <div className="flex items-center gap-3">
                <h1 className="p-0 m-0 text-[1.1rem] text-gray-400 font-normal w-32 ">
                  Coin available
                </h1>
                <h1 className="p-0 m-0 font-medium text-[1.1rem] text-gray-500">
                  {nf.format(data.allPeriodes?.dailycoinAvailable)}
                </h1>
              </div>

              <div className="flex items-center gap-3">
                <h1 className="p-0 m-0 text-[1.1rem] text-gray-400 font-normal w-32 ">
                  Topup cashback
                </h1>
                <h1 className="p-0 m-0 font-medium text-gray-500 text-[1.1rem]">
                  {nf.format(data.allPeriodes?.dailycoinTopupcashback)}
                </h1>
              </div>

              <div className="flex items-center gap-3 ">
                <h1 className="p-0 m-0 text-[1.1rem] text-gray-400 font-normal w-32 ">
                  Coin payment
                </h1>
                <h1 className="p-0 m-0 font-medium text-gray-500 text-[1.1rem]">
                  {nf.format(data.allPeriodes?.dailycoinTotal)}
                </h1>
              </div>

              <div className="flex items-center gap-3">
                <h1 className="p-0 m-0 text-[1.1rem] text-gray-400 font-normal w-32 ">
                  Total coin
                </h1>
                <h1 className="p-0 m-0 font-medium text-gray-500 text-[1.1rem]">
                  {nf.format(data.allPeriodes?.dailycoinTotal)}
                </h1>
              </div>
            </div>
          </Card>
        </Col>

        <Col span={6}>
          <h1 className="p-0 m-0 font-medium text-[1rem] pb-2">Monthly Transaction</h1>
          <Card>
            <div className="flex flex-col   gap-3">
              <div className="flex items-center gap-3">
                <h1 className="p-0 m-0 text-[1.1rem] text-gray-400 font-normal w-32 ">Topup</h1>
                <h1 className="p-0 text-gray-500 m-0 font-medium text-[1.1rem]">
                  {nf.format(data.monthly?.dailycoinTopup)}
                </h1>
              </div>

              <div className="flex items-center gap-3">
                <h1 className="p-0 m-0 text-[1.1rem] text-gray-400 font-normal w-32 ">
                  Topup cashback
                </h1>
                <h1 className="p-0 text-gray-500 m-0 font-medium text-[1.1rem]">
                  {nf.format(data.monthly?.dailycoinTopupcashback)}
                </h1>
              </div>

              <div className="flex items-center gap-3 ">
                <h1 className="p-0 m-0 text-[1.1rem] text-gray-400 font-normal w-32 ">
                  Coin payment
                </h1>
                <h1 className="p-0 text-gray-500 m-0 font-medium text-[1.1rem]">
                  {nf.format(data.monthly?.dailycoinTotal)}
                </h1>
              </div>

              <div className="flex items-center gap-3">
                <h1 className="p-0 m-0 text-[1.1rem] text-gray-400 font-normal w-32 ">
                  Total coin
                </h1>
                <h1 className="p-0 text-gray-500 m-0 font-medium text-[1.1rem]">
                  {nf.format(data.monthly?.dailycoinTotal)}
                </h1>
              </div>
            </div>
          </Card>
        </Col>

        <Col span={6}>
          <h1 className="p-0 m-0 font-medium text-[1rem] pb-2">Yesterday Transaction</h1>
          <Card>
            <div className="flex flex-col   gap-3">
              <div className="flex items-center gap-3">
                <h1 className="p-0 m-0 text-[1.1rem] text-gray-400 font-normal w-32 ">Topup</h1>
                <h1 className="p-0 m-0 text-gray-500 font-medium text-[1.1rem]">
                  {nf.format(data.yesterday?.dailycoinTopup)}
                </h1>
              </div>

              <div className="flex items-center gap-3">
                <h1 className="p-0 m-0 text-[1.1rem] text-gray-400 font-normal w-32 ">
                  Topup cashback
                </h1>
                <h1 className="p-0 m-0 text-gray-500 font-medium text-[1.1rem]">
                  {nf.format(data.yesterday?.dailycoinTopupcashback)}
                </h1>
              </div>

              <div className="flex items-center gap-3 ">
                <h1 className="p-0 m-0 text-[1.1rem] text-gray-400 font-normal w-32 ">
                  Coin payment
                </h1>
                <h1 className="p-0 m-0  text-gray-500 font-medium text-[1.1rem]">
                  {nf.format(data.yesterday?.dailycoinTotal)}
                </h1>
              </div>

              <div className="flex items-center gap-3">
                <h1 className="p-0 m-0 text-[1.1rem] text-gray-400 font-normal w-32 ">
                  Total coin
                </h1>
                <h1 className="p-0 text-gray-500 m-0 font-medium text-[1.1rem]">
                  {nf.format(data.yesterday?.dailycoinTotal)}
                </h1>
              </div>
            </div>
          </Card>
        </Col>

        <Col span={6}>
          <h1 className="p-0 m-0 font-medium text-[1rem] pb-2">Today Transaction</h1>
          <Card>
            <div className="flex flex-col   gap-3">
              <div className="flex items-center gap-3">
                <h1 className="p-0 m-0 text-[1.1rem] text-gray-400 font-normal w-32 ">Topup</h1>
                <h1 className="p-0 text-gray-500 m-0 font-medium text-[1.1rem]">
                  {nf.format(data.today?.dailycoinTopup)}
                </h1>
              </div>

              <div className="flex items-center gap-3">
                <h1 className="p-0 m-0 text-[1.1rem] text-gray-400 font-normal w-32 ">
                  Coin payments
                </h1>
                <h1 className="p-0  text-gray-500 m-0 font-medium text-[1.1rem]">
                  {nf.format(data.today?.dailycoinPayment)}
                </h1>
              </div>

              <div className="flex items-center gap-3">
                <h1 className="p-0 m-0 text-[1.1rem] text-gray-400 font-normal w-32 ">
                  Topup cashback
                </h1>
                <h1 className="p-0 text-gray-500 m-0 font-medium text-[1.1rem]">
                  {nf.format(data.today?.dailycoinTopupcashback)}
                </h1>
              </div>

              <div className="flex items-center gap-3">
                <h1 className="p-0 m-0 text-[1.1rem] text-gray-400 font-normal w-32 ">
                  Total coin
                </h1>
                <h1 className="p-0 text-gray-500 m-0 font-medium text-[1.1rem]">
                  {nf.format(data.today?.dailycoinTotal)}
                </h1>
              </div>
            </div>
          </Card>
        </Col>
      </Row> */}

      <div className="mt-9">
        <Seatlement />
      </div>
    </div>
  );
};

export default DashboardCoin;
