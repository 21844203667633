import MyTable from "@components/Table/MyTable";
import { useAppSelector } from "@stores/store";
import React from "react";

const SingleTopupProvider = () => {
  const { isLoading, singleTopupProvider } = useAppSelector((state) => state.dailyReport);

  return (
    <div>
      <MyTable
        loading={isLoading}
        exportable
        dataSource={singleTopupProvider}
        fileName="detail-topup"
        select={[
          { label: "Date", value: "date" },
          { label: "Provider", value: "provider" },
          { label: "Coin Cashback", value: "coinCashback" },
          { label: "Coin Created", value: "coinCreated" },
          { label: "Coin Purchase", value: "coinPurchase" },
          { label: "Total Payment", value: "totalPayment" }
        ]}
      />
    </div>
  );
};

export default SingleTopupProvider;
