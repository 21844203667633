import React from "react";
import { MdOutlineLocalGroceryStore } from "react-icons/md";
import { Link, Outlet } from "react-router-dom";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { useAppSelector } from "@stores/store";
import useRole from "@hooks/useRole";
import { Layout } from "antd";
import Forbidden from "@components/forbidden";

const index = () => {
  const auth = useAppSelector((state) => state.auth);
  const isForbidden = !useRole(auth.employeeData.roles, "dailyapp");
  const { Header, Content, Footer, Sider } = Layout;

  return isForbidden ? (
    <Forbidden />
  ) : (
    <>
      <div className="flex mt-[70px] flex-row">
        <div className="w-[200px]">
          <ProSidebar width={200} className="fixed">
            <Menu iconShape="square">
              <MenuItem key="dailyclick-product" icon={<MdOutlineLocalGroceryStore />}>
                <Link to="/dailyclick/product">Product</Link>
              </MenuItem>
            </Menu>
          </ProSidebar>
        </div>
        <div className="flex-1 p-5 bg-slate-100 min-h-screen ">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default index;
