import { useAppDispatch, useAppSelector } from "@stores/store";
import { Popover, Space, Tag } from "antd";
import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { Product } from "@typed/dailyapp/product";
import {
  activatedProduct,
  delProductRequest,
  getProductsRequest,
  putProductActiveRequest
} from "@stores/dailyapp/ProductSlice";
import Loading from "@components/loading";
import { IoTrashOutline } from "react-icons/io5";
import { HiOutlinePencilAlt } from "react-icons/hi";
import { FiCheck } from "react-icons/fi";
import ReactModal from "@components/ReactModal";
import InputSearch from "@components/inputSearch";
import DataTable from "react-data-table-component";

const Products = () => {
  const products = useAppSelector((state) =>
    state.dailyappProduct.products.map((x: Product) => ({ ...x, key: x._id }))
  );
  const { isLoading } = useAppSelector((state) => state.dailyappProduct);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const getId = React.useRef<string>("");
  const getProductActive = React.useRef<boolean | undefined>(undefined);
  const [text, setText] = useState<string | null>("");
  const [okProducts, setOkProducts] = useState(products);
  const [searchByName, setSearchByName] = useState("");
  const dispatch = useAppDispatch();

  const columns = [
    {
      name: "Name",
      selector: (row: Product) => row.name
      // sortable: true
    },
    {
      name: "Price",
      selector: (row: Product) => row.dailyPrice,
      sortable: true
    },
    {
      name: "Image",
      selector: (row: Product) => row.image,
      cell: (row: Product) => (
        <div>
          <img alt={"Product"} src={row.image} className="w-16" />
        </div>
      )
    },

    {
      name: "Employee ID",
      selector: (row: Product) => row.flagDailyAppIsActive,
      cell: (row: Product) => (
        <div>
          {row.flagDailyAppIsActive ? (
            <Tag color="green">Active</Tag>
          ) : (
            <Tag color="red">Deactive</Tag>
          )}
        </div>
      )
    },
    {
      name: "Action",
      selector: (row: Product) => row._id,
      cell: (record: Product) => (
        <>
          <div className="flex items-center gap-4">
            <Popover
              content={record.flagDailyAppIsActive ? "Inactive Product ?" : "Active Product?"}
            >
              <button onClick={() => showModal(record)}>
                <FiCheck
                  className={record.flagDailyAppIsActive ? "text-red-500" : "text-green-500"}
                />
              </button>
            </Popover>
            <Popover content="Delete">
              <button
                onClick={() => {
                  showModalDelete(record);
                }}
              >
                <IoTrashOutline color="red" />
              </button>
            </Popover>

            <Popover content="Edit">
              <Link to={`edit/${record._id}`}>
                <HiOutlinePencilAlt className="text-blue-500 text-[1.1rem]" />
              </Link>
            </Popover>

            <Space size="middle">
              <Link
                className="bg-blue-500 hover:text-white  text-white p-2 px-4 rounded hover:bg-blue-600"
                to={record._id}
              >
                Detail
              </Link>
            </Space>
          </div>
        </>
      )
    }
  ];

  useEffect(() => {
    // search product by name
    let Data = products.filter((item: { name: string }) => {
      return item.name.toLowerCase().includes(searchByName.toLowerCase());
    });
    if (searchByName !== "") {
      setOkProducts(Data);
    }
    if (searchByName === "") {
      setOkProducts(products);
    }
  }, [searchByName, isLoading]);

  useEffect(() => {
    dispatch(getProductsRequest());
    setOkProducts(products);
  }, [dispatch]);

  const filterByName = okProducts.filter(
    (item: Product) => item.name && item.name.toLowerCase().includes(searchByName.toLowerCase())
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <div className="flex items-centert gap-3">
        <InputSearch
          value={searchByName}
          placeholder="Search by name"
          handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setSearchByName(e.target.value);
          }}
        />
      </div>
    );
  }, [searchByName, setSearchByName]);

  // ========= START STATE MODAL =========
  const showModalDelete = useCallback(
    (record: Product) => {
      setIsModalVisible((prev) => !prev);
      setText(record.flagDailyAppIsActive ? "Are you sure you want to Delete the product ?" : null);
      getId.current = record._id;
    },
    [getId]
  );

  const showModal = useCallback(
    (record: Product) => {
      setIsModalVisible((prev) => !prev);
      setText(
        record.flagDailyAppIsActive
          ? "Are you sure you want to Deactivate the product ?"
          : "Are you sure you want to Activate the product ?"
      );
      getId.current = record._id;
      getProductActive.current = record.flagDailyAppIsActive;
    },
    [getId, getProductActive]
  );

  const handleOkModal = useCallback(() => {
    setIsModalVisible((prev) => !prev);
    if (getProductActive.current !== undefined) {
      const flagDailyAppIsActive = getProductActive.current;
      const Id = getId.current;
      dispatch(putProductActiveRequest({ Id, flagDailyAppIsActive }));
      const payload = {
        flagDailyAppIsActive,
        Id
      };
      dispatch(activatedProduct(payload));
      setTimeout(() => {
        getId.current = "";
        getProductActive.current = false;
      }, 2000);
    } else if (getId !== null) {
      dispatch(delProductRequest(getId.current));
      setTimeout(() => {
        getId.current = "";
      }, 2000);
    }
  }, [setIsModalVisible, getId, getProductActive]);

  const handleCancel = useCallback(() => {
    setIsModalVisible((prev) => !prev);
  }, []);
  // ========= END STATE MODAL =========

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <ReactModal
            title="Confirm"
            handleOkModal={handleOkModal}
            text={text}
            handleCancel={handleCancel}
            isModalVisible={isModalVisible}
          >
            {text}
          </ReactModal>

          <DataTable
            title="Product"
            data={filterByName}
            columns={columns}
            pagination
            subHeader
            // selectableRows
            persistTableHead
            // actions={actionMemo}
            subHeaderComponent={subHeaderComponentMemo}
          />
        </>
      )}
    </div>
  );
};

export default React.memo(Products);
