import React, { FC, useEffect, useState } from "react";
import { Table, Tag } from "antd";
import { useAppDispatch, useAppSelector } from "@stores/store";
import { getAllTransactionRequest } from "@stores/dailyapp/TransactionSlice";
import { dateToString, filterByKeyword } from "@helpers/utils";
import { Link } from "react-router-dom";
import InputSearch from "@components/inputSearch";
import MyTable from "@components/Table/MyTable";

const Transaction: FC = () => {
  const dispatch = useAppDispatch();

  const { isLoading } = useAppSelector((state) => state.dailyTransaction);
  const [page, setPage] = useState(50);
  const [keyword, setKeyword] = useState<string>("");

  const dataTransaction = useAppSelector((state) =>
    state.dailyTransaction.transactions.map((x: any) => ({ ...x, key: x._id }))
  );
  const [dataTrx, setDataTrx] = useState([]);

  useEffect(() => {
    dispatch(getAllTransactionRequest(page));
  }, [dispatch]);

  useEffect(() => {
    setDataTrx(filterByKeyword(dataTransaction, keyword));
  }, [isLoading]);

  return (
    <>
      <div className="flex flex-row"></div>

      <MyTable
        dataSource={dataTrx}
        fileName="All-transaction"
        searchable
        select={[
          { label: "Date", value: "createdAt" },
          { label: "Username", value: "userIdFullName" },
          { label: "Phone", value: "userIdPhoneNumber" },
          { label: "No Transaction", value: "trxNumber" },
          { label: "Description", value: "type" },
          { label: "Balance", value: "lastPoint" }
        ]}
        action={(row: any) => (
          <Link
            className="hover:text-white rounded py-2 px-5 bg-blue-500 text-white"
            to={`/dailyapp/users/${row.userId_id}`}
          >
            Detail
          </Link>
        )}
      />
    </>
  );
};

export default Transaction;
