import React from "react";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Link, Outlet } from "react-router-dom";
import { TbReport } from "react-icons/tb";
import { IoMegaphoneOutline } from "react-icons/io5";
import { HiOutlineUserGroup } from "react-icons/hi";
import { useAppSelector } from "@stores/store";
import Forbidden from "@components/forbidden";
import useRole from "@hooks/useRole";
import { BiCalendarEvent } from "react-icons/bi";
import { FaCrown } from "react-icons/fa";
import { FaHandshake } from "react-icons/fa";
import { FaWallet } from "react-icons/fa";

// nf

const index = () => {
  const auth = useAppSelector((state: { auth: any }) => state.auth);

  const isForbidden = !useRole(auth.employeeData.roles, "cexp");
  return isForbidden ? (
    <Forbidden />
  ) : (
    <div className="flex mt-[70px]   flex-row">
      <div className={"w-[200px]"}>
        <ProSidebar width={200} className="fixed">
          <Menu iconShape="square">
            {/* <MenuItem icon={<PieChartOutlined />}>
              <Link to="/dailyapp">Dashboard</Link>
            </MenuItem> */}
            <SubMenu title="Feedback" icon={<IoMegaphoneOutline />}>
              <MenuItem>
                <Link to="reviews">Reviews</Link>
              </MenuItem>
              <MenuItem>
                <Link to="complains">Complains</Link>
              </MenuItem>
              <MenuItem>
                <Link to="inbox-web">Web Inbox</Link>
              </MenuItem>
            </SubMenu>

            {/* <SubMenu title="Report" icon={<TbReport />}>
              <MenuItem>
                <Link to="reviews-report">Reviews</Link>
              </MenuItem>
              <MenuItem>
                <Link to="complains-report">Complains</Link>
              </MenuItem>
            </SubMenu> */}
            <MenuItem icon={<TbReport />}>
              <Link to="/costumer-experience/report">Report</Link>
            </MenuItem>
            <MenuItem icon={<HiOutlineUserGroup />}>
              <Link to="/costumer-experience/customer">Customer</Link>
            </MenuItem>
            <MenuItem icon={<HiOutlineUserGroup />}>
              <Link to="/costumer-experience/spb">SPB</Link>
            </MenuItem>
            <MenuItem icon={<BiCalendarEvent />}>
              <Link to="/costumer-experience/event">Event</Link>
            </MenuItem>
            <MenuItem icon={<FaCrown />}>
              <Link to="membership">Membership</Link>
            </MenuItem>
            {/* <MenuItem icon={<FaHandshake />}>
              <Link to="/costumer-experience/user-acquisition">User Acquisition</Link>
            </MenuItem> */}
            <MenuItem icon={<FaWallet />}>
              <Link to="/costumer-experience/daily-cashier">Daily Cashier</Link>
            </MenuItem>
          </Menu>
        </ProSidebar>
      </div>
      <div className="flex-1 p-5 bg-slate-100 min-h-screen">
        <Outlet />
      </div>
    </div>
  );
};

export default index;
