import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { IStoreNameCard } from "@typed/HRIS/Namecard";
import toast from "react-hot-toast";
import {
  deleteNameCardAPI,
  detailNameCardAPI,
  getNameCardAPI,
  postNameCardAPI,
  putNameCardAPI
} from "./NameCardAPI";

export const getNameCardRequest = createAsyncThunk("hris-nameCard/getNameCardRequest", async () => {
  const response = await getNameCardAPI();
  return response;
});

export const detailNameCardRequest = createAsyncThunk(
  "hris-nameCard/detailNameCardRequest",
  async (id: string) => {
    const response = await detailNameCardAPI(id);
    return response;
  }
);

export const postNameCardRequest = createAsyncThunk(
  "hris-nameCard/postNameCardRequest",
  async (data: object) => {
    const response = await postNameCardAPI(data);
    return response;
  }
);

interface IPayloadNameCard {
  id: string;
  data: object;
}

export const putNameCardRequest = createAsyncThunk(
  "hris-nameCard/putNameCardRequest",
  async ({ id, data }: IPayloadNameCard) => {
    const response = await putNameCardAPI(id, data);
    return response;
  }
);

export const deleteNameCardRequest = createAsyncThunk(
  "hris-nameCard/deleteNameCardRequest",
  async (id: string) => {
    const response = await deleteNameCardAPI(id);
    return response;
  }
);

const initialState: IStoreNameCard = {
  data: [],
  isLoading: false
};

const NameCardSlice = createSlice({
  name: "hris-nameCard",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getNameCardRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getNameCardRequest.fulfilled, (state, action) => {
      action.payload.code === 1000
        ? (state.data = action.payload.data)
        : toast.error("Something went wrong");

      state.isLoading = false;
    });
    builder.addCase(getNameCardRequest.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(postNameCardRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(postNameCardRequest.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(postNameCardRequest.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(putNameCardRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(putNameCardRequest.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(putNameCardRequest.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(deleteNameCardRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteNameCardRequest.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(deleteNameCardRequest.rejected, (state) => {
      state.isLoading = false;
    });
  }
});

export default NameCardSlice.reducer;
