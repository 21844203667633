import React, { useState, useEffect } from "react";

import InputForm from "@components/HRIS/InputForm";
import swal from "sweetalert";

import {
  postPositionRequest,
  putPositionRequest,
  resetPosition
} from "@stores/HRIS/position/PositionSlice";
import { useAppDispatch, useAppSelector } from "@stores/store";

import * as R from "ramda";
import { Toaster } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { IPayloadAdd } from "@typed/HRIS/allHris";

const AddPosition = () => {
  const [addPosition, setAddPosition] = useState<IPayloadAdd>({} as IPayloadAdd);
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const { lastCreateData, isLoading, data } = useAppSelector((state) => state.hrisPosition);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setAddPosition({ ...addPosition, [name]: value });
  };

  const { id } = useParams();

  const { name, description } = addPosition;

  useEffect(() => {
    if (id) {
      data.filter(
        (item) =>
          item._id === id &&
          setAddPosition({ ...addPosition, name: item.name, description: item.description })
      );
    }
  }, [id]);

  // useEffect(() => {
  //   // reset products
  //   if (!R.isEmpty(lastCreateData)) {
  //     dispatch(resetPosition());
  //     setTimeout(() => {
  //       navigate("/hris/position");
  //     }, 600);
  //   }
  // }, [lastCreateData]);

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    const Data = {
      name,
      description
    };
    if (id) {
      dispatch(putPositionRequest({ id, Data })).then(({ payload }) => {
        if (payload.code === 1000) {
          swal({
            title: "Success!",
            text: "You position is update!",
            icon: "success",
            buttons: {
              confirm: {
                text: "OK",
                value: navigate("/hris/position")
              }
            }
          });
        }
      });
    } else {
      dispatch(postPositionRequest(Data)).then(({ payload }) => {
        if (payload.code === 1000) {
          swal({
            title: "Success!",
            text: "You position is created!",
            icon: "success",
            buttons: {
              confirm: {
                text: "OK",
                value: navigate("/hris/position")
              }
            }
          });
        }
      });
    }
  };

  return (
    <>
      <Toaster />
      <InputForm
        isLoading={isLoading}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        title="Position"
        id={id}
        value={addPosition}
      />
    </>
  );
};

export default AddPosition;
