import React, { useEffect } from "react";
import Loading from "@components/loading";
import { useParams, Link, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@stores/store";
import {
  getDetailPtkpRequest,
  putActivePtkpRequest,
  putDeletePtkpRequest,
  deletedPtkp
} from "@stores/HRIS/PTKP/PtkpSlice";
import CardDetail from "@components/HRIS/CardDetail";
import { Toaster } from "react-hot-toast";

const SinglePtkp = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { deletedRedirect, dataDetail, isLoading } = useAppSelector((state) => state.hrisPtkp);

  useEffect(() => {
    if (deletedRedirect) {
      dispatch(deletedPtkp());
      setTimeout(() => {
        navigate("/hris/ptkp");
      }, 600);
    }
  }, [deletedRedirect]);

  useEffect(() => {
    if (id) {
      dispatch(getDetailPtkpRequest(id));
    }
  }, [id]);

  const handleActive = () => {
    const id = dataDetail._id;
    const isActive = dataDetail.isActived;
    dispatch(putActivePtkpRequest({ id, isActive }));
  };

  const handleDelete = () => {
    const id = dataDetail._id;
    const isDelete = dataDetail.isDeleted;
    dispatch(putDeletePtkpRequest({ id, isDelete }));
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Toaster />
          <CardDetail
            handleDelete={handleDelete}
            handleActive={handleActive}
            name="Name"
            active={dataDetail.isActived}
            linkTo={`/hris/ptkp/edit/${id}`}
            value={dataDetail.name}
            description="Description"
            valueDesc={dataDetail.description}
          />
        </>
      )}
    </>
  );
};

export default SinglePtkp;
