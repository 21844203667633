import { AppModules } from "typed/global";
import { IoBicycleOutline, IoPeopleOutline, IoPhonePortraitOutline } from "react-icons/io5";
import { GiClick } from "react-icons/gi";
import { RiCustomerService2Line } from "react-icons/ri";
import { TbSpeakerphone } from "react-icons/tb";
import { MdSwitchAccount, MdDiscount } from "react-icons/md";

const DAILYAPP: AppModules = {
  title: "Daily App",
  uri: "dailyapp",
  icon: IoPhonePortraitOutline
};
const DAILYOJOL: AppModules = {
  title: "Dailyojol",
  uri: "dailyojol",
  icon: IoBicycleOutline
};
const HRIS: AppModules = {
  title: "HRIS",
  uri: "hris",
  icon: IoPeopleOutline
};

const DAILYEVENT: AppModules = {
  title: "Daily Click",
  uri: "dailyclick",
  icon: GiClick
};

const CEXP: AppModules = {
  title: "C-EXP",
  uri: "costumer-experience",
  icon: RiCustomerService2Line
};

const PROMO: AppModules = {
  title: "Promo",
  uri: "promo",
  icon: MdDiscount
};

const BrandSettlemen: AppModules = {
  title: "Brand Settlement",
  uri: "brand-settlement",
  icon: TbSpeakerphone
};

export default [DAILYAPP, DAILYEVENT, DAILYOJOL, HRIS, CEXP, BrandSettlemen, PROMO];
