import React, { useCallback } from "react";
import { Switch } from "antd";
import Input from "./Input.components";
import Modal from "./Modal";
import { PayloadReviewComplain } from "@typed/dailyapp/complain";
import { useAppDispatch } from "@stores/store";
import { sendReviewComplainRequest } from "@stores/dailyapp/ComplainSlice";
import toast from "react-hot-toast";
import Textarea from "./Textarea";

interface IProps {
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isModalOpen: boolean;
  id?: string | undefined;
  message: string;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
  setIsResolveLoading: React.Dispatch<React.SetStateAction<boolean>>;
  authId: string;
  isResolveLoading: boolean;
  setIsCompensate: React.Dispatch<React.SetStateAction<boolean>>;
  isCompensate: boolean;
  coin: number;
  setCoin: React.Dispatch<React.SetStateAction<number>>;
  handleConfirmSolved: (d: PayloadReviewComplain) => void;
}

const ModalComplain: React.FC<IProps> = ({
  setMessage,
  isResolveLoading,
  authId,
  message,
  id,
  isModalOpen,
  setIsResolveLoading,
  setIsModalOpen,
  isCompensate,
  setIsCompensate,
  coin,
  setCoin,
  handleConfirmSolved
}) => {
  const dispatch = useAppDispatch();

  return (
    <>
      <div>
        <Modal
          title="Solve this complain"
          isOpen={isModalOpen}
          closeModal={() => setIsModalOpen(false)}
        >
          <div
            className={`${isCompensate ? "h-[20rem]" : "h-[15rem]"}  mt-16 flex flex-col gap-3 `}
          >
            <Textarea
              name="message"
              required={`after:content-['*'] after:text-red-500`}
              title="Message"
              value={message}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setMessage(e.target.value)}
              placeholder="Message"
            />
            <div>
              <Switch onChange={() => setIsCompensate(!isCompensate)} />{" "}
              <span className="pl-3">Compensate with coin</span>
            </div>
            <div className="">
              {isCompensate ? (
                <Input
                  value={coin}
                  name="coin"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setCoin(Number(e.target.value))
                  }
                  placeholder="Coin"
                  title={"Coin"}
                />
              ) : undefined}
            </div>
            <div className={"absolute  bottom-7 right-6"}>
              <button className="py-2 px-9 bg-blue-700 text-white   rounded-full font-poppins ">
                Submit
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

// export default R/eact.memo(ModalComplain);
export default ModalComplain;
