import React, { useEffect } from "react";
import { Table, Tag } from "antd";
import { getOjolRedemRequest } from "@stores/dailyojol/RedemSlice";
import { useAppDispatch, useAppSelector } from "@stores/store";
import { Link } from "react-router-dom";
import Loading from "@components/loading";

const column = [
  {
    key: "middlemanName",
    title: "Name",
    dataIndex: ["middleman", "username"]
  },
  {
    key: "middlemanPhone",
    title: "Phone ✆",
    dataIndex: ["middleman", "phoneNumber"],
    render: (phoneNumber: string) => (
      <a target="_blank" rel="noreferrer" href={`https://wa.me/`}>
        +{phoneNumber}
      </a>
    )
  },
  {
    key: "menuName",
    title: "Menu Name",
    dataIndex: ["menu", "name"]
  },
  {
    key: "menuPrice",
    title: "Price",
    dataIndex: ["menu", "price"]
  },
  {
    key: "outletName",
    title: "Outlet Name",
    dataIndex: ["outlet", "name"]
  },
  {
    key: "redemCode",
    title: "Redem Code",
    dataIndex: "redeemNumber"
  },
  {
    key: "redemStatus",
    title: "Redem Status",
    dataIndex: "redeemStatus",
    render: (redeemStatus: string) => (
      <div>
        {redeemStatus === "SUCCESS" ? (
          <Tag color="green">{redeemStatus}</Tag>
        ) : (
          <Tag color="red">{redeemStatus}</Tag>
        )}
      </div>
    )
  },
  {
    key: "act",
    title: "Action",
    dataIndex: "_id",
    render: (_id: string) => (
      <div>
        <Link
          className="bg-blue-500 hover:bg-blue-600 py-2 px-6 rounded text-white"
          to={`/dailyojol/redem/${_id}`}
        >
          Detail
        </Link>
      </div>
    )
  }
];

const Redem = () => {
  const dispatch = useAppDispatch();

  const { data, loading } = useAppSelector((state) => state.dailyojolRedem);

  const datasRedem = data.map((x) => ({ ...x, key: x._id }));

  useEffect(() => {
    dispatch(getOjolRedemRequest());
  }, []);

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Table columns={column} dataSource={datasRedem} />
        </>
      )}
    </div>
  );
};

export default Redem;
