import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
  getQandABatchAPI,
  getDetailQandABatchAPI,
  postQandABatchAPI,
  putQandABatchAPI,
  statusQandABatchAPI,
  IPostQABatch,
  IStatusQABatch,
  IPutQABatch,
  getQuestionAPI,
  postQuestionAPI,
  ICreateQuestions,
  putQuestionAPI,
  IEditQuestions,
  getDetailQuestionAPI,
  getAnswerAPI
} from "./QuestionsAnswerAPI";
import { flattenArray } from "@helpers/utils";
export const getQandABatchReq = createAsyncThunk(
  "dailyapp-questions/getQandABatchReq",
  async () => {
    const response = await getQandABatchAPI();
    return response;
  }
);

export const getDetailQandABatchReq = createAsyncThunk(
  "dailyapp-questions/getDetailQandABatchReq",
  async (id: string) => {
    const response = await getDetailQandABatchAPI(id);
    return response;
  }
);

export const postQandABatchReq = createAsyncThunk(
  "dailyapp-questions/postQandABatchReq",
  async (payload: IPostQABatch) => {
    const response = await postQandABatchAPI(payload);
    return response;
  }
);

export const putQandABatchReq = createAsyncThunk(
  "dailyapp-questions/putQandABatchReq",
  async (payload: IPutQABatch) => {
    const response = await putQandABatchAPI(payload);
    return response;
  }
);

export const statusQandABatchReq = createAsyncThunk(
  "dailyapp-questions/statusQandABatchReq",
  async (payload: IStatusQABatch) => {
    const response = await statusQandABatchAPI(payload);
    return response;
  }
);

// QUESTIONS

export const getQuestionsReq = createAsyncThunk("dailyapp-questions/getQuestionsReq", async () => {
  const response = await getQuestionAPI();
  return response;
});

export const getDetailQuestionsReq = createAsyncThunk(
  "dailyapp-questions/getDetailQuestionsReq",
  async (id: string) => {
    const response = await getDetailQuestionAPI(id);
    return response;
  }
);

export const postQuestionsReq = createAsyncThunk(
  "dailyapp-questions/postQuestionsReq",
  async (payload: ICreateQuestions) => {
    const response = await postQuestionAPI(payload);
    return response;
  }
);

export const putQuestionsReq = createAsyncThunk(
  "dailyapp-questions/putQuestionsReq",
  async (payload: IEditQuestions) => {
    const response = await putQuestionAPI(payload);
    return response;
  }
);

//  ANSWER
export const getAnswerReq = createAsyncThunk("dailyapp-questions/getAnswerReq", async () => {
  const response = await getAnswerAPI();
  return response;
});

interface IQuestionBatchStore {
  dataBatch: any[] | [];
  singleQuestion: any;
  dataAnswer: any[] | [];
  isLoadingBatch: boolean;
  dataQuestions: any[] | [];
  dataAnswerCSV: any[] | [];
}

const initialState: IQuestionBatchStore = {
  dataBatch: [],
  dataAnswer: [],
  singleQuestion: {},
  isLoadingBatch: false,
  dataQuestions: [],
  dataAnswerCSV: []
};

const QuestionsAnwerSlice = createSlice({
  name: "dailyapp-questions",
  initialState: initialState,
  reducers: {
    CSVAnswer: (state: IQuestionBatchStore) => {
      let data = state.dataAnswer;
      data.map((item) => item).sort((a, b) => a.questionAndAnswer - b.questionAndAnswer);

      state.dataAnswerCSV = data.map((item: any) => ({
        Date: item.createdAt,
        Username: item.userName,
        Phone: item.phone
      }));
    }
  },
  extraReducers(builder) {
    builder.addCase(getQandABatchReq.pending, (state) => {
      state.isLoadingBatch = true;
    });
    builder.addCase(getQandABatchReq.fulfilled, (state, action) => {
      action.payload.code === 1000 ? (state.dataBatch = action.payload.data) : undefined;
      state.isLoadingBatch = false;
    });
    builder.addCase(getQandABatchReq.rejected, (state) => {
      state.isLoadingBatch = false;
    });
    builder.addCase(getDetailQandABatchReq.pending, (state) => {
      state.isLoadingBatch = true;
    });
    builder.addCase(getDetailQandABatchReq.fulfilled, (state, action) => {
      state.isLoadingBatch = false;
    });
    builder.addCase(getDetailQandABatchReq.rejected, (state) => {
      state.isLoadingBatch = false;
    });
    builder.addCase(postQandABatchReq.pending, (state) => {
      state.isLoadingBatch = true;
    });
    builder.addCase(postQandABatchReq.fulfilled, (state, action) => {
      action.payload.code === 1000
        ? (state.dataBatch = [action.payload.data, ...state.dataBatch])
        : undefined;
      state.isLoadingBatch = false;
    });
    builder.addCase(postQandABatchReq.rejected, (state) => {
      state.isLoadingBatch = false;
    });
    builder.addCase(putQandABatchReq.pending, (state) => {
      state.isLoadingBatch = true;
    });
    builder.addCase(putQandABatchReq.fulfilled, (state, action) => {
      // action.payload.code === 1000
      //   ? (state.dataBatch = [action.payload.data, ...state.dataBatch])
      //   : undefined;
      state.isLoadingBatch = false;
    });
    builder.addCase(putQandABatchReq.rejected, (state) => {
      state.isLoadingBatch = false;
    });
    builder.addCase(statusQandABatchReq.pending, (state) => {
      state.isLoadingBatch = true;
    });
    builder.addCase(statusQandABatchReq.fulfilled, (state, action) => {
      // action.payload.code === 1000
      //   ? (state.dataBatch = [action.payload.data, ...state.dataBatch])
      //   : undefined;
      state.isLoadingBatch = false;
    });
    builder.addCase(statusQandABatchReq.rejected, (state) => {
      state.isLoadingBatch = false;
    });

    builder.addCase(getQuestionsReq.pending, (state) => {
      state.isLoadingBatch = true;
    });
    builder.addCase(getQuestionsReq.fulfilled, (state, action) => {
      action.payload.code === 1000 ? (state.dataQuestions = action.payload.data) : undefined;
      state.isLoadingBatch = false;
    });
    builder.addCase(getQuestionsReq.rejected, (state) => {
      state.isLoadingBatch = false;
    });
    builder.addCase(postQuestionsReq.pending, (state) => {
      state.isLoadingBatch = true;
    });
    builder.addCase(postQuestionsReq.fulfilled, (state, action) => {
      // action.payload.code === 1000 ? (state.dataQuestions = action.payload.data) : undefined;
      state.isLoadingBatch = false;
    });
    builder.addCase(postQuestionsReq.rejected, (state) => {
      state.isLoadingBatch = false;
    });
    builder.addCase(putQuestionsReq.pending, (state) => {
      state.isLoadingBatch = true;
    });
    builder.addCase(putQuestionsReq.fulfilled, (state, action) => {
      // action.payload.code === 1000 ? (state.dataQuestions = action.payload.data) : undefined;
      state.isLoadingBatch = false;
    });
    builder.addCase(putQuestionsReq.rejected, (state) => {
      state.isLoadingBatch = false;
    });
    builder.addCase(getDetailQuestionsReq.pending, (state) => {
      state.isLoadingBatch = true;
    });
    builder.addCase(getDetailQuestionsReq.fulfilled, (state, action) => {
      action.payload.code === 1000 ? (state.singleQuestion = action.payload.data) : undefined;
      state.isLoadingBatch = false;
    });
    builder.addCase(getDetailQuestionsReq.rejected, (state) => {
      state.isLoadingBatch = false;
    });
    builder.addCase(getAnswerReq.pending, (state) => {
      state.isLoadingBatch = true;
    });
    builder.addCase(getAnswerReq.fulfilled, (state, action) => {
      action.payload.code === 1000 ? (state.dataAnswer = action.payload.data) : undefined;
      state.isLoadingBatch = false;
    });
    builder.addCase(getAnswerReq.rejected, (state) => {
      state.isLoadingBatch = false;
    });
  }
});

export default QuestionsAnwerSlice.reducer;
export const { CSVAnswer } = QuestionsAnwerSlice.actions;
