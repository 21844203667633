export const suppressWarning = () => {
  // eslint-disable-next-line
  const consoleError = console.error.bind(console);
  // eslint-disable-next-line
  console.error = (errObj, ...args) => {
    if (
      process.env.NODE_ENV === "development" &&
      (typeof errObj === "string" || typeof errObj.message === "string") &&
      args.includes("findDOMNode")
    ) {
      return;
    }
    consoleError(errObj, ...args);
  };
};
