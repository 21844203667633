import React, { FC, useEffect, useState } from "react";
import { Table, Popover, Tag } from "antd";
import { useAppDispatch, useAppSelector } from "@stores/store";
import { getRedemDailyappRequest } from "@stores/dailyapp/RedemSlice";
import { Link, useNavigate } from "react-router-dom";
import { IDetailRedem } from "@typed/dailyapp/Redem";
import { AiOutlineSearch } from "react-icons/ai";
import { SyncOutlined } from "@ant-design/icons";

import Loading from "@components/loading";
import { dateToString } from "@helpers/utils";
import InputSearch from "@components/inputSearch";

const Redem: FC = () => {
  const column = [
    {
      key: "fullname",
      title: "Received",
      dataIndex: ["address", "label"]
    },
    {
      key: "address",
      title: "Address",
      dataIndex: ["address", "address"],
      render: (address: string) => (
        <div
          style={{
            width: "200px",
            textOverflow: "ellipsis"
          }}
        >
          {address}
        </div>
      )
    },
    {
      key: "phone",
      title: "Phone ✆",
      dataIndex: ["recipient", "phone"],
      render: (number: string) => (
        <div>
          <a href={`https://wa.me/${number}`}>{number}</a>
        </div>
      )
    },
    {
      key: "menu",
      title: "Menu",
      dataIndex: ["menu", "name"]
    },
    {
      key: "price",
      title: "Price",
      dataIndex: ["menu", "price"]
    },
    {
      key: "redem",
      title: "Redem Number",
      dataIndex: "redeemNumber"
    },
    {
      key: "redeemStatus",
      dataIndex: "redeemStatus",
      title: "Status",
      render: (redeemStatus: string) => (
        <>
          <div className="flex items-center justify-center">
            {redeemStatus === "PENDING" ? (
              <Tag color="yellow">{redeemStatus}</Tag>
            ) : redeemStatus === "CANCELED" ? (
              <Tag color="red">{redeemStatus}</Tag>
            ) : redeemStatus === "CONFIRMED" ? (
              <Tag color="processing">{redeemStatus}</Tag>
            ) : redeemStatus === "DONE" ? (
              <Tag color="green">{redeemStatus}</Tag>
            ) : (
              <Tag color="error">{redeemStatus}</Tag>
            )}
          </div>
        </>
      )
    },
    {
      key: "action",
      title: "Action",
      render: (data: IDetailRedem) => (
        <div className="flex gap-2 items-center">
          <button
            onClick={() => handleClick(data.redeemStatus)}
            className={
              data.redeemStatus === "PENDING"
                ? "  text-white bg-green-500 py-1 px-5 rounded hover:text-white"
                : "cursor-not-allowed drop-shadow-sm py-1 px-5 bg-[#eaeaea] rounded text-white hover:text-white"
            }
          >
            Confirm
          </button>

          <Link
            className="hover:text-white rounded py-1 px-5 bg-blue-500 text-white"
            to={`/dailyapp/redem/${data._id}`}
          >
            Detail
          </Link>
        </div>
      )
    }
  ];

  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.dailyRedem);
  const datasRedem = useAppSelector((state) =>
    state.dailyRedem.data.map((x) => ({ ...x, key: x._id }))
  );

  const navigate = useNavigate();

  const [okData, setOkData] = useState<IDetailRedem[]>(datasRedem);
  const [searchByName, setSearchbyName] = useState<string>("");
  const [searchByRedemNum, setSearchByRedemNum] = useState<string>("");

  useEffect(() => {
    // search data by label
    let Data = datasRedem.filter((item) => {
      return item.address?.label.toLowerCase().includes(searchByName.toLowerCase());
    });
    if (searchByName !== "") {
      setOkData(Data);
    } else if (searchByName === "") {
      setOkData(datasRedem);
    }
  }, [searchByName, loading]);

  useEffect(() => {
    const searchForRedemNum = datasRedem.filter((item) => {
      return item.redeemNumber.toLowerCase().includes(searchByRedemNum.toLowerCase());
    });

    if (searchByRedemNum !== "") {
      setOkData(searchForRedemNum);
    } else if (searchByRedemNum === "") {
      setOkData(datasRedem);
    }
  }, [searchByRedemNum, loading]);

  useEffect(() => {
    dispatch(getRedemDailyappRequest());
  }, [dispatch]);

  const handleClick = (redeemStatus: string) => {
    if (redeemStatus === "PENDING") {
      navigate("/dailyapp/redem/add");
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchbyName(e.target.value);
  };

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="flex flex-row">
            <div className="flex items-center gap-3">
              {/* ===== SEARCH BY RECEIVED =====*/}
              <InputSearch
                value={searchByName}
                placeholder="Search By Received"
                handleChange={handleChange}
              />
              {/* ===== SEARCH BY REDEM NUMBER =====*/}

              <InputSearch
                value={searchByRedemNum}
                handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setSearchByRedemNum(e.target.value);
                }}
                placeholder="Search By Redem Number"
              />
            </div>
          </div>
          <Table dataSource={okData} columns={column} />
        </>
      )}
    </div>
  );
};

export default Redem;
