import React, { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@stores/store";
import Modal from "@components/Modal";
import Input from "@components/Input.components";
import Textarea from "@components/Textarea";
import SelectComponents from "@components/Select";
import {
  getQandABatchReq,
  postQuestionsReq,
  putQuestionsReq
} from "@stores/dailyapp/QuestionsAnwerSlice";
import Button from "@components/Button.component";
import swal from "sweetalert";

const options = [
  { value: "selection", label: "Selection" },
  { value: "multi-selection", label: "Multi Selection" },
  { value: "free-text", label: "Free Text" }
];

interface IPropsQuestion {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  data: any;
  setData: React.Dispatch<any>;
}

const AddQuestion: React.FC<IPropsQuestion> = ({ openModal, setOpenModal, data, setData }) => {
  const dispatch = useAppDispatch();
  const dataBatch = useAppSelector((state) =>
    state.dailyQuestionsBatch.dataBatch.map((i: any) => ({ label: i.name, value: i._id }))
  );
  const { isLoadingBatch } = useAppSelector((state) => state.dailyQuestionsBatch);

  const [createQuestion, setCreateQuestion] = useState<any>({
    type: "",
    question: "",
    squence: "",
    answer: "",
    batchID: ""
  });

  const { answer, question, type, squence, batchID } = createQuestion;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;

    setCreateQuestion({ ...createQuestion, [name]: value });
  };

  const handleSelectBatch = (value?: any, actionMeta?: any) => {
    setCreateQuestion({ ...createQuestion, batchID: value.value });
  };

  const handleSelect = (value: any, actionMeta: any) => {
    setCreateQuestion({ ...createQuestion, type: value?.value });
  };

  const handleClose = () => {
    setOpenModal(!openModal);
    setData(undefined);
  };

  const handleSubmit = () => {
    if (!data?._id) {
      const payload = {
        question: question,
        type: type,
        answer: answer?.split(","),
        squence: Number(squence),
        batchId: batchID
      };
      dispatch(postQuestionsReq(payload)).then((res: any) => {
        if (res.payload?.code === 1000) {
          swal("Good job!", "You have successfully created a Question!", "success");
        }
      });
    } else {
      const payload = {
        question: question,
        type: type.value,
        answer: answer,
        batchId: batchID.value,
        squence: Number(squence),
        id: data?._id
      };
      dispatch(putQuestionsReq(payload)).then((res: any) => {
        if (res.payload?.code === 1000) {
          swal("Good job!", "You have successfully updated the Question!", "success");
          setCreateQuestion({
            type: "",
            question: " ",
            squence: 0,
            answer: "",
            batchID: ""
          });
          handleClose();
        } else {
          swal("Ops!", `${res.payload?.code}: Your Something went wrong!`, "error");
        }
      });
    }
  };

  useEffect(() => {
    if (data?._id) {
      setCreateQuestion({
        ...createQuestion,
        type: options.find((i) => i.value === data?.type),
        question: data?.question,
        squence: data?.squence,
        answer: data?.answer.map((i: string) => i),
        batchID: dataBatch?.find((i: any) => i.value === data.batchId?._id)
      });
    } else {
      setCreateQuestion({
        type: "",
        question: " ",
        squence: 0,
        answer: "",
        batchID: ""
      });
    }
  }, [data?._id]);

  useEffect(() => {
    dispatch(getQandABatchReq());
  }, []);

  console.log("data", data);

  return (
    <>
      <Modal isOpen={openModal} title="Create Qustion" closeModal={handleClose}>
        <div className="bg-white h-[33rem] pt-16 flex flex-col gap-3">
          <Input
            required={`after:content-['*'] after:text-red-500`}
            placeholder="Question"
            value={question}
            onChange={handleChange}
            name="question"
            title="Question"
          />

          <Input
            required={`after:content-['*'] after:text-red-500`}
            placeholder="Squence"
            value={squence}
            type="number"
            onChange={handleChange}
            name="squence"
            title="Squence"
          />

          <Textarea
            required={`after:content-['*'] after:text-red-500`}
            placeholder="Answer (pisahkan dengan tanda koma ' , ' )"
            value={answer}
            onChange={handleChange}
            name="answer"
            title="Answer"
          />

          <div>
            <h1 className="text-bold m-0">Type</h1>
            <SelectComponents
              defaultValue={data?._id ? type : undefined}
              onChange={handleSelect}
              options={options}
            />
          </div>

          <div>
            <h1 className="text-bold m-0">Batch</h1>
            <SelectComponents
              defaultValue={data?._id ? batchID : undefined}
              onChange={handleSelectBatch}
              options={dataBatch}
            />
          </div>
        </div>
        <div className="flex justify-end">
          <Button isLoading={isLoadingBatch} onClick={handleSubmit}>
            Submit
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default AddQuestion;
