import React, { useState, useEffect } from "react";
import Modal from "@components/Modal";
import { useAppDispatch, useAppSelector } from "@stores/store";
import { getQuestionsReq } from "@stores/dailyapp/QuestionsAnwerSlice";
import DataTable from "react-data-table-component";
import moment from "moment";
import AddQuestion from "./AddQuestion";
import { Link } from "react-router-dom";

interface IHandleColumn {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  setData: React.Dispatch<any>;
}

const handleColumns = ({ showModal, setShowModal, setData }: IHandleColumn) => {
  const columns = [
    {
      name: "Date",
      selector: (row: any) => row.createdAt,
      sortable: true,
      cell: (row: any) => moment(row.createdAt).format("MMMM Do YYYY, h:mm")
    },
    {
      name: "Squence",
      selector: (row: any) => row.squence,
      sortable: true
    },
    {
      name: "Batch",
      selector: (row: any) => row.batchId.name,
      sortable: true
    },

    {
      name: "Question",
      selector: (row: any) => row.question,
      sortable: true
    },
    {
      name: "Type",
      selector: (row: any) => row.type,
      sortable: true
    },
    {
      name: "Action",
      selector: (row: any) => row._id,
      sortable: true,
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <button
              className={`py-2 px-3 font-bold rounded text-white bg-blue-600`}
              onClick={() => {
                setData(row);
                setShowModal(!showModal);
              }}
            >
              Update
            </button>

            <Link
              className={`hover:text-white py-2 px-3 font-bold rounded text-white bg-green-600`}
              to={`/dailyapp/question/${row._id}`}
            >
              Detail
            </Link>
          </div>
        );
      }
    }
  ];
  return columns;
};

const Questions = () => {
  const dispatch = useAppDispatch();
  const { dataQuestions, isLoadingBatch } = useAppSelector((state) => state.dailyQuestionsBatch);

  const [data, setData] = useState<any>();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    dispatch(getQuestionsReq());
  }, []);

  return (
    <>
      <AddQuestion
        setData={setData}
        data={data}
        openModal={showModal}
        setOpenModal={setShowModal}
      />

      <div className="mb-4">
        <button
          className=" py-2 text-center px-6 bg-green-500 hover:bg-green-600 hover:text-white rounded hover:shadow-lg text-white font-semibold"
          onClick={() => {
            setShowModal(!showModal);
          }}
        >
          Create Question
        </button>
      </div>
      <div>
        <DataTable
          progressPending={isLoadingBatch}
          data={dataQuestions}
          columns={handleColumns({ showModal, setShowModal, setData })}
        />
      </div>
    </>
  );
};

export default Questions;
