import React, { useEffect } from "react";
import { Table, Tag } from "antd";
import { useAppDispatch, useAppSelector } from "@stores/store";
import type { ColumnsType } from "antd/es/table";
import { dateToString } from "@helpers/utils";
import { IDataHRIS } from "@typed/HRIS/allHris";
import { Link } from "react-router-dom";
import Loading from "@components/loading";
import { getPtkpRequest } from "@stores/HRIS/PTKP/PtkpSlice";
const column: ColumnsType<IDataHRIS> = [
  {
    title: "Date",
    key: "date",
    dataIndex: "createdAt",
    render: (createdAt: string) => <div>{dateToString(createdAt)}</div>
  },
  {
    title: "Name",
    key: "name",
    dataIndex: "name"
  },
  {
    title: "Description",
    key: "description",
    dataIndex: "description"
  },
  {
    title: "IsActive",
    key: "isactived",
    dataIndex: "isActived",
    onFilter: (value: any, record: IDataHRIS) => record.isActived === value,
    filters: [
      {
        text: "Active",
        value: true
      },
      {
        text: "NoActive",
        value: false
      }
    ],
    render: (active: boolean) => (
      <div>
        {active === true ? <Tag color="success">Active</Tag> : <Tag color="error">NoActive</Tag>}
      </div>
    )
  },
  {
    title: "Action",
    key: "action",
    dataIndex: "_id",
    render: (id: string) => (
      <div>
        <Link to={`/hris/ptkp/${id}`}>Detail</Link>
      </div>
    )
  }
];

const Ptkp = () => {
  const dispatch = useAppDispatch();

  const PTKP = useAppSelector((state) =>
    state.hrisPtkp.data.map((item) => ({ ...item, key: item._id }))
  );
  const { isLoading } = useAppSelector((state) => state.hrisPtkp);

  useEffect(() => {
    dispatch(getPtkpRequest());
  }, []);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Link
            to="/hris/ptkp/add"
            className="py-2 w-full px-2 rounded hover:bg-green-600 hover:text-white bg-green-500 text-white font-semibold"
          >
            Create
          </Link>
          <div className="mt-4">
            <Table columns={column} dataSource={PTKP} />
          </div>
        </>
      )}
    </>
  );
};

export default Ptkp;
