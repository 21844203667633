import React from "react";
import * as FileSaver from "file-saver";
import { AiOutlineDownload } from "react-icons/ai";
import * as XLSX from "xlsx";

const ExportCSV = ({ csvData, fileName }: { csvData: any[]; fileName: any }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (csvData: any[], fileName: any) => {
    const ws = XLSX.utils.json_to_sheet(csvData);

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "buffer" });

    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <button
      className="flex text-medium items-center gap-1 text-center outline-none h-9 w-[10rem] rounded bg-blue-500 hover:bg-blue-400 text-white justify-center"
      onClick={(e) => exportToCSV(csvData, fileName)}
    >
      <AiOutlineDownload className="text-medium" />
      <h1 className="m-0 text-white text-[1rem] p-0">Export</h1>
    </button>
  );
};

export default ExportCSV;
