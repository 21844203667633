import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ListUser from "@screens/dailyojol/user/listUser";
import { getUsersApi } from "@stores/dailyapp/UserAPI";
import { ListUserStore, TListUser, IDataUsers } from "@typed/dailojol/listUser";
import { DataUser } from "@typed/dailojol/listUser";
import { Overwrite, PayloadResponse } from "@typed/global";
import { getUserDetail, getOjolUsersAPI } from "./UserAPI";

type Payload = Overwrite<
  PayloadResponse,
  {
    data: TListUser[];
  }
>;

export const getUsersOjolRequest = createAsyncThunk("dailyojol-user/getUsersRequest", async () => {
  const response = await getOjolUsersAPI();
  return response;
});

type ResponseSingleProduct = Overwrite<
  PayloadResponse,
  {
    data: DataUser;
  }
>;

export const getUserDetailRequest = createAsyncThunk(
  "dailyojol-user/getUserDetailRequest",
  async (id: { id: string | undefined }) => {
    const response = await getUserDetail(id);
    return response;
  }
);

const initialState: ListUserStore = {
  listUsers: [],
  loading: false,
  detailUser: {} as DataUser
};

const ListUserSlice = createSlice({
  name: "dailyojol-user",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUsersOjolRequest.fulfilled, (state, action) => {
      state.listUsers = action.payload.data;
    });
    builder.addCase(getUserDetailRequest.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserDetailRequest.fulfilled, (state, action) => {
      state.detailUser = action.payload.data;
      state.loading = false;
    });
  }
});

export default ListUserSlice.reducer;
