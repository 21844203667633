import API from "@constants/API";

const BASE_URL = "https://api.dev.daily.co.id/hris/person/dashboard/v1/person";

export const addPersonAPI = async (Data: object) => {
  const response = await API.HTTP.post(BASE_URL, Data);

  return response.data;
};

export const getPersonAPI = async () => {
  const response = await API.HTTP.get(BASE_URL + "s");
  return response.data;
};

export const putPersonAPI = async (id: string, Data: object) => {
  const response = await API.HTTP.put(`${BASE_URL}/${id}`, Data);
  return response.data;
};
