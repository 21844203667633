import React, { useEffect, useState } from "react";
import { Table, Tag } from "antd";
import { useAppSelector, useAppDispatch } from "@stores/store";
import {
  getOutletAccountRequest,
  putOutletAccountRequest
} from "@stores/dailyapp/dailyOutletSlice";
import InputSearch from "@components/inputSearch";
import { Link } from "react-router-dom";
import { dateToString } from "@helpers/utils";
import Input from "@components/Input.components";
import { Formik, Form } from "formik";
import Modal from "@components/Modal";

const initialValues = {
  newPassword: ""
};

const OutletAccounts = () => {
  const columns = [
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date: string) => <div>{dateToString(date)}</div>
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name"
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email"
      // render: (email: string) => <div className="flex items-center justify-center">{email}</div>
    },
    {
      title: "Code",
      dataIndex: ["outlet", "code"],
      key: "code",
      render: (code: string) => <div className="flex items-center justify-center">{code}</div>
    },
    {
      title: "Flag Active",
      dataIndex: "flagActive",
      key: "flagActive",
      render: (flag: boolean) => (
        <div className="flex justify-center items-center">
          <Tag className="block mx-auto text-center" color={flag ? "success" : "error"}>
            {flag ? "True" : "False"}
          </Tag>
        </div>
      )
    },
    {
      title: "Action",
      dataIndex: "_id",
      key: "id",
      render: (id: string) => (
        <div>
          <div
            className="bg-blue-500 hover:text-white text-white p-2 text-center rounded hover:bg-blue-400"
            onClick={() => {
              setIsOpen(true);
              setIdOutlet(id);
            }}
          >
            Change Password
          </div>
        </div>
      )
    }
  ];
  const [idOutlet, setIdOutlet] = useState<string>("");
  const dispatch = useAppDispatch();
  const [searchByName, setSearchByName] = useState<string>("");
  const { isLoading } = useAppSelector((state) => state.dailyappOutlet);
  const DataOutletAcc = useAppSelector((state) =>
    state.dailyappOutlet.AppOutletAccount?.map((i) => ({ ...i, key: i._id }))
  );
  const [data, setData] = useState(DataOutletAcc);
  const [submitPopup, setSubmitPopup] = useState(false);

  useEffect(() => {
    dispatch(getOutletAccountRequest());
    setData(DataOutletAcc);
  }, [dispatch]);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    // search product by name
    let Data = DataOutletAcc.filter((item: { name: string }) => {
      return item.name?.toLowerCase().includes(searchByName.toLowerCase());
    });
    if (searchByName !== "") {
      setData(Data);
    }
    if (searchByName === "") {
      setData(DataOutletAcc);
    }
  }, [searchByName, isLoading]);

  return (
    <>
      {/* START MODAL */}
      <Modal isOpen={isOpen} closeModal={() => setIsOpen(false)} title="Change Password">
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => {
            const { newPassword } = values;
            const data = {
              newPassword
            };
            dispatch(putOutletAccountRequest({ id: idOutlet, data }));
          }}
        >
          {({ values, resetForm, handleChange, handleSubmit }) => {
            return (
              <Form className="min-h-min h-36 mt-16 flex flex-col gap-3 " onSubmit={handleSubmit}>
                <Input
                  required={`after:content-['*'] after:text-red-500`}
                  placeholder="Password"
                  type="password"
                  name="newPassword"
                  value={values.newPassword}
                  title="Password"
                  onChange={handleChange}
                />

                <div className="absolute  bottom-7 right-6">
                  <button
                    className="py-2 px-9 bg-blue-700 text-white   rounded-full font-poppins "
                    // type="s"
                  >
                    Submit
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal>
      {/* END MODAL */}
      <div className="flex justify-between items-center">
        <div className="flex justify-start items-center gap-4">
          <div>
            <InputSearch
              value={searchByName}
              handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSearchByName(e.target.value);
              }}
              placeholder="Search by name of outlet account"
            />
          </div>
        </div>
      </div>

      <div>
        <Table
          className="border-[1px] border-abuAbu rounded-md"
          columns={columns}
          dataSource={data}
        />
      </div>
    </>
  );
};

export default OutletAccounts;
