import React, { useEffect } from "react";
import { Radio } from "antd";
import Select from "react-select";
import type { RadioChangeEvent } from "antd";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { status } from "@helpers/utils";
import { IaddPerson, IDataPersons } from "@typed/HRIS/Person";
import { useAppDispatch, useAppSelector } from "@stores/store";
import { postPersonRequest, putPersonRequest } from "@stores/HRIS/Person/PersonSlice";
import { useParams } from "react-router-dom";
import Input from "@components/Input.components";
import Textarea from "@components/Textarea";
import moment from "moment";

const validationSchema = Yup.object({
  firstName: Yup.string().required(),
  birthPlace: Yup.string().required(),
  religion: Yup.string().required(),
  address: Yup.string().required(),
  dateOfBirth: Yup.string().required(),
  citizenId: Yup.string().required(),
  martialStatus: Yup.string().required()
});

interface IProps {
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
  current: number;
  step: string[];
}

const PersonStep: React.FC<IProps> = ({ current, step, setCurrent }) => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { dataPersons } = useAppSelector((state) => state.hrisPerson);

  const renderError = (message: string) => <p className="text-red-500 is-danger">{message}</p>;

  return (
    <>
      <div className="transition w-full duration-1000 flex items-center justify-center">
        <Formik
          initialValues={{} as IaddPerson}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            const {
              address,
              birthPlace,
              citizenId,
              firstName,
              gender,
              martialStatus,
              lastName,
              religion,
              dateOfBirth,
              residentialAddress
            } = values;
            const dateTime = new Date(dateOfBirth);

            const Data = {
              firstName,
              lastName,
              birthPlace,
              identityAddress: address,
              religion,
              gender: gender,
              residentialAddress,
              citizenId,
              birthDate: dateTime,
              martialStatus
            };
            if (id) {
              await dispatch(putPersonRequest({ id, Data }));
              setCurrent(3);
            } else {
              await dispatch(postPersonRequest(Data));
              let newStep = current;

              current === 1 && newStep++;
              setCurrent(newStep);
            }
          }}
        >
          {({ values, handleSubmit, errors, touched, handleChange, setFieldValue }) => {
            useEffect(() => {
              if (id) {
                dataPersons.find(
                  (item: IDataPersons) =>
                    item._id === id &&
                    (setFieldValue("firstName", item.firstName),
                    setFieldValue("residentialAddress", item.residentialAddress),
                    // setCheckList(item.gender),
                    setFieldValue("gender", item.gender),
                    setFieldValue("citizenId", item.citizenId),
                    setFieldValue("birthPlace", item.birthPlace),
                    setFieldValue("martialStatus", item.martialStatus),
                    setFieldValue("address", item.identityAddress),
                    setFieldValue("religion", item.religion),
                    setFieldValue("lastName", item.lastName),
                    setFieldValue("dateOfBirth", moment(item.birthDate).format("D")))
                );
              }
            }, []);

            return (
              <Form className="flex  w-full flex-col gap-4">
                <div className="flex  w-full  justify-between gap-2">
                  <div className={"w-[50%]"}>
                    <Input
                      type="text"
                      title="First Name"
                      name="firstName"
                      required={`after:content-['*'] after:text-red-500`}
                      onChange={handleChange}
                      value={values.firstName ?? ""}
                      placeholder="FirstName"
                    />
                    <ErrorMessage name="firstName" render={renderError} />
                  </div>

                  <div className={"w-[50%]"}>
                    <Input
                      type="text"
                      title="Last Name"
                      onChange={handleChange}
                      name="lastName"
                      value={values.lastName ?? ""}
                      placeholder="LastName"
                    />
                  </div>
                </div>

                <div className="flex  items-center justify-between">
                  <div className="w-[50%]">
                    <div className="flex flex-col">
                      <label className="after:content-['*']  after:text-red-500 font-bold">
                        Gender
                      </label>
                      <Radio.Group
                        onChange={(e: RadioChangeEvent) => {
                          setFieldValue("gender", e.target.value);
                        }}
                        value={values.gender ?? ""}
                      >
                        <Radio value="Female">Female</Radio>
                        <Radio value="Male">Male</Radio>
                      </Radio.Group>
                    </div>
                  </div>

                  <div className="w-[50%] ">
                    <Input
                      type="text"
                      title="ID Card Number"
                      required={`after:content-['*'] after:text-red-500`}
                      value={values.citizenId ?? ""}
                      name="citizenId"
                      onChange={handleChange}
                      placeholder="ID Card Number"
                    />
                    <ErrorMessage name="citizenId" render={renderError} />
                  </div>
                </div>

                <div className="w-full">
                  <Textarea
                    value={values.address ?? ""}
                    required={`after:content-['*'] after:text-red-500`}
                    title="Address"
                    name="address"
                    onChange={handleChange}
                    placeholder="Address"
                  />

                  <ErrorMessage name="address" render={renderError} />
                </div>

                <div>
                  <Input
                    type="text"
                    title="Residential Address"
                    value={values.residentialAddress ?? ""}
                    name="residentialAddress"
                    onChange={handleChange}
                    placeholder="Residential Address"
                  />
                </div>

                <div className="flex items-center justify-between gap-2">
                  <div className=" w-[50%] ">
                    <Input
                      title="Date of Birth"
                      placeholder="Date of birth"
                      required={`after:content-['*'] after:text-red-500`}
                      type="date"
                      value={values.dateOfBirth ?? ""}
                      onChange={handleChange}
                      name="dateOfBirth"
                    />
                  </div>

                  <div className="w-[50%]">
                    <Input
                      title="Place of Birth"
                      type="text"
                      onChange={handleChange}
                      value={values.birthPlace ?? ""}
                      name="birthPlace"
                      placeholder="Place of Birth"
                    />
                    <ErrorMessage name="birthPlace" render={renderError} />
                  </div>
                </div>

                <div className="flex justify-between gap-2">
                  <div className="w-[50%]">
                    <Input
                      required={`after:content-['*'] after:text-red-500`}
                      title="Religion"
                      type="text"
                      value={values.religion ?? ""}
                      name="religion"
                      onChange={handleChange}
                      placeholder="Religion"
                    />
                    <ErrorMessage name="religion" render={renderError} />
                  </div>

                  <div className="w-[50%]">
                    <label className="font-bold after:content-['*'] after:text-red-500 ">
                      Status
                    </label>
                    <Select
                      options={status}
                      className="react-select-container"
                      classNamePrefix="react-select"
                      defaultValue={values.martialStatus ?? "Status"}
                      onChange={(option: any, actionMeta: any) => {
                        setFieldValue("martialStatus", option.value);
                      }}
                    />
                    <ErrorMessage name="martialStatus" render={renderError} />
                  </div>
                </div>

                <div className="my-7">
                  <button
                    type="submit"
                    className="cursor-pointer w-[130px] rounded bg-green-500 py-2 px-4 font-semibold  text-white transition duration-200 ease-in-out hover:bg-slate-400 "
                  >
                    Next
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default PersonStep;
