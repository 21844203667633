import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { IDataHRIS, IHRISStore, IPayloadAdd } from "@typed/HRIS/allHris";
import toast from "react-hot-toast";
import {
  getOrganizationAPI,
  getDetailOrganizationAPI,
  postOrganizationAPI,
  putOrganizationAPI,
  putActiveOrganizationAPI,
  putDeletedOrganizationAPI
} from "./OrganizationAPI";

export const getOrganizationRequest = createAsyncThunk(
  "hris-organization/getOrganizationRequest",
  async () => {
    const response = await getOrganizationAPI();

    return response;
  }
);

export const getDetailOrganizationRequest = createAsyncThunk(
  "hris-organization/getDetailOrganizationRequest",
  async (id: string) => {
    const response = await getDetailOrganizationAPI(id);

    return response;
  }
);

export const postOrganizationRequest = createAsyncThunk(
  "hris-organization/postOrganizationRequest",
  async (Data: object) => {
    const response = await postOrganizationAPI(Data);

    return response;
  }
);

export const putOrganizationRequest = createAsyncThunk(
  "hris-organization/putOrganizationRequest",
  async ({ id, Data }: { id: string; Data: object }) => {
    const response = await putOrganizationAPI({ id, Data });

    return response;
  }
);

export const putActiveOrganizationRequest = createAsyncThunk(
  "hris-organization/putActiveOrganizationRequest",
  async ({ id, isActive }: { id: string; isActive: boolean }) => {
    const response = await putActiveOrganizationAPI(id, isActive);
    return response;
  }
);

export const putDeleteOrganizationRequest = createAsyncThunk(
  "hris-organization/putDeleteOrganizationRequest",
  async ({ id, isDelete }: { id: string; isDelete: boolean }) => {
    const response = await putDeletedOrganizationAPI(id, isDelete);
    return response;
  }
);

const initialState: IHRISStore = {
  data: [],
  isLoading: false,
  dataDetail: {} as IDataHRIS,
  lastCreateData: {} as IDataHRIS,
  deletedRedirect: false
};

const OrganizationSlice = createSlice({
  name: "hris-organization",
  initialState: initialState,
  reducers: {
    resetOrganization: (state: { lastCreateData: IDataHRIS }) => {
      state.lastCreateData = {} as IDataHRIS;
    },
    deletedOrganization: (state: { deletedRedirect: boolean }) => {
      state.deletedRedirect = false;
    }
  },
  extraReducers(builder) {
    builder.addCase(getOrganizationRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getOrganizationRequest.fulfilled, (state, action) => {
      if (action.payload.code === 1000) {
        state.data = action.payload.data;
      }
      state.isLoading = false;
    });
    builder.addCase(getOrganizationRequest.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getDetailOrganizationRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getDetailOrganizationRequest.fulfilled, (state, action) => {
      if (action.payload.code === 1000) {
        state.dataDetail = action.payload.data;
      }
      state.isLoading = false;
    });
    builder.addCase(getDetailOrganizationRequest.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(postOrganizationRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(postOrganizationRequest.fulfilled, (state, action) => {
      if (action.payload.code === 1000) {
        toast.success("Success");
        state.lastCreateData = action.payload.data;
      }
      if (action.payload.code === 2004) {
        toast.error("Please enter an empty field");
      }
      state.isLoading = false;
    });
    builder.addCase(postOrganizationRequest.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(putOrganizationRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(putOrganizationRequest.fulfilled, (state, action) => {
      if (action.payload.code === 1000) {
        toast.success("Success");
        state.lastCreateData = action.payload.data;
      }
      state.isLoading = false;
    });
    builder.addCase(putOrganizationRequest.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(putActiveOrganizationRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(putActiveOrganizationRequest.fulfilled, (state, action) => {
      if (action.payload.code === 1000) {
        const stats = state.dataDetail.isActived;
        toast.success(stats ? "Inactive Success" : "Active Success");
        state.dataDetail.isActived = action.payload.data.isActived;
      }
      state.isLoading = false;
    });
    builder.addCase(putActiveOrganizationRequest.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(putDeleteOrganizationRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(putDeleteOrganizationRequest.fulfilled, (state, action) => {
      if (action.payload.code === 1000) {
        toast.success("Delete Success");
        state.deletedRedirect = true;
      }
      state.isLoading = false;
    });
    builder.addCase(putDeleteOrganizationRequest.rejected, (state) => {
      state.isLoading = false;
    });
  }
});

export default OrganizationSlice.reducer;
export const { resetOrganization, deletedOrganization } = OrganizationSlice.actions;
