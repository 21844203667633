import React, { useCallback, useEffect, useState } from "react";

import { dateToString } from "@helpers/utils";

import * as R from "ramda";

import { useAppDispatch, useAppSelector } from "@stores/store";
import { sendReviewComplainRequest } from "@stores/dailyapp/ComplainSlice";

import { User, IUser } from "@typed/dailyapp/user";
import { Order } from "@typed/dailyapp/order";
import { Complain, Media, PayloadReviewComplain } from "@typed/dailyapp/complain";

import { Button, Descriptions, List, Space, Modal, Input, Switch } from "antd";
import { Link, useParams } from "react-router-dom";
import ModalComplain from "@components/ModalComplain";
import { Toaster } from "react-hot-toast";

const columns = [
  {
    title: "No. Transaction",
    dataIndex: "trxNumber",
    key: "trxNumber"
  },
  {
    title: "Date",
    dataIndex: "createdAt",
    key: "createdAt"
  },
  {
    title: "Description",
    dataIndex: "type",
    key: "type"
  },
  {
    title: "Coin",
    dataIndex: "point",
    key: "point",
    render: (text: number, record: any) => (
      <div>
        {record.operator !== "DEBIT" && "-"}
        {text}
      </div>
    )
  },
  {
    title: "Coin Balance",
    dataIndex: "lastPoint",
    key: "lastPoint",
    render: (text: number, record: any) => (
      <div>{record.operator !== "DEBIT" ? text - record.point : text + record.point}</div>
    )
  },
  {
    title: "Action",
    key: "action",
    render: (text: string, record: any) => (
      <Space size="middle">
        <Link to={"/dailyapp/user-trx/" + record._id}>Detail</Link>
      </Space>
    )
  }
];

const SingleComplain: React.FC = () => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const { complain, authId } = useAppSelector((state) => {
    return {
      complain: state.dailyComplain.complains.find((x: Complain) => x._id === params.id),
      authId: state.auth.employeeData._id
    };
  });

  const [innerUser, setInnerUser] = useState<IUser | Record<string, never>>(complain?.userId || {});
  const [innerOrder, setInnerOrder] = useState<Order | Record<string, never>>(
    complain?.orderId || {}
  );
  const [message, setMessage] = useState<string>("");
  const [coin, setCoin] = useState<number>(0);
  const [isCompensate, setIsCompensate] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isResolveLoading, setIsResolveLoading] = useState<boolean>(false);

  useEffect(() => {
    setInnerUser(
      R.pick(["fullName", "email", "phone", "birthDate", "gender", "uid"], complain?.userId || {})
    );
    setInnerOrder(
      R.pick(["complainId", "code", "code", "totalAmount", "orderNumber"], complain?.orderId || {})
    );
  }, [complain]);

  console.log("innerUser", innerUser);

  const handleConfirmSolved = useCallback((d: PayloadReviewComplain) => {
    setIsResolveLoading(true);
    dispatch(sendReviewComplainRequest(d)).then(() => {
      //   setIsResolveLoading(false);
      //   setIsModalOpen(false);
      setIsResolveLoading((prev) => !prev);
      setIsModalOpen((prev) => !prev);
    });
  }, []);

  return (
    <div className="p-3 flex flex-col">
      <Toaster />
      <ModalComplain
        isModalOpen={isModalOpen}
        id={params.id}
        authId={authId}
        message={message}
        setMessage={setMessage}
        isResolveLoading={isResolveLoading}
        setIsModalOpen={setIsModalOpen}
        setIsResolveLoading={setIsResolveLoading}
        setIsCompensate={setIsCompensate}
        isCompensate={isCompensate}
        coin={coin}
        setCoin={setCoin}
        handleConfirmSolved={handleConfirmSolved}
      />
      <Descriptions
        bordered
        title={complain?.userId?.fullName}
        size="small"
        extra={
          complain?.reviewStatus !== "reviewed" && (
            <Button type="primary" onClick={() => setIsModalOpen(true)}>
              Change to Solved
            </Button>
          )
        }
        column={2}
      >
        <Descriptions.Item label="Date" span={2}>
          {dateToString(complain?.createdAt)}
        </Descriptions.Item>
        <Descriptions.Item label="User">
          <List size="small">
            <List.Item className="flex">
              <div className="flex flex-col justify-start">
                <div className="flex">
                  <div className="pr-2 font-bold">UID:</div>
                  {innerUser.uid}
                </div>
                <div className="flex">
                  <div className="pr-2 font-bold">Fullname:</div>
                  {innerUser.fullName}
                </div>
                <div className="flex">
                  <div className="pr-2 font-bold">Birhdate:</div>
                  {/* {innerUser.fullName} */}
                  {dateToString(innerUser.birthDate)}
                </div>

                <div className="flex">
                  <div className="pr-2 font-bold">Phone:</div>
                  {/* {"+" + innerUser.phone?.number || <h1 className="text-red-500">Empty</h1>} */}
                  {/* console.log(innerUser) */}
                </div>

                <div className="flex">
                  <div className="pr-2 font-bold">Email:</div>
                  {innerUser.email?.address || <h1 className="text-red-500">Empty</h1>}
                </div>

                <div className="flex">
                  <div className="pr-2 font-bold">Gender:</div>
                  {innerUser.gender}
                </div>
              </div>
            </List.Item>
          </List>
          {/* {innerUser &&
            Object?.keys(innerUser)?.map((item, i) => {
              return (
                <List key={i} size="small">
                  <List.Item
                    className="flex justify-start"
                    style={{ justifyContent: "flex-start" }}
                  >
                    <div className="pr-2 font-bold">:</div>
                    {item !== "phone" && <div>{(innerUser as any)[item] || ""}</div>}
                    {item === "phone" && <div>+{(innerUser as any)[item].number || ""}</div>}
                    {item === "phone" && <div>+{(innerUser as any)[item].verified || ""}</div>}
                    {item !== "email" && <div>{(innerUser as any)[item] || ""}</div>}
                    {item === "email" && <div>{(innerUser as any)[item].address || ""}</div>}
                  </List.Item>
                </List>
              );
            })} */}
        </Descriptions.Item>
        <Descriptions.Item label="Based On">
          {Object?.keys(innerOrder).map((item, i) => {
            return (
              <List key={i} size="small">
                <List.Item className="justify-start" style={{ justifyContent: "flex-start" }}>
                  <div className="pr-2 font-bold">{item}</div>
                  <div className="pr-2 font-bold">:</div>
                  <div>{(innerOrder as any)[item]}</div>
                </List.Item>
              </List>
            );
          })}
        </Descriptions.Item>
        <Descriptions.Item label="Content" span={2}>
          {complain?.content}
        </Descriptions.Item>
        <Descriptions.Item label="Status" span={2}>
          {complain?.reviewStatus}
        </Descriptions.Item>
      </Descriptions>
      <div className="mt-5 font-bold text-xl">Attachments</div>
      <div className="flex flex-row mt-5">
        {complain?.media.map((x: Media) => (
          <div key={x._id} className="w-80 overflow-hidden rounded-lg">
            <img className="w-60 h-60" src={x?.mediaUrl} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SingleComplain;
