import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
  getProductClickAPI,
  postProductClickAPI,
  putActiveProduct,
  putEndEvent,
  putProductClickAPI,
  putRedeemProduct,
  singleProductClickAPI
} from "./DailyclickAPI";
import { IProductDetail, IStoreDailyclick } from "@typed/Dailyclick";

import swal from "sweetalert";

export const getProductClickRequest = createAsyncThunk(
  "dailyclick/getProductClickRequest",

  async () => {
    const response = await getProductClickAPI();
    return response;
  }
);

export const postProductClickRequest = createAsyncThunk(
  "dailyclick/postProductClickRequest",
  async (data: any) => {
    const response = await postProductClickAPI(data);
    return response;
  }
);

export const putProductClickRequest = createAsyncThunk(
  "dailyclick/putProductClickRequest",
  async ({ id, data }: { id: string; data: any }) => {
    const response = await putProductClickAPI(id, data);
    return response;
  }
);

export const singleProductClickRequest = createAsyncThunk(
  "dailyclick/singleProductClickRequest",
  async (id: string) => {
    const response = await singleProductClickAPI(id);
    return response;
  }
);

export const putActiveProductRequest = createAsyncThunk(
  "dailyclick/putActiveProductRequest",
  async ({ id, isActive }: { id: string; isActive: boolean }) => {
    const response = await putActiveProduct(id, isActive);
    return response;
  }
);

export const putEndEventRequest = createAsyncThunk(
  "dailyclick/putEndEventRequest",
  async ({ id, isEndEvent }: { id: string; isEndEvent: boolean }) => {
    const response = await putEndEvent(id, isEndEvent);
    return response;
  }
);

export const putRedeemProductRequest = createAsyncThunk(
  "dailyclick/putRedeemProductRequest",
  async ({ id, isRedeemed }: { id: string; isRedeemed: boolean }) => {
    const response = await putRedeemProduct(id, isRedeemed);
    return response;
  }
);

const initialState: IStoreDailyclick = {
  isLoading: false,
  productClick: [],
  singleProductClick: {} as IProductDetail
};

const DailyclickSlice = createSlice({
  name: "dailyclick",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getProductClickRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getProductClickRequest.fulfilled, (state, action) => {
      action.payload.code === 1000 ? (state.productClick = action.payload.data) : null;
      state.isLoading = false;
    });
    builder.addCase(getProductClickRequest.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(postProductClickRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(postProductClickRequest.fulfilled, (state, action) => {
      action.payload.code === 1000
        ? swal("success", "Berhasil membuat product baru", "success")
        : swal("error", "Something went wrong", "error");
      state.isLoading = false;
    });
    builder.addCase(postProductClickRequest.rejected, (state) => {
      state.isLoading = false;
    });
    putProductClickRequest;
    builder.addCase(putProductClickRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(putProductClickRequest.fulfilled, (state, action) => {
      action.payload.code === 1000
        ? swal("success", "Berhasil edit product ", "success")
        : swal("error", "Something wont wrong", "error");
      state.isLoading = false;
    });
    builder.addCase(putProductClickRequest.rejected, (state) => {
      state.isLoading = false;
    });
    //
    builder.addCase(singleProductClickRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(singleProductClickRequest.fulfilled, (state, action) => {
      action.payload.code === 1000 ? (state.singleProductClick = action.payload.data) : null;
      state.isLoading = false;
    });
    builder.addCase(singleProductClickRequest.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(putActiveProductRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(putActiveProductRequest.fulfilled, (state, action) => {
      action.payload.code === 1000
        ? (state.productClick = state.productClick =
            state.productClick.map((item) => ({
              ...item,
              isActive:
                item._id === action.payload?.data?._id
                  ? action.payload?.data?.isActive
                  : item.isActive
            })))
        : swal("error", "Something went wrong", "error");

      state.isLoading = false;
    });
    builder.addCase(putActiveProductRequest.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(putEndEventRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(putEndEventRequest.fulfilled, (state, action) => {
      action.payload.code === 1000
        ? (state.productClick = state.productClick =
            state.productClick.map((item) => ({
              ...item,
              isEndEvent:
                item._id === action.payload?.data?._id
                  ? action.payload?.data?.isEndEvent
                  : item.isEndEvent
            })))
        : swal("error", "Something went wrong", "error");

      state.isLoading = false;
    });
    builder.addCase(putEndEventRequest.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(putRedeemProductRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(putRedeemProductRequest.fulfilled, (state, action) => {
      action.payload.code === 1000
        ? (state.productClick = state.productClick =
            state.productClick.map((item) => ({
              ...item,
              isRedeemed:
                item._id === action.payload?.data?._id
                  ? action.payload?.data?.isRedeemed
                  : item.isRedeemed
            })))
        : swal("error", "Something went wrong", "error");

      state.isLoading = false;
    });
    builder.addCase(putRedeemProductRequest.rejected, (state) => {
      state.isLoading = false;
    });
  }
});

export default DailyclickSlice.reducer;
