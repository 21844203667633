import CardDetail from "@components/CardDetail";
import { dateToString, ratingAverage } from "@helpers/utils";
import { getOutletsRequest } from "@stores/dailyapp/dailyOutletSlice";
import { getReviewsRequest } from "@stores/dailyapp/ReviewSlice";
import { useAppDispatch, useAppSelector } from "@stores/store";
import { Review } from "@typed/dailyapp/review";
import { User } from "@typed/dailyapp/user";
import { Button, Card, Col, Descriptions, Popover, Row, Space, Statistic, Table } from "antd";
import React, { useEffect } from "react";
import { IoStar } from "react-icons/io5";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";

const columns = [
  {
    name: "Date",
    selector: (row: Review) => row.createdAt,
    id: "date",
    cell: (row: Review) => <div>{dateToString(row.createdAt)}</div>
  },
  {
    name: "User",
    selector: (row: Review) => row.userId.fullName,
    id: "userId",
    cell: (row: Review) => (
      <h1 className="font-medium uppercase bold">{row.userId.fullName || ""}</h1>
    )
  },
  {
    name: "Content",
    selector: (row: Review) => row.content,
    id: "content"
  },
  {
    name: "Rate",
    selector: (row: Review) => row.ratingLevel,
    id: "ratingLevel",
    sortable: true,
    width: "100px",
    sortFunction: (a: Review, b: Review) => a.ratingLevel - b.ratingLevel
  },
  {
    name: "Based On",
    selector: (row: Review) => row.orderId,
    id: "orderId",
    cell: (record: Review) => {
      const content = () => {
        return (
          <div className=" w-40">
            <div className="flex flex-row">
              <div className="pr-3 font-bold">Spend</div>
              <div>IDR {record.orderId?.totalAmount}</div>
            </div>
          </div>
        );
      };
      return (
        <Popover content={content} title={record.orderId?.orderNumber} trigger="click">
          <Button>{record.orderId?.orderNumber}</Button>
        </Popover>
      );
    }
  }
];

const Reviews = () => {
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector((state) => state.dailyReview);
  const reviews = useAppSelector((state) =>
    state.dailyReview.reviews.filter((x) => x.userId).map((x) => ({ ...x, key: x._id }))
  );

  useEffect(() => {
    dispatch(getReviewsRequest());
    dispatch(getOutletsRequest());
  }, []);

  return (
    <div>
      <div className="mb-4">
        <Row gutter={16}>
          {isLoading ? (
            <>
              <Col span={4}>
                <CardDetail />
              </Col>
              <Col span={4}>
                <CardDetail />
              </Col>
            </>
          ) : (
            <>
              <Col span={4}>
                <Card>
                  <Statistic
                    title="Review (avg)"
                    value={ratingAverage(reviews)}
                    prefix={<IoStar />}
                  />
                </Card>
              </Col>
              <Col span={4}>
                <Card>
                  <Statistic title="Total Reviews" value={reviews.length} />
                </Card>
              </Col>
            </>
          )}
        </Row>
      </div>
      {/* <Table dataSource={reviews} columns={columns} /> */}
      <DataTable data={reviews} columns={columns} pagination persistTableHead />
    </div>
  );
};

export default Reviews;
