import axios from "axios";
import { RefreshToken } from "typed/global";
import API from "../constants/API";
import { updateToken } from "@stores/TokenSlice";
import { Store } from "redux";

let store;

const setup = (_store: Store) => {
  store = _store;
  const { dispatch, getState } = store;

  API.HTTP.interceptors.request.use(
    (config) => {
      const { token } = getState();

      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${token.token}`
        }
      };
    },
    (error) => Promise.reject(error)
  );

  API.HTTP.interceptors.response.use(
    (res) => res,
    async (err) => {
      console.log(err.response.status);
      console.log(err.response.config.url);
      const { secure } = getState();
      const originalConfig = err.config;
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          const newToken = await refreshAccessToken(secure.auth.login_data.refreshToken);
          // console.log("New Token", newToken.token);
          // console.log("New Refresh Token", newToken.refreshToken);
          dispatch(
            updateToken({
              token: newToken.token,
              refreshToken: newToken.refreshToken
            })
          );
          return API.HTTP({
            ...originalConfig,
            headers: {
              Authorization: `Bearer ${newToken.token}`
            }
          });
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
      return Promise.reject(err);
    }
  );
};

const refreshAccessToken = async (refreshToken: RefreshToken) => {
  const res = await axios.post("", {
    refreshToken: refreshToken
  });
  return res.data.data;
};

export default { setup };
