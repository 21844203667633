import React, { useEffect, useState } from "react";
import { IDataHRIS, IPayloadAdd } from "@typed/HRIS/allHris";
import InputForm from "@components/HRIS/InputForm";
import { useAppDispatch, useAppSelector } from "@stores/store";
import * as R from "ramda";
import { Toaster } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { postPtkpRequest, putPtkpRequest, resetPtkp } from "@stores/HRIS/PTKP/PtkpSlice";
import swal from "sweetalert";

const AddPtkp = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { data, isLoading, lastCreateData } = useAppSelector((state) => state.hrisPtkp);

  const [addPtkp, setAddptkp] = useState<IPayloadAdd>({} as IPayloadAdd);
  const { name, description } = addPtkp;

  useEffect(() => {
    if (id) {
      data.filter(
        (item: IDataHRIS) =>
          item._id === id &&
          setAddptkp({ ...addPtkp, name: item.name, description: item.description })
      );
    }
  }, [id]);

  // useEffect(() => {
  //   if (!R.isEmpty(lastCreateData)) {
  //     dispatch(resetPtkp());
  //     setTimeout(() => {
  //       navigate("/hris/ptkp");
  //     }, 600);
  //   }
  // }, [lastCreateData]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setAddptkp({ ...addPtkp, [name]: value });
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    const Data = {
      name,
      description
    };
    if (id) {
      dispatch(putPtkpRequest({ id, Data })).then(({ payload }) => {
        if (payload.code === 1000) {
          navigate("/hris/ptkp");
          swal("Success!", "You PTKP is update!", "success");
        }
      });
    } else {
      dispatch(postPtkpRequest(Data)).then(({ payload }) => {
        if (payload.code === 1000) {
          navigate("/hris/ptkp");
          swal("Success!", "You PTKP is created!", "success");
        }
      });
    }
  };

  return (
    <>
      <Toaster />
      <InputForm
        isLoading={isLoading}
        id={id}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        value={addPtkp}
        title="PTKP"
      />
    </>
  );
};

export default AddPtkp;
