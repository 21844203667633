import React, { useEffect, useState } from "react";
import { DatePicker, Tabs } from "antd";
import type { DatePickerProps } from "antd";
import { useAppDispatch, useAppSelector } from "@stores/store";
import { filteringCSV, getCoinPaymentRequest } from "@stores/dailycoin/dailyCoinSlice";
import { Link } from "react-router-dom";
import { filterByKeyword, nf } from "@helpers/utils";
import moment from "moment";
import {
  getCashbackRegisRequest,
  getFinanceRequest,
  getSummariesRequest,
  getTopupEmployeRequest,
  setEndDate,
  setStartDate
} from "@stores/dailycoin/ReportingSlice";
import TopupEmployee from "./TopupEmploye";

import Loading from "@components/loading";
import PaymentOrigin from "./PaymentOrigin";
import CutOffOrigin from "./CutOffOrigin";
import MyTable from "@components/Table/MyTable";
import SummaryTransaction from "./SummaryTrx";
import SummaryMutasi from "./SummaryMutasi";

const Seatlement: React.FC = () => {
  const dispatch = useAppDispatch();

  const { isLoading } = useAppSelector((state) => state.dailyCoins);
  const coinOutlets = useAppSelector((state) =>
    state.dailyCoins.coinOutlet?.map((item) => ({ ...item, key: item._id }))
  );
  const [dataCoinOutlet, setDataCoinOutlet] = useState([]);
  const [keyword, setKeyword] = useState("");

  const startDate = useAppSelector((state) => state.dailyReport.startDate);
  const endDate = useAppSelector((state) => state.dailyReport.endDate);

  useEffect(() => {
    dispatch(filteringCSV());
    setDataCoinOutlet(filterByKeyword(coinOutlets, keyword));
  }, [isLoading]);

  useEffect(() => {
    dispatch(
      getCoinPaymentRequest({
        startDate: moment(startDate).format("YYYY-MM-DD"),
        endDate: moment(endDate).format("YYYY-MM-DD")
      })
    );
    dispatch(filteringCSV());
  }, []);

  const filterOutletTrx = () => {
    dispatch(
      getCoinPaymentRequest({
        startDate: startDate,
        endDate: endDate
      })
    );
    dispatch(filteringCSV());
  };

  const handleStartDate: DatePickerProps["onChange"] = (date: any, dateString: any) => {
    dispatch(setStartDate(dateString));
  };

  const handleEndDate: DatePickerProps["onChange"] = (date: any, dateString: any) => {
    dispatch(setEndDate(dateString));
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <div className="flex justify-between items-center">
        <div className=" flex items-center gap-4">
          <h1 className="m-0">Filter </h1>

          <DatePicker
            className="h-9"
            name="startDate"
            onChange={handleStartDate}
            placeholder="Start date"
          />

          <DatePicker
            className="h-9"
            name="endDate"
            onChange={handleEndDate}
            placeholder="End date"
          />

          <button
            onClick={filterOutletTrx}
            className="flex text-medium items-center gap-1 text-center outline-none h-9 w-[10rem] rounded bg-blue-500 hover:bg-blue-400 text-white justify-center"
          >
            Submit
          </button>
        </div>
      </div>

      <div className="mt-5">
        <MyTable
          fileName="coin-outlet"
          dataSource={dataCoinOutlet}
          exportable
          select={[
            { label: "Periode", value: "periode" },
            { label: "Outlet", value: "outlet" },
            { label: "Dailycoin", value: "dailycoin" },
            { label: "Dailycoin Currency", value: "dailycoinCurrency" }
          ]}
          action={(row: any) => (
            <Link
              className="bg-blue-500 hover:bg-blue-400 font-medium text-white hover:text-white p-2 rounded-md"
              to={`/daily-coin/review/${row._id}`}
            >
              Review
            </Link>
          )}
        />
      </div>
    </>
  );
};

const DashboardFinance = () => {
  const dispatch = useAppDispatch();
  const LoadingSetlement = useAppSelector((state) => state.dailyCoins.isLoading);
  const { isLoading } = useAppSelector((state) => state.dailyReport);
  const { startDate, endDate } = useAppSelector((state) => state.dailyReport);

  useEffect(() => {
    dispatch(
      getCoinPaymentRequest({
        startDate: moment(startDate).format("YYYY-MM-DD"),
        endDate: moment(endDate).format("YYYY-MM-DD")
      })
    );
    dispatch(
      getFinanceRequest({
        startDate: moment(startDate).format("YYYY-MM-DD"),
        endDate: moment(endDate).format("YYYY-MM-DD")
      })
    );
    dispatch(
      getTopupEmployeRequest({
        startDate: moment(startDate).format("YYYY-MM-DD"),
        endDate: moment(endDate).format("YYYY-MM-DD")
      })
    );
    dispatch(
      getCashbackRegisRequest({
        startDate: moment(startDate).format("YYYY-MM-DD"),
        endDate: moment(endDate).format("YYYY-MM-DD")
      })
    );

    dispatch(getSummariesRequest());
  }, []);

  return (
    <Tabs
      defaultActiveKey="1"
      items={[
        {
          label: `Settlement`,
          key: "1",
          children: <Seatlement />
        },
        {
          label: `Summary Transaction`,
          key: "2",
          children: <SummaryTransaction />
        },
        {
          label: `Summary Mutasi`,
          key: "3",
          children: <SummaryMutasi />
        },
        {
          label: `Employee Topup`,
          key: "4",
          children: <TopupEmployee />
        },
        {
          label: `Payment Origin`,
          key: "5",
          children: <PaymentOrigin />
        },
        {
          label: `Cut-off Origin`,
          key: "6",
          children: <CutOffOrigin />
        }
      ]}
    />
  );
};

export default DashboardFinance;
