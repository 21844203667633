import { getOutletDetailRequest } from "@stores/dailyojol/OutletSlice";
import { useAppDispatch, useAppSelector } from "@stores/store";
import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Descriptions } from "antd";
import { dateToString } from "@helpers/utils";

const detailOutlet = () => {
  const { detailOutlet, loading } = useAppSelector((state) => state.dailyojolOutlet);

  const dispatch = useAppDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getOutletDetailRequest(id));
  }, []);
  return (
    <>
      {loading ? (
        <div className="flex items-center justify-center">
          <div>
            <div
              className="spinner-border animate-spin inline-block w-12 h-12 border-4 rounded-full"
              role="status"
            >
              <span className="visually-hidden"></span>
            </div>
          </div>
        </div>
      ) : (
        <>
          <Descriptions className="mb-3" bordered title={detailOutlet?.name} size="small">
            <Descriptions.Item label=" Code">
              {detailOutlet?.code || <div className="text-red-500">Empty</div>}
            </Descriptions.Item>
            <Descriptions.Item label="Lat">{detailOutlet?.lat || "Empty"}</Descriptions.Item>
            <Descriptions.Item label="Long">{detailOutlet?.long || "Empty"}</Descriptions.Item>
            <Descriptions.Item label="Create Outlet">
              {dateToString(detailOutlet?.createdAt)}
            </Descriptions.Item>
            <Descriptions.Item label="Kitchen">
              {detailOutlet?.kitchen || <div className="text-red-500">Empty</div>}
            </Descriptions.Item>
            <Descriptions.Item label="Region">
              {detailOutlet?.region || <div className="text-red-500">Empty</div>}
            </Descriptions.Item>
            <Descriptions.Item label="Outlet ID">{detailOutlet?._id || "Empty"}</Descriptions.Item>
            <Descriptions.Item label="Use">
              {detailOutlet.usedAt?.map((item, idx) => <div key={idx}>{item}</div>) || (
                <div className="text-red-500">Empty</div>
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Address">
              {detailOutlet?.address || <div className="text-red-500">Empty</div>}
            </Descriptions.Item>
          </Descriptions>
          <Link
            className="py-2 px-6 mt-3 bg-blue-500 hover:bg-blue-600 hover:text-white rounded hover:shadow-lg text-white font-semibold"
            to={`/dailyojol/outlet/edit-outlet/${id}`}
          >
            Edit
          </Link>
        </>
      )}
    </>
  );
};

export default detailOutlet;
// function item(item: any, idx: any): React.ReactNode {
//     throw new Error("Function not implemented.");
// }
