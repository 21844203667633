import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllTransactionRequest } from "@stores/dailyapp/TransactionSlice";
import { IDataDetailRedem, IRedemStore } from "@typed/dailojol/Redem";
import { getDetailOjolRedemAPI, getOjolRedemAPI } from "./RedemAPI";

export const getOjolRedemRequest = createAsyncThunk(
  "dailyojol-redem-getOjolRedemRequest",
  async () => {
    const response = await getOjolRedemAPI();

    return response.data;
  }
);

export const getDetailOjolRedemRequest = createAsyncThunk(
  "dailyojol-redem-getDetailOjolRedemRequest",
  async (id: string) => {
    const response = await getDetailOjolRedemAPI(id);

    return response;
  }
);

const initialState: IRedemStore = {
  data: [],
  loading: false,
  RegionDetail: {} as IDataDetailRedem
};

const RedemOjolSlice = createSlice({
  name: "dailyojol-redem",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getOjolRedemRequest.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getOjolRedemRequest.fulfilled, (state, action) => {
      if (action.payload.code === 1000) {
        state.data = action.payload.data;
      }
      state.loading = false;
    });
    builder.addCase(getOjolRedemRequest.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getDetailOjolRedemRequest.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getDetailOjolRedemRequest.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.code === 1000) {
        state.RegionDetail = action.payload.data;
      }
    });
    builder.addCase(getDetailOjolRedemRequest.rejected, (state) => {
      state.loading = false;
    });
  }
});

export default RedemOjolSlice.reducer;
