import React, { useEffect, useRef } from "react";
import { Table } from "antd";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "@stores/store";
import { getReportPaymentRequest } from "@stores/dailycoin/ReportingSlice";
import { nf } from "@helpers/utils";

import Loading from "@components/loading";

import DataTable from "react-data-table-component";

const columns = [
  {
    name: "Date",
    selector: (row: any) => row._id,
    cell: (row: any) => <div>{row._id}</div>
  },
  {
    name: "Transaction",
    selector: (row: any) => row.transaction,
    cell: (row: any) => <div>{nf.format(row.transaction)}</div>
  },
  {
    name: "Payment",
    selector: (row: any) => row.payment,
    cell: (row: any) => <div>{nf.format(row.payment)}</div>
  }
];

interface IProps {
  startDate: string;
  endDate: string;
}

const PaymentReport: React.FC<IProps> = ({ startDate, endDate }) => {
  const { isLoading } = useAppSelector((state) => state.dailyReport);

  const reportPayment = useAppSelector((state) =>
    state.dailyReport.reportPayment.map((i) => ({ ...i, key: i._id }))
  );

  if (isLoading) {
    return <Loading />;
  }
  return (
    <>
      <div>
        <div>
          {/* <Table loading={isLoading} dataSource={reportPayment} columns={columns} /> */}
          <DataTable data={reportPayment} columns={columns} pagination />
        </div>
      </div>
    </>
  );
};

export default PaymentReport;
